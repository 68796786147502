import React, { useEffect, useState } from 'react';
import data from "../../JsonData/data.json";
import SweetAlert from 'react-bootstrap-sweetalert'; 
const PipelinePermissions = (props) => {
    const [alert,setAlert] = useState(false);
    const [createUserData,setCreateUserData] = useState([]);
    const [renderState,setRenderState] =  useState(false);
    const sectiondata = props?.sectiondata;//total json
    const copiedJson = sectiondata;
   
    const toggleData = data?.roledata[0].toggles;//toggle data
    const roleVal = props?.roleName;//selected role name
    const [pipelinePermissions, setPipelinePermissions] = useState(props?.userData);

 

    
    // const sectionKeys = Object.keys(sectionData);
       
    const columns = []; 
    let column,column2;

    const trimString = (str) => {
      
        return str?.replace(/\s+/g, '').trim();
    };
   

    const renderElements = () => {
                for (let i = 0; i < props?.pipelineMenus?.length; i += 8) {
                    column = props?.pipelineMenus.slice(i, i + 8).map((element, index) => (
                        <div key={index}>
                        <div className="con">
                            {element.menu}
                        </div>
                        {element.submenus && element.submenus.length > 0 && (
                            
                            element.submenus.map((submenu, submenuIndex) => (
                                 <div key={submenuIndex} className="con sub">
                                     {submenu.menu}
                                 </div>
                             ))
                        
                     )}</div>
                                    
                    ));
                
                    column2 = props?.pipelineMenus.slice(i, i + 8).map((element, index) => (
                     
                       <div key={index}><div className="con">
                            {roleVal !== "" &&
                                toggleData?.map((toggle,toggleIndex) => {
                                    const founduserDataPermissions = props.userData && props.userData.find(rp => rp.route === element.route);
                                    const checkBool = (founduserDataPermissions && toggle.name in founduserDataPermissions) ? founduserDataPermissions[toggle.name] :false;
                                   
                                    return(
                                        <div className="column" key={toggleIndex}> 
                                            {element[toggle.name] &&<label className="toggle" >
                                                <input id={`${trimString(element?.menu)}-${toggle.name}-${toggleIndex}`} className="toggle-checkbox"
                                                    type="checkbox" checked = {checkBool}  onChange={(e) => handleToggle(e, element.route)} name={toggle.name+","+element.menu+","+roleVal+","+checkBool}/>
                                                <div className="toggle-switch"></div>

                                                </label>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>   
                        {element.submenus && element.submenus.length > 0 && (
                            element.submenus.map((submenu, submenuIndex) => (
                                <div key={submenuIndex} className="con">
                                    {roleVal !== "" &&
                                toggleData?.map((toggle,toggleIndex) => {
                                    const founduserDataPermissions = props?.userData && props?.userData?.find(rp => rp.route === element.route);
                                    const founduserDatasubPermissions = founduserDataPermissions && founduserDataPermissions?.submenus && founduserDataPermissions?.submenus?.find(rp => rp.menu === submenu.menu);
                                    const checkBool = (founduserDatasubPermissions && toggle.name in founduserDatasubPermissions) ? founduserDatasubPermissions[toggle.name] : false;
                                    return(
                                        submenu[toggle.name] && <div className="column" key={toggleIndex}> 
                                            <label className="toggle" key={toggleIndex}>
                                            <input id={`${trimString(submenu?.menu)}-${toggle.name}-${toggleIndex}`} className="toggle-checkbox"
                                                type="checkbox" checked={checkBool} onChange={(e) => handlesubToggle(e, element.route, submenu.menu)} name={toggle.name+","+submenu.menu+","+roleVal+","+checkBool}/>
                                            <div className="toggle-switch"></div>
                                            </label>
                                        </div>
                                    )
                                })
                            }
                                </div>))
                        )}
                        </div>       
                          
                    ));
                    
                    columns.push(
                        <div className="col-md-12" key={i}>
                            <div className="check_group">
                                <div className="left">
                                    <div className="hed"></div>
                                        {column}
                                        {/* <div className="con sub">Sub menu</div>
                                        <div className="con sub_sub">Sub sub menu</div> */}
                                    </div>
                                    <div className="right">
                                        <div className="hed">
                                            <div className="column">EDIT</div>
                                            <div className="column">VIEW</div>
                                            {/* <div className="column">DELETE</div> */}
                                        </div> 
                                        {column2}
                                    </div>
                                        
                                    
                                    
                
                                </div>
                            </div>
                            
                        
                    );
                
                }
               
        
        
   
        return columns
    };

    const handleToggle = (e, route) => {
        setRenderState(!renderState);
        const delimiter = ",";
        const str = e.target.name;
        const toggleVal = str.split(delimiter);
        const toggName = toggleVal[0];
        const toggPerm = toggleVal[1];
        const toggRole = toggleVal[2];
        const toggSec = toggleVal[3];
        const toggswitch = toggleVal[4];
        // console.log(typeof(toggswitch));
        let tempData = JSON.parse(JSON.stringify(props?.userData));
        tempData.map((data, dataIndex) => {
            if (data?.route === route) {
                let checkedValue = e.target.checked
                data[toggName] = e.target.checked;
                if(toggName == "view" && checkedValue == false){
                    data["edit"] = checkedValue  
                }
            }
        });
console.log('tempData',tempData);
setPipelinePermissions(tempData)
        props?.childUserData(tempData);
    }
    const handlesubToggle = (e, route, submenuId) => {
        setRenderState(!renderState);
        const delimiter = ",";
        const str = e.target.name;
        const toggleVal = str.split(delimiter);
        const toggName = toggleVal[0];
        const toggPerm = toggleVal[1];
        const toggRole = toggleVal[2];
        const toggSec = toggleVal[3];
        const toggswitch = toggleVal[4];
         let tempData = JSON.parse(JSON.stringify(props?.userData));
         tempData = tempData.map((data, dataIndex) => {
            if (data?.route === route) {
                if (data?.submenus) {
                    const submenuIndex = data.submenus.findIndex(rp => rp.menu === submenuId);
                    let checkedValue = e.target.checked
                    if (submenuIndex !== -1) { 
                        let updatedSubmenu = { ...data.submenus[submenuIndex], [toggName]: e.target.checked };
                        if(toggName == "view" && checkedValue == false){
                            updatedSubmenu["edit"] = checkedValue  
                        }
                        data.submenus[submenuIndex] = updatedSubmenu;
                    }
                }
            }
            return data; 
          });
          console.log('tempData2',tempData);
          setPipelinePermissions(tempData)
         props?.childUserData(tempData);
    }

    const handleToggRoleEmpty = (e) => {
        if(roleVal === ''){
            setAlert(!alert);
           
        }
    }

    const handleAlertClose = () => {
        setAlert(!alert);
    }


    useEffect(() => {
       
        renderElements()
                
    },[renderState])

    

    return(
        <div className="section">
           
            <div className='row'>
                <h3>Pipeline Menus</h3>
                {renderElements()}
            </div>
            {alert && <SweetAlert
            warning
            title="Select Role is Empty"
            onConfirm={handleAlertClose}
            >
            {/* Display your validation error message */}
            Please select role 
            </SweetAlert>}
        </div>
        
    );
};

export default PipelinePermissions;