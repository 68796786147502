import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import heic2any from "heic2any";

const FileViewer = (props) => {
  const [convertedFile, setConvertedFile] = useState(null);
  let typeFromUrl = ''
console.log('props',props)
const inferFileTypeFromUrl = (url) => {
  if (!url) return "";
  const parts = url.split(".");
  return parts.length > 1 ? parts.pop().toLowerCase() : "";
};
typeFromUrl = props?.type?.toLowerCase() || inferFileTypeFromUrl(props.source);
  useEffect(() => {
    // Handle HEIC file conversion
    let type = props?.type?.toLowerCase()
    if (typeFromUrl === "heic" || typeFromUrl === "HEIC" || typeFromUrl === "image/heic"  ) {
      fetch(props.source)
        .then((response) => response.blob())
        .then((blob) => heic2any({ blob, toType: "image/jpeg" }))
        .then((convertedBlob) => {
          const url = URL.createObjectURL(convertedBlob);
          setConvertedFile(url);
        })
        .catch((error) => console.error("Error converting HEIC file:", error));
    } else {
      setConvertedFile(props.source); // Use the source directly if not HEIC
    }
  }, [props.source, props.type]);

  const docs = [
    { uri: convertedFile, fileType: (typeFromUrl === "heic" || typeFromUrl === "HEIC" || typeFromUrl === "image/heic" ) ? "jpeg" : props.type },
  ];

  return (
    <>
      <div
        className="modal popup new_popup fade pdf_doc show"
        id="pdfdoc"
        tabIndex="-1"
        aria-labelledby="pdfdoc_achLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {props.name}
              </h5>
              <button
                id="fileViewClose"
                type="button"
                className="pop_close"
                onClick={(e) => props.close(false)}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-symbols-outlined">close</span>
              </button>
            </div>
            <div
              className="modal-body pdf-popup"
              style={{ height: "100%", overflowY: "auto" }}
            >
              {convertedFile ? (
                <DocViewer
                  style={{ width: "100%", height: "100%" }}
                  pluginRenderers={DocViewerRenderers}
                  documents={docs}
                />
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileViewer;
