import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate,useLocation } from 'react-router-dom';
import { getTermSheets ,deleteTermSheetsData} from "./../utils/reducer";
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';   
import PopupMessage from './PopupMessage.js';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import HomeFooter from './HomeFooter';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';


const TermSheetManager = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteConfirmation,setDeleteConfirmation] = useState(false);
  const [alltermsheets, setAlltermsheets] = useState([]);
  const loading = useSelector((state) => state.loaderVisible);
  const [deleteData , setDeleteData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalcount,setTotalCount] = useState(null);
  const [currentPage,setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  let userData = getUserDataFromLocalStorage();
  const [pipelinePermissions, setPipelinePermissions] = useState();
  const [editPermissions, setEditPermissions] = useState( );
  const location = useLocation();

  useEffect(() => {
    if(location.pathname){
        const route = location.pathname;
        const cleanRoute = route.slice(1);
        let permission = userData?.securityInfo?.permission
       
        let checkedPermission =  hasViewPermission(cleanRoute, permission) 
          console.log('viewPermission',location.pathname,checkedPermission); 
          setPipelinePermissions(checkedPermission) 
        //   if(!checkedPermission){
        //     navigate('/pipeline')
        //   }
        }

        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.permission
           
            let editPermission =  hasEditPermission(cleanRoute, permission) 
              console.log('editPermission',location.pathname,editPermission); 
              setEditPermissions(editPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }
        //setViewStatus()
  }, []);

  useEffect(() => {
    //dispatch(showLoader());
    getSheets();
  }, [currentPage,perPage]);

  useEffect(() => {
    console.log("all term sheets ",alltermsheets)
  },[alltermsheets])

  const getSheets = () => { 
    dispatch(showLoader());
    getTermSheets(currentPage,perPage).then(function (response) {
        if (response?.result) {
            setAlltermsheets(response?.result);
            console.log('response---->',response?.result);
            setTotalCount(response?.result?.totalCount);
            const updatedTermSheet = response.result.map((sheet) => ({
              "label": sheet?.termSheet,
              "value": sheet?.termSheet,
            }));
    
            console.log('updatedTermSheet', updatedTermSheet);
    
            // Use a Set to ensure unique values
            // const uniqueTermSheets = new Set([...termSheet, ...updatedTermSheet]);
    
            // Convert the Set back to an array
            // const uniqueTermSheetsArray = Array.from(uniqueTermSheets);
    
            // Update the state with unique values
            // setTermSheets(uniqueTermSheetsArray);
          }
          dispatch(hideLoader());
    }).catch((err) => {
        dispatch(hideLoader());
        console.error.bind("my account error", err);
    })
  }

  const handlePerPageChange = (newperPage,page) => {
    setPerPage(newperPage);
    setCurrentPage(page); // Reset to first page
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

const handleDelete = ()=>{
    console.log('termSheetsData',deleteData);
    setShowConfirmation(false);
    dispatch(showLoader());
    deleteTermSheetsData(deleteData).then(function (response) {
      console.log('response delete',response?.result);
      if(response.result === 'deleted successfully'){
        getSheets();
        setDeleteConfirmation(true);
      }
        dispatch(hideLoader());
      }).catch((err) => {
        dispatch(hideLoader());
        console.error.bind("my account error", err);
      })
}

  const editSheet = (data) => {
    console.log('data',data)
    navigate('/termsheet', { state: { data,selectedsheet:data ,termId : data?.templateid,mode : "Edit"} });
  };

  const deleteSheet = (data) => {
    setShowConfirmation(true);
    setDeleteData(data?.templateid);
    console.log('data',data);
  };

  const handleSearchChange = (e) => {
    console.log('serach term',e.target.value);
    setSearchTerm(e.target.value);
  };

  const filteredTermsheets = alltermsheets?.data?.filter((sheet) =>
    sheet?.sheetName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
    sheet?.programType?.toLowerCase().includes(searchTerm?.toLowerCase())
  ); 

  const columns = [
    {
      name: 'TEMPLATE NAME',
      selector: (row) => row?.sheetName,
      sortable: true,
      sortFunction: (a, b) => {
    
        const nameA = a?.sheetName?.toLowerCase();
        const nameB = b?.sheetName?.toLowerCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      minWidth: '60%',
      maxWidth: '60%',
    },
    {
      name: 'PROGRAM TYPE',
      selector: (row) => row?.programType,
      sortable: true,
      sortFunction: (a, b) => {
    
        const nameA = a?.programType?.toLowerCase();
        const nameB = b?.programType?.toLowerCase();
  
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      },
      minWidth: '33.5%',
      maxWidth: '33.5%',
    },

    {
        name: 'ACTIONS',
        cell: (row,index) => (<>
         <div className="btn-group">
          <button id={`edit-termSheetManager-${index}}`} type="button" onClick={(e) => { editSheet(row); }} className="btn btn-outline-secondary">
            <span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span>edit</span>
          </button></div>
          {/* <a href="#" onClick={(e) => { e.preventDefault(); deleteSheet(row); }} className="icon_btn edit">
          <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container"><span className="tooltip">Delete</span>delete</span>
        </a> */}
        </>
        ),
        minWidth: '6.5%',
        maxWidth: '6.5%',
      },
  ];

  const customStyles = {
    //   headRow: {
	// 	style: {
    //         fontWeight: 'bold',
    //         backgroundColor: '#E9F0E6',
	// 	},
	// },
    headCells: {
		style: {
            fontWeight: 'bold',
            backgroundColor: '#E9F0E6',
		
		},
	},
        table: {
      style: {
        border: '1px solid #CCD8F6', 
      },
    },
  };

  const closePopup = () => {
    setShowConfirmation(false);
  };

  const closePopup2 = () => {
    setDeleteConfirmation(false)
  };

  return (
    <div id="layout-wrapper">
      {loading ? <Loader /> : null}

      {deleteConfirmation && (
                <PopupMessage
                    type={'success'}
                    message={'Deleted Successfully'}
                    onClose={closePopup2}
                />
            )}

      {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDelete}
                onCancel={closePopup}
                focusCancel={true}
            >
                    <p>Do you really want to delete this term sheet?</p>
                {/* Additional content can go here */}
            </SweetAlert>}
      <MainHeader />
      <SideMenu />

      <div className="main-content">
        <div className="page-content user_management">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Underwriting Term Sheet Manager</h4>
                </div>
                <div className="card" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                  <div className="card-body">
                    <div>
                      {/* <a href="#" onClick={(e) => { e.preventDefault(); navigate('/termsheet')}} className="create_btn">Create Term Sheet <span className="material-symbols-outlined icon"> add </span></a> */}
                      <div className="right">
                        {/* <div className='form-group search_block'>
                          <input type="text" className='form-control' placeholder='Search Term Sheet' value={searchTerm} onChange={handleSearchChange}/>
                          <button className="material-symbols-outlined btn">search</button>
                        </div> */}
                        
                      </div>
                    </div>
                    <div>
                      <div >
                      {filteredTermsheets?.length > 0 &&
                      <DataTable className='termsheetmanager_table under_writing_termsheet'
                        columns={columns}
                        data={filteredTermsheets}
                        pagination
                        paginationServer
                        paginationTotalRows={(!searchTerm) ? totalcount : filteredTermsheets?.length}
                        customStyles={customStyles}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[15, 30, 45, 60,75,90]}
                        paginationComponentOptions={{
                          rowsPerPageText: 'Per Page',
                        }}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerPageChange}
                      />
                    }
                    {filteredTermsheets?.length === 0  &&
                        <div className="inprogress_page">
                          <span className="material-symbols-outlined icon"> content_paste_search </span>
                          <p>No Data Found</p>
                        </div>
                    }
                    
                      </div>
                    </div>
               
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HomeFooter />
      </div>
    </div>
  );
};

export default TermSheetManager;