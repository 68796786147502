import React from 'react';
import { useSelector} from 'react-redux';
import { footerSiteUrl } from '../utils/reducer';
const HomeFooter = () => {

  const configdata = useSelector(state => state.configdata);
  const replaceEasyStreetCapital = configdata?.admin?.rights?.replace(/Easy Street Capital LLC/g,"easystreetcap.com");
  const siteUrl = footerSiteUrl
//   console.log("config data",configdata);

  return (
    <footer className="footer">
<div className="container-fluid">
    <div className="row">
        <div className="col-sm-12">
            <div className="text-sm-end d-none d-sm-block">
                {/* {configdata?.admin?.rights} */}
                <a href={siteUrl || '#'} target='_blank' className='footer-anchor' rel="noopener noreferrer"> 
                {replaceEasyStreetCapital}
                </a>
            </div>
        </div>
    </div>
</div>
</footer>
  );
};

export default HomeFooter;