import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {getLoanprograms, updateLoanPrograms} from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import SweetAlert from 'react-bootstrap-sweetalert';   
import HomeFooter from './HomeFooter';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
import { useNavigate,useLocation } from 'react-router-dom';
const LoanProgramsAdmin = () => {
    const dispatch = useDispatch();
    const [loanprograms, setLoanprograms] = useState([]);
    const [editedFeature, setEditedFeature] = useState({ programId: null, featureIndex: null, value: '' ,index_id : ''});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('Form successfully submitted.');
    const loading = useSelector((state) => state.loaderVisible);
    const [showConfirmation,setShowConfirmation] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});
    const [errorMsg,setErrorMsg] = useState({});
    const [underscoreId,setUnderscoreId] = useState(null);
    const [loanOld,setLoanOld] = useState([]);
    const [callLoanChanged,setCallLoanChanged] = useState(null);
    const [loanBoolId,setLoanBoolId] = useState([false,false,false]);
    const [addBoolId,setAddBoolId] = useState([true,true,true]);
    const [featureStatus,setFeatureStatus] = useState(false);
    const [descriptionStatus,setDescriptionStatus] = useState(false);
    const [deleteFeatureBool,setDeleteFeatureBool] = useState(false);
    let userData = getUserDataFromLocalStorage();
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const location = useLocation();

    let errors = {};

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.permission
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }
    
            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.permission
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

    useEffect(() => {
        getLoans()
      }, []);

    const compareLoanPrograms = (und_id,getId) => {
        // const allowedKeys = ['description','features'];
        const allowedKeys = ['description'];
        let obj1,obj2;
        obj1 = loanOld[getId];
        obj2 = loanprograms[getId];

        if(und_id !== "noid" && und_id !== "editfea"){
            return allowedKeys.every(key => obj1[key] === obj2[key]);
        }
        
        else if(und_id === "editfea" && Array.isArray(obj1?.features) === true){
            // if(editedFeature?.value.trim() === ''){
            //     return true;
            // }
            console.log("comapre loan called",obj1?.features?.includes(editedFeature?.value));
            return obj1?.features?.includes(editedFeature?.value);
        }

        for(let i = 0;i< loanOld?.length;i++){
           
            obj1 = loanOld[i];
            obj2 = loanprograms[i];
          
            // Check if all properties of the objects are equal
            // allowedkeys.map((item,index) => {
                // console.log(item);
                // for (let item in obj2) {
                   
                    
                    // else if(Array.isArray(obj1[item]) && Array.isArray(obj2[item])){
                    //     // console.log( JSON.stringify(obj1[item]) === JSON.stringify(obj2[item]))
                    //     // return JSON.stringify(obj1[item]) === JSON.stringify(obj2[item]);
                    //     for(let k = 0; k < obj1[item].length; k++) {
                    //         if(obj1[item][k] === obj2[item][k]) {
                    //             return true;
                    //         }
                    //     }
                       
                    // }
                   
                    
                // }
            // })


           
                return allowedKeys.every(key => obj1[key] === obj2[key]);
            
          
        }
        
       
        
    }

    // useEffect(() => {
    //     console.log("old loan programs",loanOld);
    //     //console.log("edited fetaure changed",editedFeature?.featureIndex)
    //     // let newButtonStates = [...loanBoolId];
    //     // if(editedFeature?.index_id !== '' && editedFeature?.index_id !== undefined){
    //     //     console.log("feature called");
    //     //     console.log(editedFeature?.index_id);
    //     //     //const featuresBool = compareLoanPrograms('editfea',editedFeature?.index_id);
    //     //     newButtonStates[editedFeature?.index_id] = true;
    //     // }
    //     console.log("old loan programs", loanOld);
    //     console.log(callLoanChanged);
    //     const newLoanBoolId = [...loanBoolId];
    //     // if (editedFeature?.index_id !== '' && editedFeature?.index_id !== undefined) {
    //         // Update the corresponding index in newButtonStates to true
    //         newLoanBoolId[1] = true;
    //     // }
    //     console.log("new button states",newLoanBoolId);
    // },[callLoanChanged]);

    const getIndex = (ind) => {
        const index = loanBoolId.findIndex(item => item === ind);
        return index;
    }
    
    function cleanString(str) {
        // Remove symbols and spaces, and convert to lowercase
        return str?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    }

    function compareFeatures(){
        const featureExists = loanprograms[callLoanChanged]?.features;
        // if(featureExists.includes(editedFeature?.value)){
        //    return true;
        // }else{
        //     return false;
        // }
        const cleanedInputValue = cleanString(editedFeature?.value);
        return featureExists?.some(item => cleanString(item) === cleanedInputValue);
        //return loanprograms[callLoanChanged]?.features?.includes(editedFeature?.value);
    }

   

    useEffect(() => {
        console.log("old loan programs",loanOld);
        let newButtonStates = [...loanBoolId];
        
        if(callLoanChanged === null){
            const getBool = compareLoanPrograms('noid');
            console.log("loan save bool",getBool);
            // setLoanSaveBool(getBool);
            newButtonStates[0] = getBool;
            newButtonStates[1] = getBool;
            newButtonStates[2] = getBool;
            // setLoanBoolId({...loanBoolId,0:getBool,1:getBool,2:getBool});
        }

        if(editedFeature?.index_id === callLoanChanged){
            console.log('id',callLoanChanged);
            const featureCall = compareLoanPrograms("editfea",callLoanChanged);
            newButtonStates[callLoanChanged] = featureCall;
            
            
        }
        if(descriptionStatus === true){
            const getBool2 = compareLoanPrograms(underscoreId,callLoanChanged);
            console.log("loan save bool",getBool2);
            newButtonStates[callLoanChanged] = getBool2;
        }
        if(deleteFeatureBool === true){
            newButtonStates[callLoanChanged] = false;
        }
        //else if(featureStatus !== true || (editedFeature?.index_id && featureStatus === true)){
            //const getBool2 = compareLoanPrograms(underscoreId,callLoanChanged);
            //console.log("loan save bool",getBool2);
            //newButtonStates[callLoanChanged] = getBool2;
        //}

            //(!editedFeature?.index_id && featureStatus !== true)
            
            // setLoanBoolId({...loanBoolId,callLoanChanged:getBool});


        // Update newButtonStates such that the element at callLoanChanged is false, and all others are true
        // newButtonStates = newButtonStates.map((val, idx) => idx === callLoanChanged ? false : true);

        
            setLoanBoolId(newButtonStates);
        
        
    },[loanprograms,editedFeature?.index_id,deleteFeatureBool])

    const getLoans = () => {  
        dispatch(showLoader());
      getLoanprograms().then(function (response) {
        // console.log('privacymenunewwe',response);
        if (response?.result) {
          setLoanprograms(response?.result);
          //if(loanOld?.length === 0){
            setLoanOld(response?.result);
          //}
          
          dispatch(hideLoader());
        }
        }).catch((err) => {
          console.error.bind("my account error", err);
          dispatch(hideLoader());
        })
    }
    const handleDescriptionChange = (id, newDescription , loanid ,loan_index) => {
        errors = {};
        setDescriptionStatus(true);
        setLoanprograms(currentPrograms =>
            currentPrograms.map(program =>
                program.loanProgramId === id ? { ...program, description: newDescription } : program
            )
        );
        setErrorMsg(loan_index);
        setUnderscoreId(loanid);
        
        setCallLoanChanged(loan_index);
    };

    const handleFeature = (lp_id,e,und_id,indexid) => {
        console.log(lp_id,e,und_id,indexid);
        //console.log("call loan changed",callLoanChanged)
        setCallLoanChanged(indexid);
        setEditedFeature({ ...editedFeature,programId: lp_id ,value: e.target.value ,index_id : indexid});
        setAddBoolId(addBoolId.map((val,idx) => idx === indexid ? false : true));
        
        
    }

    const handleDomainChange = (id, domain , domain_index) => {
        setLoanprograms(currentPrograms =>
            currentPrograms.map(program =>
                program.loanProgramId === id ? { ...program, domain: domain } : program
            )
        );
        // setCallLoanChanged(domain_index)
    };

    const handleUpdateFeature = () => {
        
        console.log('editedFeature.value111', editedFeature);
        const callFeatureValue = compareFeatures();
        if(callFeatureValue){
            dispatch(showLoader());
            setPopupOpen(true);
            setmessage('Feature already exists');
            dispatch(hideLoader());
        } else if (editedFeature?.value === null || editedFeature?.value?.trim() === '') {
            dispatch(showLoader());
            setPopupOpen(true);
            setmessage('Feature is empty!');
            dispatch(hideLoader()); 
        }

        else if (editedFeature.programId !== null && editedFeature.featureIndex !== null && editedFeature?.value?.trim() !== '') {
            
            setLoanprograms(currentPrograms =>
                currentPrograms.map(program =>
                    program.loanProgramId === editedFeature.programId
                        ? {
                            ...program,
                            features: program.features.map((item, index) =>
                                index === editedFeature.featureIndex ? editedFeature.value : item
                            ),
                        }
                        : program
                )
            );
            
           
            setEditedFeature({ programId: null, featureIndex: null, value: '' }); // Reset edited feature state
        } else {
            // Add the editedFeature.value as a new feature
           
            console.log('editedFeature.value', editedFeature?.value?.trim() !== '');
            if (editedFeature?.value?.trim() !== '') {
                dispatch(showLoader());

                setLoanprograms(currentPrograms =>
                    currentPrograms.map(program =>
                        program.loanProgramId === editedFeature.programId
                            ? { ...program, features: [...program.features, editedFeature.value] }
                            : program
                    )
                );
               
                
                setFeatureStatus(true);
                setEditedFeature({ programId: null, featureIndex: null, value: '' }); // Reset edited feature state
                setPopupOpen(true);
                setmessage("Feature added successfully");
                
                
                dispatch(hideLoader());
               
            } else {
                console.log('Invalid input for feature');
            }
        }
    };
    const closePopup = () => {
        setPopupOpen(false);
        if(popupMessage == 'Changes updated successfully.'){
        getLoans()
        }
        setShowConfirmation(false)
    };

    const handleEditFeature = (programId, featureIndex, value) => {
        setEditedFeature({ programId, featureIndex, value });
    };

   

    const handleDeleteFeature = (programId, featureIndex) => {
        setDeleteFeatureBool(true);
        dispatch(showLoader());
        setShowConfirmation(false)
        setPopupOpen(true);
        setmessage("Deleted successfully");
        setLoanprograms(currentPrograms =>
            currentPrograms.map(program =>
                program.loanProgramId === programId
                    ? { ...program, features: program.features.filter((_, index) => index !== featureIndex) }
                    : program
            )
        );
        // Reset all keys of editedFeature to null
        setEditedFeature({
            programId: null,
            featureIndex: null,
            value: null,
            index_id: null
        });
        dispatch(hideLoader());
        //setmessage("Deleted successfullyully");
       // setPopupOpen(true);
        
       
       
    };

    const isValidDomain = (domain) => {
         domain = domain?.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/+$/, '');
        const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
        return domainRegex.test(domain);
    };

    const checkIsTaglineEmpty = () => {
        errors = {};
        // loanprograms.map((loan,callLoanChanged) => {
        //     if(loan.description.trim() === ""){
        //         // Object.assign(errors,{loan._id : 'select'});
        //         errors[index] = loan._id;

        //     }
        // })
        if(loanprograms[callLoanChanged]?.description.trim() === ""){
            errors[callLoanChanged] = 'plese select';
        }
        setErrorMsg(errors);
        
    }

    const handleUpdateLoanPrograms = (programId,loanProgramId) => {
        const callFeatureValue = compareFeatures();
        if(callFeatureValue){
            dispatch(showLoader());
            setPopupOpen(true);
            setmessage('Feature already exists');
            dispatch(hideLoader());
        }
        else if(editedFeature?.value){
            // errors.features = "please enter";
            const featureExists = loanprograms[callLoanChanged]?.features?.some(item => editedFeature?.value === item);
                
                    if(!featureExists){
                        dispatch(showLoader());
                        setPopupOpen(true);
                        setmessage('Please Add or Update The Feature!');
                        dispatch(hideLoader());
                    }
                
            
            
        }
        else{
            console.log('loanorrooso', loanprograms);
            // console.log("id",id);
            // console.log(programId )
            if(loanProgramId == 10003 || loanprograms[programId]?.loanProgramType == "EASY RENT"){
                console.log('loanprograms[programId].domain',loanprograms[programId].domain);
                if (!isValidDomain(loanprograms[programId].domain)) {
                    // If validation fails, set an error and stop the submission
                    return;
                }
            }
            console.log('featuresfeatures', loanprograms[programId]?.features.length);
            if(loanprograms[programId]?.features.length == 0){
                // errors.features = "please enter";
                return;
            }
            checkIsTaglineEmpty();

           
            
            
        

            if(Object.keys(errors).length === 0){
                
                dispatch(showLoader());
                updateLoanPrograms(loanprograms[programId],loanProgramId).then(function (response) {
                if(response?.result == "incorrect old password"){
                    setmessage(response?.result)
                    //const callFeature = compareFeatures();
                    //let newButtons = [...loanBoolId];
                    //newButtons[callLoanChanged] = callFeature;
                    //setLoanBoolId(newButtons);
                }else{
                    setmessage("Changes updated successfully.")
                }
        
                dispatch(hideLoader());
                setPopupOpen(true);
                console.log('responseresponse',response);
                }).catch((err) => {
                console.error.bind("Login error", err);
                dispatch(hideLoader());
                })
            }else{
                return false;
            }
        }
    }
 

    return (
        <div id="layout-wrapper">
 {loading ? <Loader/> : null} 
    {isPopupOpen && !loading && (
        <PopupMessage
          type={popupMessage ==  'Please try with new reset link' || popupMessage === "Feature already exists" || popupMessage === 'Feature is empty!' || popupMessage === 'Please Add or Update The Feature!' ? 'warning' : 'success'}
          message={popupMessage}
          onClose={closePopup}
        />
      )}
       {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={(e) => handleDeleteFeature(selectedOption.key, selectedOption.i)}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-loanProgram-yes',
                        className: 'btn btn-primary',
                        onClick: () => handleDeleteFeature(selectedOption.key, selectedOption.i),
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-loanProgram-no',
                        className: 'btn btn-light',
                        onClick: closePopup,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                    <p>Do you really want to delete this feature?</p>
                {/* Additional content can go here */}
            </SweetAlert>}

           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Loan Programs</h4>
                            </div>
                            <div className="plans_section" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                            {loanprograms.map((key, index) => {
                                let easyTypeVal = key?.loanProgramType?.split(' ').slice(1).join(' ');

                                return (
                                    <div key={index} className="plan">
                                        <div className="logo">
                                            <div className="lp_icon">
                                                <img src={key?.image} alt="" />
                                            </div>
                                            <h2>{key?.loanProgramType?.split(' ')[0]} <span>{easyTypeVal}</span></h2>
                                        </div>
                                        <div className="form_section">

                                            <div className="form-group">
                                                <h3 className="title mb_10">TAGLINE</h3>
                                                <input id={`tagline-${easyTypeVal}`} name="tagline" onChange={(e) => handleDescriptionChange(key.loanProgramId, e.target.value, key?._id, index)} type="text" placeholder="Groundup Construction Loan" value={key.description || ''}
                                                    className={`form-control ${!errorMsg[index] ? '' : 'invalid-input'}`} />
                                            </div>

                                            {key.loanProgramType === 'EASY RENT' && (
                                                <div className="form-group">
                                                    <h3 className="title mb_10">Domain</h3>
                                                    <input id={`domain-${easyTypeVal}`} onChange={(e) => handleDomainChange(key.loanProgramId, e.target.value, index)} type="text" placeholder="Enter valid domain" value={key?.domain || ''}
                                                        className={`form-control ${isValidDomain(key?.domain) ? '' : 'invalid-input'}`} />
                                                </div>
                                            )}

                                            <div className="form-group">
                                                <h3 className="title mb_10 mt_10">FEATURES</h3>
                                                <div className='dsp_flx'>
                                                    <input id={`feature-${easyTypeVal}`} onChange={(e) => handleFeature(key.loanProgramId, e, key?._id, index)} value={editedFeature?.programId === key.loanProgramId ? editedFeature?.value : ''} type="text" placeholder="Add feature" className="form-control" />
                                                    <button id={`addFeature-${easyTypeVal}`} className="fs_btn" onClick={handleUpdateFeature} style={{ 'opacity': (addBoolId[index] === true) ? '0.6' : '1' }} disabled={addBoolId[index]}>
                                                        <span className="material-icons icon">add</span>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="left">
                                                <div className="cont">
                                                    <ul className="lp_feature">
                                                        {key?.features.map((item, i) => (
                                                            <li key={i}> 
                                                                <span className="text">
                                                                    <span className="material-symbols-outlined icon">check_small</span> 
                                                                    <span className='lp_text'>{item}</span> 
                                                                </span>
                                                                <span className="right">
                                                                    <a id={`edit-${easyTypeVal}-${i}`} href="#" onClick={(e) => {e.preventDefault(); handleEditFeature(key.loanProgramId, i, item)}} className="icon material-symbols-outlined">edit</a>
                                                                    <a id={`delete-${easyTypeVal}-${i}`} href="#" onClick={(e) => {e.preventDefault(); setShowConfirmation(true); setSelectedOption({key:key.loanProgramId, i:i}); setCallLoanChanged(index)}} className="icon material-symbols-outlined">delete</a>
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-0">
                                            <button id={`saveBtn-${easyTypeVal}`} key={index} onClick={() => handleUpdateLoanPrograms(index, key.loanProgramId)} className="fs_btn" style={{ 'opacity': (loanBoolId[index] === true) ? '0.6' : '1' }} disabled={loanBoolId[index]}>Save</button>
                                        </div>
                                    </div>
                                );
                            })}

                            </div>
                            {/* <div className="form-group"><button className="fs_btn mb_20">Add</button></div> */}
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default LoanProgramsAdmin;