import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate , useLocation} from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { dealratepointsAdd , dealratepointsGet , dealratepointsUpdate,getAllFundManagmentAdmin} from '../utils/reducer';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from './Loader';
import { useLoanInfo } from '../utils/loanInfoContext';
import moment from 'moment';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';

const DealRatePoints = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { loanInfo } = useLoanInfo();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('Submitted successfully');
    const [statusConfirmation,setStatusConfirmation] = useState(false);
    const [errors,SetErrors] = useState({});
    let userData = getUserDataFromLocalStorage();
    // console.log("userData",userData)
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const [dealRateData , setDealRateData ] = useState({
        "fundsource" : "",
        "loanorganisationpoints" : "",
        "rate" : "",
        "points" : "",
    });
    const [fundsDropdown,setFundsDropdown] = useState([]);
    const [defaultFund,setDefaultFund] = useState({});
    const [updateData,setUpdateData] = useState({});
    const [approvedPoints,setApprovedPoints]=useState([]);
    const [pendingPoints,setPendingPoints]=useState([]);
    const [btnType,setBtnType] = useState('save');

    useEffect(() => {
        if(loanInfo?.loanid){
            getFundsName()
        }
    }, [loanInfo?.loanid]);
    useEffect(() => {
        
    }, []);
    
    useEffect(() => {
        console.log(typeof dealRateData?.loanorganisationpoints);
        console.log('dealRateData', dealRateData);
    }, [dealRateData]);
    
    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.pipelinePermissions
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

    const getRatePoints = (defaultFund)=>{
        dispatch(showLoader());
        dealratepointsGet(loanInfo?.loanid)
        .then(function(response) {
            if (response?.result?.length !==0) {
                console.log('all rate and points response', response.result);
                const approved = response.result.filter(item => item.status === 'APPROVED');
                const pending = response.result.filter(item => item.status === 'PENDINGAPPROVAL');
                setApprovedPoints(approved);
                setPendingPoints(pending);
                const lastApprovedPoint = approved[approved.length - 1];
                console.log("Last approved point:", lastApprovedPoint);
                if(approved?.length>0){
                    setBtnType('changerequest');
                    setDealRateData(lastApprovedPoint);
                }else{
                    setDealRateData({
                        "fundsource" : defaultFund?.name || '',
                        "fundid" : defaultFund?.uuid || '',
                        "loanorganisationpoints": "",
                        "rate": "",
                        "points": "",
                    });
                }
                SetErrors({})
                dispatch(hideLoader());
            }else{
                setApprovedPoints([]);
                setPendingPoints([]);
                setDealRateData({
                    "fundsource" : defaultFund?.name || '',
                    "fundid" : defaultFund?.uuid || '',
                    "loanorganisationpoints": "",
                    "rate": "",
                    "points": "",
                });
                SetErrors({});
                setBtnType('save');
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };

    const addApiCall =(apiData)=>{
        dealratepointsAdd(apiData)
        .then(function(response) {
            if (response?.result) {
                console.log('add rate and points --- > ', response?.result);
                setPopupOpen(true);
                getRatePoints(defaultFund);
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    }
    const getFundsName =()=>{
        dispatch(showLoader());
        let type = 'fundDropdown'
        getAllFundManagmentAdmin('','',type,'')
        .then(function(response) {
            console.log('funds name',response)
            console.log('funds name', response);
            if (response?.result) {
                setFundsDropdown(response.result?.data.map((fundoption) => ({
                    value: fundoption?.name,
                    label: fundoption?.name,
                    uuid : fundoption?.uuid,
                })));
                const defaultFund = response.result.data.find(fund => fund.default === true);
                setDefaultFund({name : defaultFund?.name , uuid : defaultFund?.uuid});
                getRatePoints(defaultFund);
            } else {
                setFundsDropdown([]);
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
        
    }
    const addRatePoints = ()=>{
        dispatch(showLoader());
        let apiData = {...dealRateData,"loanid" : loanInfo?.loanid};
        console.log(approvedPoints);
        if(approvedPoints?.length === 0){
            apiData['status'] = 'APPROVED'
        }else{
            apiData['status'] = 'PENDINGAPPROVAL'
        };
        setmessage('Saved Successfully');
        addApiCall(apiData);
    };
    const updateRatePoints = ()=>{
        setStatusConfirmation(false);
        dispatch(showLoader());
        let apiData = {...updateData};
        setmessage('Status Updated')
        addApiCall(apiData);
    };

    const handleFocus = (e) => {
        const {name,value} = e.target;
        SetErrors({...errors,[name] : ""});
    };
    const handleSubmit = () => {
       let errorsCheck = {}
       let fieldsToCheck = {"fundsource" : "fundsource","loanorganisationpoints" : "loanorganisationpoints","rate" : "rate", "points" : "points"};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            const fieldValue = dealRateData[fieldName];
            if (typeof fieldValue === 'string' && !fieldValue.trim()) {
                errorsCheck[fieldName] = `Please Enter ${fieldLabel} `;
            } else if (typeof fieldValue === 'number') {
                const stringValue = fieldValue.toString();
                if (!stringValue.trim()) {
                    errorsCheck[fieldName] = `Please Enter a valid ${fieldLabel} `;
                }
            }
        };
        SetErrors(errorsCheck);
        if(Object.keys(errorsCheck).length === 0){
            addRatePoints();
        }
    };
    const handleUpdate =(data)=>{
        SetErrors({});
        setUpdateData({
            ...data,
            'status' : 'APPROVED',
        });
        setStatusConfirmation(true);
        console.log('data------>',data);
    };
    const changeRequest =()=>{
        setDealRateData({
        "fundsource" : defaultFund?.name,
        "fundid" : defaultFund?.uuid,
        "loanorganisationpoints" : "",
        "rate" : "",
        "points" : "",
        });
        setUpdateData({});
        setBtnType('save');
    }
    const inputFields = [
        { label: "Loan Origination Points", name: "loanorganisationpoints", placeholder: "Enter Loan Origination Points", disabled: btnType === 'save' ? false : true },
        { label: "Rate", name: "rate", placeholder: "Enter Rate" , disabled: btnType === 'save' ? false : true},
        { label: "Broker Points", name: "points", placeholder: "Enter Broker Points" , disabled: btnType === 'save' ? false : true}
    ];

    const handleRateChange=(e)=>{
        let {name,value} = e.target;
        let updatedValue = value;
        if(name === 'fundsource'){
            const selectedFund = fundsDropdown.find((fund) => fund.value === updatedValue);
            setDealRateData((prev) => ({
                ...prev,
                [name] : updatedValue,
                fundid: selectedFund ? selectedFund?.fundid : '',
            }));
        }else{
            const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
            updatedValue = isNaN(parsedValue) ? '' : parsedValue;
            setDealRateData((prev) => ({
                ...prev,
                [name] : updatedValue,
            }));
        }
        
    };
   

    const closePopup = () => {
        setPopupOpen(false);
        setStatusConfirmation(false);
    };


    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader/> : null} 
            {isPopupOpen && (
                <PopupMessage
                type={!popupMessage ? 'warning' : 'success'}
                message={popupMessage}
                onClose={closePopup}
                />
            )}
            {statusConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, Change it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={updateRatePoints}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'approve-yes',
                        className: 'btn btn-primary',
                        onClick: updateRatePoints,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'approve-no',
                        className: 'btn btn-light',
                        onClick: closePopup,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                    <p>Do you really want to Change Status to Approve?</p>
            </SweetAlert>}
        <PipelineHeader />
        <div className="dashboard_block">
        <PipelineStatus />
            <h2 className="form_title">Closing and Funding <span className="space">&#10095;</span>  <span className="sub">Rate And Points</span> </h2>
            <div className="card user_management detailed_app">
                <div className="card-body my_account">
                    <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        <li className="nav-item">
                            <a id="dealInfoRatePoints" className="nav-link active" data-bs-toggle="tab" href="#ENTITY" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">DEAL INFO</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a id="dealInfoLockHistory" className="nav-link " data-bs-toggle="tab" href="#APPLICANT" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">LOCK HISTORY</span>
                            </a>
                        </li>
                    </ul>
                     <div className="tab-content pt-15 text-muted" style={{
                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
              }}>
                        <div className="tab-pane active" id="ENTITY" role="tabpanel">
                          
                            
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18 title">DEAL INFO</h4>
                                  
                                </div>

                                <div className="sl_block form_section ">
                            <div className="left">
                                <div className="cont">
                                <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Fund Source</label>
                                                <select id='fundsource' className={`form-control ${errors?.fundsource && 'invalid-input'}`} value={dealRateData?.fundsource} disabled={ btnType === 'save' ? false : true} onFocus={handleFocus} name="fundsource" onChange={handleRateChange}>
                                                       <option value="">Select a Fund</option>
                                                       {fundsDropdown?.map((fundoption, index) => {
                                                                        return (
                                                                            <option value={fundoption?.value}>{fundoption?.label}</option>
                                                                        )

                                                                    })}
                                                       
                                                    </select>
                                                {/* <input id="fundSource" type="text" className={`form-control ${errors?.fundsource && 'invalid-input'}`} placeholder="Enter" name="fundsource" value={dealRateData?.fundsource} disabled={true}/> */}
                                            </div> 
                                        </div>
                                        <>
                                            {inputFields.map((field, index) => (
                                                <div className="col-md-12" key={index}>
                                                    <div className="form-group">
                                                        <label>{field?.label}</label>
                                                        <input id={field?.name} type="number" className={`form-control ${errors[field?.name] && 'invalid-input'}`} onFocus={handleFocus} placeholder={field?.placeholder} name={field?.name} onChange={handleRateChange} value={dealRateData[field?.name]} disabled={field?.disabled} />
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                {btnType === 'save' ? <button id="saveDealInfo" className="fs_btn" onClick={(e) => {{ handleSubmit() }}}>{"Save"}</button> :
                                                 <button id="dealInfoChangeRequest" className="fs_btn" onClick={(e) => {{ changeRequest() }}}>{"Change Request"}</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                               
                                </div>
                            </div>
                            <div className="right">
                                <div className="table-responsive">
                                    <table className="table db_table">

                                        <thead className="table-light">
                                            <tr>
                                                <th width="15%" className='text-center'>Fund Source</th>
                                                <th width="30%" className='text-center'>Loan Origination Points</th>
                                                <th width="25%" className='text-center'>Rate</th>
                                                {/* <th width="20%" className='text-center'>Default Fund Rate</th> */}
                                                <th width="20%" className='text-center'>Broker Points</th>
                                                <th width="10%"></th>
                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pendingPoints?.map((data,index)=>{
                                                return(
                                                    <tr key={index}>
                                                <td className='text-center'>{data?.fundsource}</td>
                                                <td className='text-center'>{data?.loanorganisationpoints}</td>
                                                <td className='text-center'>{data?.rate}</td>
                                                {/* <td className='text-center'>{data?.defaultfundrate}</td> */}
                                                <td className='text-center'>{data?.points}</td>
                                                <td><button id="approveDealInfo" onClick={(e) => {{ handleUpdate(data) }}} className="icon_btn approve_btn"> <span className="material-symbols-outlined icon"> task_alt </span>Approve </button></td>
                                            </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>



                         
                            

                        </div>
                        <div className="tab-pane" id="APPLICANT" role="tabpanel">
                          
                                 
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18 co_borrower title">Lock History</h4>
                                 </div>
                            <div className="table-responsive">
                                <table className="table db_table">

                                    <thead className="table-light">
                                        <tr>
                                            <th width="9%">Rate</th>
                                            <th width="10%">Points</th>
                                            <th width="9%">Spread</th>
                                            <th width="10%">Is Locked</th>
                                            <th width="10%">Updated/Approved</th>
                                            <th width="12%">Base</th>
                                            <th width="11.5%">FUND NAME</th>
                                            <th width="5.5%">Locked Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {approvedPoints?.map((data,index)=>{
                                            return(
                                                <tr key={index}>
                                                <td>{data?.rate}</td>
                                                <td>{data?.points}</td>
                                                <td>{data?.spread}</td>
                                                <td>{data?.islocked}</td>
                                                <td>{data?.approvedby?.username}</td>
                                                <td>{data?.base}</td>
                                                <td>{data?.fundsource}</td>
                                                <td>{moment.utc(data?.created).format("MM-DD-YYYY")}</td>
                                            </tr>
                                            )
                                        })} 
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                       

                    </div>

                </div>
            </div>
        </div>
        <PipelineFooter />
    </div>
    );
};

export default DealRatePoints;