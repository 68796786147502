import React, { useState ,useEffect} from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import {getValuesDropdown} from "../utils/reducer";
import {transformData} from '../utils/commonUtils.js';

const ValuesDropdown = ({ onChange }) => {
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [message , setMessage] = useState('Updated Successfully');
    const [formValuesData,setFormValuesData] = useState(null);
    const [openCategoryIndex, setOpenCategoryIndex] = useState(null);
    const [formValues,setFormValues] = useState(null);
    const [formValuesOption,setFormValuesOption] = useState(null);
    const [valueDependency,setValuesDependency] = useState(0);
 
    useEffect(() => {
        dispatch(showLoader());
        getValuesDropdown().then(function (response) {
            if (response?.result) {
                const result = response?.result;
                console.log("get values dropdown",result);
                setFormValuesData(result);
            }
            dispatch(hideLoader())
        }).catch((err) => {
              console.error.bind("get values dropdown  error", err);
              dispatch(hideLoader())
        })
    },[])

    useEffect(() => {
        if (formValuesData && formValuesData.length > 0) {
            console.log("form values data",formValuesData)
            const transformedData = transformData(formValuesData);
            console.log("Formatted Dropdown Data", transformedData);
            setFormValues(transformedData); // Set array, not stringified data
        }
    }, [formValuesData])

     // Toggle sub-options on category click
     const toggleSubOptions = (index) => {
        setOpenCategoryIndex(openCategoryIndex === index ? null : index);
    };

    const handleChangeValues = (value) => {
        const selectedValue = value;

        setFormValuesOption(selectedValue);
        setValuesDependency(valueDependency + 1);
         // Call the onChange function passed from the parent
         if (onChange) {
            onChange(value);
        }
    };
  

  return (
    <div className="col-md-3 value_block value_email_block">
        <div className="form-group value_email">
            <label>Values</label>
            <div className="custom-dropdown">
                <button id="values" className="custom-dropdown-btn">
                    Values<span className="custom-dropdown-chevron">&#x25BC;</span>
                </button>
                <div className="custom-dropdown-content">
                    {formValues?.map((category, index) => (
                        <div key={index} className="custom-dropdown-category">
                            <span
                                className="custom-category-label" id="category-values"
                                onClick={() => {
                                    if (!category.subOptions?.length) {
                                        handleChangeValues(category.value);
                                    } else {
                                        toggleSubOptions(index); // Toggle the sub-options on click
                                    }
                                }}
                            >
                                {category.label}
                                {category.subOptions?.length > 0 && (
                                    <span className="custom-sub-chevron">&#x25BC;</span>
                                )}
                            </span>
                            {category.subOptions?.length > 0 && openCategoryIndex === index && (
                                <div className="custom-dropdown-subcontent">
                                    {category.subOptions.map((option, subIndex) => (
                                        option.label !== "Escrow" && <div
                                            key={subIndex}
                                            className="custom-dropdown-item"
                                            id="subcategory-values"
                                            onClick={() => handleChangeValues(option.value)} // Handle click for sub-options
                                        >
                                            {option.label}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  );
};

export default ValuesDropdown;
