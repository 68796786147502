import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import EditorEdit from './EditorEdit.js';
import {Appname, APIUrl,getNeeds,addNeeds,updateNeed,addNeedNote,DeleteNeedNote} from '../utils/reducer';
import PopupMessage from './PopupMessage';
import SweetAlert from 'react-bootstrap-sweetalert';  
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import Loader from './Loader';
import { useLoanInfo } from '../utils/loanInfoContext';
import axios from "axios";
import { useDispatch,useSelector} from 'react-redux';
import { getUserDataFromLocalStorage } from "./../utils/local";
import moment from 'moment';
import FileViewer from './DocViewer';
const Needs = () => {
    const dispatch = useDispatch();
    const { loanInfo } = useLoanInfo();
    const navigate = useNavigate();
    const getUserData = getUserDataFromLocalStorage();
    console.log('getUserData',getUserData.emailid);
    let loanid = loanInfo?.loanid;
    // 
     const visibilityOptions = ['Borrower', 'QC', 'Processor', 'Ops', 'Loan Officer', 'Servicer', 'UW', 'Secondary'];
     const halfIndex = Math.ceil(visibilityOptions.length / 2);
    const [showAddform, setShowAddform] = useState(false);
    const [formContent, setFormContent] = useState("");
    const [editorValidate,setEditorValidate] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const configdata = useSelector(state => state.configdata);
    const [allNeeds,setAllNeeds] = useState([]);
    const loading = useSelector((state) => state.loaderVisible);
    const [selectedStatus , setSelectedStatus] = useState({
    });
    const [note , setNote] = useState({
    });
    const [noteError,setNoteError] = useState(false);
    const [ viewNote, setViewNote] = useState('');
    const [message,setMessage] = useState('');
    const [popUpMsg,setPopUpMsg] = useState(false);
    const [statusConfirmation , setStatusConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const [deleteNote,setDeleteNote] = useState({});
    const [deleteIndex,setDeleteIndex] = useState('');
    const [deleteFileUpload,setDeleteFileUpload] = useState(false);
    let uploadFilesData = {
      file : '',
      filecode : ''
    };
    const [deleteFileId ,setDeleteFileId] = useState({
      file : '',
      filecode : '',
    });
    const [deleteFileConfirmation , setDeleteFileConfirmation] = useState(false);
    const [formData, setFormData] = useState({
      duedate: '',
      internaldescription: '',
      needtype: '',
      document: '',
    });
    const [showDoc, setshowDoc] = useState(false);
    const [type, setType] = useState(false);
    const [playContent, setPlayContent] = useState({});
    const [fileDocName, setFileDocName] = useState("");

    const [errors, setErrors] = useState({
      duedate: false,
      internaldescription: false,
      needtype: false,
      document: false
    });

    useEffect(() => {
      console.log(formData);
    }, [formData])
    
    // useEffect(() => {
    //     setFormData({
    //         ...formData,
    //         ['selectedFiles']: selectedFiles
    //       });
      
    //   }, [selectedFiles]);

      useEffect(() => {
        setFormData({
            ...formData,
            ['needdescription']: formContent
          });
      
      }, [formContent]);
      
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };
    
    useEffect(() => {
        GetNeedsData();
    }, [loanInfo?.loanid])
    console.log(uploadFilesData);
   
    const GetNeedsData = ()=>{
        dispatch(showLoader());
        getNeeds(loanInfo?.loanid)
        .then(function(response) {
            if (response) {
                console.log('response', response);
                setAllNeeds(response?.result);
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const AddNeedApi =()=>{
        let data = {...formData,...uploadFilesData,needdescription :formContent ,loanid : loanInfo?.loanid,status : 'Outstanding'}
        // dispatch(showLoader());
        console.log('data------>',data)
        addNeeds(data,loanInfo?.loanid)
        .then(function(response) {
            if (response) {
                console.log('response', response);
                dispatch(hideLoader());
                setMessage('Need Added Successfully');
                GetNeedsData();
                setPopUpMsg(true);
                setShowAddform(!showAddform);
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const updateNeedApi =(dataToApi)=>{
        let data = {...dataToApi ,loanid : loanInfo?.loanid}
        dispatch(showLoader());
        updateNeed(data,loanInfo?.loanid)
        .then(function(response) {
            if (response) {
                console.log('response', response);
                dispatch(hideLoader());
                GetNeedsData();
                setPopUpMsg(true);
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const AddNeedNote =(note)=>{
        let data = {...note,loanid : loanInfo?.loanid}
        dispatch(showLoader());
        addNeedNote(data,loanInfo?.loanid)
        .then(function(response) {
            if (response) {
                console.log('response', response);
                setMessage('Note Added Successfully');
                setNote({});
                setPopUpMsg(true);
                GetNeedsData();
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const deleteNeedNote =()=>{
        dispatch(showLoader());
        DeleteNeedNote(deleteNote,loanInfo?.loanid)
        .then(function(response) {
            if (response) {
                console.log('response', response);
                setMessage('Note Deleted Successfully');
                GetNeedsData();
                setPopUpMsg(true);
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const getStatusClass = (status) => {
  switch (status) {
    case 'Completed':
      return 'green';
    case 'Outstanding':
      return 'orange';
    case 'Underreview':
      return 'blue';
    case 'n/a':
      return 'grey';
    default:
      return '';
  }
    };
    const addNew = () => {
        setShowAddform(!showAddform);
        uploadFilesData['file'] = '';
        uploadFilesData['filecode']= '';
        setFormData({
      duedate: '',
      internaldescription: '',
      needtype: '',
      document: '',
      selectedFiles: [],
      file : '',
      filecode : ''
    });
  setErrors({
      duedate: false,
      internaldescription: false,
      needtype: false,
      document: false
    });
    setFormContent('');
    setSelectedFiles([]);
    };
    const handleStatusChange = (e,data)=>{
        let status = e.target.value;
        console.log(status);
        setSelectedStatus({
            "status" : e.target.value,
            "needid" : data?.needid
        });
        setStatusConfirmation(true);
    };
    const handleSelectUpdate = ()=>{
        setMessage('Updated Successfully');
        updateNeedApi(selectedStatus);
        setStatusConfirmation(false);
    };
    const handleAddNoteClick = (data)=>{
      setNoteError(false)
        setNote({
        'notes' : '',
         "needid" : data?.needid
        })
    };
    const handleAddNote = ()=>{
     let error = false;
     console.log(note)
    if(!note?.notes?.trim()){
      error = true;
    }
    setNoteError(error)
    if(!error){
        AddNeedNote(note);
        document.getElementById('addClose').click();
    }
    }
    const handleDeleteNoteClick =(data)=>{
        setDeleteConfirmation(true);
        setDeleteNote(data);
        console.log(data);
    }
    const handleDeleteNote =()=>{
        setDeleteConfirmation(false);
        deleteNeedNote();
    }
    const handleDelete = (index) => {
      setDeleteFileUpload(false)
        const newFiles = [...selectedFiles];
        newFiles.splice(deleteFileId, 1);
        setSelectedFiles(newFiles);
    };

     // Function to truncate file name if it exceeds a certain length
  const truncateFileName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '...';
    }
    return name;
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (value.trim() !== '') {
        setErrors({
          ...errors,
          [name]: false
        });
      }
  };

  const handleDeleteFile = (data)=>{
    if(!data?.file){
    }else{
      let needid = data?.needid;
      setDeleteFileId({
        ...deleteFileId,
        needid : needid,
      });
      setDeleteFileConfirmation(true);
    }
  };
  const handleDeleteUpdate = ()=>{
    setMessage('Deleted Successfully');
    updateNeedApi(deleteFileId);
    setDeleteFileConfirmation(false);
};

  const handleFileChange2 = async (data, event) => {
    setMessage('File Uploaded Successfully');
    const file = event.target.files[0];
    console.log(file);
    // handleUpload(file)
    // let needid = data?.needid;
    // let apiData = { needid: needid, ...uploadFilesData };
    // updateNeedApi(apiData);
    if(file){
      dispatch(showLoader());
      handleUpload(file)
                .then(() => {
                    let needid = data?.needid;
                    let apiData = { needid: needid, ...uploadFilesData };
                    updateNeedApi(apiData);
                })
                .catch(error => {
                    // Handle error if upload fails
                    console.error('Error uploading file:', error);
                });
    //   await handleUpload(file); 
    // let needid = data?.needid;
    // let apiData = { needid: needid, ...uploadFilesData };
    // updateNeedApi(apiData);
    }
    
  };
  const handleViewOpen = (e, content) => {
    const fileType = content?.file?.split('.').pop();
    let source;
    // let type = content?.filetype?.split("/")
    // if(type[0]==="image"){
    //     source = configdata?.common?.awsImgix  + content.filepath;
    // }else{
    //     source = configdata?.common?.resourcesCloudfront  + content.filepath;
    // };
    source = configdata?.common?.resourcesCloudfront  + content.file;
    setFileDocName(content?.filename)
    setPlayContent(source);
    setshowDoc(true);
    setType(fileType);
    // console.log("playercontent==========>", source)
}

  const handleSubmit = async(event) => {
    // dispatch(showLoader())
    event.preventDefault();
    // Validation logic for required fields
    const errors = {};
    let hasErrors = false;
    const requiredFields = ['duedate', 'internaldescription', 'needtype', 'document'];
    requiredFields.forEach(field => {
      if (!formData[field]) {
        errors[field] = true;
        hasErrors = true;
      }
    });
    console.log('errors',errors);
    setErrors(errors);
    if (!hasErrors) {
      // Submit the form data
      console.log('Form data submitted:', formData);
      dispatch(showLoader())
      try {
        await Promise.all(selectedFiles.map((file, index) => handleUpload(file, index)));
        
        // Once all files are uploaded, submit the form data
        AddNeedApi();
        console.log('Form data submitted:', formData);
        // You can add your form submission logic here
    } catch (error) {
        console.error('Error uploading files:', error);
    }

    } 
  };
  const handleUpload = async (file, index) => {
    try {

        var bucket;
        const timestamp = Date.now();
        let format = file?.name?.split(".");
        var fileformat = format[format.length - 1];
        bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
        var ObjectID = require("bson-objectid");
        var filecode = new ObjectID().toString(); 
        let uploadFilePath = Appname + "/needs/" + filecode + "/" + timestamp + "." + fileformat;
        var data = { source_bucket: bucket, sourcepath: uploadFilePath };
        const response = await axios.post(APIUrl + 'uploadURL?appname=' + Appname, data, { type: 'application/json' });
        if (response?.data && response?.data?.result) {
          uploadFilesData['file'] = uploadFilePath;
          uploadFilesData['filecode'] = filecode;
            var url = response?.data?.result;
            await axios.put(url, file, {
                "headers": {
                    "Content-Type": "multipart/form-data",
                    "Accept": "/",
                    "Cache-Control": "no-cache",
                    "Accept-Encoding": "gzip, deflate",
                    "Connection": "keep-alive",
                    "cache-control": "no-cache"
                }
            });
            const updatedFiles = [...selectedFiles];
            updatedFiles[index]['path'] = uploadFilePath;
            console.log("updatedFiles",updatedFiles,filecode)
            // setFormData({
            //     ...formData,
            //     selectedFiles: updatedFiles
            // });
        }
    } catch (error) {
        console.error('Error uploading file:', error);
    }
 };
const [noteJSON, setNoteJSON] = useState({ visibleTo: [] });

const updateNoteJSON = (event) => {
    const element = event.target;
    const name = element.getAttribute('name');
    const value = element.type === 'checkbox' ? element.checked : element.value;

    if (name === 'visibleTo') {
        const option = element.value;
        const isChecked = element.checked;
        setNoteJSON(prevNoteJSON => {
            if (isChecked) {
                return { ...prevNoteJSON, [name]: [...prevNoteJSON[name], option] };
            } else {
                return { ...prevNoteJSON, [name]: prevNoteJSON[name].filter(item => item !== option) };
            }
        });
    } else {
        setNoteJSON(prevNoteJSON => ({
            ...prevNoteJSON,
            [name]: value
        }));
    }
};

    const handleReset = (e)=>{
      e.preventDefault();
      setSelectedFiles([]);
      setFormData({
        duedate: '',
        internaldescription: '',
        needtype: '',
        document: '',
        selectedFiles: [],
        file : '',
        filecode : ''
      });
      setErrors({
        duedate: false,
        internaldescription: false,
        needtype: false,
        document: false
      });
      setFormContent('');
    }

    const addNote = () => {
        console.log(noteJSON);
        // Reset JSON object for next note
       // setNoteJSON({});
    };

    const closePopup = ()=>{
        setStatusConfirmation(false);
        setDeleteConfirmation(false);
        setDeleteFileConfirmation(false);
        setDeleteFileUpload(false);
    };
    const closePopup2  =()=>{
        setPopUpMsg(false);
    }

    return (
        <div id="layout-wrapper" className="dashboard">
            {popUpMsg && (
            <PopupMessage
             type={'success'}
             message={message}
             onClose={closePopup2}
            />
        )}
        {statusConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes"
                cancelButtonText="No, cancel!"
                title="Are you sure? "
                onConfirm={handleSelectUpdate}
                onCancel={closePopup}
                focusCancel={true}
            >
                    <p>Do you really want to Change Status?</p>
            </SweetAlert>}
            {deleteFileConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes Delete It"
                cancelButtonText="No, cancel!"
                title="Are you sure? "
                onConfirm={handleDeleteUpdate}
                onCancel={closePopup}
                focusCancel={true}
            >
                    <p>Do you really want to Delete This File?</p>
            </SweetAlert>}
            {deleteConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes Delete It"
                cancelButtonText="No, cancel!"
                title="Are you sure? "
                onConfirm={handleDeleteNote}
                onCancel={closePopup}
                focusCancel={true}
            >
                    <p>Do you really want to Delete This Note?</p>
            </SweetAlert>}
            {deleteFileUpload && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes Delete It"
                cancelButtonText="No, cancel!"
                title="Are you sure? "
                onConfirm={handleDelete}
                onCancel={closePopup}
                focusCancel={true}
            >
                    <p>Do you really want to Delete This File?</p>
            </SweetAlert>}
            {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} name={fileDocName} />}
             {loading ? <Loader/> : null} 
             <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
             
                <h2 className="form_title"> <span className="sub">Needs</span> </h2>
                
            
                                   
                               
                <div className="card task_block needs">
                {!showAddform ? 
                
                <div className="card-body services_form">
                        <div className="search ">
                            <h3 className="title">NeedS SUMMARY</h3>
                            <button type="button" className="create_btn" onClick={addNew}>Add<span
                                    className="material-symbols-outlined">Add</span></button>
                        </div>
                        {allNeeds?.length >0 && 
                        <div className="task_hed">
                        <div className="clmn">Need Type</div>
                        <div className="clmn">dESCRIPTION</div>
                        <div className="clmn">status</div>
                        <div className="clmn">dUE dATE</div>
                        <div className="clmn">cOMPLeted bY</div>
                        <div className="clmn">Docs</div>
                       <div className="clmn">nOTES </div>
                      </div> }
                        
                        {allNeeds?.map((data,index)=>{
                        const statusClass = getStatusClass(data?.status);
                        const parser = new DOMParser();
                        const htmlDoc = parser.parseFromString(data?.needdescription, 'text/html');
                        const descriptionText = htmlDoc.body.textContent;
                            return(
                                <>
                               <div className={`task_item ${statusClass}`}>
                            <div className="clmn"><span>{data?.needtype}</span></div>
                            <div className="clmn">{descriptionText}</div>
                                <div className="clmn"><select value={data?.status} onChange={(e)=>{handleStatusChange(e,data)}} name="channel" className="form-control null form-select" tabindex="2">
                                <option value="Completed">Completed</option>
                                <option value="Outstanding">Outstanding</option>
                                <option value="Underreview">Under Review</option>
                                <option value="n/a">N/A</option>
                                </select>
                                </div>
                                <div className="clmn"><span>{moment.utc(data?.duedate).format("MM-DD-YYYY")}</span></div>
                            <div className="clmn"><span>{data?.createdby?.username}</span></div>
                            <div className="clmn">
                            {data?.filecode?.length>0 ? 
                            <><span className="material-symbols-outlined icon2 btn" 
                            // data-bs-toggle="modal" data-bs-target="#viewattachment" data-bs-whatever="@mdo"
                             onClick={(e) => handleViewOpen(e, data)}> visibility </span> <a href={configdata?.common?.resourcesCloudfront  + data?.file} type="button" className="btn download_pdf"><span className="material-symbols-outlined icon">download</span></a> </>
                              : <span className="material-symbols-outlined icon2 upload-btn-wrapper"> upload_2
                               <input type="file" onChange={(event)=>{handleFileChange2(data,event)}} name="myfile" accept=".doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png, .zip,.heic" />
                               </span>}

                            <span className="material-symbols-outlined icon2 btn" onClick={()=>{handleDeleteFile(data)}} style={{'opacity' : data?.file ? 1 : 0.6}}> delete </span></div>

                            <div className="clmn">
                            <div className="d-flex align-items-center">
                                <button className="upload_btn" data-bs-toggle="modal" data-bs-target="#addnote" onClick={()=>{handleAddNoteClick(data)}}
                                    data-bs-whatever="@mdo"> Add <span className="material-icons icon"> add </span></button>
                                <div className="btn-group">
                                    <button className="border_btn" 
                                    disabled={data?.note ? data?.note?.filter(note => note.status === 'ACTIVE').length === 0 : true}
                                     type="button" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        View <i className="mdi mdi-chevron-down"></i>
                                    </button>
                                    <div className="dropdown-menu">
  {data?.note
    ?.filter((note) => note.status === 'ACTIVE') 
    .map((activeNote, activeNoteIndex) => {
      let notenum = '';
      return (
        <div key={activeNoteIndex} className="dropdown-item">
          <a
            href="#"
            data-bs-toggle="modal"
            onClick={() => {
              setViewNote(activeNote.notes);
            }}
            data-bs-target="#viewnote"
            data-bs-whatever="@mdo"
          >
            Note {activeNoteIndex + 1}
          </a>
          <span className="material-symbols-outlined icon">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleDeleteNoteClick(activeNote);
              }}
            >
              delete
            </a>
          </span>
        </div>
      );
    })}
</div>
                                </div>
                            </div>
                            </div>
                        </div>
                                </>
                            )
                        })}
                        
                    </div>
                    :  
                    <div className="card-body user_management needs_description">
                    {/* <div className="search">
                      <h3 className="title">Need Description</h3>
                    </div> */}
                    <div className="form_section">
                  
                      <div className="search"><h3 className="title mb-0">Need Description</h3>
                      {showAddform &&  <a href="#" onClick={(e) => {e.preventDefault();addNew() }} className="back_btn">
                    <span className="material-icons icon"> chevron_left</span>BACK
                  </a>}
                      </div>
                
                      <div className="row">
                        <div className="col-md-6">
                        <div className="row"> 
                        <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12">
                              <div className={`form-group ${errors.duedate ? 'has-error' : ''}`}>
                                <label>Due Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="duedate"
                                  value={formData.duedate}
                                  onChange={handleInputChange}
                                  placeholder="mm-dd-yyy"
                                  min={new Date().toISOString().split('T')[0]} 
                                />
                              </div>
                            </div>
                         
                            <div className="col-md-12">
                              <div className={`form-group ${errors.needtype ? 'has-error' : ''}`}>
                                <label>Need Type</label>
                                                    <select
                                                        className="form-control"
                                                        name="needtype"
                                                        value={formData.needtype}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Please Select</option>
                                                        <option value="Financial">Financial</option>
                                                        <option value="Property">Property</option>
                                                        <option value="Market">Market</option>
                                                        <option value="Borrower">Borrower</option>
                                                        <option value="Summary">Summary</option>
                                                    </select>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className={`form-group ${errors.document ? 'has-error' : ''}`}>
                                <label>Document Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="document"
                                  value={formData.document}
                                  onChange={handleInputChange}
                                  placeholder="Enter"
                                />
                              </div>
                            </div>
              
                            <div className="col-md-12">
                              <h3 className="title mt_20 mb-0">Choose a File</h3>
                              <div className="row files">
                                {selectedFiles.map((file, index) => (
                                  <div key={index} className="col-md-3">
                                    <div className="file_upload">
                                      <div className='file_img'>
                                      {file.type.startsWith('image/') ? (
                                        <img
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                          className="img"
                                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                                        />
                                      ) : (
                                        <img src="assets/images/document.svg" alt="Document" className="img" />
                                      )}
                                      </div>
                                    
                                      <div className="file_title"> <span className='text_wrp'>{truncateFileName(file.name, 15)}</span>  <a onClick={() => {setDeleteIndex(index);setDeleteFileUpload(true);}} className="material-symbols-outlined icon"> delete </a></div>
                                    </div>
                                  </div>
                                ))}
                               {selectedFiles?.length === 0 &&
                                <div className="col-md-5">
                                  <div className="file_upload">
                                    <div className="upload_file">
                                      <span className="material-symbols-outlined">
                                        add
                                      </span>
                                    </div>
                                    <input onChange={handleFileChange} className="custom-file-input" accept=".doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png, .zip" type="file" 
                                    // accept="application/pdf"
                                    />
                                  </div>
                                </div>}
                              </div>
                              <div className="form-group mt_30">
                                <a href="#" onClick={handleReset} className="border_btn mr_10">RESET</a>
                                <button onClick={handleSubmit} className="fs_btn">add Need</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                        <div className="row">
                        <div className="col-md-12">
                              <div className={`form-group ${errors.internaldescription ? 'has-error' : ''}`}>
                                <label>Internal Description</label>
                                <textarea id="w3review" className="form-control " value={formData.internaldescription} name="internaldescription"onChange={handleInputChange} rows="4" cols="50" placeholder="Enter" spellcheck="false"></textarea>
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  name="internaldescription"
                                  value={formData.internaldescription}
                                  onChange={handleInputChange}
                                  placeholder="Enter"
                                /> */}
                              </div>
                            </div>

                            </div>
                        </div>
                        </div>



                          
                        </div>



                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Need Description</label>
                            <EditorEdit content={formContent} setContent={setFormContent} editorValidate = {editorValidate} setEditorValidate = {setEditorValidate} flag={true}/>
                          </div>
                        </div>
                      </div>
                     </div>
                     </div>}
                </div>
                <div className="modal popup new_popup doc fade" id="viewdocument" tabindex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Jan 2023</h5>
                                <button type="button" className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body">
                                <img src="assets/images/document.svg" alt="" className="w-100" />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal popup new_popup small fade" id="addnote" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"  id="staticBackdropLabel">Add Note</h5>
                                <button type="button" id='addClose' className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body add_note">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="title mt_20 mb_10">Add Note</h3>
                                        <div className="form-group">
                                        <textarea placeholder="Comment......" value={note?.notes} className={`form-control ${noteError && 'invalid-input'}`} onChange={(e)=>{setNote({...note , notes : e.target.value})}} rows="4" cols="50" name="noteText"
                                        //  onChange={updateNoteJSON}
                                         ></textarea>

                                        </div>
                                    </div>
                                   
                                    <div className="col-md-12">
                                        <div className="btn-group mt_20 mb_10"><button onClick={handleAddNote} className="fs_btn">Add Note</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal popup new_popup small fade" id="viewnote" data-bs-backdrop="static" tabindex="-1" data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">VIEW NOTE</h5>
                                <button type="button" id='viewClose' className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body add_note">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        <p>{viewNote}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="btn-group mt_20 mb_10"><button onClick={()=>{document?.getElementById('viewClose').click()}} className="fs_btn">okay, got it</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal popup new_popup fade pdf_doc" id="viewattachment" tabindex="-1"
                    aria-labelledby="pdfdoc_achLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">ATTACHMENT1.jpg</h5>
                                <button type="button" className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="attachment_hed">
                                    <div className="left">
                                        <label className="toggle mb-0">
                                            <input className="toggle-checkbox" type="checkbox" />
                                            <div className="toggle-switch mr_5"></div>
                                            <span className="toggle-label">Verified/Approved</span>
                                        </label>
                                        <label className="toggle mb-0 ">
                                            <input className="toggle-checkbox" type="checkbox" />
                                            <div className="toggle-switch mr_5"></div>
                                            <span className="toggle-label">Rejected</span>
                                        </label>
                                    </div>
                                    <div className="right">
                                        <div className="btn-group">
                                            <button type="button" className="fillbtn_new">Edit<span
                                                    className="material-symbols-outlined">Edit</span></button>
                                            <button type="button" className="fillbtn_new">Download<span
                                                    className="material-symbols-outlined">Download</span></button>
                                            <button type="button" className="fillbtn_new">Delete<span
                                                    className="material-symbols-outlined">Delete</span></button>
                                        </div>
                                    </div>
                                </div>
                                <img src="assets/images/document.svg" alt="" height="30" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default Needs;