import React, {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate,useLocation } from 'react-router-dom';
import { getAllInvestors,getAllFundManagmentAdmin} from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import { formatCurrency, formatPhoneNumber } from '../utils/commonUtils.js';
import Loader from './Loader';
import SweetAlert from 'react-bootstrap-sweetalert';   
import PopupMessage from './PopupMessage.js';
import HomeFooter from './HomeFooter';
import moment from 'moment';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';

const AdminInvestors = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.loaderVisible);
    const [fundsDropdown,setFundsDropdown] = useState([]);
    const [fundManagementData, setFundMangementData] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [showConfirmation,setShowConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const [filterTerm, setFilterTerm] = useState('Easy Street Capital LLC');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTract, setSearchTrack] = useState('');
    const [SearchError, setSearchError] = useState(false);
    const [totalcount,setTotalCount] = useState(null);
    const [currentPage,setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [tableKey, setTableKey] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    let userData = getUserDataFromLocalStorage();
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const location = useLocation();



    const InvestorsDummyData = [
        {
            investorname: "John Doe",
            username: "jdoe",
            created: "2023-09-15T00:00:00Z",
            accredited: true,
            subscriptionbooklet: false,
            typeofreturn: "Growth",
            createdBy: { name: "Admin" }
        },
        {
            investorname: "Jane Smith",
            username: "jsmith",
            created: "2022-06-20T00:00:00Z",
            accredited: false,
            subscriptionbooklet: true,
            typeofreturn: "Growth",
            createdBy: { name: "Manager" }
        },
        {
            investorname: "Michael Johnson",
            username: "mjohnson",
            created: "2023-01-05T00:00:00Z",
            accredited: true,
            subscriptionbooklet: true,
            typeofreturn: "Growth",
            createdBy: { name: "User" }
        },
        {
            investorname: "Emily Brown",
            username: "ebrown",
            created: "2023-03-12T00:00:00Z",
            accredited: false,
            subscriptionbooklet: false,
            typeofreturn: "Growth",
            createdBy: { name: "Admin" }
        },
        {
            investorname: "Chris Lee",
            username: "clee",
            created: "2023-05-25T00:00:00Z",
            accredited: true,
            subscriptionbooklet: true,
            typeofreturn: "Capital",
            createdBy: { name: "Manager" }
        },
    ];
    const [InvestorsData,setInvestorsData] = useState([])

    useEffect(() => {
      if(location.pathname){
          const route = location.pathname;
          const cleanRoute = route.slice(1);
          let permission = userData?.securityInfo?.permission
         
          let checkedPermission =  hasViewPermission(cleanRoute, permission) 
            console.log('viewPermission',location.pathname,checkedPermission); 
            setPipelinePermissions(checkedPermission) 
          //   if(!checkedPermission){
          //     navigate('/pipeline')
          //   }
          }
  
          if(location.pathname){
              const route = location.pathname;
              const cleanRoute = route.slice(1);
              let permission = userData?.securityInfo?.permission
             
              let editPermission =  hasEditPermission(cleanRoute, permission) 
                console.log('editPermission',location.pathname,editPermission); 
                setEditPermissions(editPermission) 
              //   if(!checkedPermission){
              //     navigate('/pipeline')
              //   }
              }
          //setViewStatus()
    }, []);
    
    useEffect(() => {
      let search = '';
      let filter = filterTerm;
      // fetchInvestors(currentPage,perPage,search,filter);
      getFundsName();
      // setInvestorsData(InvestorsDummyData);
    }, []);

    const fetchInvestors = (pageno,perpage,search,filter) => {
      let pageNumber = pageno;
      let perPage = perpage;
      dispatch(showLoader());
      getAllInvestors(pageNumber,perPage,search,filter).then(function (response) {
          if (response?.result) {
              let result = response?.result.data;
              if(result){
                setInvestorsData(result);
              }else{
                setInvestorsData([]);
                // setInvestorsData(InvestorsDummyData);
              };
              setTotalRows(response?.result?.totalCount);
              
          }
          dispatch(hideLoader());
      })
          .catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader());
              setInvestorsData([]);
          })
  };
  const getFundsName = () => {
    let type = 'fundDropdown'
    getAllFundManagmentAdmin('','',type,'')
        .then(function(response) {
            if (response?.result?.data) {
                setFundsDropdown(response.result?.data.map((fundoption) => ({
                    value: fundoption?.uuid,
                    label: fundoption?.name
                })));
            } else {
                setFundsDropdown([]);
            }
            const defaultFund = response.result.data.find(fund => fund.default === true);
            const defaultUuid = defaultFund ? defaultFund.uuid : null;
            if (defaultUuid) {
                setFilterTerm(defaultUuid);
                let search = '';
                fetchInvestors(currentPage,perPage,search,defaultUuid);
            } else {
                setFilterTerm(''); 
                let search = '';
                fetchInvestors(currentPage,perPage,search,'');
            }
        })
        .catch((err) => {
            console.error("error", err);
            dispatch(hideLoader());
        });
};
  
  useEffect(() => {
    console.log('InvestorsData',InvestorsData);
  }, [InvestorsData]);
  const handleTypeFilter = (e) => {
    let search = '';
    let filter = e.target.value;
    setCurrentPage(1);
    // setPerPage(10);
    fetchInvestors(1,perPage,search,filter);
    // setTableKey(prevKey => prevKey + 1);
    setFilterTerm(e.target.value);
    setSearchTerm('');
    setSearchTrack('')
}
  const handleSearch = (e) => {
    let type = '';
    setSearchTerm(e.target.value);
};
const handleSearchClick = () => {
  setCurrentPage(1);
  setPerPage(10);
  if (!searchTerm?.trim()) {
      setSearchError(true);
      return;
  } else {
      
      setSearchError(false);
  }
  let search = '';
  let filter = '';
  // setSortObj({});
  setSearchTrack(searchTerm);
  setFilterTerm('');
  fetchInvestors(1,10,searchTerm,filter);
  // setTableKey(prevKey => prevKey + 1);
};
const handleClearSearch = () => {
  setSearchTrack('');
  setSearchTerm('');
  let search = '';
  let filter = '';
  setCurrentPage(1);
  setPerPage(10);
  fetchInvestors(1,10,search,filter);
  setTableKey(prevKey => prevKey + 1);
}

const handlePageChange = (page) => {
  // if (!isSorting.current) {
      console.log('rannnn');
      let filter = '';
      let search = '';
      // let payload = { ...sortObj };
      if (searchTerm) {
        fetchInvestors(page,perPage,searchTerm,filter);
      } else if (filterTerm) {
        fetchInvestors(page,perPage,search,filterTerm);
      } else {
        fetchInvestors(page,perPage,search,filter);
      }
      setCurrentPage(page);
  // }
};
  const handlePerRowsChange = async (newPerPage, page) => {
    // if (!isSorting.current) {
      console.log('i ran tooo')
      let filter = '';
      let search = '';
      // let payload = { ...sortObj };
      if (searchTerm) {
        fetchInvestors(page,newPerPage,searchTerm,filter);
      } else if (filterTerm) {
        fetchInvestors(page,newPerPage,search,search,filterTerm);
      } else {
        fetchInvestors(page,newPerPage,search,filter);
      }
      setCurrentPage(page);
      setPerPage(newPerPage);
    // }
  };



    const editSheet = (data) => {
        console.log('data',data)
        navigate('/createinvestor', { state: { investorid : data?.uuid,selectedinvestor: data,  mode : "Edit" } });
    };
    const trasactionSummary = (data) => {
      console.log('data',data)
      navigate('/investortransactions', { state: { investorid : data?.uuid,selectedinvestor: data,  } });
  };
  const capitalizeText = (text) => {
    if (!text) return ''; // Handle null/undefined gracefully
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };
  
    
    
      const columns = [
        {
          name: 'Investor Name',
          selector: (row) => 
          capitalizeText(row?.firstname) + " " + capitalizeText(row?.lastname),
          minWidth: '14%',
          maxWidth: '14%',
        },
        ,{
            name: 'User Name',
            selector: (row) => row?.email,
            minWidth: '20%',
            maxWidth: '20%',
          },
        
        {
            name: 'Created Date',
            selector: (row) => moment.utc(row?.created).format("MM-DD-YYYY") || '',
            minWidth: '14%',
            maxWidth: '14%',
        },
        ,{
          name: 'Interest Rate',
          selector: (row) => row?.preferredinvestorreturn 
    ? `${parseFloat(row?.preferredinvestorreturn).toFixed(2)}%` 
    : '',
          // selector: (row) => row?.preferredinvestorreturn ? row?.preferredinvestorreturn + '%' : '',
          minWidth: '15%',
          maxWidth: '15%',
        },
        ,{
          name: 'Balance',
          selector: (row) => row?.balance ? formatCurrency(row?.balance) : '$0.00',
          minWidth: '14%',
          maxWidth: '14%',
        },
    ,{
        name: 'Type of Return',
        selector: (row) => row?.typeofreturn,
        minWidth: '14%',
        maxWidth: '14%',
      },
      
      
        {
            name: 'ACTIONS',
            cell: (row,index) => (
            <>
              <button id = {`editFund-${index}`} type="button" onClick={(e) => {  editSheet(row); }} className="icon_btn edit" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container"><span className="tooltip">Edit</span>edit</span>
              </button>
              <button id = {`investorsummary-${index}`} type="button" onClick={(e) => {  trasactionSummary(row); }} className="icon_btn edit" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container"><span className="tooltip">Summary</span>receipt_long</span>
              </button>
            </>
            ),
            minWidth: '9%',
            maxWidth: '9%',
          },
      ];

      const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
      };

    


  
    const closePopup = () => {
        setShowConfirmation(false)
     }


    return (
        <div id="layout-wrapper">

{loading ? <Loader/> : null} 
           <MainHeader />

          
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management fund_summary investor_statement fund_management investor_list">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Investors</h4>
                            </div>
                            <div className="card" >
                                <div className="card-body ">
                                <div className='search mb-3 align-items-end'>
                                <div className="d-flex align-items-center w-50 justify-content-between">
                            <div className="form-group">
                                    <label>Investment Fund</label>
                                    <select id="investmentfund"  value={filterTerm} name="investmentfund" onChange={(e) => handleTypeFilter(e)} className="form-control" tabindex="1">
                                    <option value="">Select</option>
                                     {fundsDropdown?.map((fundoption, index) => {
                                        return (
                                          <option value={fundoption?.value}>{fundoption?.label}</option>
                                               )

                                       })}
                                  
                                    </select>
                                    </div>
                                    <div className='form-group search_block'>
                                    <label></label>
                                    <div className='d-flex align-items-center'>
                                                <input id="searchinvestor" type="text" className={`form-control ${SearchError && 'invalid-input'}`} onFocus={(e) => { setSearchError(false) }} placeholder='Search Name' value={searchTerm} onChange={handleSearch} />
                                                <button id="searchinvestor-btn" className="material-symbols-outlined btn" onClick={handleSearchClick}>search</button>
                                            </div>
                                            </div>
                                  
                                    </div>
                                    
                                    <label></label>
                                    <button id="createinvestor" type="button" onClick={(e) => {  navigate('/createinvestor')}} className="create_btn" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>CREATE <span className="material-symbols-outlined icon"> add </span></button>
                                   
                                </div>
                                {searchTract &&
                                        <div className='search_result'>
                                            <h4>Search results</h4>
                                            <button type="button" className="border_btn" onClick={handleClearSearch}>Clear search </button>
                                        </div>
                                    }
                                        {InvestorsData?.length > 0 &&
                                            <DataTable 
                                                key={tableKey}
                                                columns={columns}
                                                data={InvestorsData}
                                                paginationTotalRows={totalRows}
                                                pagination
                                                paginationServer
                                                paginationPerPage={perPage}
                                                paginationDefaultPage={currentPage}
                                                customStyles={customStyles}
                                                onChangePage={handlePageChange}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                paginationRowsPerPageOptions={[10, 15, 30, 40]}
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Per Page',
                                                }}
                                                persistTableHead
                                                noDataComponent="No results found" // Show a message when there are no rows
                                            />

                                        }
                                    {InvestorsData?.length === 0  &&
                                        <div className="inprogress_page">
                                            <span className="material-symbols-outlined icon"> content_paste_search </span>
                                            <p>No Data Found</p>
                                        </div>
                                    }
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AdminInvestors;