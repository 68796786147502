import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate,useLocation } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
const DealCredit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let userData = getUserDataFromLocalStorage();
    // console.log("userData",userData)
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    // 
    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.pipelinePermissions
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);


    return (
        <div id="layout-wrapper" className="dashboard">
            <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Processing <span className="space">&#10095;</span> <span className="sub">Credit</span> </h2>
            <div className="card user_management detailed_app" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                <div className="card-body my_account">

                    <div className="row form_section justify-content-center login_box credit_scrore">
                        <div className="xactus">
                            <h4>FETCH CREDIT SCORE</h4>
                            <div className="card overflow-hidden">
                                <div className="card-body text-center">
                                    <img src="assets/images/xactus.svg" />

                                    <form className="form-horizontal" action="#">

                                        <div className="form-group">
                                            <label>Applicant</label>
                                            <input type="text" className="form-control capitalize-text" id="username"
                                                placeholder="Enter Applicant" />
                                        </div>

                                        <div className="form-group">
                                            <label>User Name</label>
                                            <div className="input-group auth-pass-inputgroup">
                                                <input type="password" className="form-control"
                                                    placeholder="Enter User Name" />

                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <div className="input-group auth-pass-inputgroup">
                                                <input type="password" className="form-control"
                                                    placeholder="Enter password" />

                                            </div>
                                        </div>

                                        <div className="btn_wrp">
                                            <button className="fillbtn_new" type="submit">Submit Credit Request</button>
                                            <a href="#" onClick={(e)=>{e.preventDefault();}} className="text-muted">Submit for All
                                                Applicants<span className="material-symbols-outlined">arrow_right</span></a>
                                        </div>
                                    </form>


                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="create_entity">
                        <div className="row form_section justify-content-center login_box credit_scrore">
                            <div className="xactus score_details">
                                <div className="d-flex align-items-center justify-content-center mb-4">
                                    <h5>Garron Johnson</h5>
                                    <img src="assets/images/icons/history.svg" />
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <img className='dummy_img' src="assets/images/score-img.png" />

                                </div>
                                <div className="score_block">
                                    <div className="company_score">
                                        <img src="assets/images/equifax.svg" />
                                        <p>755</p>
                                    </div>
                                    <div className="company_score">
                                        <img src="assets/images/experian.svg" />
                                        <p>750</p>
                                    </div>
                                    <div className="company_score">
                                        <img src="assets/images/transunion.svg" />
                                        <p>747</p>
                                    </div>

                                </div>
                                <div className="other_details">
                                    <h5>Report Number</h5>
                                    <p>Default Fund Rate</p>
                                </div>
                                <div className="other_details">
                                    <h5>PULL DATE</h5>
                                    <p>Mar 15th , 2024</p>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default DealCredit;