import React, { useEffect, useState, useRef } from 'react';
import { getMenus, getAllLoans } from "../redux/reducers/reducer";
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getLoans,getUserPipelineColumns, manageMostRecent, getSavedSearchListApi, getRecentLoans, getStagesForList, searchLoanApi, 
    getSavedNamedSearchApi, deleteSavedSearchListApi ,updateSingleSavedUserSearchData , getSingleSavedUserSearchData} from "../utils/reducer";
import MainHeader from './MainHeader';
import SideMenu from './SideMenu';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions';
import { useNavigate } from 'react-router-dom';
import { useLoanInfo } from '../utils/loanInfoContext';
import SelectColumns from './SelectColumns';
import SearchOptions from './SearchOptions';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import HomeFooter from './HomeFooter';
import moment from 'moment';
import Tasks from './Tasks';
import { Appname, APIUrl } from "../utils/reducer";
import ShareSearch from './ShareSearch';
import { formatCurrency } from '../utils/commonUtils';
import { getTokenFromLocalStorage, getUserDataFromLocalStorage } from '../utils/local';
import axios from 'axios';
import { hasViewPermission } from '../utils/permissionsCheck';
import PopupMessage from './PopupMessage';
import { usePipeline } from '../utils/PipelineContext';
import PipelineNotes from './PipelineNotes';

const Pipeline = () => {
    const navigate = useNavigate();
    const scrollContainerRef = useRef(null);
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const menusdata = useSelector(state => state.menusdata);
    const loansData = useSelector(state => state.loansdata);
    const totalcountData = useSelector(state => state.totalcount);
    const dispatch = useDispatch();
    const [loans, setLoans] = useState(loansData);
    // const [showPage, setShowPage] = useState('');
    // const[advFormData,setAdvFormData]=useState({});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('Saved Successfully');
    const [countByStage, setCountByStage] = useState({});
    // const [filteredStage, setFilteredStage] = useState('Account Executive Review');
    const [totalcount, setTotalCount] = useState(totalcountData);
    // console.log('totalcountData totalcount',totalcountData,totalcount)

    // const [currentPage,setCurrentPage] = useState(1);
    // const [perPage, setPerPage] = useState(10);
    const [userData, setUserData] = useState(null);
    const [NotesFlag, setNotesFlag] = useState(false)
    const [activeLoanidNotes, setactiveLoanidNotes] = useState("")
    const [stageOptionKeys, setStageOptionKeys] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [searchErr, setSearchErr] = useState("");
    const loading = useSelector((state) => state.loaderVisible);
    const [mostRecentLoans, setMostRecentLoans] = useState([]);
    const [stagesForList, setStagesForList] = useState([]);
    const configdata = useSelector(state => state.configdata);
    const [savedSearchData, setSavedSearchData] = useState({});
    const [showOptions, setShowOptions] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    // const [advFlag, setAdvFlag] = useState(false);
    // const [sortObj, setSortObj] = useState({});
    // const [isSorting, setIsSorting] = useState(false);
    const isSorting = useRef(false);
    const isInitialRender = useRef(true);
    const isInitialPageChange = useRef(true);
    const isInitialPerPageChange = useRef(true);
    const [pipelinePermissions, setPipelinePermissions] = useState([]);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const inputRef = useRef(null);
    const [NameSearchErr, setNameSearchErr] = useState({});
    // const [advanceNameSearch, setAdvanceNameSearch] = useState('');
    const [columns, setColumns] = useState([]);
    const [tableKey, setTableKey] = useState(0);
    const [isloadDone, setIsloadDone] = useState(false);
    // // console.log('configdatahere',configdata);
    // const [filteredLoans, setfilteredLoans] = useState([]);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [searchLoader, setSearchLoader] = useState(null);
    const abortControllerRef = useRef(null);
    const [savedUserSearchData,setSavedUserSearchData] = useState({});
    const [savedUserSearchId,setSavedUserSearchId] = useState(null);
    const [searchSaveUserNum,setSearchSaveUserNum] = useState(null);
    const typingTimeoutRef = useRef(null);
    const [notSavedSearchData,setNotSavedSearchData] = useState(false);
    const [displayUnSavedData,setDisplayUnSavedData] = useState(false);
    const [saveSearchClose,setSaveSearchClose] = useState(false);
    const [isReset,setIsReset] = useState(false);

    const [stagesToMatch, setStagesToMatch] = useState(configdata?.admin?.loanStatus || [
        "ALL",
        "Account Executive Review",
        "Underwriting",
        "Term Sheet Issued",
        "Processing",
        "Reviewed/Requested",
        "On Hold",
        "Docs Out",
        "Funded",
        "Closed",
        "Cancelled",
        "Terms Not Issued",
        "Servicing Setup",
        "Servicing"
    ]);
    const { usePrevious, route, setPerPage, setCurrentPage, currentPage, perPage, setRoute, usePreviousRoute, advFormData, setAdvFormData, advFlag, setAdvFlag, showPage, setShowPage,advanceNameSearch, setAdvanceNameSearch ,filteredStage, setFilteredStage,filteredLoans, setfilteredLoans,sortObj, setSortObj,getTaskData, setGetTaskData} = usePipeline();  // Get context values
    const prevRoute = usePreviousRoute();  // Track the previous route
// console.log('stagesToMatch',configdata?.admin)


    useEffect(() => {
        // Set a timeout to change isloadDone to true after 10 seconds
        const timer = setTimeout(() => {
            setIsloadDone(true);
        }, 5000);

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, []); // Empty dependency array means this runs once on mount
    // console.log('advFormDataadvFormDataadvFormDataadvFormDataadvFormData', totalcount)
    // useEffect(() => {
    //     // Check if the user is coming back from another page
    //     if (prevRoute === "loanDetails" && route === "pipelinePage") {
    //         console.log('prev route use effect--------------------------------->')
    //         // User is navigating back to the pipeline, restore pagination
    //         dispatch(showLoader());
    //         dispatch(getAllLoans(currentPage, perPage, 'Account Executive Review', setTotalCount));  // Fetch with saved pagination
    //     }
    // }, [prevRoute, route]); 

    // useEffect(() => {
    //     if (prevRoute != undefined && prevRoute != route) {
    //         console.log('use eefffect prevroute')

    //     }
    // }, [prevRoute]);
// console.log('sortobj',sortObj)
useEffect(() => {
    if(showPage === "" && filteredStage === "" && !advFlag){
        // setFilteredStage("Account Executive Review")
        setFilteredStage("ALL")
    }
}, [showPage,filteredStage]);
    useEffect(() => {

        setRoute("pipelinePage");

    }, []);
    useEffect(() => {
        if (configdata?.admin?.loanStatus) {
            setStagesToMatch(configdata?.admin?.loanStatus)
        }
    }, [configdata]);
    // useEffect(() => {
    //     dispatch(showLoader());
    //     // dispatch(getAllLoans());
    //     getAllLoansFun();
    //     getColumns();
    //     getSavedSearchListFun();

    //     getRecentLoans().then(function (response) {
    //         // console.log('getRecentLoans', response);
    //         if (response?.result) {
    //             console.log('recent loanss',response?.result?.[0]?.recentLoans)
    //             setMostRecentLoans(response?.result?.[0]?.recentLoans?.reverse())
    //         }
    //     }).catch((err) => {
    //         // console.error.bind("my account error", err);
    //     })
    //     getStagesForList().then(function (response) {
    //         // console.log('getRecentLoans', response);
    //         if (response?.result) {
    //             const options = Object.keys(response?.result);

    //             setStageOptionKeys(options)
    //             setStagesForList(response?.result)
    //         }
    //     }).catch((err) => {
    //         // console.error.bind("my account error", err);
    //     })
    // }, []);


    useEffect(() => {
        // if (currentPage && perPage) {  // Only call if currentPage and perPage are set
            dispatch(showLoader());
            getColumns();
            if(showPage=== "MyTasks" && filteredStage === "" && !advFlag){
                dispatch(hideLoader())
            }else{
                getAllLoansFun();  // Fetch loans only when necessary
               
            }
           
            getSavedSearchListFun();

            getRecentLoans().then(function (response) {
                if (response?.result) {
                    setMostRecentLoans(response?.result?.[0]?.recentLoans?.reverse());
                }
            }).catch((err) => {
                console.error("Error fetching recent loans", err);
            });

            getStagesForList().then(function (response) {
                if (response?.result) {
                    const options = Object.keys(response?.result);
                    setStageOptionKeys(options);
                    setStagesForList(response?.result);
                }
            }).catch((err) => {
                console.error("Error fetching stages", err);
            });
        // }
    // }, [currentPage, perPage]);  // Depend on currentPage and perPage to trigger the effect
}, []);
    useEffect(() => {
        const defaultColumns = [
            { "label": "LOAN ID", "id": "loanid", "checked": false },
            { "label": "BORROWER NAME", "id": "borrowername", "checked": false },
            { "label": "AE NAME", "id": "accountexecutive", "checked": false },
            { "label": "PROCESSOR NAME", "id": "processorname", "checked": false },
            { "label": "UW NAME", "id": "uwname", "checked": false },
            { "label": "LENDER CASE ID", "id": "lendercaseindentifier", "checked": false },
            { "label": "LOAN AMOUNT", "id": "loanamount", "checked": false },
            { "label": "PROPERTY STATE", "id": "propertystate", "checked": false },
            { "label": "CREATED DATE", "id": "created", "checked": false },
            { "label": "PHASE", "id": "phase", "checked": false },
            { "label": "STAGE", "id": "status", "checked": false },
            { "label": "REQUESTED CLOSING DATE", "id": "requestedclosingdate", "checked": false }
        ];

        const givenArray = selectedColumns || defaultColumns

        const columns = givenArray.map(item => {
            let selector;
            switch (item.id) {
                case "borrowername":
                    selector = (row) => row?.borrowername || '';
                    break;
                case "looffice":
                    selector = (row) => row?.looffice?.[0] || '';
                    break;
                case "propertystate":
                    selector = (row) => row?.propertystate || '';
                    break;
                case "created":
                    selector = (row) => row?.created ? moment.utc(row?.created).format("MM-DD-YYYY") : '';
                    break;
                case "requestedclosingdate":
                    selector = (row) => row?.requestedclosingdate ? moment.utc(row?.requestedclosingdate).format("MM-DD-YYYY") : '';
                    break;
                case "phase":
                    selector = (row) => row?.phase || '';
                    break;
                case "processorname":
                    selector = (row) => row?.processorname || '';
                    break;
                case "uwname":
                    selector = (row) => row?.uwname || '';
                    break;
                case "loanamount":
                    selector = (row) => row?.loanamount && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row?.loanamount) || '';
                    break;
                case "rehabfunding":
                    selector = (row) => row?.rehabfunding && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row?.rehabfunding) || '';
                    break;
                case "lendercaseindentifier":
                    selector = (row) => row?.lendercaseindentifier || '';
                    break;
                
                case "stage":
                    selector = (row) => row?.stage || '';
                    break;
                case "recurringpayments":
                    selector = (row) => row?.recurringpayments === true ? 'true'  : row?.recurringpayments=== false ? 'false' : '';
                    break;
                // case "categories":
                //     selector = (row) => row?.categories ? row?.categories?.join(',') : '';
                //     break;
                case "categories":
                selector = (row) => {
                    if (Array.isArray(row?.categories)) {
                        return row.categories.join(',');
                    }
                    return ''; // Fallback for non-array or undefined values
                };
                break;

                case "propertyaddress":
                    selector = (row) => row?.propertyaddress || '';
                    break;
                default:
                    selector = (row) => {
                        const keys = item.id.split('.');
                        let value = row;
                        for (const key of keys) {
                            if (value && value.hasOwnProperty(key)) {
                                let dates = ['created', 'requestedclosingdate', 'actalcloseddate', 'declaredmaturitydate', 'scheduledclosingdate',
                            'lastpaymententered','lastpaymentdate','nextpaymentDate','currentpaymentDate','payoffexpirationdate','lastdrawdate','dateoflastdraw','currentduedate','stagedate','lastchangeddate',
                            'nextduedate','loanstartdate','servicingmaturitydate','servicingenddate','phasedate','folderdate'];
                                let currensy = ['loanamount', 'loanoriginationfee', 'rehabfunding', 'initialfunding','disbursedloanamount',
                            'servicedLoanAmount','lastpaymentamount','currentdueamount','approveddrawamount','drawbalance','requesteddrawamount',
                            'dayonedisbursement', 'currentservicingloanamount','servicedloanamount','servicingloanamount','holdbackamount'];
                                let arrayValues = ['borrowerstatedcredit', 'ltc',''];
                                let arrayAmountValues = ['arv','rehabcost']
                                if (dates.includes(key)) {
                                    value = value[key] && moment.utc(value[key]).format("MM-DD-YYYY")
                                } else if (currensy.includes(key)) {
                                    value = value[key] && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value[key]) || '';
                                } else if (arrayValues.includes(key)) {
                                    value = value[key]?.[0] || '';
                                } else if (arrayAmountValues.includes(key)) {
                                    value = value[key]?.[0] && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value[key]?.[0]) || '';
                                } else {
                                    value = value[key];
                                }
                            } else {
                                return '';
                            }
                        }
                        return value;
                    };
            }

            // Render loan ID column differently
            if (item.id === "loanid") {
                return {
                    name: item.label,
                    id: item.id,
                    cell: (row) => (
                        <div className="tb_menu">
                            <button className="tb_btn">{row[item.id]} <span className="material-symbols-outlined icon"> expand_more </span></button>
                            {userData && <div className="dropdown-content">
                                <div className="number">Loan ID : {row[item.id]}</div>
                                <ul>
                                    {hasViewPermission('dashboard', pipelinePermissions) && <li><a id="navigatePipelineDashBard" href={"/dashboard?loanid=" + row.loanid} onClick={(e) => { e.preventDefault(); handleNavigation('/dashboard', row); }}> <span className="material-symbols-outlined icon"> space_dashboard </span> Dashboard</a></li>}
                                    {hasViewPermission('deal', pipelinePermissions) && <li><a id="navigateDealDeatiledApp" href={"/dealdetailedapp?loanid=" + row.loanid} onClick={(e) => { e.preventDefault(); handleNavigation('/dealdetailedapp', row); }}> <span className="material-symbols-outlined icon"> handshake </span> Deal</a></li>}
                                    {hasViewPermission('underwriting', pipelinePermissions) && <li><a id="navigateDealUnderwritingTermsheet" href={"/underwritingtermsheet?loanid=" + row.loanid} onClick={(e) => { e.preventDefault(); handleNavigation('/underwritingtermsheet', row); }}> <span className="material-symbols-outlined icon"> signature </span> Underwriting</a></li>}
                                    {hasViewPermission('processing', pipelinePermissions) && <li><a id="navigateProcessingForms" href={"/processingforms?loanid=" + row.loanid} onClick={(e) => { e.preventDefault(); handleNavigation('/processingforms', row); }}> <span className="material-symbols-outlined icon"> article_shortcut </span> Processing</a></li>}
                                    {hasViewPermission('closingandfunding', pipelinePermissions) && <li><a id="navigateClearToClose" href={"/cleartoclose?loanid=" + row.loanid} onClick={(e) => { e.preventDefault(); handleNavigation('/cleartoclose', row); }}> <span className="material-symbols-outlined icon"> task </span> Closing</a></li>}
                                    {/* {hasViewPermission('funding', pipelinePermissions) &&<li><a id="navigateInstructions" href="/instructions" onClick={(e) => { e.preventDefault(); handleNavigation('/instructions', row); }}> <span className="material-symbols-outlined icon"> payments </span> Funding </a></li>} */}
                                    {/* <li><a href="/comingup" onClick={(e) => { e.preventDefault(); handleNavigation('/pricingratelock', row); }}> <span className="material-symbols-outlined icon"> request_quote </span> Pricing</a></li> */}
                                    {hasViewPermission('servicing', pipelinePermissions) && <li><a id="navigateServicingLoanSummary" href={"/servicingservicingsetup?loanid=" + row.loanid} onClick={(e) => { e.preventDefault(); handleNavigation('/servicingservicingsetup', row); }}> <span className="material-symbols-outlined icon"> style </span> Servicing</a></li>}
                                    {hasViewPermission('draws', pipelinePermissions) && <li><a id="navigateDrawLoanSummary" href={"/drawloansummary?loanid=" + row.loanid} onClick={(e) => { e.preventDefault(); handleNavigation('/drawloansummary', row); }}> <span className="material-symbols-outlined icon"> import_contacts</span> Draws</a></li>}
                                </ul>
                            </div>}
                        </div>
                    ),
                    sortable: true,
                    minWidth: '160px',
                    maxWidth: '170px',
                };
            }
            if (item.id === "drawinteresttype") {
                return {
                    name: item.label,
                    id: item.id,
                    cell: (row) => (
                        <div className="tb_menu">
                            <input
                           className="toggle-checkbox" 
                                type="checkbox"
                                disabled
                                checked={row?.drawinteresttype === "Interest on Full Balance"}
                                readOnly
                            /><div class="toggle-switch"></div>
                        </div>
                    ),
                    sortable: true,
                    minWidth: '160px',
                    maxWidth: '170px',
                };
            }
            if (item.id === "pipelinenotes") {
                return {
                    name: item.label,
                    id: item.id,
                    cell: (row) => (
                        <div className="tb_menu">
                            <button className="border_btn" onClick={()=>{onclickNotes(row['loanid'])}}>{row['pipelinenotes'] > 0 ? row['pipelinenotes'] : <span className="material-symbols-outlined icon">add</span>}





                            </button>
                          
                        </div>
                    ),
                    sortable: true,
                    minWidth: '160px',
                    maxWidth: '170px',
                };
            }
            return {
                name: item.label,
                id: item.id,
                selector: selector,
                sortable: true,
                minWidth: '160px',
                maxWidth: '170px',
            };
        });

        setColumns(columns);

    }, [selectedColumns]);
    // useEffect(() => {
    //     console.log('useEffect perPage', perPage)
    //     console.log('useEffect currentPage', currentPage)
    // }, [perPage, currentPage]);

const onclickNotes= (loanid) =>{
    setNotesFlag(true);
    setactiveLoanidNotes(loanid);
    console.log('loanidloanidloanidloanidloanid',loanid)
}
useEffect(() => {
    // console.log('useEffect activeLoanidNotes', activeLoanidNotes)
}, [activeLoanidNotes,NotesFlag]);

    useEffect(() => {
        // console.log('useEffect currentPage', currentPage)
    }, [currentPage]);

    // const getAllLoansFun = () =>{
    //     // // dispatch(getAllLoans());
    //     setFilteredStage('Account Executive Review')
    //     setCurrentPage(1);
    //         console.log('getAllLoansFun');
    //     dispatch(getAllLoans(1,10,'Account Executive Review',setTotalCount));

    // }
    // console.log('sortttttttttttttttobjjjj',sortObj)
    // const getAllLoansFun = () => {
    //     console.log('getAllLoansFun');
    //     dispatch(getAllLoans(currentPage, perPage, 'Account Executive Review', setTotalCount));
    // };

    const getAllLoansFun = () => {
        console.log('getAllLoansFun');
        console.log('advFormData',advFormData);
        // Check if advFormData is an object and has keys
        if (advFormData && Object.keys(advFormData).length > 0) {
            console.log('BLOCK 11111111111');

            // If advFormData has keys, call getAllLoans with it
            dispatch(getAllLoans(currentPage, perPage, '', setTotalCount,{...sortObj, ...advFormData}, false, "A1.1"));  // Not a saved search
        } else if(filteredStage) {
            console.log('BLOCK 22222222222');

            // If advFormData is empty, call getAllLoans with an empty object
            dispatch(getAllLoans(currentPage, perPage, filteredStage, setTotalCount, {...sortObj}, false, "A1.2"));  // Not a saved search
        }else {
            console.log('BLOCK 333333333');

            // If advFormData is empty, call getAllLoans with an empty object
            // dispatch(getAllLoans(currentPage, perPage, 'Account Executive Review', setTotalCount, {...sortObj}, false, "A1.3"));  // Not a saved search
            dispatch(getAllLoans(currentPage, perPage, 'ALL', setTotalCount, {...sortObj}, false, "A1.3"));  // Not a saved search
        }
    };//old code


    
    // console.log('showppage ------->',showPage)
    // console.log('advFlag ------->',advFlag)
    // console.log('advanceNameSearch ------->',advanceNameSearch)
    // const handlePerPageChange = (newPerPage, page) => {
    //     console.log('handlePerPageChange')
    //     setPerPage(newPerPage);
    //     setCurrentPage(1);
    //     console.log('1111111111111111 PerPage')
    //     if (!isSorting.current) {
    //         console.log('222222222222222 PerPage')

    //         if (filteredStage) {
    //             console.log('33333333333333 PerPage')

    //             handleSavedSearchData(1, newPerPage, { ...sortObj, status: filteredStage }, 'page');
    //         } else {
    //             console.log('4444444444444 PerPage')

    //             handleSavedSearchData(1, newPerPage, { ...sortObj, ...advFormData }, 'page');
    //         }
    //     }
    // };

    // const handlePageChange = (page) => {
    //     setCurrentPage(page);
    //     console.log('555555555555555 PerPage')

    //     if (!isSorting.current) {
    //         console.log('666666666666666 PerPage')

    //         if (filteredStage) {
    //             console.log('777777777777 PerPage')

    //             handleSavedSearchData(page, perPage, { ...sortObj, status: filteredStage }, 'page');
    //         } else {
    //             console.log('88888888888888 PerPage')

    //             handleSavedSearchData(page, perPage, { ...sortObj, ...advFormData }, 'page');
    //         }
    //     }
    // };

    const handlePageChange = (page) => {
        if (isloadDone) {


            setCurrentPage(page);
            console.log('Page change:', page);

            if (!isSorting.current) {
                // if (filteredStage) {
                //     handleSavedSearchData(page, perPage, { ...sortObj, status: filteredStage }, 'page');
                // } else {
                //     handleSavedSearchData(page, perPage, { ...sortObj, ...advFormData }, 'page');
                // }

                if (advFormData && Object.keys(advFormData).length > 0) {
                    handleSavedSearchData(page, perPage, { ...sortObj, ...advFormData }, 'page');
                }else if (filteredStage) {
                    handleSavedSearchData(page, perPage, { ...sortObj, status: filteredStage }, 'page');
                } else {
                    handleSavedSearchData(page, perPage, { ...sortObj }, 'page');
                }
            }
        }
    };

    
    
    const handlePerPageChange = (newPerPage, page) => {
        if (isloadDone) {
            console.log('Per page change:', newPerPage);
            setPerPage(newPerPage);
            setCurrentPage(1);

            if (!isSorting.current) {
                if (advFormData && Object.keys(advFormData).length > 0) {
                    handleSavedSearchData(1, newPerPage, { ...sortObj, ...advFormData }, 'page');
                }else if (filteredStage) {
                    handleSavedSearchData(1, newPerPage, { ...sortObj, status: filteredStage }, 'page');
                } else {
                    handleSavedSearchData(1, newPerPage, { ...sortObj}, 'page');
                }
            }
        }
    };

    // const handlePerPageChange = (newPerPage, page) => {
    //     if (isInitialPerPageChange.current) {
    //         isInitialPerPageChange.current = false;
    //         return;
    //     }
    //     dispatch(showLoader());
    //     if (filteredStage) {
    //         handleSavedSearchData(1, newPerPage, { ...sortObj, status: filteredStage }, 'page');
    //     } else {
    //         handleSavedSearchData(1, newPerPage, { ...sortObj, ...advFormData }, 'page');
    //     }
    //     setPerPage(newPerPage);
    //     setCurrentPage(1);
    // };
    // const handlePageChange = (page) => {
    //     if (isInitialPageChange.current) {
    //         isInitialPageChange.current = false;
    //         return;
    //     }
    //      if (isSorting) {
    //   console.log('blocked page change', isSorting);
    //   return;
    // }
    //     dispatch(showLoader());
    //     if(filteredStage){
    //         console.log('handlePageChange ',isSorting,'filteredStage',sortObj);

    //         // dispatch(getAllLoans(page,perPage,filteredStage,setTotalCount));
    //         handleSavedSearchData(page,perPage,{...sortObj,status:filteredStage},'page');

    //     // }else if(Object.keys(advFormData).length>0){
    //     }else {
    //         console.log('handlePageChange filteredStage');

    //         // dispatch(getAllLoans(page,perPage,filteredStage,setTotalCount));
    //         handleSavedSearchData(page,perPage,{...sortObj,...advFormData},'page');
    //     }
    //     console.log('pageee',page)
    //     setCurrentPage(page);
    //   };
    //   const handlePerPageChange = (newperPage,page) => {
    //     if (isInitialPerPageChange.current) {
    //         isInitialPerPageChange.current = false;
    //         return;
    //     }
    //     console.log('rannnnn',newperPage)
    //     dispatch(showLoader());
    //     if(filteredStage){
    //         console.log('handlePerPageChange filteredStage');
    //         handleSavedSearchData(1,newperPage,{...sortObj,status:filteredStage},'page');

    //         // dispatch(getAllLoans(1,newperPage,filteredStage,setTotalCount));
    //     // }else if(Object.keys(advFormData).length>0){
    //     }else {
    //         console.log('handlePerPageChange handleSavedSearchData');

    //         handleSavedSearchData(1,newperPage,{...sortObj,...advFormData},'page');
    //     }
    //     // dispatch(getAllLoans(1,newperPage,filteredStage,setTotalCount));
    //     setPerPage(newperPage);
    //     setCurrentPage(1);
    //   };
    //   const handleFilterChange=(key)=>{
    //     console.log('handleFilterChange');
    //     setPerPage(10);
    //     setCurrentPage(1);
    //     setTotalCount(10);
    //     setTableKey(prevKey => prevKey + 1);
    //     dispatch(showLoader());
    //     setFilteredStage(key);
    //     dispatch(getAllLoans(1,10,key,setTotalCount));
    //     setShowPage('');
    //     setSearchInputValue('');
    //     setAdvFormData({});
    //     setAdvFlag(false);


    //   }
    const handleFilterChange = (key) => {
        console.log('handleFilterChange');
        setPerPage(10);
        setCurrentPage(1);
        // setTotalCount(10);
        setTableKey(prevKey => prevKey + 1);
        dispatch(showLoader());
        setFilteredStage(key);
        dispatch(getAllLoans(1, 10, key, setTotalCount, {}, false, "A2"));  // Not a saved search
        setShowPage('');
        setSearchInputValue('');
        setAdvFormData({});
        setAdvFlag(false);
    };

    // // console.log('showPage showPage',showPage);
    // // console.log('loans data 11111111111111',loansData);
    const getColumns = () => {
        getUser().then(function (response) {
            if (response?.result) {
                const filteredTrue = response?.result?.[0]?.pipelinecolumns?.filter(obj => obj.default);
                const transformedTrue = filteredTrue
                    .map(obj => ({
                        label: obj.name,
                        id: obj.field,
                        checked: false,
                        order: obj.order
                    }))
                    .sort((a, b) => a.order - b.order);
                setSelectedColumns(transformedTrue);
                setPipelinePermissions(response?.result?.[0]?.securityInfo?.pipelinePermissions)
                setUserData(response?.result?.[0]?.userInfo || response?.result?.[0])
            }
        }).catch((err) => {
            // console.error.bind("my account error", err);
        })
    }
    // console.log('selectedColumnsselectedColumnsselectedColumnsselectedColumns', loansData);
    useEffect(() => {
        // console.log('loansDatahere', loansData);
        if (loansData) {
            setLoans(loansData);
            // setTotalCount(loansData?.totalCount)
            // setCurrentPage(loansData?.PageNumber)

            let countByStages = {};
            stagesToMatch.forEach(stage => {
                countByStages[stage] = 0;
            });

            loansData?.forEach(obj => {
                const currentStage = obj.status;
                if (currentStage && stagesToMatch.includes(currentStage)) {
                    countByStages[currentStage]++;
                } else {
                    // countByStages['Account Executive Review']++;
                    countByStages['ALL']++;
                }
            });
            setCountByStage(countByStages)
        }
    }, [loansData]);
    useEffect(()=>{
        if(totalcountData){
            setTotalCount(totalcountData);
        }

    },[totalcountData])
    const customNoRecords = () => {
        return (


            <div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No Loans were found for the searched keyword</p></div>
        )
    }

    // useEffect(() => {
    //     // console.log('filteredStage', filteredStage);
    //     const filteredLoans = loansData?.filter(obj => {
    //         const currentStage = obj.status;
    //         if (!currentStage) {
    //             return filteredStage === 'Account Executive Review';
    //         }
    //         return currentStage && currentStage === filteredStage;
    //     });

    //     setLoans(filteredLoans);
    // }, [filteredStage]);
    const advFlagRef = useRef(advFlag);
    const advFormDataRef = useRef(advFormData);
    useEffect(() => {
        advFlagRef.current = advFlag;
        advFormDataRef.current = advFormData;
        // console.log('here advFormData------------------------------------------------------->', advFlag, showPage, advFormData);
    }, [advFlag,advFormData]);
    useEffect(() => {
        // console.log('here advFormData2222', advFlag, showPage, advFormData);
    }, []);


    const handleNavigation = (path, key) => {
        if (hasViewPermission(path, pipelinePermissions)) {
            //setLoanInfo(key);
            addToRecent(key)
            //navigate(path+"/"+key.loanid);
            if (advFlagRef.current) {
                console.log('advFormDataadvFormData', advFormDataRef?.current);
                 navigate(path + "?loanid=" + key.loanid, { state: advFormDataRef?.current });
            } else {
                 navigate(path + "?loanid=" + key.loanid);
            }
        } else {
            const cleanRoute = path.startsWith('/') ? path.slice(1) : path;
            let currentRoute = cleanRoute == 'underwritingtermsheet' ? 'underwriting' : cleanRoute == 'dealdetailedapp' ? 'deal' : cleanRoute == 'processingforms' ? 'processing' : cleanRoute == 'dealunderwritingtermsheet' ? 'underwriting' : cleanRoute == 'cleartoclose' ? 'closingandfunding' : cleanRoute == 'servicingloansummary' ? 'servicing' : cleanRoute == 'drawloansummary' ? 'draws' : cleanRoute;
            let currentMenu = pipelinePermissions.find(option => option.route === currentRoute)
            console.log('path', path);
            console.log('currentMenu', currentMenu);
            if (path == '/dashboard') {
                let firstAvaialblemenu = pipelinePermissions?.find(option => option.view === true);
                console.log('firstAvaialblemenu', firstAvaialblemenu);
                const firstAvaialblemenuSubmenu = firstAvaialblemenu?.submenus?.find(submenu => submenu.view === true);
                if (firstAvaialblemenuSubmenu?.route) {
                    addToRecent(key)
                    if (advFlagRef.current) {
                         navigate('/' + firstAvaialblemenuSubmenu?.route + "?loanid=" + key.loanid, { state: advFormDataRef?.current });
                    } else {
                         navigate('/' + firstAvaialblemenuSubmenu?.route + "?loanid=" + key.loanid);
                    }
                }
            }
            const firstViewTrueSubmenu = currentMenu?.submenus?.find(submenu => submenu.view === true);
            if (firstViewTrueSubmenu?.route) {
                addToRecent(key)
                if (advFlagRef.current) {
                     navigate('/' + firstViewTrueSubmenu?.route + "?loanid=" + key.loanid, { state: advFormDataRef?.current });
                } else {
                     navigate('/' + firstViewTrueSubmenu?.route + "?loanid=" + key.loanid);
                }
            }


        }
    };
    const tasks = () => {
        navigate('/tasks')
    }
    // const handleSort = (column, sortDirection) => {
    //     console.log('sort funcitonnnn')

    //     isSorting.current = true;
    //     let name = column?.id?.toLowerCase();
    //     name = name === "loan id" ? "loanid" : name;
    //     let direction = sortDirection === "asc" ? "ascending" : "descending";
    //     let payload = { type: direction, sortBy: name };
    //     if (filteredStage) {
    //         payload.status = filteredStage;
    //         setFilteredStage(filteredStage);
    //     }
    //     setSortObj({ ...payload });
    //     handleSavedSearchData(1, perPage, { ...advFormData, ...payload }, 'page');
    //     setTimeout(() => isSorting.current = false, 500);
    // };
    const handleSort = (column, sortDirection) => {
     
        if (isloadDone) {
            dispatch(showLoader())
            console.log('Sorting function triggered');

            // Set the sorting flag
            isSorting.current = true;

            // Prepare sort parameters
            let name = column?.id?.toLowerCase();
            name = name === "loan id" ? "loanid" : name;  // Map "loan id" to "loanid"

            let direction = sortDirection === "asc" ? "ascending" : "descending";
            let payload = { type: direction, sortBy: name };  // Sorting payload

            // If there's a filtered stage, include it in the payload
            // if (filteredStage) {
            //     payload.status = filteredStage;
            //     setFilteredStage(filteredStage);  // Ensure the UI reflects the selected stage
            // }

            // Update the sorting object
            setSortObj({ ...payload });
            let isStage = "";
            if (filteredStage) {
                isStage = filteredStage
                setFilteredStage(filteredStage);
            }
            // Call the unified API with the sorting information
            dispatch(getAllLoans(1, perPage, isStage, setTotalCount, { ...advFormData, ...payload }, false, "A3"));
            dispatch(hideLoader())
            // Reset sorting flag after a brief delay
            setTimeout(() => isSorting.current = false, 500);
        }
    };

    // const handleSort = (column, sortDirection,clicked) => {

    //     if (isInitialRender.current) {
    //         // Skip the initial sort call
    //         isInitialRender.current = false;
    //         return;
    //     }
    //     isSorting = true;
    //     console.log('handle sort', column)
    //     // console.log('sort direction', sortDirection)

    //     let name = column?.id?.toLowerCase();
    //     name = name === "loan id" ? "loanid" : name ;

    //     // let k = [ borrowername,looffice,propertystate,created,requestedclosingdate,phase,processorname,uwname,loanamount,holdbackamount,lendercaseindentifier,stage,propertyaddress]
    //     let direction = sortDirection === "asc" ? "ascending" : "descending"
    //     let payload = { "type": direction, "sortBy": name }
    //     // setAdvFormData({...advFormData,...payload})
    //     if(filteredStage){

    //         payload.status = filteredStage
    //         setFilteredStage(payload.status)
    //     }
    //         setSortObj({...payload})

    //     handleSavedSearchData(1,perPage,{...advFormData,...payload},'page');
    //     setTimeout(() => isSorting = false, 500); 
    // };
    // useEffect(()=>{
    //     if (isInitialRender2.current) {
    //         // Skip the first invocation
    //         isInitialRender2.current = false;
    //         return;
    //     }
    //     console.log('useffect sort obj',sortObj)
    //     handleSavedSearchData(1,perPage,sortObj,'page');
    // },[sortObj])

    // Generate columns array dynamically
    const handleClearSearch = (param) => {
        setSavedUserSearchId("");
        setSaveSearchClose(false);
        setNameSearchErr("");
        setSavedSearchData({});
        setSearchSaveUserNum("");
        if (advanceNameSearch === 'advanceNameSearch') {
            setShowPage('SearchOptions');
            setAdvFormData({});
            setfilteredLoans([]);
            setFilteredStage('');
            setSearchInputValue("");
            setPerPage(10);
            setCurrentPage(1);
            setTotalCount(10);
            setAdvanceNameSearch('');

        }else{
            dispatch(showLoader());
            // setLoans([])
            const clearedObject = { ...advFormData };
            for (let key in clearedObject) {
                delete clearedObject[key];
            }
            console.log('clearedObject', clearedObject);
            setAdvFormData(clearedObject);
            //setAdvFormData({});
            // setFilteredStage()
            setSortObj({})
            setShowPage("")
            setAdvFlag(false)
            setPerPage(10)
            setSearchInputValue("")
            // setFilteredStage('Account Executive Review')
            setFilteredStage('ALL')

            setCurrentPage(1)
            getAllLoansDefaultStateFunction()
            // dispatch(hideLoader());
            setAdvanceNameSearch('');
        }
        setTableKey(prevKey => prevKey + 1);
        getSavedSearchListFun();
    }

    const handleExitSearch = () => {
        setAdvFlag(false);//added newly

        setShowPage('SearchOptions');
        if(!notSavedSearchData){
            setAdvFormData({});
        }
       
        setfilteredLoans([]);
        setFilteredStage('');
        setSearchInputValue("");
        
        setSavedUserSearchId(searchSaveUserNum);
        if(notSavedSearchData){
            setDisplayUnSavedData(true);
        }
    }
    
    const getAllLoansDefaultStateFunction = () => {
        // console.log('getAllLoansDefaultStateFunction');
      
        // dispatch(getAllLoans(1, 10, 'Account Executive Review', setTotalCount, {}, false, "A8"));  // Not a 
        dispatch(getAllLoans(1, 10, 'ALL', setTotalCount, {}, false, "A8"));  // Not a 
        
    };

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
    const exportToExcel = () => {
        dispatch(showLoader())
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        // let status = filteredStage
        const { sortBy, status, type, ...body } = { ...sortObj, ...advFormData, status: filteredStage };

        let token = getTokenFromLocalStorage();
        let userData = getUserDataFromLocalStorage();
        let UserId = userData?.userId;
        axios.post(

            `${APIUrl}loans?appname=${Appname}&token=${token}&userid=${UserId}&projection=pipeline${sortBy ? `&sortBy=${sortBy}` : ""}${type && sortBy ? `&type=${type}` : ""}${status ? `&status=${status}` : ""}`,
            //   APIUrl+"loans?appname="+Appname+"&token="+token +"&projection=pipeline&status="+(status !== undefined && status !== "" ? status : '')+"&userid="+UserId,
            body,
            { type: "application/json" }
        )
            .then(function (response) {
                if (response?.data?.result?.data) {
                    const loans = response?.data?.result?.data



                    const formattedData = loans.map(item => {
                        const rowData = {};
                        columns.forEach(column => {
                            // console.log('coloumnnnn', column)
                            if (column.id === "loanid") {
                                rowData[column.name] = item[column.id];
                            } else if(column.id === "pipelinenotes"){
                                rowData[column.name] = item['pipelinenotes'] > 0 ? item['pipelinenotes'] : '';
                            }else  if(column.id === "drawinteresttype"){
                                rowData[column.name] = item[column.id] == 'Interest on Full Balance' ? 'true' : 'false';
                            }else {
                                rowData[column.name] = column.selector(item);
                            }
                        });
                        return rowData;
                    });

                    const ws = XLSX.utils.json_to_sheet(formattedData);

                    // Calculate column widths based on content
                    const columnWidths = columns.map(column => {
                        const maxContentLength = formattedData.reduce((max, row) => {
                            const cellContent = row[column.name] || '';
                            return Math.max(max, cellContent.toString().length);
                        }, column.name.length); // Set initial width to column name length

                        return { wch: maxContentLength + 2 }; // Add some padding
                    });

                    // Apply column widths
                    ws['!cols'] = columnWidths;

                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const fileData = new Blob([excelBuffer], { type: fileType });
                    saveAs(fileData, 'fushion_app_loans' + fileExtension);
                    dispatch(hideLoader());
                }
            }).catch((err) => {
                dispatch(hideLoader());
            })

    };
    const recenltyOpened = (loanid) => {
        // console.log(loanid, loans)
        const matchingObject = loans.find(object => object.loanid == loanid);
        dispatch(showLoader())
        searchLoanApi(loanid).then(function (response) {

            if (response?.result && response?.result) {
                dispatch(hideLoader())
                handleNavigation('/dashboard', response?.result?.[0]);
            }
        }).catch((err) => {
            setSearchLoader(false)

        })
        // console.log("matchingObject", matchingObject)
        // console.log('matchingObjectmatchingObject', matchingObject);
        // handleNavigation('/pipelinedashboard', matchingObject);
    }
    // console.log('show page',showPage)

    const handlSearch = () => {
        if (searchInputValue.length > 0) {
            dispatch(showLoader());
            searchLoanApi(searchInputValue).then(function (response) {
                // console.log('getLoanDetails--->', response);
                if (response?.result) {
                    setLoans(response?.result);
                    setShowPage("")
                    // console.log(response);
                    dispatch(hideLoader());
                }
            }).catch((err) => {
                // console.error.bind("my account error", err);
                dispatch(hideLoader());
            })

        } else {
            setSearchErr("please search by any keyword")
        }
    }
    const addToRecent = (loan) => {
        // console.log('loanloanloan', loan);
        let borrowerfullname = loan?.borrowername ? loan?.borrowername : loan?.entity && loan?.entity?.[0] && loan?.entity?.[0]?.legalname ? loan?.entity?.[0]?.legalname : loan?.applicant?.[0].borrowerfirstname + ' ' + loan?.applicant?.[0].borrowerlastname;
        let data = { "borrowerName": borrowerfullname }
        console.log('addToRecent borrowerfullname', loan);

        manageMostRecent(data, loan.loanid).then(function (response) {
            // console.log('privacymenunewwe', response);
            if (response?.result) {
                // console.log(response?.result)
            }
        }).catch((err) => {
            // console.error.bind("my account error", err);

        })
    }

    const getSavedSearchListFun = () => {
        // console.log('`````````````````````````````````333333333333333333333333`````````````````````');
        getSavedSearchListApi().then(function (response) {
            // console.log('get all advFormData response', response);
            if (response?.result) {
                // setLoans(response?.result)
                // setShowPage('')
                setSavedSearchData(response?.result);
                if(isReset){
                    //get last record search id and set to setSavedSearchNum
                    // Get last record
                    const lastRecord = response.result[response.result.length - 1];
                    // Retrieve search ID from the last record
                    const lastSearchId = lastRecord?.searchid;
                    console.log("last record id",lastSearchId);
                    setSearchSaveUserNum(lastSearchId);
                }
            }
        })
            .catch((err) => {
                // console.error.bind("my account error", err);
            })
    };
    //  useEffect(()=>{
    // // console.log('advFormData advFormData advFormData',advFormData)
    // },[loans])

    // const handleSavedSearchData = (currentPage, perPage, data, value) => {
    //     let payload = {};
    //     if (value === "list") {
    //         console.log('scope of handle savedsearch data block',value)
    //         setCurrentPage(1);
    //         setPerPage(10);
    //         setAdvFormData(data.filters);
    //         payload = data.filters;
    //         setAdvanceNameSearch('');
    //     } else {
    //         payload = data;
    //     }

    //     dispatch(showLoader());
    //     getSavedNamedSearchApi(currentPage, perPage, payload).then(response => {
    //         setLoans(response?.result?.data);
    //         setTotalCount(response?.result?.totalCount);
    //         setShowPage('');
    //         // setAdvFlag(true);

    //         dispatch(hideLoader());
    //     }).catch(err => {
    //         dispatch(hideLoader());
    //     });
    // };


    // const handleSavedSearchData = (currentPage, perPage, data, value) => {
    //     let payload = {};
    //     if (value === "list") {
    //         console.log('handleSavedSearchData - Saved Search');
    //         setFilteredStage("");
    //         setAdvFlag(true);
    //         setShowPage('');
    //         setCurrentPage(1);
    //         setPerPage(10);
    //         setAdvFormData(data.filters);
    //         payload = data.filters;
    //         setAdvanceNameSearch('');
            
    //         dispatch(showLoader());
    //         dispatch(getAllLoans(1, 10, "", setTotalCount, payload, true, "A4"));  
    //     } else {
    //         payload = data;
    //         let isStage ="";
    //         if(data?.status){
    //             isStage = data?.status
    //         }
    //         dispatch(showLoader());
    //         dispatch(getAllLoans(currentPage, perPage, isStage, setTotalCount, payload, true, "A5"));  // Pass saved search flag
    //     }

    // };

    const handleSavedSearchData = (currentPage, perPage, data, value) => {
        if(!savedUserSearchId){
            setNotSavedSearchData(false);
        }

        // if(!notSavedSearchData){
        //     setSaveSearchClose(false);
        // }
        // 
        // else{
        //     setSaveSearchClose(true);
        // }

        let payload = {};
        if (value === "list") {
            
            console.log('handleSavedSearchData - Saved Search',data);
            setFilteredStage("");
            setAdvFlag(true);
            setShowPage('');
            setCurrentPage(1);
            setPerPage(10);
            setAdvFormData(data.filters);
            payload = data.filters;
            setAdvanceNameSearch('');
            setSearchSaveUserNum(data.searchid);//added newly
            dispatch(showLoader());
            dispatch(getAllLoans(1, 10, "", setTotalCount, payload, true, "A4"));  
        } else {
            payload = data;
            let isStage ="";
            if(data?.status){
                isStage = data?.status
            }
            dispatch(showLoader());
            dispatch(getAllLoans(currentPage, perPage, isStage, setTotalCount, payload, true, "A5"));  // Pass saved search flag
        }

    };

    const handleSavedUserSearchData = (currentPage, perPage, search_id, value) => {
        console.log("saved search daat",search_id);
        setAdvFlag(false);//added newly

        setShowPage('SearchOptions');
        setAdvFormData({});
        setfilteredLoans([]);
        setFilteredStage('');
        setSearchInputValue("");
        setSavedUserSearchId(search_id);
    };

    const handleShowCriteria = (currentPage, perPage, search_id, value) => {
        //console.log("saved search daat",search_id);
        
            setAdvFlag(false);//added newly

            setShowPage('SearchOptions');
            if(!notSavedSearchData){
                setAdvFormData({});
            }
           
            setfilteredLoans([]);
            setFilteredStage('');
            setSearchInputValue("");
            
            setSavedUserSearchId(searchSaveUserNum);
            if(notSavedSearchData){
                setDisplayUnSavedData(true);
            }
        
        
        
        
    };

    const callUpdateSingleCriteria = () => {
    
            // let updatedAdvFormData = props?.advFormData;
            const updatedAdvFormData = { ...advFormData }; // Ensure a shallow copy
            // Remove unwanted keys
            //const { _id, searchedon, searchedby, status, appname, filters , ...updatedSearchData } = updatedAdvFormData;
            // updatedSearchData.filters = {
            //     ...updatedSearchData.filters, // Existing filters
            //     ...updatedAdvFormData, // Additional data from updatedAdvFormData
            // };

            // Extract only 'name' and 'filters' from updatedAdvFormData
            const { name , ...updatedSearchData } = updatedAdvFormData;
            let updatedData = {};
            updatedData.filters = updatedSearchData;
            updatedData.searchid = savedUserSearchId;
            updatedData.name = savedUserSearchData?.name;

            
            console.log("updatedAdvFormData data",updatedAdvFormData);
            //console.log("updatedSearchData data",updatedSearchData);
    
            console.log("final saved search user data ", updatedData);
            dispatch(showLoader());
            updateSingleSavedUserSearchData(savedUserSearchId,updatedData).then(function (response) {
                // console.log('privacymenunewwegetallUsers', response.result);
                if (response?.result) {
                    
                    console.log("update search response --- > ",response?.result);
                    
                    
                    setPopupOpen(true);
                    setmessage("Updated Successfully");
                    //callgetSingleSavedSearchUser(props?.savedUserSearchId)
                    setSaveSearchClose(true);
                    getSavedSearchListFun();
                   
                   
                   
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                // console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }

    const handleUpdateCriteria = () => {
        console.log(" called isUpdateClicked");
        callUpdateSingleCriteria();
    };
    

    // const handleSavedSearchData = (currentPage,perPage,data,value)=>{
    //     // setAdvFormData(data.filters)
    //     console.log('handleSavedSearchData')
    //     let payload = {};
    //     if(value==="list"){
    //         setCurrentPage(1)
    //         setPerPage(10)
    //         setAdvFormData(data.filters)
    //         payload = data.filters
    //     }else{
    //         payload = data
    //     }
    //     // setFilteredStage('');
    //     // setShowPage('SearchOptions')
    //     dispatch(showLoader());
    //     console.log('`````````````````````````````````44444444444444444444444444`````````````````````');

    //     getSavedNamedSearchApi(currentPage,perPage,payload).then(function (response) {
    //         // console.log('get all props.advFormData response', response);
    //         // if (response?.result) {
    //             console.log('dataaaaaa---->',response?.result?.data)
    //             setLoans(response?.result?.data);
    //             setTotalCount(response?.result?.totalCount);
    //             // setFilteredStage('');
    //             setShowPage('')
    //             // setAdvFlag(true)

    //           dispatch(hideLoader());
    //         // }
    //       })
    //       .catch((err) => {
    //         // console.error.bind("my account error", err);
    //         dispatch(hideLoader());
    //       })
    // }


    const searchResultApi = () => {
        if (searchInputValue.trim().length > 2) {
            setSearchLoader(true);

            // Cancel the previous API request if it exists
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            const controller = new AbortController();
            abortControllerRef.current = controller;

            typingTimeoutRef.current = setTimeout(() => {
                searchLoanApi(searchInputValue.trim(), { signal: controller.signal })
                    .then((response) => {
                        if (response?.result) {
                            setfilteredLoans(response.result);
                        } else {
                            setfilteredLoans([]);
                        }
                        setSearchLoader(false);
                    })
                    .catch((err) => {
                        if (err.name !== 'AbortError') {
                            console.error('Fetch error:', err);
                        }
                        setSearchLoader(false);
                    });
            }, 300);
        } else {
            if (typingTimeoutRef.current) {
                clearTimeout(typingTimeoutRef.current);
            }
            setfilteredLoans([]);
            setSearchLoader(false);
        }
    };

    useEffect(() => {
        if (searchInputValue !== '') {
            searchResultApi();
        } else {
            setfilteredLoans([]);
        }

        // Cleanup function to clear timeout and abort controller
        return () => {
            if (typingTimeoutRef.current) {
                clearTimeout(typingTimeoutRef.current);
            }
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [searchInputValue]);


    const handleSearch = (e) => {

        const value = e.target.value
        // console.log('value',value.trim.length)
        setSearchInputValue(value);
        setSearchErr("")
        // if (value.trim().length >= 2) {
        //     clearTimeout(typingTimeout);
        //     setSearchLoader(true)
        //     const newTypingTimeout = setTimeout(() => {
        //     searchLoanApi(value.trim()).then(function (response) {

        //         if (response?.result) {
        //             // console.log('sdfsfsfd',response?.result);
        //             setfilteredLoans(response?.result)
        //             setSearchLoader(false)
        //         }
        //     }).catch((err) => {
        //         setSearchLoader(false)
        //         // console.error.bind("my account error", err);
        //     })
        // }, 300);
        // setTypingTimeout(newTypingTimeout);

        // }else {
        //     clearTimeout(typingTimeout);
        //     setfilteredLoans([]);
        // }


    }
    const selectSearched = (loan) => {
        handleNavigation('/dashboard', loan);
    }

    const trimString = (str) => {

        return str?.replace(/[()]/g, '').replace(/&/g, '').replace(/-/g, '').replace(/\s+/g, '').trim();
    };

    //added press key down and key up to search loan
    const handleKeyDown = (e) => {
        if (showOptions && filteredLoans.length > 0) {
            if (e.key === 'ArrowDown') {

                setFocusedIndex((prevIndex) => (prevIndex + 1) % filteredLoans.length);
                e.preventDefault();
            } else if (e.key === 'ArrowUp') {
                //setFocusedIndex((prevIndex) => (prevIndex - 1 + filteredLoans.length) % filteredLoans.length);
                setFocusedIndex((prevIndex) => {
                    if (prevIndex === -1) {
                        return filteredLoans.length - 1;
                    } else {
                        return (prevIndex - 1 + filteredLoans.length) % filteredLoans.length;
                    }
                });

                e.preventDefault();
            } else if (e.key === 'Enter') {
                if (focusedIndex >= 0 && focusedIndex < filteredLoans.length) {
                    selectSearched(filteredLoans[focusedIndex]);
                    e.preventDefault();
                    setFocusedIndex(-1);

                }
            }

            // else if (e.key === 'Escape') {
            //     // Reset focused index when Escape key is pressed
            //     setFocusedIndex(-1);
            // }
        }
    };

    useEffect(() => {
        setFocusedIndex(-1); // Reset focus index when search input changes
    }, [searchInputValue]);

    const handleMyTasks = (e) => {
        setShowPage('MyTasks');
        setFilteredStage('');
        setfilteredLoans([]);
        setSearchInputValue("");
    }

    const handleShareSearch = (e) => {
        setShowPage('ShareSearch');
        setFilteredStage('');
        setfilteredLoans([]);
        setSearchInputValue("")
    }

    const handleAdvanceSearch = () => {
        setShowPage('SearchOptions');
        setAdvFormData({});
        setfilteredLoans([]);
        setFilteredStage('');
        setSearchInputValue("");
        // setPerPage(10);
        // setCurrentPage(1);
        // setTotalCount(10);
        if(savedUserSearchData){
            setSavedUserSearchId(null);
            setSavedUserSearchData({});
        }
       

    }

    //name save search 

    const onChangeSearch = (e, fieldName = null) => {
        const { name, value } = e.target;
        if (fieldName === 'category') {
            const selectedValues = e ? e.map(option => option.value) : [];
            advFormData['category'] = selectedValues;
        } else {
            advFormData[name] = value;
        }
        // props.advFormData[name] = value
        const updatedObject = { ...advFormData };
        for (const key in updatedObject) {
            if (updatedObject.hasOwnProperty(key)) {
                const value = updatedObject[key];
                if (value === '' || (Array.isArray(value) && value.length === 0) || value === undefined) {
                    delete updatedObject[key];
                }
            }
        }
        // console.log('updatedObject', updatedObject)
        setAdvFormData(updatedObject)


    };

    // const getSavedNamedSearchFun = (data) => {
    //     console.log('```````````````````````111111111111111111111111111```````````````````````````')
    //     dispatch(showLoader());
    //     getSavedNamedSearchApi(1,10,data).then(function (response) {

    //         let objData = {...advFormData}

    //         if (objData?.name) {
    //             delete objData['name']
    //         }
    //         setAdvFormData(objData)
    //         // console.log('get all props.advFormData response', response);
    //         // if (response?.result) {
    //         console.log('response?.result---->',response?.result)
    //         setLoans(response?.result?.data)
    //         setTotalCount(response?.result?.totalCount);
    //         setShowPage('')
    //         setAdvFlag(true)
    //         getSavedSearchListFun();
    //         setPopupOpen(true);
    //         setmessage('Saved Successfully');
    //         // dispatch(getSavedSearchListFun());
    //         // setBrokersData(response?.result);
    //         dispatch(hideLoader());
    //         // }
    //     })
    //         .catch((err) => {
    //             // console.error.bind("my account error", err);
    //             dispatch(hideLoader());
    //         })
    // }
    const getSavedNamedSearchFun = (data) => {
        console.log('Executing Saved Named Search');
        dispatch(showLoader());

        // Prepare the payload, removing the "name" if it exists
        let objData = { ...data };
        if (objData?.name) {
            delete objData['name'];
        }
        setAdvFormData(objData);

        // Use the unified getAllLoans function to handle saved searches
        dispatch(getAllLoans(1, 10, '', setTotalCount, data, true, "A6"))  // Passing saved search flag
            .then((response) => {
                console.log('response.result:', response?.result);
                if (response?.data?.result) {
                    setLoans(response?.data?.result?.data);  // Update loans data
                    setTotalCount(response?.data?.result?.totalCount);  // Set the total count
                    setShowPage('');
                    setAdvFlag(true);
                    getSavedSearchListFun();  // Call saved search list function
                    setPopupOpen(true);
                    setmessage('Saved Successfully');
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                console.error("Error fetching saved search results", err);
                dispatch(hideLoader());
            });
    };

    const handleSaveToMySavedSearch = () => {
        if (advFormData?.name === undefined || advFormData?.name?.trim() === "") {
            setNameSearchErr("please enter the name")
        }
        else {
            getSavedNamedSearchFun(advFormData);
        }
    }


    const handleDeleteSavedSearch = (searchId) => {
        //console.log(searchId);
        dispatch(showLoader());
        deleteSavedSearchListApi(searchId).then(function (response) {
            // console.log('get all advFormData response', response);
            if (response?.result) {
                setPopupOpen(true);
                setmessage('Deleted Successfully');
                setAdvFormData({});
                getSavedSearchListFun();
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                // console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }

    const closePopup = () => {
        setPopupOpen(false);

    };
    // console.log("Rendering DataTable with currentPage:", currentPage, " and perPage:", perPage, " tableKey", tableKey);

    const keyForRerender = `${perPage}_${loans?.length}`;

    // useEffect(() => {
    //     if(isReset){
    //         //getSavedSearchListFun();
    //     }
    // },[isReset])

    // const callgetSingleSavedSearchUser = (searchUserId) => {
    //         dispatch(showLoader());
    //         getSingleSavedUserSearchData(searchUserId).then(function (response) {
    //             // console.log('privacymenunewwegetallUsers', response.result);
    //             if (response?.result[0]) {
                    
    //                 console.log("getSingleSavedUserSearchData res",response?.result[0]);
    //                 setSavedUserSearchData(response?.result[0]);
    //                 setAdvFormData(response?.result[0]?.filters);
    //                 setAdvFormData((prevData) => ({
    //                     ...prevData,
    //                     "name" : response?.result[0]?.name
    //                 }));
                   
    //             }
    //             dispatch(hideLoader());
    //         })
    //             .catch((err) => {
    //                 // console.error.bind("my account error", err);
    //                 dispatch(hideLoader());
    //             })
    // }

    // useEffect(() => {
    //     if(searchSaveUserNum){
    //         callgetSingleSavedSearchUser(searchSaveUserNum);
    //     }
    // },[searchSaveUserNum])

    return (
        <div id="layout-wrapper">
            {(!userData || loading) ? <Loader /> : null}
            {isPopupOpen && (
                <PopupMessage

                    type={popupMessage == 'Saved Successfully' || popupMessage == 'Deleted Successfully' || 
                        popupMessage == "Updated Successfully"
                         ? 'success' : 'warning'}
                    message={popupMessage}
                    onClose={closePopup}

                />
            )}

            <MainHeader />


            {userData && <SideMenu />}

            <div className="main-content">

                <div className="page-content zoom">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Filter by Stages</h4>
                                </div>
                                <div className='stages_block'>
                                    <div className='stages_nav'>
                                        <button id="filterStagePrevious" type="button" className="ar_btn" onClick={() => { console.log('scrollContainerRef1', scrollContainerRef); scrollContainerRef.current.scrollBy({ left: -260, behavior: 'smooth' }); }}><span className="material-symbols-outlined icon"> arrow_back_ios_new </span></button>
                                        <button id="filterStageNext" type="button" className="ar_btn" onClick={() => { console.log('scrollContainerRef2', scrollContainerRef); scrollContainerRef.current.scrollBy({ left: 260, behavior: 'smooth' }); }}><span className="material-symbols-outlined icon"> arrow_forward_ios </span></button>
                                    </div>
                                    <div className="stages" ref={scrollContainerRef}>
                                        <div className="stages_wrp" >
                                            {stagesToMatch.map((key, index) => (
                                                <div className={"card" + (filteredStage === key ? ' active' : '')} key={key} onClick={(e) => { handleFilterChange(key) }}>
                                                    <div className="card-body">
                                                        <p>{key}<span className='count'>{stagesForList?.[key]?.count || 0}</span></p>
                                                        <button id={`filterTotalLoanAmount-${trimString(key)}`} type="button" className="btn mb-10 btn-outline-success">Total {formatCurrency(stagesForList?.[key]?.totalLoanAmount) || '0.00'}</button>
                                                        <button id={`filterAverageLoanAmount-${trimString(key)}`} type="button" className="btn btn-success">Avg {formatCurrency(stagesForList?.[key]?.averageLoanAmount) || '0.00'}</button>
                                                    </div>
                                                </div>))}

                                        </div>
                                    </div>
                                </div>
                                <div className="card fbs">
                                    <div className="card-body">
                                        <div className='filters'>
                                            <div className='form-group search_block'>
                                                <input ref={inputRef} type="text" placeholder='Search Loan ID, First/Last Name, or Address' name="searchInputValue" value={searchInputValue ?? ""} onChange={e => handleSearch(e)} className={`form-control ${searchErr.length > 0 && 'invalid-input'}`} onFocus={() => { setSearchErr(""); setShowOptions(true) }} onKeyDown={handleKeyDown} />

                                                {searchLoader && searchInputValue.length > 0 && <img src='assets/images/loader.gif' className='search_spinner' />}
                                                {!searchLoader && searchInputValue.length > 0 && <img src='assets/images/close.svg' onClick={() => { setfilteredLoans([]); setSearchInputValue("") }} className='search_close' />}
                                                <button className="material-symbols-outlined btn pe-none" >search</button>
                                                {showOptions && (
                                                    <div className="dropdown">
                                                        {searchInputValue.trim().length >= 2 && filteredLoans?.length > 0 ? filteredLoans?.map((option, index) => {
                                                            let borrowerfullname = option?.entity?.[0]?.legalname ? option?.entity?.[0]?.legalname : option?.applicant?.[0].borrowerfirstname + ' ' + option?.applicant?.[0].borrowerlastname;
                                                            let title = option.loanid + ' - ' + option?.propertyinfo[0]?.propertyaddress + ', ' + borrowerfullname
                                                            return (<div key={index} className={`item ${focusedIndex === index ? 'focused' : ''}`} onClick={() => { selectSearched(option); }}>
                                                                {title}
                                                            </div>)
                                                        }) : filteredLoans?.length === 0 && searchInputValue.length >= 2 && !searchLoader && <div className='item'>No searched Data found</div>}
                                                    </div>
                                                )}
                                            </div>
                                            {/* <button id="advancedSearch" className="material-symbols-outlined btn" title="Advanced Search" onClick={(e) => { setShowPage('SearchOptions');setAdvFormData({});setfilteredLoans([]);setFilteredStage('');setSearchInputValue("");setPerPage(10);setCurrentPage(1);setTotalCount(10) }}>quick_reference_all</button> */}

                                            <button id="advancedSearch" className="material-symbols-outlined btn" title="Advanced Search" onClick={handleAdvanceSearch}>quick_reference_all</button>
                                            {/* <button id="myTasks" className="icon_btn" type="button" onClick={(e) => {setShowPage('MyTasks');setFilteredStage('');setfilteredLoans([]);setSearchInputValue("")}}> My Tasks <span
                                                className="material-symbols-outlined icon"> checklist
                                            </span></button> */}

                                            <button id="myTasks" className="icon_btn" type="button" onClick={handleMyTasks}> My Tasks <span
                                                className="material-symbols-outlined icon"> checklist
                                            </span></button>


                                            <div className="btn-group">
                                                <button id="mostRecentLoans" type="button" className="icon_btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Most Recent Loans <span
                                                        className="material-symbols-outlined icon"> real_estate_agent
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu">
                                                    {mostRecentLoans && mostRecentLoans?.length > 0 ? mostRecentLoans?.map((data, index) => (
                                                        <button id={`recentLoan-${data?.loanid}`} key={index} className="dropdown-item" onClick={(e) => { recenltyOpened(data?.loanid) }}>{data?.loanid}, {data?.borrowerName}</button>
                                                    )) : <button id="mostRecentLoanNoData" className="dropdown-item">No data found</button>
                                                    }
                                                </div>
                                            </div>

                                            <div className="btn-group">
                                                <button id="savedsearch" type="button" className="icon_btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Saved Search <span
                                                        className="material-symbols-outlined icon"> search_check
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu ss">
                                                    {savedSearchData && savedSearchData?.length > 0 ? savedSearchData?.map((item, index) => {
                                                        return (
                                                            <div className='dropdown-item'>
                                                                <button id={`savedsearchList-${index}`} className="dropdown-item" key={item._id} onClick={() => handleSavedSearchData(1, 10, item, "list")}>{item.name}</button>
                                                                {/* <button id={`savedsearchList-${index}`} className="dropdown-item" key={item.searchid} onClick={() => handleSavedUserSearchData(1, 10, item.searchid, "list")}>{item.name}</button> */}
                                                                <button id={`deleteSavedSearch-${index}`} type="button" className="delete" onClick={() => handleDeleteSavedSearch(item.searchid)}>
                                                                    <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container"><span className="tooltip">Delete</span>delete</span>
                                                                </button>
                                                            </div>


                                                        )
                                                    }) :
                                                        <a id="savedSearchNoData" className="dropdown-item">No data found</a>

                                                    }

                                                </div>
                                            </div>


                                            {/* <button id="shareSearch" className="icon_btn" type="button" onClick={(e) => {setShowPage('ShareSearch');setFilteredStage('');setfilteredLoans([]);setSearchInputValue("")}}>Share Search<span
                                                className="material-symbols-outlined icon"> share
                                            </span></button> */}
                                            <button id="shareSearch" className="icon_btn" type="button" onClick={handleShareSearch}>Share Search<span
                                                className="material-symbols-outlined icon"> share
                                            </span></button>


                                            <button id="configurePipeline" className="material-symbols-outlined btn" onClick={(e) => { setShowPage('Columns'); setFilteredStage(''); setfilteredLoans([]); setSearchInputValue(""); }} title='Configure pipeline'>settings_ethernet</button>
                                            <button id="excelLoanData" className=" btn" disabled={loans?.length === 0 || showPage !== ''} onClick={exportToExcel}> <img src="assets/images/icons/excel.svg" className='img' />
                                            </button>
                                            {/* <button className="material-symbols-outlined btn" onClick={(e) => {setShowPage('')}}>close</button> */}
                                        </div>

                                        {advFlag && showPage === "" &&
                                            <div className='search_result'>
                                                <h4>Search results</h4>
                                                {
                                                    // advanceNameSearch === 'advanceNameSearch' &&
                                                    (advanceNameSearch === 'advanceNameSearch' && !savedUserSearchId ) &&
                                                    <div className='search_btns'>
                                                        <div className='sts'>
                                                            <p>Save this Search</p>
                                                            <div className="form-group">

                                                                <input id="saveAdvancedSearch" type="text" placeholder="Enter Name" name="name" value={advFormData.name ?? ""} onChange={(e) => onChangeSearch(e)} className={`form-control ${NameSearchErr.length > 0 && 'invalid-input'}`} onFocus={() => { setNameSearchErr("") }} /> <span
                                                                    className="material-symbols-outlined icon"> id_card </span>
                                                            </div>
                                                            <div className="btn-group">
                                                                <button id="saveMySavedSearches" type="button" className="fillbtn_new" onClick={() => handleSaveToMySavedSearch()}>Save to My Saved Searches<span
                                                                    className="material-symbols-outlined">save</span></button>


                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className='d-flex'>
                                                    {notSavedSearchData && <button id="updateCriteria" type="button" className="border_btn me-3" onClick={handleUpdateCriteria}>Update Criteria</button>}
                                                    {/* {searchSaveUserNum && loans?.length !== 0 && <button id="showCriteria" type="button" className="border_btn me-3" onClick={handleShowCriteria}>Show Criteria</button>} */}
                                                    {(searchSaveUserNum || savedUserSearchId) && <button id="showCriteria" type="button" className="border_btn me-3" onClick={handleShowCriteria}>Show Criteria</button>}
                                                    
                                                    {/* {saveSearchClose ? 
                                                        <button id="exitSaveSearch" type="button" className="border_btn" onClick={handleExitSearch}>Exit saved search </button>
                                                        : 
                                                        <button id="clearSearch" type="button" className="border_btn" onClick={handleClearSearch}>Clear search </button>
                                                    } */}
                                                    
                                                        <button id="clearSearch" type="button" className="border_btn" onClick={handleClearSearch}>{saveSearchClose ? 'Exit Saved search' : 'Clear search'} </button>
                                                    
                                                        </div>
                                               

                                            </div>
                                        }
                                          {NotesFlag &&
          <PipelineNotes activeLoanid={activeLoanidNotes} setNotesFlag={setNotesFlag} getAllLoansFun={getAllLoansFun}/>
       }
                                        {showPage === 'Columns' ?

                                            <SelectColumns handleClearSearch={handleClearSearch} setShowPage={setShowPage} setfilteredLoans={setfilteredLoans} reload={getColumns} /> : showPage === 'SearchOptions' ?

                                                <SearchOptions handleClearSearch={handleClearSearch}
                                                 getAllLoansFun={getAllLoansFun}
                                                  stageOptionKeys={stageOptionKeys} 
                                                  setAdvFlag={setAdvFlag}
                                                   setFilteredStage={setFilteredStage}
                                                    setTotalCount={setTotalCount} 
                                                    setLoans={setLoans} 
                                                    setfilteredLoans={setfilteredLoans}
                                                     setShowPage={setShowPage} 
                                                     setAdvFormData={setAdvFormData}
                                                      advFormData={advFormData} 
                                                      getSavedSearchListFun={getSavedSearchListFun}
                                                       setSavedSearchData={setSavedSearchData}
                                                        setAdvanceNameSearch={setAdvanceNameSearch}
                                                        savedUserSearchData = {savedUserSearchData}
                                                        setSavedUserSearchData = {setSavedUserSearchData}
                                                        savedUserSearchId = {savedUserSearchId}
                                                        setSavedUserSearchId = {setSavedUserSearchId}
                                                        advFlag = {advFlag}
                                                        handleSavedSearchData = {handleSavedSearchData}
                                                        notSavedSearchData = {notSavedSearchData}
                                                        setNotSavedSearchData = {setNotSavedSearchData}
                                                        displayUnSavedData = {displayUnSavedData}
                                                        setDisplayUnSavedData = {setDisplayUnSavedData}
                                                        saveSearchClose = {saveSearchClose}
                                                        setSaveSearchClose = {setSaveSearchClose}
                                                        setSearchSaveUserNum ={setSearchSaveUserNum}
                                                        setIsReset = {setIsReset}
                                                        isReset = {isReset}
                                                        
                                                         /> : showPage === 'MyTasks' ?

                                                    <Tasks
                                                    getTaskData={getTaskData} setGetTaskData={setGetTaskData} setfilteredLoans={setfilteredLoans} setShowPage={setShowPage} isChild={true} setFilteredStage={setFilteredStage} handleClearSearch={handleClearSearch} /> :
                                                    showPage === 'ShareSearch' ?

                                                        <ShareSearch setfilteredLoans={setfilteredLoans} setShowPage={setShowPage} setLoans={setLoans} handleClearSearch={handleClearSearch} /> :
                                                        showPage === "" &&

                                                        // loans?.length > 0 &&
                                                        // <DataTable className='tablegrid'
                                                        //         columns={columns}
                                                        //         data={loans}
                                                        //         pagination
                                                        //         paginationServer
                                                        //         customStyles={customStyles}
                                                        //         // paginationPerPage={10}
                                                        //         paginationPerPage={perPage}
                                                        //         paginationTotalRows={totalcount}
                                                        //         paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                                        //         paginationComponentOptions={{
                                                        //             rowsPerPageText: 'Per Page',
                                                        //         }}
                                                        //         sortServer={true}
                                                        //         paginationDefaultPage={currentPage}

                                                        //         onChangePage={handlePageChange}
                                                        //         onChangeRowsPerPage={handlePerPageChange}
                                                        //         responsive
                                                        //         onSort={handleSort}
                                                        //         noDataComponent={!loading && customNoRecords()}
                                                        //     />
                                                        <DataTable
                                                            key={keyForRerender}  // This can stay to force re-renders on data changes
                                                            className='tablegrid'
                                                            columns={columns}
                                                            data={loans}
                                                            pagination
                                                            paginationServer
                                                            customStyles={customStyles}
                                                            paginationPerPage={perPage}
                                                            paginationTotalRows={totalcount}
                                                            paginationRowsPerPageOptions={[10, 20, 30, 40]}

                                                            sortServer={true}
                                                            paginationDefaultPage={currentPage}  // This must be bound to context/state
                                                            onChangePage={handlePageChange}
                                                            onChangeRowsPerPage={handlePerPageChange}
                                                            responsive
                                                            onSort={handleSort}
                                                            noDataComponent={!loading && customNoRecords()}
                                                           
                                                        />
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
              
       
                <HomeFooter />
            </div>

        </div>
    );
};

export default Pipeline;