import React,{useState, useEffect} from 'react';
import LocationSearch from '../LocationsSeachBox';
import { useNavigate } from 'react-router-dom';
import { useSelector} from 'react-redux';
const PropertyInfo3 = (props) => {
    const configdata = useSelector(state => state.configdata);
  const mapsKey = configdata?.common?.googleMapsKey;
    const navigate = useNavigate();
    const [addressData,setAddressData] = useState({});
    const [selectedPlace, setSelectedPlace] = useState(null);

    const [labelName, setLabelName] = useState('');
    
       

    useEffect(() => {
        // Clean up logic here (e.g., unmount the LoadScript)
        return () => {
          // This function is called when the component is unmounted
          console.log("Component is unmounted");
        };
      }, []); 
      
      useEffect(() => {
        if(selectedPlace){
            props?.handleInputChange('propertyaddress', addressData?.address?.trim() || addressData?.fullAddress);
            props?.handleInputChange('propertycity', addressData?.city);
            props?.handleInputChange('propertyareaaddress', addressData?.area);
            props?.handleInputChange('propertyfulladdress', addressData?.fullAddress);
            props?.handleInputChange('propertystate', addressData?.state);
            props?.handleInputChange('propertyzipcode', addressData?.zipcode);
            props?.handleInputChange('propertygeolocation',  addressData?.geolocation);
            props?.handleInputChange('propertygooglemaplink', addressData?.url);
            props?.handleInputChange('propertyplaceimage', addressData?.image);
            props?.handleInputChange('propertystateshort', addressData?.stateShortName);
            props?.handleInputChange('propertycounty', addressData?.county);
        }
      }, [selectedPlace, addressData]); 


      const removeErrorClass = (field) => {
        const inputElement = document.querySelector(`[name=${field}]`);
        if (inputElement) {
          inputElement.classList.remove('invalid-input');
        }
    };

    useEffect(() => {
        if(selectedPlace){
            removeErrorClass('search_block');
        }
        
    },[selectedPlace])

      const navNext = () => {
        // let selPlace = props?.properyInfo?.propertyaddress || selectedPlace;
        // console.log('selPlaceselPlace',props?.properyInfo, selectedPlace);
        // if(selPlace){
        //     navigate('/loanProgram')
        // }else{
        //     const inputElement = document.querySelector(`[name='search_block']`);
        //     if (inputElement) {
        //         inputElement.classList.add('invalid-input');
        //     }
        // }

        if (!props?.properyInfo?.propertyaddress || selectedPlace?.trim() === "") {
         
            const searchWrpElement = document.querySelector(`[name='search_block']`);
        
            if (searchWrpElement) {
                //isValid = false;
              searchWrpElement.classList.add("invalid-input");
            }
        }else{
            navigate('/loanProgram');
        }
      }
    return (
            
           
               
                    <div className="cont pi3">
                        {/* <div className="seleted_item">
                            <span className="material-icons-outlined"> done </span> <span className="text">I am
                                <strong> {props?.properyInfo?.loanfor}</strong> </span>
                        </div>
                        {props?.properyInfo?.stepIntheProcess && <div className="seleted_item">
                            <span className="material-icons-outlined"> done </span> <span className="text">{props?.properyInfo?.stepIntheProcess} </span> 
                            
                        </div>} */}
                        {/* <h3 className="feild_title">{props?.formJSON[2]?.label}</h3> */}

                        <h3 className="feild_title">
                        {/* {
                            (props?.useType == "broker" && (props?.properyInfo?.loanfor == 'Refinance' || (props?.properyInfo?.loanfor == 'Purchase' && props?.properyInfo?.stepIntheProcess != "Doing research for borrower" ))) ? 
                                props?.formJSON[2]?.label?.replace("AREA", "ADDRESS")
                            : props?.formJSON[2]?.label 
                        } */}
                        {((props?.useType == "broker") && (props?.properyInfo?.loanfor == 'Refinance' || (props?.properyInfo?.loanfor == 'Purchase' && props?.properyInfo?.stepIntheProcess != "Doing research for borrower" ))) ? 
                                props?.formJSON[2]?.label?.replace("AREA", "ADDRESS") :
                            ((props?.useType == "borrower") && (props?.properyInfo?.loanfor == 'Purchase' && props?.properyInfo?.stepIntheProcess == "I'm doing research" )) ? 
                                props?.formJSON[2]?.label?.replace("AWESOME! WHAT IS THE ADDRESS?","WHAT AREA ARE YOU LOOKING?") 
                            : props?.formJSON[2]?.label}
                        </h3>

                        

                        <div className="search_block" name="search_block">
                            <div className="search_wrp">
                                <span className="material-icons-outlined">
                                    location_on
                                </span>
                                {mapsKey && 
                                <LocationSearch name="location" setSelectedPlace={setSelectedPlace} addressData={addressData} setAddressData={setAddressData} selectedPlace={props?.properyInfo?.propertyfulladdress || selectedPlace}/>
                                }
                                
                                {/* <button className="search_btn"> */}
                                    <span className="material-symbols-outlined">
                                        search
                                    </span>
                                {/* </button> */}
                            </div>
                        </div>

            <div className="btn-group2">
            <button id="borrowerBrokerNext" type="button" className="cp_btn"  onClick={(e) => {navNext()}}>Next</button>
            </div>
                    </div>
              
    
    );
};

export default PropertyInfo3;