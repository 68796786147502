import React, { useState, useEffect, useRef} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { getStates, getTermSheetPDF, getUnderwritingPDF} from "./../utils/reducer";
import {getUnderwriting ,loanUnderwritingData , underwritingPipelineUpdate , LoanTermDropdown ,getLoanDetails,addLoanDocumentApi, getSingleTermSheetPrefillState, dealratepointsGet, sendOriginatorInstructionsToEmail , lookupsCallForPipeline} from "./../utils/reducer";
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import PopupMessage from './PopupMessage';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import { useLoanInfo } from '../utils/loanInfoContext';
import { formatPhoneNumber } from '../utils/commonUtils';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import LocationSearch from './LocationsSeachBox';
import CurrencyInput from 'react-currency-input-field';
import SweetAlert from 'react-bootstrap-sweetalert';
import BorrowerExposure from './BorrowerExposure';
import { useLocation } from "react-router-dom";
import { Appname, APIUrl, submitNew } from '../utils/reducer';
import axios from "axios";
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
import { formatNumericInput} from './../utils/commonUtils';


const DealUnderwritingTermsheet = (props) => {
    //console.log("props?.userData",props?.pipelinePermissions)
    const location = useLocation();
    const isUnderWriting = location.pathname.includes("dealunderwritingtermsheet");
    const configdata = useSelector(state => state.configdata);
    const mapsKey = configdata?.common?.googleMapsKey;
    const [isDataChanged,setIsDataChanged] = useState(false);
    const dispatch = useDispatch();
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid, setLoanid] = useState(loanInfo?.loanid);
    const [states, setStates] = useState([]);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message,setMessage] = useState('');
    const loading = useSelector((state) => state.loaderVisible);
    const [borrowerErrors , setBorrowerErrors] = useState({});
    const [propertyErrors , setPropertyErrors] = useState({});
    const [dealErrors,setDealErrors] = useState({});
    const [feeErrors , setFeesErrors] = useState({});
    const [calculationErrors , setCalculationErrors] = useState({});
    const [comparisionsErrors,setComparisionsErrors] = useState({});
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [addressData,setAddressData] = useState({ });
    const [addressIndex,setAddressIndex] = useState([]);
    const [bool,setBool] = useState(false);
    const [underwritingFormulas,setUnderwritingFormulas] = useState({});
    const [calcBool,setCalcBool] = useState(false);
    const [loanTerm,setLoanTerm] = useState([]);
    const [loanPurposeType, setloanPurposeType] = useState([]);
    const [calculationBool,setCalculationBool] = useState(false);
    const [singleTermSheet,setSingleTermSheet] = useState({});
    const [isDownloadTermSheetClicked,setIsDownloadTermSheetClicked] = useState(false);
    const [confirmationPopUp,setConfirmationPopUp] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [lastFieldtName, setLastFieldtName] = useState('');
    const [termSheetName, settermSheetName] = useState('TERMSHEET');
    const [OverridePopup, setOverridePopup] = useState(false);
    const [OverridePopupMessage, setOverridePopupMessage] = useState('');
    const [TempOverRide, setTempOverRide] = useState(false);
    const [propertyAddress,setPropertyAddress] = useState({ });
    const [coBorrowerAddressData, setCoBorrowerAddressData] = useState([]);
    const [coBorrowerSelectedPlace, setCoBorrowerSelectedPlace] = useState([]);
    
    const [propertyPlace, setPropertyPlace] = useState(null);
    const [PopupMessages, setPopupMessages] = useState('');
    const [IsPropertyInsuranseChanged, setIsPropertyInsuranseChanged] = useState(false);
    const [borrowerLength, setBorrowerLength] = useState(0);
    // const [pipelinePermissions, setViewStatus] = useState(false);
    let underwritingVariable = {
        "loanid": loanid,
        "abouttheborrower":{
            "applicantid":"",
            "borrowerfirstname":"",
            "borrowerlastname":"",
            "borroweremail":"",
            "borrowerphone":"",
            "statedcreditscore":"",
	        "liquidcashreserve" : "",
            //"lenderfundedrehab":"",
            "borroweroccupation":"",
            "investmentproperties": "",
            "cashreverserequired" : "",
            "cashreverserequiredvalue" : false,
            "applicationlicense" : "",
           
        },
        "entity": [],
        "abouttheproperty":{
            "propertyid": "",
            "propertytype":"",
            "numberofunits" : "",
            "propertyaddress":"",
            "apt_unit":"",
            "propertycity":"",
            "propertystate":"",
            "propertyzipcode":"",
            "totalbedrooms":"",
            "totalbathrooms":"",
            "haspool":"",
            "lotsqft":"",
            "buildingsqft":"",
            "finishedsqft":"",
            "occupancy" : "",
            "purchaseprice" : "",
            "easystreetaiv" : "",
            "renovationcost" : "",
            "easystreetarv": "",
            "borrowerarv": "",
            "maxltc": "",
            "maxltv": "",
            "points": "",
            //"amortizationterm":"",
            "valuationfee": "",
            "exitstrategy": "",
            "monthlyrentalrate": "",
            "downpaymentsource": "",
            "floodinsurence": "",
            "floodinsurencevalue": false ,
            "lenderfundedrehab" : "",
            "debtservicecoverageratio": "",
            "existingloan": "",
            "executionstrategysummary": "",
            "yearbuilt":""
           
        },
       
       
        "fee":{
            "brokerid" : "",
            "brokerpoints":"",
            "brokerprocessingfee":"",
           
            
        },
        "overridecalculationfee":false,
        "initialfunding": "",
        "initialequity": "",
        "rehabfunding": "",
        "rehabequity": "",
        "loanamount" : "",
        "insuranceamount": "",
        "interestreserveamount": "",
        "underwritingsummary": "",
        "originatorinstructions": "",
        // "holdbackamount":"",
        "interestrate": "",
        "loanterm":"",
        "loantype" : "",
        "interestreservesloanterm":"",
        "creditreport":"",
        "programtype": "",
        "loanfor": "",
        "drawinteresttype": "",
        "appraisal":"N/A",
        //"interestonly":"",
        "originationFee":"",
        "title":"", 
        "escrow":"",
        "titleAmount":"",
        // "propertyInsurance":"",
        "drawFee":"",
        "loanorganisationpoints":"",
        "usewhitelabel":false,
        "docfee":"",
        "interestreserves":"No",
        "propertycomparisons":  [
            {
                "address": "",
                "price": "",
                "bathroom": "",
                "bedroom": "",
                "sf": "",
                "yearbuilt": "",
                "comparisonnarrative": ""
            },
            {
                "address": "",
                "price": "",
                "bathroom": "",
                "bedroom": "",
                "sf": "",
                "yearbuilt": "",
                "comparisonnarrative": ""
            },
            {
                "address": "",
                "price": "",
                "bathroom": "",
                "bedroom": "",
                "sf": "",
                "yearbuilt": "",
                "comparisonnarrative": ""
            },
            {
                "address": "",
                "price": "",
                "bathroom": "",
                "bedroom": "",
                "sf": "",
                "yearbuilt": "",
                "comparisonnarrative": ""
            },
        ]
        
    };
    const [underWritingData , setUnderWritingData]= useState(underwritingVariable);
    const [loanUnderwriting,setLoanUnderwriting] = useState({});
    const [underwritingErrors,setUnderwritingErrors] = useState({});
    let userData = getUserDataFromLocalStorage();
   console.log("userData",loanUnderwriting)
    const [pipelinePermissions, setPipelinePermissions] = useState( );
    const [editPermissions, setEditPermissions] = useState( );
    const [sectionPermissions,setSectionPermissions] = useState([]);
    const emptySectionKeys = [ "overridecalculationfee",
    "initialfunding",
    "initialequity",
    "rehabfunding",
    "rehabequity",
    "debtservicecoverageratio",
    "insuranceamount",
    "executionstrategysummary",
    "underwritingsummary",
    "originatorinstructions",
    "holdbackamount",
    "interestrate",
    "loanterm",
    "programtype",
    "loanfor",
    "drawinteresttype",
    //"interestonly",
    "existingloan",
    "usewhitelabel"];

    let checkLength = (Object.keys(underWritingData).length > 0) ? true : false;
    // let checkApplicantLength = (Object.keys(loanUnderwriting?.applicant).length > 0) ? true : false;
    // let checkpropertyLength = (Object.keys(loanUnderwriting?.propertyinfo).length > 0) ? true : false;
    let checkBrokerLength = (loanUnderwriting?.broker !== null) ? true : false;
    let borrowerFieldsToCheck = {};
    let propertyFieldsToCheck = {};
    let propertycomparisonsFieldsToCheck = {};
    let errors = {};
    let floatJsonInputs = ['price','liquidcashreserve',
            'renovationcost','brokerprocessingfee','docfee','purchaseprice','cashreverserequired',
            'easystreetaiv','easystreetarv', 'valuationfee', 'monthlyrentalrate','existingloan', 'floodinsurence','borrowerarv',
            'loanamount','initialfunding','initialequity','rehabfunding','rehabequity','insuranceamount',
            'totalcost',"maxmonthlyloanpayment","cashtoclose", "interestreserveamount", 'originationFee', 'escrow','titleAmount', 'drawFee','salesexpense','netprofit', 'currentvalue', 'maximummonthlyinterest', 'minimummonthlyinterest','estimatedvalue'
            
    ];

    let dealUnderwritingJson = [
        {
            "abouttheborrower": [
                {
                    "type": "text",
                    "label": "First Name",
                    "placeholder": "Enter Borrower First Name",
                    "name": "borrowerfirstname"
                },
                {
                    "type": "text",
                    "label": "Last Name",
                    "placeholder": "Enter Borrower Last Name",
                    "name": "borrowerlastname"
                },
                {
                    "type": "email",
                    "label": "Email",
                    "placeholder": "Enter Borrower Email",
                    "name": "borroweremail"
                },
                {
                    "type": "tel",
                    "label": "Phone",
                    "placeholder": "(xxx) xxx-xxxx",
                    "name": "borrowerphone"
                },
                {
                    "type": "text",
                    "label": "Stated Credit Score",
                    "placeholder": "Enter Stated Credit Score",
                    "name": "statedcreditscore"
                },
                {
                    "type": "text",
                    "label": "Cash Reserves",
                    "placeholder": "Enter Cash Reserves",
                    "name": "liquidcashreserve"
                },
                {
                    "type": "text",
                    "label": "Occupation",
                    "placeholder": "Enter Borrower Occupation",
                    "name": "borroweroccupation"
                },
                {
                    "type": "text",
                    "label": "Entity",
                    "placeholder": "Enter the Borrower Entity",
                    "name": "legalname"
                },
                {
                    "type": "text",
                    "label": "Deal (Past 2 Years)",
                    "placeholder": "Enter Deal Value",
                    "name": "investmentproperties"
                },
                
                {
                    "label": "Applicable Licenses",
                    "name": "applicationlicense",
                    "type": "text",
                    "placeholder" : "Enter Applicable Licenses"
                },
                
            ]
        },
        {
            "abouttheproperty": [
                {
                    "type": "select",
                    "label": "Property Type",
                    "name": "propertytype",
                    "options": [
                        { "label": "Select ", "value": "" },
                        // { "label": "Single Family Residence (SFR - 1 Unit)", "value": "single_family" },
                        // { "label": "Townhome", "value": "townhome" },
                        // { "label": "Condominium", "value": "condominium" },
                        // { "label": "5+ Unit", "value": "5+_unit" },
                        // { "label": "2 Unit", "value": "2_unit" },
                        // { "label": "3 Unit", "value": "3_unit" },
                        // { "label": "4 Unit", "value": "4_unit" },
                        
                        { "value": "Single Family Residence (SFR - 1 Unit)", "label": "Single Family Residence (SFR - 1 Unit)" },
                        { "value": "Duplex", "label": "Duplex" },
                        { "value": "Triplex", "label": "Triplex" },
                        { "value": "Fourplex", "label": "Fourplex" },
                        { "value": "5 Units+", "label": "5 Units+" },
                        { "value": "Townhouse or Condo", "label": "Townhouse or Condo" },
                        { "label": "Lot/New Construction", "value": "lot_new_construction" }
                    ]
                },
                {
                    "type": "text",
                    "label": "# Of Units",
                    "placeholder": "# Of Units",
                    "name": "numberofunits",
                },
                {
                    "type": "text",
                    "label": "Address",
                    "placeholder": "Enter Property Address",
                    "name": "propertyaddress"
                },
                {
                    "type": "text",
                    "label": "Property Address 2",
                    "placeholder": "Enter Property Address 2",
                    "name": "propertyaddress2"
                },
                // {
                //     "type": "text",
                //     "label": "Apt/Unit",
                //     "placeholder": "Enter apt/unit",
                //     "name": "apt_unit"
                // },
                {
                    "type": "text",
                    "label": "City",
                    "placeholder": "Enter City",
                    "name": "propertycity"
                },
                {
                    "type": "text",
                    "label": "County",
                    "placeholder": "Enter County",
                    "name": "propertycounty"
                },
                {
                    "type": "select",
                    "label": "State",
                    "name": "propertystate",
                   
                },
                {
                    "type": "text",
                    "label": "Zip",
                    "placeholder": "Enter Zip Code",
                    "name": "propertyzipcode"
                },
                {
                    "type": "text",
                    "label": "Bedrooms",
                    "placeholder": "Enter No Of Bedrooms",
                    "name": "totalbedrooms"
                },
                {
                    "type": "text",
                    "label": "Bathrooms",
                    "placeholder": "Enter No Of Bathrooms",
                    "name": "totalbathrooms"
                },
                {
                    "type": "select",
                    "label": "Pool",
                    "name": "haspool",
                    "options": [
                        {"label": "Select", "value": ""},
                        {"label": "Yes", "value": "Yes"},
                        {"label": "No", "value": "No"},
                    ]
                },
                {
                    "type": "text",
                    "label": "Lot SqFt",
                    "placeholder": "Enter Lot SqFt",
                    "name": "lotsqft"
                },
                {
                    "type": "text",
                    "label": "Building SqFt",
                    "placeholder": "Enter Building SqFt",
                    "name": "buildingsqft"
                },
                {
                    "type": "text",
                    "label": "Finished SqFt",
                    "placeholder": "Enter Finished SqFt",
                    "name": "finishedsqft"
                },
                {
                    "type": "select",
                    "label": "Occupancy",
                    "name": "occupancy",
                    "options": [
                        {"label": "Select", "value": ""},
                        {"label": "Delivered Vacant", "value": "Delivered Vacant"},
                        {"label": "COE + 7 days", "value": "COE + 7 days"},
                        {"label": "COE + 30 Days", "value": "COE + 30 Days"},
                        {"label": "Tenant Occupied", "value": "Tenant Occupied"},
                        {"label": "Unknown", "value": "Unknown"},
                    ]
                },
                {
                    "type": "text",
                   "label": "Year Built",
                    "name": "yearbuilt",
                    "placeholder": "Enter Year",
                    "checkboxlabel": "Year Built",
                    "checkboxlabelname": "yearbuiltvalue",
                  },
                {
                    "label": "Existing Loan",
                    "name": "existingloan",
                    "placeholder": "Enter Existing Loan",
                    "type": "text"
                },
                {
                    "label": "Loan For",
                    "name": "loanfor",
                    "options": [
                        {"label": "Select", "value": ""},
                        {"label": "Purchase", "value": "PURCHASE"},
                        {"label": "Refinance", "value": "REFINANCE"}
                    ],
                    "type": "select"
                },
                {
                    "label": "Easy Street AIV",
                    "name": "easystreetaiv",
                    "placeholder": "Enter AIV",
                    "type": "text"
                },
                {
                    "label": "Current Value",
                    "name": "currentvalue",
                    "placeholder": "Enter Current Value",
                     "type": "text"
                },
                {
                    "label": "Lien position",
                    "name": "proposedlienposition",
                    "options": [
                        {"label": "Select", "value": ""},
                        { "value": "1st Position", "label": "1st Position" },
                        { "value": "2nd Position", "label": "2nd Position" },
                    ],
                    "type": "select"
                },
                {
                    "label": "Borrower's Estimated",
                    "name": "estimatedvalue",
                    "placeholder": "Enter Estimated Value",
                     "type": "text"
                },
            ]
        },
        {
            "aboutthedeal": [
                {
                    "label": "Purchase",
                    "name": "purchaseprice",
                    "placeholder": "Enter Purchase",
                    "type": "text"
                },
                {
                    "label": "Purchase Date",
                    "name": "purchasedate",
                    "placeholder": "mm/dd/yyyy",
                    "type": "date"
                },
                {
                    "label": "Renovation/Construction $",
                    "name": "renovationcost",
                    "placeholder": "Enter Renovation/Construction $",
                    "type": "text"
                },
                {
                    "label": "Borrower ARV",
                    "name": "borrowerarv",
                    "placeholder": "Enter Borrower ARV",
                    "type": "text"
                },
                {
                    "label": "Easy Street ARV",
                    "name": "easystreetarv",
                    "placeholder": "Enter Easy Street ARV",
                    "type": "text"
                },
                {
                    "label": "Exit Strategy",
                    "name": "exitstrategy",
                    "type": "select",
                    "options": [
                        { "value": "Sale", "label": "Sale" ,"id" : "saleBorrower"},
                        { "value": "Refinance", "label": "Refinance" ,"id" : "refinanceBorrower"},
                        { "value": "Other", "label": "Other" ,"id" : "otherLoanBorrower"}
                      ],
                },
                {
                    "label": "Source of Down Payment",
                    "name": "downpaymentsource",
                    "placeholder": "Enter Source of Down Payment",
                    "type": "text"
                },
                {
                    "label": "Credit Report",
                    "name": "creditreport",
                    "options": [
                        {"label": "Select", "value": ""},
                        {"label": "Yes", "value": "Yes"},
                        {"label": "No", "value": "No"},
                    ],
                    "type": "select"
                },
                {
                    "label": "Appraisal",
                    "name": "appraisal",
                    "options": [
                        {"label": "N/A", "value": "N/A"},
                        {"label": "External Evaluation", "value": "External Evaluation"},
                        {"label": "Broker Price Opinion", "value": "Broker Price Opinion"},
                        {"label": "Hybrid Appraisal", "value": "Hybrid Appraisal"},
                        {"label": "Appraisal", "value": "Appraisal"},
                    ],
                    "type": "select"
                   
                },
                {
                    "type": "text",
                    "label": "Debt Service Coverage Ratio",
                    "placeholder": "Enter Debt Service Coverage Ratio",
                    "name": "debtservicecoverageratio",
                   
                },

                           
                // {
                //     "label": "Interest Only",
                //     "name": "interestonly",
                //     "options": [
                //         {"label": "Select", "value": ""},
                //         {"label": "True", "value": true},
                //         {"label": "False", "value": false}
                //     ],
                //     "type": "select"
                // },
                
                
                // {
                //     "label": "Cost of Renovation Completed",
                //     "name": "renovationcost",
                //     "placeholder": "Enter Cost of Renovation Completed",
                //     "type": "text"
                // },
                // {
                //     "label": "How much is the remaining rehab budget",
                //     "name": "remainingrehabbudget",
                //     "placeholder": "Enter remaining rehab budget",
                //     "type": "text"
                // },
                // {
                //     "type" : "checkbox",
                //     "label": "Flood Insurance Required",
                //     "name": "floodinsurencevalue",
                //     "checkboxlabel": "Flood Insurance Required",
                //     "checkboxlabelname": "floodinsurencevalue",
                // },
                
                
            ]
        },
        {
            "loanstructure": [{
                    "label": "Max LTC (%)",
                    "name": "maxltc",
                    "placeholder": "Enter Max LTC (%)",
                    "type": "text"
                },
                {
                    "label": "Max LTV (%)",
                    "name": "maxltv",
                    "placeholder": "Enter Max LTV (%)",
                    "type": "text"
                },
                {
                    "label": "Program Type",
                    "name": "programtype",
                    "options": [
                        {"label": "Select", "value": ""},
                        {"label": "Easy Fix", "value": "EASY FIX"},
                        {"label": "Easy Build", "value": "EASY BUILD"}
                    ],
                    "type": "select"
                },
                {
                    "label": "Loan Type",
                    "name": "loantype",
                    "options": [
                        {"label": "Select", "value": ""},
                        ...loanPurposeType.map(option => {
                            return { "label": option.option, "value": option.option }; 
                        })
                    ],
                    "type": "select"
                },
                {
                    "type": "select",
                    "label": "Lender Funded Rehab",
                    "name": "lenderfundedrehab",
                    "options": [
                        { "label": "Select ", "value": "" },
                        { "value": "Yes", "label": "Yes" },
                        { "value": "No", "label": "No" },
                    ]
                        
                },
                {
                    "label": "Points (%)",
                    "name": "points",
                    "placeholder": "Enter Points (%)",
                    "type": "text"
                },
                {
                    "label": "Interest Rate (%)",
                    "name": "interestrate",
                    "placeholder": "Enter Interest Rate (%)",
                    "type": "text"
                },
                {
                    "label": "Draw Interest Type",
                    "name": "drawinteresttype",
                    "options": [
                        {"label": "Select", "value": ""},
                        {"label": "Interest as Disbursed", "value": "Interest as Disbursed"},
                        {"label": "Interest on Full Balance", "value": "Interest on Full Balance"}
                    ],
                    "type": "select"
                },
                {
                    "label": "Loan Term",
                    "name": "loanterm",
                    "options": [
                        {"label": "Select", "value": ""},
                        ...loanTerm.map(option => {
                            const numericValue = parseInt(option.option); 
                            return { "label": option.option, "value": numericValue }; 
                        })
                    ],
                    "type": "select"
                },
                {
                    "type": "text",
                    "label": "Origination Fee",
                    "name": "originationFee",
                    "formula": "Max(((Points + Broker Points) / 100) * Loan Amount, PrefillsOriginationFee)"  
                },
                {
                    "type": "text",
                    "label": "Doc Prep Fee",
                    "placeholder": "Enter Doc Prep Fee",
                    "name": "docfee"
                },
                {
                    "type": "text",
                    "label": "Draw Fee",
                    "name": "drawFee",
                },
                {
                    "label": "Valuation Fee",
                    "name": "valuationfee",
                    "placeholder": "Enter Valuation Fee",
                    "type": "text"
                },   
                {
                    "type": "select",
                    "label": "Interest Reserves",
                    "options": [
                        { "label": "Select", "value": "" },
                        { "label": "Yes", "value": "Yes" },
                        { "label": "No", "value": "No" }
                    ],
                    "name": "interestreserves"
                },
                {
                    "label": "Interest Reserves Term",
                    "name": "interestreservesloanterm",
                    "options": [
                        {"label": "Select", "value": ""},
                        ...loanTerm.map(option => {
                            const numericValue = parseInt(option.option); 
                            return { "label": option.option, "value": numericValue }; 
                        })
                    ],
                    "type": "select"
                },
                {
                    "label": "Cash Reserves Required",
                    "name": "cashreverserequired",
                    "type": "text",
                    "placeholder" : "Enter Cash Reserves Required",
                    "checkboxlabel": "Cash Reserves Required",
                    "checkboxlabelname": "cashreverserequiredvalue",
                    "formula": "Cash to Close + Rehab Equity + ((Max Monthly Loan Payment + Initial Monthly Loan Payment)/2) * Loan Term;" 
                }, 
                {
                    "label": "Monthly Rental Rate",
                    "name": "monthlyrentalrate",
                    "placeholder": "Enter Monthly Rental Rate",
                    "type": "text"
                },
                {
                    "label": "Minimum Monthly Interest",
                    "name": "minimummonthlyinterest",
                    "type": "text",
                    "placeholder" : "Enter Minimum Monthly Interest",
                    "formula": "If Draw Interest Type == Interest on Full Balance ? Loan Amount * InterestRate / 12 : If Draw Interest Type = Interest as Disbursed ? Initial Funding * InterestRate / 12 : 0" 
                },
                {
                    "label": "Maximum Monthly Interest",
                    "name": "maximummonthlyinterest",
                    "type": "text",
                    "placeholder" : "Enter Cash Reserves Required",
                    "formula": "Loan Amount * Interest Rate / 12" 
                },
            
            ]},
        {
            "fee": [
                {
                    "type": "text",
                    "label": "Broker Points (%)",
                    "placeholder": "Enter Broker Points",
                    "name": "brokerpoints",
                    "checkboxlabel": "Broker Points (%)",
                    "checkboxlabelname": "addtoESfeesBrokerPoints",
                },
                {
                    "type": "text",
                    "label": "Broker Processing Fee",
                    "placeholder": "Enter Broker Processing Fee",
                    "name": "brokerprocessingfee",
                    "checkboxlabel": "Broker Processing Fee",
                    "checkboxlabelname": "addtoESfeesBrokerProcessingFee",
                },
                {
                    "label": "Flood Insurance",
                    "name": "floodinsurence",
                    "type": "text",
                    "placeholder" : "$0.00",
                    "checkboxlabel": "Flood Insurance Required",
                    "checkboxlabelname": "floodinsurencevalue",
                },
                {
                    "type": "text",
                    "label": "Escrow",
                    "name": "escrow",
                },
                {
                    "type": "text",
                    "label": "Property Insurance",
                    "name": "insuranceamount",
                    "placeholder": "Enter Property Insurance Amount",
                    "formula": "Insurance Percent of Loan amount" 
                },
                {
                    "type": "text",
                    "label": "Title (%)",
                    "name": "title",
                },
                {
                    "type": "text",
                    "label": "Title Amount",
                    "name": "titleAmount",
                },
            ]
        },
        {
            "calculations": [
                {
                    "type": "checkbox",
                    "label": "Override Calculated Fields",
                    "name": "overridecalculationfee",
                    
                },
                {
                    "type": "text",
                    "label": "Total Cost",
                    "placeholder": "Total Cost",
                    "name": "totalcost",
                    "formula": "Purchase + Reno $" 
                    
                },
                {
                    "type": "text",
                    "label": "Loan Amount",
                    "placeholder": "Enter Loan Amount",
                    "name": "loanamount",
                    "formula": "Lender Funded Rehab === 'Yes' ? Math.min(Max LTV  * Easy Street ARV, Max LTC * (Purchase + Reno $)) : Math.min(Max LTV *  Easy Street ARV / (Purchase + Reno $), Max LTC) * Purchase;" 
                   
                },
                {
                    "type": "text",
                    "label": "Actual LTV (%)",
                    "placeholder": "Enter Actual LTV (%)",
                    "name": "actualltv",
                    "formula": "Loan Amount / Easy Street ARV" 
                    
                },
                {
                    "type": "text",
                    "label": "Actual LTC (%)",
                    "placeholder": "Enter Actual LTC (%)",
                    "name": "actualltc",
                    "formula": "Loan Amount / Total Cost" 
                    
                },
                {
                    "type": "text",
                    "label": "Initial Funding",
                    "placeholder": "Enter Initial Funding",
                    "name": "initialfunding",
                    "formula": "Lender Funded Rehab === 'Yes' ? Math.min(Max LTV * Easy Street ARV, Max LTC * Purchase + Reno $) - (Reno $ * (Math.min(Max LTV * Easy Street ARV, Max LTC * Purchase +  Reno $) / Purchase + Reno $)); : Math.min(Max LTV * Easy Street ARV / Purchase + Reno $,  Max LTC) * Purchase;" 
                    
                },
                {
                    "type": "text",
                    "label": "Initial Equity",
                    "placeholder": "Enter Initial Equity",
                    "name": "initialequity",
                    "formula": "Purchase - Initial Funding;" 
                    
                },
                {
                    "type": "text",
                    "label": "Rehab Funding",
                    "placeholder": "Enter Rehab Funding",
                    "name": "rehabfunding",
                    "formula": "Lender Funded Rehab === 'Yes' ? Math.min(Max LTV * Easy Street ARV, Max LTC * Total Cost) - Initial Funding : 0;" 
                    
                },
                {
                    "type": "text",
                    "label": "Rehab Equity",
                    "placeholder": "Enter Rehab Equity",
                    "name": "rehabequity",
                    "formula": "Reno $ - Rehab Funding" 
                   
                },
                // {
                //     "type": "text",
                //     "label": "Max Monthly Interest Payment",
                //     "placeholder": "Enter Max Monthly Interest Payment",
                //     "name": "maxmonthlyloanpayment",
                //     "formula": "(Interest Rate / 12) * Loan Amount;" 
                   
                // },
                {
                    "type": "text",
                    "label": "Cash to Close",
                    "placeholder": "Enter Cash to Close",
                    "name": "cashtoclose",
                    "formula": "Purchase + (Points * Loan Amount) + Valuation Fee + Flood Insurance + (Broker Points * Loan Amount) + Broker Processing Fee + Doc Prep Fee + Insurance Amount - Initial Funding;" 
                   
                },
                
                // {
                //     "type": "text",
                //     "label": "Insurance Amount",
                //     "placeholder": "Enter Insurance Amount",
                //     "name": "insuranceamount",
                //     "formula": "Max Monthly Loan Payment * Loan Term"
                    
                // },
                {
                    "type": "text",
                    "label": "Interest Reserve Amount",
                    "placeholder": "Enter interest Reserve Amount",
                    "name": "interestreserveamount",
                    "formula": "If Draw Interest Type is 'Interest on Full Balance' ? (((Initial Funding+Rehab Funding)/2)*Interest Rate) * Interest Reserves Loan Term : (((Initial Funding + Loan Amount) / 2) * (Interest Rate / 100)) / 12 * Interest Reserves Loan Term"
                    
                },
                {
                    "type" : "text",
                    "name": "emptyinput",
                },
                {
                    "type" : "text",
                    "name": "emptyinput",
                },
                {
                    "type": "textarea",
                    "label": "Borrower/Originator Summary",
                    "placeholder": "Enter Borrower/Originator Summary",
                    "name": "executionstrategysummary",
                   
                },
                {
                    "type": "textarea",
                    "label": "Underwriting Summary",
                    "placeholder": "Enter Underwriting Summary",
                    "name": "underwritingsummary",
                   
                },
                {
                    "type": "textarea",
                    "label": "Originator Instructions",
                    "placeholder": "Enter Originator Instructions",
                    "name": "originatorinstructions",
                   
                }
            ]
        },
        {
            "profitabilityanalysis": [
                {
                    "type": "text",
                    "label": "Sales Expense",
                    "placeholder": "Sales Expense",
                    "name": "salesexpense",
                    "formula": "5% of Easy Street ARV"
                    
                },
                {
                    "type": "text",
                    "label": "Net Profit",
                    "name": "netprofit",
                    "formula": "Easy Street ARV - Total Cost of Deal - Sale Expense"
                 },
                {
                    "type": "text",
                    "label": "Deal Margin",
                    "name": "dealmargin",
                    "formula": "Net Profit/Total Cost of Deal"
                    
                },
                {
                    "type": "text",
                    "label": "Cash on Cash Return ",
                    "name": "cashoncashreturn",
                    "formula": "Net Profit/Cash Required"
                    
                },
                {
                    "type": "text",
                    "label": "Annualized Return",
                    "name": "annualizedreturn",
                    "formula": "(Cash on Cash Return x 12)/Loan Term"
                },
            ]
        }
    ]
    

    useEffect(() => {
        if(loanInfo?.loanid){
            setUnderWritingData(underwritingVariable)
            getUnderwritingData();
            setLoanid(loanInfo?.loanid);
            setUnderwritingErrors({})
        }
    }, [loanInfo?.loanid])

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('checkedPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }
            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.pipelinePermissions
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('checkedPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }

                
            }
            
            //setViewStatus()
      }, []);

    useEffect(() => {
        getstates();
        loandropdown();
        loandropdownType();
    }, [])
    const isValid = value => value !== '' && value !== null && value !== undefined;
    useEffect(() => {
     //   console.log('singleTermSheetsingleTermSheetnw', (singleTermSheet?.underwritingSheet?.insurancePercent ? (singleTermSheet.underwritingSheet?.insurancePercent / 100 * underWritingData?.loanamount) : 0));
        // setUnderWritingData(prevState => ({
        //     ...prevState,
        //     abouttheproperty: {
        //         ...prevState.abouttheproperty,
        //         points: isValid(prevState.abouttheproperty.points) ? prevState.abouttheproperty.points : singleTermSheet?.underwritingSheet?.points,
        //         maxltv: isValid(prevState.abouttheproperty.maxltv) ? prevState.abouttheproperty.maxltv : singleTermSheet?.underwritingSheet?.maxLTV,
        //         maxltc: isValid(prevState.abouttheproperty.maxltc) ? prevState.abouttheproperty.maxltc : singleTermSheet?.underwritingSheet?.maxLTC,
        //     },
        //     interestrate: isValid(prevState.interestrate) ? prevState.interestrate : singleTermSheet?.underwritingSheet?.interestRate,
        //     loanterm: isValid(prevState.loanterm) ? prevState.loanterm : singleTermSheet?.underwritingSheet?.loanTerm,
        //     originationFee: isValid(prevState.originationFee) ? prevState.originationFee : singleTermSheet?.termSheet?.originationFee,
        //     title: isValid(prevState.title) ? prevState.title : singleTermSheet?.termSheet?.title,
        //     escrow: isValid(prevState.escrow) ? prevState.escrow : singleTermSheet?.termSheet?.escrow,
        //     insuranceamount: isValid(prevState.insuranceamount) ? prevState.insuranceamount : (singleTermSheet?.underwritingSheet?.insurancePercent ? (singleTermSheet.underwritingSheet.insurancePercent / 100 * underWritingData?.loanamount) : 0),
        //     drawFee: isValid(prevState.drawFee) ? prevState.drawFee : singleTermSheet?.termSheet?.drawFee,
        //     docfee: isValid(prevState.docfee) ? prevState.docfee : singleTermSheet?.termSheet?.docfee,
        // }));
    }, [singleTermSheet]);
    const [isStateProgTypeChanged,setStateProgTypeChanged] = useState(false);
    useEffect(() => {
      //  console.log('underWritingData?.programtype',underWritingData?.programtype, underWritingData?.abouttheproperty?.propertystate)
        if(underWritingData?.programtype && underWritingData?.abouttheproperty?.propertystate && isStateProgTypeChanged){
        //   console.log('stateshere',states);
        //    getSingleTermSheetPrefillState(underWritingData?.programtype, underWritingData?.abouttheproperty?.propertystate).then(function (response) {
        //  if(response?.result){
        //     console.log('get single term sheet ',response?.result?.data[0]);
        //     setSingleTermSheet(response?.result?.data[0]);
        //     const result = response?.result?.data[0] ?? {};
        //     setUnderWritingData(prevState => ({
        //         ...prevState,
        //         abouttheproperty: {
        //             ...prevState.abouttheproperty,
        //             points: result?.underwritingSheet?.points,
        //             maxltv: result?.underwritingSheet?.maxLTV,
        //             maxltc: result?.underwritingSheet?.maxLTC,
        //         },
        //         interestRate: result?.underwritingSheet?.interestRate,
        //         insurancePercent: result?.underwritingSheet?.insurancePercent,
        //         docfee: result?.termSheet?.docFee,
        //         title: result?.termSheet?.title,
        //         escrow: result?.termSheet?.escrow,
        //         originationFee: result?.termSheet?.originationFee,
        //         drawFee: result?.termSheet?.drawFee,
        //         loanTerm: result?.underwritingSheet?.loanTerm,

        //     }));
        //  }
        
        // }).catch((err) => {
        // console.error.bind("get single term sheet", err);
        // })
    }
    }, [underWritingData?.programtype, underWritingData?.abouttheproperty?.propertystate])

    useEffect(() => {
       
        if(underWritingData?.programtype == "EASY BUILD" &&  underWritingData?.programtype != loanUnderwriting?.programtype){
            setUnderWritingData(prevState => ({
                         ...prevState,
                         drawinteresttype: 'Interest as Disbursed',
                         loanterm: 9
            }))
        }
    }, [underWritingData?.programtype])


    const loandropdown = async () => {
        LoanTermDropdown().then(function (response) {
            if (response?.result) {
                let data = response?.result?.data[0]?.lookupOptions
                setLoanTerm(data);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };
    const loandropdownType = async () => {
        lookupsCallForPipeline(10016).then(function (response) {
            if (response?.result) {
                let data = response?.result?.data[0]?.lookupOptions;
                setloanPurposeType(data)
            }
        }).catch((err) => {
            console.error.bind("my account error", err);
        })
    };

    useEffect(() => {
        if(isDownloadTermSheetClicked){
            //generatePDF()
            handleSave()
        }
    }, [isDownloadTermSheetClicked])

    

     // useEffect to update underWritingData when loanUnderwriting changes

    //  useEffect(() => {
    //     // Function to update underWritingData with values from loanUnderwriting
    //     // dispatch(showLoader());
       
    //         const updateUnderWritingData = () => {
    //             const updatedUnderWritingData = { ...underWritingData };
                
    //             // Loop through keys in loanUnderwriting
    //             for (const key in loanUnderwriting) {
    //                 // Check if the key exists in underWritingData
    //                 if (updatedUnderWritingData.hasOwnProperty(key)) {
    //                     // Update the value in underWritingData with value from loanUnderwriting
    //                     updatedUnderWritingData[key] = loanUnderwriting[key];
    //                 }
    //             }
                
    //             // Set the updated underWritingData state
    //             setUnderWritingData(updatedUnderWritingData);
    //             // dispatch(hideLoader());
    //         };
    //         // if(loanUnderwriting?.length !== 0){
    //             updateUnderWritingData();
    //         // }
        

    //     // Call the update function
        
        
        
    // }, []);

    
 
    
    useEffect(() => {
        const newMessages = {
            msg1: underWritingData?.abouttheborrower?.cashreverserequiredvalue || false,
            msg2: underWritingData?.abouttheproperty?.floodinsurencevalue || false,
            msg3: underWritingData?.abouttheproperty?.yearbuiltvalue || false,
        };
    

        let mixedmsg = '';
        if (newMessages.msg1 && loanUnderwriting?.prefills?.originatorInstructions?.cashReservesRequired) {
            mixedmsg += loanUnderwriting?.prefills?.originatorInstructions?.cashReservesRequired+ '\n';
        }
        if (newMessages.msg2 && loanUnderwriting?.prefills?.originatorInstructions?.floodInsuranceRequired) {
            mixedmsg += loanUnderwriting?.prefills?.originatorInstructions?.floodInsuranceRequired+ '\n';
        }
        if (newMessages.msg3 && loanUnderwriting?.prefills?.originatorInstructions?.yearBuiltBefore1940) {
            mixedmsg += loanUnderwriting?.prefills?.originatorInstructions?.yearBuiltBefore1940 + '\n';
        }
    
        // Use the mixedmsg for whatever you need to do
       // console.log('mixedmsg', mixedmsg);
        setUnderWritingData(prevState => {
            const userTypedData = prevState?.originatorinstructions?.split('\n').filter(line => 
                ![
                    loanUnderwriting?.prefills?.originatorInstructions?.cashReservesRequired,
                    loanUnderwriting?.prefills?.originatorInstructions?.floodInsuranceRequired,
                    loanUnderwriting?.prefills?.originatorInstructions?.yearBuiltBefore1940
                ].includes(line)
            ).join('\n') || '';
    
            // Clean up newlines
            const cleanedMixedMsg = (userTypedData + '\n' + mixedmsg).replace(/\n+/g, '\n').trim();
    
            return {
                ...prevState,
                originatorinstructions: cleanedMixedMsg
            };
        });
    
    }, [
        underWritingData?.abouttheborrower?.cashreverserequiredvalue,
        underWritingData?.abouttheproperty?.floodinsurencevalue,
        underWritingData?.abouttheproperty?.yearbuiltvalue
    ]);

   
    function addCommas(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function handleInputFormatting(value, type) {
        if (value === undefined || value === null) {
            return '';
        }
        const strValue = String(value);
    
        switch (type) {
            // case 'numeric':
            //     // Remove any non-numeric characters
            //     return strValue.replace(/[^0-9]/g, '');
            // case 'float':
            //     // Remove any non-numeric characters except dot (.)
            //     const floatValue = strValue.replace(/[^0-9.]/g, '');
            //     return '$' + floatValue;
            // case 'numeric':
            //     // Remove any non-numeric characters
            //     const numericValue = strValue.replace(/[^0-9]/g, '');
            //     // Add commas to the numeric value
            //     return addCommas(numericValue);
            case 'numeric':
                // Remove any non-numeric characters
                return strValue.replace(/[^0-9]/g, '');
            case 'float':
                // Remove any non-numeric characters except dot (.)
                const floatValue = strValue.replace(/[^0-9.]/g, '');
                // Add commas to the float value
                let formattedFloat = addCommas(floatValue);
                // Add $ currency symbol
                formattedFloat = '$' + formattedFloat;
                return formattedFloat;
            case 'percentage':
                // Remove any non-numeric characters
                const percentageValue  = strValue.replace(/[^0-9]/g, '');
                // Append '%' symbol to the sanitized numeric value
                return percentageValue  + '%';
            default:
                return strValue;
        }
    }
    

    function handleTypeChange(fieldName, value) {
        let formattedValue = value;
        let numericInputs = ['yearbuilt',"bathroom", "bedroom", 'statedcreditscore','investmentproperties','zipcode','totalbedrooms','totalbathrooms','numberofunits',
            'lotsqft','finishedsqft','buildingsqft',
        ];

        let floatInputs = ['price','liquidcashreserve','purchaseprice',
            'renovationcost','brokerprocessingfee','docfee','currentvalue','cashreverserequired',
            'easystreetaiv','easystreetarv', 'valuationfee', 'monthlyrentalrate','existingloan', 'floodinsurence'
            
        ];

        const perecentageInputs = [ 'maxltc', 'maxltv', 'interestrate','points','brokerpoints', 'title'];
    
        // Check if the field name belongs to numeric inputs
        if (numericInputs.includes(fieldName)) {
            formattedValue = handleInputFormatting(value, 'numeric');
        }
        // Check if the field name belongs to float inputs
        else if (floatInputs.includes(fieldName)) {
            formattedValue = handleInputFormatting(value, 'float');
            formattedValue = (formattedValue === '$' ? '' : formattedValue)
        }
        // Check if the field name belongs to percentage inputs
        else if (perecentageInputs.includes(fieldName)) {
            formattedValue = handleInputFormatting(value, 'percentage');
        }
        
        formattedValue = (formattedValue === 'NaN') ? '' : formattedValue;
        return formattedValue;
    }

    useEffect(() => {
         
        if (loanUnderwriting) {
       
      
            const hasData = loanUnderwriting?.propertycomparisons?.some(comparison => Object.values(comparison).some(value => value !== ""));
         //   console.log('loanUnderwriting?.broker?.length - 1',loanUnderwriting);
            setUnderWritingData({
                ...underwritingVariable,
                loanid: loanInfo?.loanid,
                abouttheborrower: {
                  ...underwritingVariable.abouttheborrower,
                  applicantid: loanUnderwriting?.applicant?.[0]?.applicantid,
                  borrowerfirstname: loanUnderwriting?.applicant?.[0]?.borrowerfirstname,
                  borrowerlastname: loanUnderwriting?.applicant?.[0]?.borrowerlastname,
                  borroweremail: loanUnderwriting?.applicant?.[0]?.borroweremail,
                  borrowerphone: loanUnderwriting?.applicant?.[0]?.borrowerphone,
                  statedcreditscore: loanUnderwriting?.applicant?.[0]?.statedcreditscore,
                  liquidcashreserve: loanUnderwriting?.applicant?.[0]?.liquidcashreserve,
                  borroweroccupation: loanUnderwriting?.applicant?.[0]?.borroweroccupation,
                  investmentproperties: loanUnderwriting?.applicant?.[0]?.investmentproperties,
                  cashreverserequiredvalue: loanUnderwriting?.applicant?.[0]?.cashreverserequiredvalue,
                  applicationlicense: loanUnderwriting?.applicant?.[0]?.applicationlicense,
                  
                },
                applicant:loanUnderwriting?.applicant,
                propertyinfo: loanUnderwriting?.propertyinfo,
                // entity: {
                //   ...underwritingVariable.entity,
                //   entityid: loanUnderwriting?.entity?.[0]?.entityid,
                //   legalname: (loanUnderwriting?.entity?.[0]?.legalname) ? loanUnderwriting?.entity?.[0]?.legalname : "No"
                // },
                entity: loanUnderwriting.entity,
                abouttheproperty: {
                  ...underwritingVariable.abouttheproperty,
                  propertyid: loanUnderwriting?.propertyinfo?.[0]?.propertyid,
                  apt_unit : loanUnderwriting?.propertyinfo?.[0]?.apt_unit,
                  propertytype: loanUnderwriting?.propertyinfo?.[0]?.propertytype,
                  numberofunits : loanUnderwriting?.propertyinfo?.[0]?.numberofunits,
                  propertyaddress: loanUnderwriting?.propertyinfo?.[0]?.propertyaddress?.trim(),
                  propertyaddress2: loanUnderwriting?.propertyinfo?.[0]?.propertyaddress2,
                  propertycity: loanUnderwriting?.propertyinfo?.[0]?.propertycity,
                  propertycounty: loanUnderwriting?.propertyinfo?.[0]?.propertycounty,
                  propertystate: loanUnderwriting?.propertyinfo?.[0]?.propertystate,
                  propertyzipcode: loanUnderwriting?.propertyinfo?.[0]?.propertyzipcode,
                  totalbedrooms: loanUnderwriting?.propertyinfo?.[0]?.totalbedrooms,
                  yearbuilt: loanUnderwriting?.propertyinfo?.[0]?.yearbuilt,
                  yearbuiltvalue: loanUnderwriting?.propertyinfo?.[0]?.yearbuiltvalue,
                  totalbathrooms: loanUnderwriting?.propertyinfo?.[0]?.totalbathrooms,
                  haspool: loanUnderwriting?.propertyinfo?.[0]?.haspool,
                  lotsqft: formatNumericInput(loanUnderwriting?.propertyinfo?.[0]?.lotsqft?.toString()),
                  buildingsqft: formatNumericInput(loanUnderwriting?.propertyinfo?.[0]?.buildingsqft?.toString()),
                  finishedsqft: formatNumericInput(loanUnderwriting?.propertyinfo?.[0]?.finishedsqft?.toString()),
                  purchaseprice: loanUnderwriting?.propertyinfo?.[0]?.purchaseprice,
                  purchasedate: loanUnderwriting?.propertyinfo?.[0]?.purchasedate,
                  renovationcost: loanUnderwriting?.propertyinfo?.[0]?.renovationcost || loanUnderwriting?.propertyinfo?.[0]?.construction_rehab_budget,
                  downpaymentsource: loanUnderwriting?.propertyinfo?.[0]?.downpaymentsource,
                  floodinsurence: loanUnderwriting?.propertyinfo?.[0]?.floodinsurence,
                  floodinsurencevalue: loanUnderwriting?.propertyinfo?.[0]?.floodinsurencevalue,
                  debtservicecoverageratio : loanUnderwriting?.propertyinfo?.[0]?.debtservicecoverageratio,
                  borrowerarv : (loanUnderwriting?.propertyinfo?.[0]?.borrowerarv) ? (loanUnderwriting?.propertyinfo?.[0]?.borrowerarv) : (loanUnderwriting?.propertyinfo?.[0]?.renovationarv),
                  occupancy : loanUnderwriting?.propertyinfo?.[0]?.occupancy,
                  easystreetaiv : loanUnderwriting?.propertyinfo?.[0]?.easystreetaiv,
                  currentvalue : loanUnderwriting?.propertyinfo?.[0]?.currentvalue,
                  easystreetarv : loanUnderwriting?.propertyinfo?.[0]?.easystreetarv,
                  maxltc :   loanUnderwriting?.propertyinfo?.[0]?.maxltc ? parseFloat(loanUnderwriting?.propertyinfo?.[0]?.maxltc)?.toFixed(2) : 0.00,
                  maxltv :   loanUnderwriting?.propertyinfo?.[0]?.maxltv ? parseFloat(loanUnderwriting?.propertyinfo?.[0]?.maxltv)?.toFixed(2) : 0.00,
                  points :   loanUnderwriting?.propertyinfo?.[0]?.points ? parseFloat(loanUnderwriting?.propertyinfo?.[0]?.points)?.toFixed(2) : 0.00,
                  //amortizationterm :  loanUnderwriting?.propertyinfo?.[0]?.amortizationterm ,
                  valuationfee :  loanUnderwriting?.propertyinfo?.[0]?.valuationfee ,
                  exitstrategy :  (loanUnderwriting?.propertyinfo?.[0]?.exitstrategy == 'Refinance' || loanUnderwriting?.propertyinfo?.[0]?.exitstrategy == 'Sale') ? loanUnderwriting?.propertyinfo?.[0]?.exitstrategy : 'Other',
                  monthlyrentalrate :  loanUnderwriting?.propertyinfo?.[0]?.monthlyrentalrate ,
                  lenderfundedrehab : loanUnderwriting?.propertyinfo?.[0]?.lenderfundedrehab || loanUnderwriting?.propertyinfo?.[0]?.refinanceloanrehabfunding,
                  existingloan:  loanUnderwriting?.propertyinfo?.[0]?.existingloan,
                  executionstrategysummary:  loanUnderwriting?.propertyinfo?.[0].executionstrategysummary,
                },
                fee: {
                  ...underwritingVariable.fee,
                  brokerid: loanUnderwriting?.broker?.[loanUnderwriting?.broker?.length - 1]?.brokerid,
                  brokerpoints:  loanUnderwriting?.broker?.[loanUnderwriting?.broker?.length - 1]?.brokerpoints ? parseFloat(loanUnderwriting?.broker?.[loanUnderwriting?.broker?.length - 1]?.brokerpoints)?.toFixed(2) : 0.00,
                  brokerprocessingfee: loanUnderwriting?.brokerprocessingfee,
                  
                },
                programtype: loanUnderwriting?.programtype,
                loantype: loanUnderwriting?.loantype,
                loanfor: loanUnderwriting?.loanfor,
                overridecalculationfee: loanUnderwriting?.overridecalculationfee ?? false,
                initialfunding: loanUnderwriting?.initialfunding,
                initialequity:  loanUnderwriting?.initialequity,
                rehabfunding:  loanUnderwriting?.rehabfunding,
                rehabequity:  loanUnderwriting?.rehabequity,
                debtservicecoverageratio:  loanUnderwriting?.debtservicecoverageratio,
                insuranceamount:  loanUnderwriting?.insuranceamount,
                interestreserveamount: loanUnderwriting?.interestreserveamount,
                underwritingsummary:  loanUnderwriting?.underwritingsummary,
                originatorinstructions:  loanUnderwriting?.originatorinstructions,
                interestrate:  loanUnderwriting?.interestrate?  parseFloat(loanUnderwriting?.interestrate)?.toFixed(2) :0.00,
                loanterm:  loanUnderwriting?.loanterm,
                interestreservesloanterm: loanUnderwriting?.interestreservesloanterm,
                creditreport: loanUnderwriting?.creditreport,
                appraisal: loanUnderwriting?.appraisal,
                loanamount :  loanUnderwriting?.loanamount ,
                drawinteresttype:  loanUnderwriting?.drawinteresttype,
                //interestonly:  loanUnderwriting?.interestonly,
                loanorganisationpoints: loanUnderwriting?.loanorganisationpoints,
                usewhitelabel:  loanUnderwriting?.usewhitelabel,
                propertycomparisons : (hasData) ? loanUnderwriting?.propertycomparisons : underwritingVariable?.propertycomparisons,
                //propertycomparisons : loanUnderwriting?.propertycomparisons 
                //propertycomparisons: loanUnderwriting?.propertycomparisons
                docfee : loanUnderwriting?.docfee,
                interestreserves: loanUnderwriting?.interestreserves,
                actualltc: loanUnderwriting?.actualltc ? parseFloat(loanUnderwriting?.actualltc)?.toFixed(2) : 0.00,
                actualltv: loanUnderwriting?.actualltv ? parseFloat(loanUnderwriting?.actualltv)?.toFixed(2) : 0.00,
                title: loanUnderwriting.title ? parseFloat(loanUnderwriting.title)?.toFixed(2) : 0.00,
                originationFee: loanUnderwriting.originationFee,
                escrow: loanUnderwriting.escrow,
                drawFee:loanUnderwriting.drawFee,
                titleAmount: loanUnderwriting.titleAmount,
                totalcost: loanUnderwriting?.totalcost,
                cashtoclose: loanUnderwriting?.cashtoclose,
                // maxmonthlyloanpayment: loanUnderwriting?.maxmonthlyloanpayment, 
                cashreverserequired: loanUnderwriting?.cashreverserequired,
                salesexpense: loanUnderwriting?.salesexpense,
                netprofit:  loanUnderwriting?.netprofit ? parseFloat(loanUnderwriting?.netprofit)?.toFixed(2) : 0,
                dealmargin:  loanUnderwriting?.dealmargin ? parseFloat(loanUnderwriting?.dealmargin)?.toFixed(2) : 0,
                cashoncashreturn:  loanUnderwriting?.cashoncashreturn ? parseFloat(loanUnderwriting?.cashoncashreturn)?.toFixed(2) : 0,
                annualizedreturn:  loanUnderwriting?.annualizedreturn ? parseFloat(loanUnderwriting?.annualizedreturn)?.toFixed(2) : 0,
                addtoESfeesBrokerPoints: loanUnderwriting?.addtoESfeesBrokerPoints,
                addtoESfeesBrokerProcessingFee: loanUnderwriting?.addtoESfeesBrokerProcessingFee,
                maximummonthlyinterest: loanUnderwriting?.maximummonthlyinterest,
                minimummonthlyinterest: loanUnderwriting?.minimummonthlyinterest,
                 
            });

            
            //if(hasData){
                //setUnderWritingData({...underwritingVariable,propertycomparisons : loanUnderwriting?.propertycomparisons})
                
            //}
       


            

        }

    }, [loanUnderwriting]);



  
    /*get call api */
    // useEffect(() => {
    //     dispatch(showLoader());
    //     getUnderwriting(loanid).then(function(response) {
    //         if (response?.statusCode === 200 && response?.result?.length !== 0) {
    //             // console.log('loan underwriting data api response', response);
    //             setLoanUnderwriting(response?.result[0]);
    //             dispatch(hideLoader());
    //         }
    //     })
    //     .catch((err) => {
    //         console.error("loan underwrting data api", err); 
    //         dispatch(hideLoader());
    //     });
    // },[])

    


    
    const getUnderwritingData = (isDataSaved) =>{
        dispatch(showLoader());
        // getUnderwriting(loanInfo?.loanid)
        // .then(function(response) {
        //     if (response?.statusCode === 200 && response?.result?.length !== 0) {
        //         console.log('get underwriting data api response', response);
        //         setLoanUnderwriting(response?.result[0]);
               
        //     }
        //     dispatch(hideLoader());
        // })
        // .catch((err) => {
        //     console.error("error", err); 
        //     dispatch(hideLoader());
        // });
        getLoanDetails(loanInfo?.loanid)
        .then(function(response) {
            if (response?.statusCode === 200 && response?.result?.length !== 0) {
                setLoanUnderwriting(response?.result[0]);
                if(isDataSaved){
                    setLoanInfo(response?.result[0]);
                }
                const result = response?.result[0] ?? {};

                setSingleTermSheet({
                    underwritingSheet: {
                        loanTerm: result.loanterm,
                        points: result.propertyinfo[0].points,
                        interestRate: result.interestRate,
                        maxLTV: result.propertyinfo[0].maxltv?.toFixed(2),
                        maxLTC: result.propertyinfo[0].maxltc?.toFixed(2),
                        insurancePercent: result.propertyinfo[0].insurancePercent
                    },
                    termSheet: {
                        docfee: result.docfee,
                        title: result.title,
                        escrow: result.escrow,
                        originationFee: result.originationFee,
                        drawFee: result.drawFee
                    }
                });
            }
            dispatch(hideLoader());
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    }; 
    
    
    function calculateFormulas() {
        if (!underWritingData || !underWritingData.abouttheproperty || !underWritingData.fee) {
            console.error('Missing required data for calculation.');
            return;
        }
      //  console.log('initial load')
        let {
            floodinsurence = 0,
            valuationfee = 0,
            renovationcost = 0,
            purchaseprice = 0,
            maxltv = 0,
            maxltc = 0,
            easystreetarv = 0,
            points = 0,
            lenderfundedrehab = 'No'
        } = underWritingData?.abouttheproperty || {};
    
        let {
            brokerprocessingfee = 0,
            brokerpoints = 0
        } = underWritingData?.fee || {};
    
        let {
            interestrate = 0,
            loanamount = 0,
            insuranceamount = 0,
            docfee = 0,
            loanterm = 0,
            interestreservesloanterm = 0,
            initialfunding = 0,
            initialequity = 0,
            rehabfunding = 0,
            rehabequity = 0,
            interestreserveamount = 0,
            originationFee = 0,
            loanorganisationpoints = 0,
            escrow=0,
            titleAmount = 0,
            title = 0,
            salesexpense = 0,
            netprofit = 0,
            dealmargin = 0,
            cashrequired = 0,
            cashoncashreturn = 0,
            annualizedreturn = 0,
        } = underWritingData || {};
    
        // Convert strings to numbers
        renovationcost = parseFloat(renovationcost) || 0;
        purchaseprice = parseFloat(purchaseprice) || 0;
        loanamount = parseFloat(loanamount) || 0;
        easystreetarv = parseFloat(easystreetarv) || 0;
        maxltc = parseFloat(maxltc) || 0;
        maxltv = parseFloat(maxltv) || 0;
        insuranceamount = parseFloat(insuranceamount) || 0;
        initialfunding = parseFloat(initialfunding) || 0;
        initialequity = parseFloat(initialequity) || 0;
        docfee = parseFloat(docfee) || 0; // Ensure docfee is converted to a number
        brokerprocessingfee = parseFloat(brokerprocessingfee) || 0;
        brokerpoints = parseFloat(brokerpoints) || 0;
        points = parseFloat(points) || 0;
        interestrate = parseFloat(interestrate) || 0;
        valuationfee = parseFloat(valuationfee) || 0;
        floodinsurence = parseFloat(floodinsurence) || 0;
        loanterm = parseFloat(loanterm) || 0;
        interestreservesloanterm = parseFloat(interestreservesloanterm) || 0;
        rehabfunding = parseFloat(rehabfunding) || 0;
        rehabequity = parseFloat(rehabequity) || 0;
        interestreserveamount = parseFloat(interestreserveamount) || 0;
        originationFee = parseFloat(originationFee) || 0;
        escrow = parseFloat(escrow) || 0;
        titleAmount = parseFloat(titleAmount) || 0;
        salesexpense = parseFloat(salesexpense) || 0;
        netprofit = parseFloat(netprofit) || 0;
        dealmargin = parseFloat(dealmargin) || 0;
        cashrequired = parseFloat(cashrequired) || 0;
        cashoncashreturn = parseFloat(cashoncashreturn) || 0;
        annualizedreturn = parseFloat(annualizedreturn) || 0;
  
        // Convert percentage values
        let maxLTV = maxltv / 100;
        let maxLTC = maxltc / 100;
        let pointsVal = points / 100;
        let brokerpointsVal = brokerpoints / 100;
        let interestrateVal = interestrate / 100;
        let loanorganisationpointsVal = loanorganisationpoints/100;
        let titleVal = title / 100;
        let salesExpensePersentageVal = 5/100;

        // Calculate maximum loan amounts
        let LTVARVMaxLoan = maxLTV * easystreetarv;
        let costBasis = purchaseprice + renovationcost;
        let LTCMaxLoan = maxLTC * costBasis;
        let lenderFundedRehabRatio = costBasis ? LTVARVMaxLoan / costBasis : 0;
        let loanSize = Math.min(lenderFundedRehabRatio, maxLTC) * purchaseprice;
        let lenderRehabNoLoanSize = Math.min(LTVARVMaxLoan, LTCMaxLoan);
        let loanAmountDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc',]
       // console.log('lastFieldtName',lastFieldtName, !loanAmountDependencies?.includes(lastFieldtName));
        let loanAmount = (lastFieldtName == 'loanamount' && underWritingData?.overridecalculationfee) ? loanamount : (underWritingData?.overridecalculationfee && !loanAmountDependencies?.includes(lastFieldtName)) ? loanamount : lenderfundedrehab === 'Yes' ? lenderRehabNoLoanSize : loanSize;
        //let loanAmount = (lastFieldtName == 'loanamount' && underWritingData?.overridecalculationfee) ? loanamount : lenderfundedrehab === 'Yes' ? lenderRehabNoLoanSize : loanSize;
        // Calculate actual LTV and LTC
        let actualLTV = easystreetarv ? Math.ceil((loanAmount / easystreetarv) * 100 * 100) / 100 : 0;
        let actualLTC = costBasis ? Math.ceil((loanAmount / costBasis) * 100 * 100) / 100 : 0;
    
        // Calculate initial funding and equity
        let initialFund = lenderRehabNoLoanSize - (renovationcost * (Math.min(LTVARVMaxLoan, LTCMaxLoan) / costBasis));
        let initialFundingDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc',]
        let initialFunding = (lastFieldtName == 'initialfunding' && underWritingData?.overridecalculationfee) ? initialfunding : (underWritingData?.overridecalculationfee && !initialFundingDependencies?.includes(lastFieldtName)) ? initialfunding : lenderfundedrehab === 'Yes' ? initialFund : loanSize;

        let initialEquityDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc','initialfunding']
        let initialEquity =  (lastFieldtName == 'initialequity' && underWritingData?.overridecalculationfee) ? initialequity : (underWritingData?.overridecalculationfee && !initialEquityDependencies?.includes(lastFieldtName)) ? initialequity : purchaseprice - initialFunding;

        // Calculate rehab funding and equity
        let rehabFund = lenderRehabNoLoanSize - initialFunding;
        let rehabFundingDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc','initialfunding']
        let rehabFunding = (lastFieldtName == 'rehabfunding' && underWritingData?.overridecalculationfee) ? rehabfunding :(underWritingData?.overridecalculationfee && !rehabFundingDependencies?.includes(lastFieldtName)) ? rehabfunding : lenderfundedrehab === 'Yes' ? rehabFund : 0;

        let rehabEquityDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc','rehabfunding']
        let rehabEquity = (lastFieldtName == 'rehabequity' && underWritingData?.overridecalculationfee) ? rehabequity : (underWritingData?.overridecalculationfee && !rehabEquityDependencies?.includes(lastFieldtName)) ? rehabequity : renovationcost - rehabFunding;

        // Calculate max monthly loan payment
        let maxMonthlyInterestForCal = (interestrateVal / 12) * loanAmount;
        maxMonthlyInterestForCal = Math.round(maxMonthlyInterestForCal * 100) / 100;
        // insuranceamount = (singleTermSheet?.underwritingSheet?.insurancePercent ? (singleTermSheet?.underwritingSheet?.insurancePercent / 100 * loanAmount) : insuranceamount);        
        titleAmount =  titleVal * loanAmount
        // Calculate cash to close
        const cashToClose = purchaseprice
            + (pointsVal * loanAmount)
            + valuationfee
            + floodinsurence
            + (underWritingData?.addtoESfeesBrokerPoints ? (brokerpointsVal * loanAmount) : 0)
            + (underWritingData?.addtoESfeesBrokerProcessingFee ? brokerprocessingfee : 0)
            + docfee
            + titleAmount
            + escrow
            + insuranceamount
            - initialFunding;
            console.log('purchaseprice',underWritingData?.addtoESfeesBrokerPoints);
        let initialMonthlyLoanPayment = underWritingData?.drawinteresttype == 'Interest on Full Balance' ? maxMonthlyInterestForCal : (interestrateVal/12) * initialFunding;

        // Calculate cash reserve required
        //const cashReserveRequired = cashToClose + rehabEquity + (maxMonthlyLoanPayment * loanterm);
        const cashReserveRequired = cashToClose + rehabEquity + ((maxMonthlyInterestForCal + initialMonthlyLoanPayment)/2)*loanterm;
         //let originationFeesVal = underWritingData?.points && loanorganisationpointsVal / 100;
         let originationFees = (pointsVal || (underWritingData?.addtoESfeesBrokerPoints && brokerpointsVal)) ? ((pointsVal + (underWritingData?.addtoESfeesBrokerPoints ? brokerpointsVal : 0)) * loanAmount) : null;
        let termSheetoriginationFee = loanUnderwriting?.prefills?.termSheet?.originationFee ? loanUnderwriting?.prefills?.termSheet?.originationFee :0
        let minimumFee = originationFees !== null ? originationFees : termSheetoriginationFee;
        originationFees = Math.max(minimumFee, termSheetoriginationFee);

        let interestReserveamountDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc','interestrate', 'loanamount','initialfunding','rehabfunding']
        let interestReserveamount = (lastFieldtName == 'interestreserveamount' && underWritingData?.overridecalculationfee) ? interestreserveamount : (underWritingData?.overridecalculationfee && !interestReserveamountDependencies?.includes(lastFieldtName)) ? interestreserveamount : underWritingData?.interestreserves == 'Yes' ? underWritingData?.drawinteresttype == 'Interest on Full Balance' ? (((initialFunding+rehabFunding)/2)*interestrateVal)*interestreservesloanterm : (((initialFunding + loanAmount) / 2) * (interestrate / 100)) / 12 * interestreservesloanterm: 0;
        let interestPayments = loanterm * maxMonthlyInterestForCal;
        let salesExpense = salesExpensePersentageVal * easystreetarv;
        let totalCostOfDeal = purchaseprice + renovationcost + (pointsVal * loanAmount) + valuationfee + floodinsurence + (brokerpointsVal * loanAmount) + brokerprocessingfee + docfee + insuranceamount + interestPayments;
       // console.log('purchaseprice',purchaseprice, renovationcost, pointsVal, valuationfee, floodinsurence, brokerpointsVal, brokerprocessingfee, docfee, insuranceamount, interestPayments)
        let netProfit = easystreetarv - totalCostOfDeal - salesExpense;
        let dealMarginPersentage = (netProfit/totalCostOfDeal)*100;
        let cashRequired = cashToClose+rehabEquity+(maxMonthlyInterestForCal*loanterm);
        let cashOncashreturnPersentage = (netProfit/cashRequired)*100;
        let annualizedReturn = ((netProfit/cashRequired) * 12/loanterm)*100
        let minimumMonthlyInterest = underWritingData?.drawinteresttype == 'Interest on Full Balance' ? (interestrateVal/12) * loanAmount : (interestrateVal/12) * initialFunding;
        let maximumMonthlyInterest =  (interestrateVal/12) * loanAmount;
      //  console.log('cashRequired1',cashRequired);
        // Prepare formulas to set
        const formulas = {
            totalcost: Math.round(Number(costBasis.toFixed(2))),
            initialfunding: Math.round(Number(initialFunding.toFixed(2))),
            initialequity: Math.round(Number(initialEquity.toFixed(2))),
            rehabfunding: Math.round(Number(rehabFunding.toFixed(2))),
            rehabequity: Math.round(Number(rehabEquity.toFixed(2))),
            loanamount: Math.round(Number(loanAmount.toFixed(2))),
            actualltv: Number(actualLTV.toFixed(2)),
            actualltc: Number(actualLTC.toFixed(2)),
            // maxmonthlyloanpayment: Number(maxMonthlyLoanPayment.toFixed(2)),
            cashtoclose: Math.round(Number(cashToClose.toFixed(2))),
            cashreverserequired: Math.round(Number(cashReserveRequired.toFixed(2))),
            interestreserveamount: Number(interestReserveamount.toFixed(2)),
            originationFees:  Math.round(Number(originationFees.toFixed(2))),
            titleAmount : Math.round(Number(titleAmount.toFixed(2))), 
            salesexpense : Math.round(Number(salesExpense.toFixed(2))),
            netprofit : Math.round(Number(netProfit.toFixed(2))),
            dealmargin: Number(dealMarginPersentage.toFixed(2)),
            cashoncashreturn: Number(cashOncashreturnPersentage.toFixed(2)),
            annualizedreturn : Number(annualizedReturn.toFixed(2)),
            minimumMonthlyInterest: Number(minimumMonthlyInterest.toFixed(2)),
            maximumMonthlyInterest: Number(maximumMonthlyInterest.toFixed(2)),
        };
    
        // Update state with calculated formulas
        setUnderwritingFormulas(formulas);
    
        setUnderWritingData(prevState => ({
            ...prevState,
            loanamount: formulas.loanamount,
            initialfunding: formulas.initialfunding,
            initialequity: formulas.initialequity,
            rehabfunding: formulas.rehabfunding,
            rehabequity: formulas.rehabequity,
            // maxmonthlyloanpayment: formulas.maxmonthlyloanpayment,
            cashtoclose: formulas.cashtoclose,
            cashreverserequired: formulas.cashreverserequired,
            interestreserveamount:formulas.interestreserveamount,
            actualltc: formulas.actualltc,
            actualltv: formulas.actualltv,
            originationFee: formulas.originationFees,
            titleAmount: formulas?.titleAmount,
            totalcost: formulas?.totalcost, 
            salesexpense: formulas?.salesexpense,
            netprofit: formulas?.netprofit,
            dealmargin: formulas?.dealmargin,
            cashoncashreturn:formulas?.cashoncashreturn,
            annualizedreturn: formulas?.annualizedreturn,
            minimummonthlyinterest: formulas?.minimumMonthlyInterest,
            maximummonthlyinterest: formulas?.maximumMonthlyInterest
        }));
    }
    function calculateFormulasBuild() {
        if (!underWritingData || !underWritingData.abouttheproperty || !underWritingData.fee) {
            console.error('Missing required data for calculation.');
            return;
        }
       // console.log('initial load2')
        let {
            floodinsurence = 0,
            valuationfee = 0,
            renovationcost = 0,
            purchaseprice = 0,
            maxltv = 0,
            maxltc = 0,
            easystreetarv = 0,
            points = 0,
            lenderfundedrehab = 'No'
        } = underWritingData?.abouttheproperty || {};
    
        let {
            brokerprocessingfee = 0,
            brokerpoints = 0
        } = underWritingData?.fee || {};
    
        let {
            interestrate = 0,
            loanamount = 0,
            insuranceamount = 0,
            docfee = 0,
            loanterm = 0,
            interestreservesloanterm = 0,
            initialfunding = 0,
            initialequity = 0,
            rehabfunding = 0,
            rehabequity = 0,
            interestreserveamount = 0,
            originationFee = 0,
            loanorganisationpoints = 0,
            escrow=0,
            titleAmount = 0,
            title = 0,
            salesexpense = 0,
            netprofit = 0,
            dealmargin = 0,
            cashrequired = 0,
            cashoncashreturn = 0,
            annualizedreturn = 0,
        } = underWritingData || {};
    
        // Convert strings to numbers
        renovationcost = parseFloat(renovationcost) || 0;
        purchaseprice = parseFloat(purchaseprice) || 0;
        loanamount = parseFloat(loanamount) || 0;
        easystreetarv = parseFloat(easystreetarv) || 0;
        maxltc = parseFloat(maxltc) || 0;
        maxltv = parseFloat(maxltv) || 0;
        insuranceamount = parseFloat(insuranceamount) || 0;
        initialfunding = parseFloat(initialfunding) || 0;
        initialequity = parseFloat(initialequity) || 0;
        docfee = parseFloat(docfee) || 0; // Ensure docfee is converted to a number
        brokerprocessingfee = parseFloat(brokerprocessingfee) || 0;
        brokerpoints = parseFloat(brokerpoints) || 0;
        points = parseFloat(points) || 0;
        interestrate = parseFloat(interestrate) || 0;
        valuationfee = parseFloat(valuationfee) || 0;
        floodinsurence = parseFloat(floodinsurence) || 0;
        loanterm = parseFloat(loanterm) || 0;
        interestreservesloanterm = parseFloat(interestreservesloanterm) || 0;
        rehabfunding = parseFloat(rehabfunding) || 0;
        rehabequity = parseFloat(rehabequity) || 0;
        interestreserveamount = parseFloat(interestreserveamount) || 0;
        originationFee = parseFloat(originationFee) || 0;
        escrow = parseFloat(escrow) || 0;
        titleAmount = parseFloat(titleAmount) || 0;
        salesexpense = parseFloat(salesexpense) || 0;
        netprofit = parseFloat(netprofit) || 0;
        dealmargin = parseFloat(dealmargin) || 0;
        cashrequired = parseFloat(cashrequired) || 0;
        cashoncashreturn = parseFloat(cashoncashreturn) || 0;
        annualizedreturn = parseFloat(annualizedreturn) || 0;
  
        // Convert percentage values
        let maxLTV = maxltv / 100;
        let maxLTC = maxltc / 100;
        let pointsVal = points / 100;
        let brokerpointsVal = brokerpoints / 100;
        let interestrateVal = interestrate / 100;
        let loanorganisationpointsVal = loanorganisationpoints/100;
        let titleVal = title / 100;
        let salesExpensePersentageVal = 5/100;

        // Calculate maximum loan amounts
        let LTVARVMaxLoan = maxLTV * easystreetarv;
        let costBasis = purchaseprice + renovationcost;
        let LTCMaxLoan = maxLTC * costBasis;
        let loanSize = Math.min(LTVARVMaxLoan, LTCMaxLoan);
        let lenderRehabNoLoanSize = Math.min(LTVARVMaxLoan, LTCMaxLoan);
        let loanAmountDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc',]
        let loanAmount = (lastFieldtName == 'loanamount' && underWritingData?.overridecalculationfee) ? loanamount : (underWritingData?.overridecalculationfee && !loanAmountDependencies?.includes(lastFieldtName)) ? loanamount : lenderfundedrehab === 'Yes' ? lenderRehabNoLoanSize : loanSize;
        // Calculate actual LTV and LTC
        let actualLTV = easystreetarv ? Math.ceil((loanAmount / easystreetarv) * 100 * 100) / 100 : 0;
        
        let actualLTC = costBasis ? Math.ceil((loanAmount / costBasis) * 100 * 100) / 100 : 0;
     //   console.log('loanAmount, costBasis2',loanAmount, costBasis, actualLTC);
        // insuranceamount = (singleTermSheet?.underwritingSheet?.insurancePercent ? (singleTermSheet?.underwritingSheet?.insurancePercent / 100 * loanAmount) : insuranceamount);

        // Calculate rehab funding and equity
        let rehabFundingDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc','initialfunding']
        let rehabFunding = (lastFieldtName == 'rehabfunding' && underWritingData?.overridecalculationfee) ? rehabfunding : (underWritingData?.overridecalculationfee && !rehabFundingDependencies?.includes(lastFieldtName)) ? rehabfunding : (lenderfundedrehab === 'Yes' ? (Math.min(renovationcost, loanSize)) : 0) ;
        let rehabEquityDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc','rehabfunding']
        let rehabEquity = (lastFieldtName == 'rehabequity' && underWritingData?.overridecalculationfee) ? rehabequity : (underWritingData?.overridecalculationfee && !rehabEquityDependencies?.includes(lastFieldtName)) ? rehabequity : renovationcost - rehabFunding;

         // Calculate initial funding and equity
         let initialFundingDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc',]
         let initialFunding = (lastFieldtName == 'initialfunding' && underWritingData?.overridecalculationfee) ? initialfunding : (underWritingData?.overridecalculationfee && !initialFundingDependencies?.includes(lastFieldtName)) ? initialfunding :  loanSize - Math.min(renovationcost, loanSize);
            
         let initialEquityDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc','initialfunding']
         let initialEquity = (lastFieldtName == 'initialequity' && underWritingData?.overridecalculationfee) ? initialequity : (underWritingData?.overridecalculationfee && !initialEquityDependencies?.includes(lastFieldtName)) ? initialequity : purchaseprice - initialFunding;
      //   console.log('renovationcost',renovationcost, rehabFunding,loanSize, initialFunding)
        // Calculate max monthly loan payment
        let maxMonthlyInterestForCal = (interestrateVal / 12) * loanAmount;
        maxMonthlyInterestForCal = Math.round(maxMonthlyInterestForCal * 100) / 100;

        titleAmount =  titleVal * loanAmount
        // Calculate cash to close
        const cashToClose = purchaseprice
            + (pointsVal * loanAmount)
            + valuationfee
            + floodinsurence
            + (underWritingData?.addtoESfeesBrokerPoints ? (brokerpointsVal * loanAmount) : 0)
            + (underWritingData?.addtoESfeesBrokerProcessingFee ? brokerprocessingfee : 0)
            + docfee
            + titleAmount
            + escrow
            + insuranceamount
            - initialFunding;
            console.log('purchaseprice2',underWritingData?.addtoESfeesBrokerPoints);
        let initialMonthlyLoanPayment = underWritingData?.drawinteresttype == 'Interest on Full Balance' ? maxMonthlyInterestForCal : (interestrateVal/12) * initialFunding;
        // Calculate cash reserve required
        //const cashReserveRequired = cashToClose + rehabEquity + (maxMonthlyLoanPayment * loanterm);
        const cashReserveRequired = cashToClose + rehabEquity + ((maxMonthlyInterestForCal + initialMonthlyLoanPayment)/2)*loanterm;
        //let originationFeesVal = underWritingData?.points && loanorganisationpointsVal / 100;
        let originationFees = (pointsVal || (underWritingData?.addtoESfeesBrokerPoints && brokerpointsVal)) ? ((pointsVal + (underWritingData?.addtoESfeesBrokerPoints ? brokerpointsVal : 0)) * loanAmount) : null;
        let termSheetoriginationFee = loanUnderwriting?.prefills?.termSheet?.originationFee ? loanUnderwriting?.prefills?.termSheet?.originationFee :0
        let minimumFee = originationFees !== null ? originationFees : termSheetoriginationFee;
        originationFees = Math.max(minimumFee, termSheetoriginationFee);
        let interestReserveamountDependencies = ['maxltv','easystreetarv','purchaseprice','renovationcost','maxltc','interestrate', 'loanamount','initialfunding']
        let interestReserveamount = (lastFieldtName == 'interestreserveamount' && underWritingData?.overridecalculationfee) ? interestreserveamount : (underWritingData?.overridecalculationfee && !interestReserveamountDependencies?.includes(lastFieldtName)) ? interestreserveamount : underWritingData?.interestreserves == 'Yes' ? underWritingData?.drawinteresttype == 'Interest on Full Balance' ? (((initialFunding+rehabFunding)/2)*interestrateVal)*interestreservesloanterm : (((initialFunding + loanAmount) / 2) * (interestrate / 100)) / 12 * interestreservesloanterm: 0;
        let interestPayments = loanterm * maxMonthlyInterestForCal;
        let salesExpense = salesExpensePersentageVal * easystreetarv;
        let totalCostOfDeal = purchaseprice + renovationcost + (pointsVal * loanAmount) + valuationfee + floodinsurence + (brokerpointsVal * loanAmount) + brokerprocessingfee + docfee + insuranceamount + interestPayments;
      //  console.log('sdfsdfsfdsf',easystreetarv, totalCostOfDeal, salesExpense)
        let netProfit = easystreetarv - totalCostOfDeal - salesExpense;
        let dealMarginPersentage = (netProfit/totalCostOfDeal)*100;
        let cashRequired = cashToClose+rehabEquity+(maxMonthlyInterestForCal*loanterm);
        let cashOncashreturnPersentage = (netProfit/cashRequired)*100;
        let annualizedReturn = ((netProfit/cashRequired) * 12/loanterm)*100
     //   console.log('cashRequirednetProfit',netProfit,cashRequired);
        let minimumMonthlyInterest = underWritingData?.drawinteresttype == 'Interest on Full Balance' ? (interestrateVal/12) * loanAmount : (interestrateVal/12) * initialFunding;
        let maximumMonthlyInterest =  (interestrateVal/12) * loanAmount;
        const formulas = {
            totalcost: Math.round(Number(costBasis.toFixed(2))),
            initialfunding: Math.round(Number(initialFunding.toFixed(2))),
            initialequity: Math.round(Number(initialEquity.toFixed(2))),
            rehabfunding: Math.round(Number(rehabFunding.toFixed(2))),
            rehabequity: Math.round(Number(rehabEquity.toFixed(2))),
            loanamount: Math.round(Number(loanAmount.toFixed(2))),
            actualltv: Number(actualLTV.toFixed(2)),
            actualltc: Number(actualLTC.toFixed(2)),
            // maxmonthlyloanpayment: Number(maxMonthlyLoanPayment.toFixed(2)),
            cashtoclose: Math.round(Number(cashToClose.toFixed(2))),
            cashreverserequired: Math.round(Number(cashReserveRequired.toFixed(2))),
            interestreserveamount: Number(interestReserveamount.toFixed(2)),
            originationFees:  Math.round(Number(originationFees.toFixed(2))),
            titleAmount : Math.round(Number(titleAmount.toFixed(2))),
            salesexpense : Math.round(Number(salesExpense.toFixed(2))),
            netprofit : Math.round(Number(netProfit.toFixed(2))),
            dealmargin: Number(dealMarginPersentage.toFixed(2)),
            cashoncashreturn: Number(cashOncashreturnPersentage.toFixed(2)),
            annualizedreturn : Number(annualizedReturn.toFixed(2)),
            minimumMonthlyInterest: Number(minimumMonthlyInterest.toFixed(2)),
            maximumMonthlyInterest: Number(maximumMonthlyInterest.toFixed(2)),
        };
    
        // Update state with calculated formulas
        setUnderwritingFormulas(formulas);
    
        setUnderWritingData(prevState => ({
            ...prevState,
            loanamount: formulas.loanamount,
            initialfunding: formulas.initialfunding,
            initialequity: formulas.initialequity,
            rehabfunding: formulas.rehabfunding,
            rehabequity: formulas.rehabequity,
            // maxmonthlyloanpayment: formulas.maxmonthlyloanpayment,
            cashtoclose: formulas.cashtoclose,
            cashreverserequired: formulas.cashreverserequired,
            interestreserveamount:formulas.interestreserveamount,
            actualltc: formulas.actualltc,
            actualltv: formulas.actualltv,
            originationFee: formulas.originationFees,
            titleAmount: formulas?.titleAmount,
            totalcost: formulas?.totalcost,
            salesexpense: formulas?.salesexpense,
            netprofit: formulas?.netprofit,
            dealmargin: formulas?.dealmargin,
            cashoncashreturn:formulas?.cashoncashreturn,
            annualizedreturn: formulas?.annualizedreturn,
            minimummonthlyinterest: formulas?.minimumMonthlyInterest,
            maximummonthlyinterest: formulas?.maximumMonthlyInterest
        }));
    }

    
    
    const calculateInsuranseAmount = () =>{
        let { insuranceamount = 0, loanamount = 0} = underWritingData || {};
        loanamount = parseFloat(loanamount) || 0;
        insuranceamount = parseFloat(insuranceamount) || 0;
        insuranceamount = (singleTermSheet?.underwritingSheet?.insurancePercent ? (singleTermSheet?.underwritingSheet?.insurancePercent / 100 * loanamount) : insuranceamount);

     
        setUnderwritingFormulas(prevState => ({
            ...prevState,
            insuranceamount: Math.round(Number(insuranceamount.toFixed(2))),
        }))
    
        setUnderWritingData(prevState => ({
            ...prevState,
            insuranceamount: Math.round(Number(insuranceamount.toFixed(2)))
        }));
    }
    useEffect(() => {
        const loanterm = Number(underWritingData?.loanterm);
        const interestreservesloanterm = Number(underWritingData?.interestreservesloanterm);
        // If loanterm is defined and interestreservesloanterm is greater than loanterm, update it
        if (interestreservesloanterm > loanterm) {

     setUnderWritingData(prevState => ({
                ...prevState,
                interestreservesloanterm: loanterm
            }));
        }
      }, [underWritingData?.loanterm]);
    //const loanAmount = Math.round(Math.min(maxLTV * easyStreetARV, maxTLC * totalCost));
    useEffect(() => {
        if (!isDataChanged) {
            return;
        }
        if(underWritingData?.programtype === "EASY FIX" ){
            calculateFormulas();
        }else{
            calculateFormulasBuild();
        }
        
    
    }, [underWritingData?.abouttheproperty?.floodinsurence,
        underWritingData?.abouttheproperty?.valuationfee,
        underWritingData?.abouttheproperty?.renovationcost,
        underWritingData?.abouttheproperty?.purchaseprice,
        underWritingData?.abouttheproperty?.maxltv,
        underWritingData?.abouttheproperty?.maxltc,
        underWritingData?.abouttheproperty?.easystreetarv,
        underWritingData?.abouttheproperty?.easystreetaiv,
        underWritingData?.abouttheproperty?.borrowerarv,
        underWritingData?.abouttheproperty?.points,
        underWritingData?.abouttheproperty?.lenderfundedrehab,
        underWritingData?.abouttheproperty?.monthlyrentalrate,
        underWritingData?.abouttheproperty?.existingloan,
        underWritingData?.fee?.brokerprocessingfee,
        underWritingData?.fee?.brokerpoints,
        underWritingData?.interestrate,
        // underWritingData?.loanamount,
        underWritingData?.docfee,
        underWritingData?.loanterm,
        underWritingData?.interestreservesloanterm,
        underWritingData?.overridecalculationfee,
        underWritingData?.insuranceamount,
        underWritingData?.abouttheborrower?.liquidcashreserve,
        underWritingData?.drawinteresttype,
        underWritingData?.interestreserves,
        underWritingData?.title,
        underWritingData?.escrow,
        underWritingData?.titleAmount,
        underWritingData?.programtype,
        underWritingData?.addtoESfeesBrokerPoints,
        underWritingData?.addtoESfeesBrokerProcessingFee,
        singleTermSheet
        ]);
        useEffect(() => {
            if (!isDataChanged) {
                return;
            }
            if((underWritingData?.loanamount) && lastFieldtName != 'insuranceamount' && !IsPropertyInsuranseChanged){
                calculateInsuranseAmount()
            }
            
        
        }, [isFocused, underWritingData?.loanamount, singleTermSheet?.underwritingSheet?.insurancePercent])
        useEffect(() => {
            if (!isDataChanged) {
                return;
            }
            if((underWritingData?.overridecalculationfee) &&  lastFieldtName != 'insuranceamount' && !IsPropertyInsuranseChanged){
               calculateInsuranseAmount()
            }
            
        
        }, [isFocused, underWritingData?.loanamount])

        useEffect(() => {
            if (!isDataChanged) {
                return;
            }
            if((underWritingData?.overridecalculationfee) && !isFocused){
                if(underWritingData?.programtype === "EASY FIX" ){
                    calculateFormulas();
                }else{
                    calculateFormulasBuild();
                }
            }
            
        
        }, [isFocused, underWritingData?.loanamount,underWritingData?.totalcost, underWritingData?.formulas?.actualltc, underWritingData?.formulas?.actualltv, underWritingData?.initialfunding, underWritingData?.rehabfunding, underWritingData?.rehabequity, underWritingData?.maxmonthlyloanpayment, underWritingData?.cashtoclose,underWritingData?.maximumMonthlyInterest]);
    // useEffect(() => {
       
    //     if (!underWritingData.overridecalculationfee &&
    //         (underWritingData.abouttheproperty.purchaseprice !== underwritingFormulas?.purchaseprice ||
    //          underWritingData.abouttheproperty.renovationcost !== underwritingFormulas?.renovationcost ||
    //          underWritingData.abouttheproperty.maxltc !== underwritingFormulas?.maxltc ||
    //          underWritingData.abouttheproperty.maxltv !== underwritingFormulas?.maxltv)) {
    //             console.log("calculations called");
    //             calculateFormulas();
    //     }
    // }, [underWritingData]);
    

    // useEffect(() => {
    //     setUnderWritingData(prevState => ({
    //         ...prevState,
    //         abouttheproperty: {
    //             ...prevState.abouttheproperty,
    //             ["loanamount"]: underwritingFormulas?.loanamount
    //         }
    //     }));
    // },[underwritingFormulas]);

   
    const getstates = async () => {
        getStates().then(function (response) {
            if (response?.result) {
               setStates(response?.result)
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };
    useEffect(() => {
       // console.log('underWritingData----->',underWritingData);
    }, [underWritingData]);

    // function addCurrency(value){
    //     // Remove any non-numeric characters except dot (.)
    //     value = value.replace(/[^0-9.]/g, '');

    //     // Add dollar symbol and format as currency
    //     value = `$${parseFloat(value)}`;
    //     value = (value === "$NaN" ? '' : value);
    //     return value;
    // }

    /* taking float values also */
    // function addCurrency(value){

    // // Remove any non-numeric characters except dot (.)
    // const numericValue = value.replace(/[^0-9.]/g, '');

    // // If the value is empty or only contains a dot, return an empty string
    // if (!numericValue || numericValue === '.') {
    //     return '';
    // }

    // // Parse the numeric value as a float
    // const floatValue = parseFloat(numericValue);

    // // If the parsed value is NaN, return an empty string
    // if (isNaN(floatValue)) {
    //     return '';
    // }

    // // Format the float value with commas and two decimal places
    // const formattedValue = floatValue.toLocaleString('en-US', {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2
    // });

    // // Add dollar symbol at the beginning
    // return `$${formattedValue}`;
    // }
    
    function addCurrency(value){
        // Remove any non-numeric characters except dot (.)
        const numericValue = value.replace(/[^0-9.]/g, '');
    
        // If the value is empty or only contains a dot, return an empty string
        if (!numericValue || numericValue === '.') {
            return '';
        }
    
        // Parse the numeric value as a float
        const floatValue = parseFloat(numericValue);
    
        // If the parsed value is NaN, return an empty string
        if (isNaN(floatValue)) {
            return '';
        }
    
        // Format the float value with commas
        const formattedValue = floatValue.toLocaleString('en-US');
    
        // Add dollar symbol at the beginning
        return `$${formattedValue}`;
    }
    

    function addNumeric(value){
        const numberRegex = /^[0-9]+$/;
        //let value = e.target.value;
        let newValue = "";
        
        for(let i = 0; i< value?.length ; i++){
            if(!numberRegex.test(value[i])){
                newValue += "";
            }else{
                newValue += value[i];
            }
        }
        value = newValue;
        return value;
    }

    function addPercentage(value){
       
        // Check if the numeric value is a valid float
        const isValidFloat = /^-?\d*\.?\d*$/;

        let newValue = "";
        //const regex = /^[a-zA-Z\s]+$/;
        for(let i = 0; i< value?.length ; i++){
            if(!isValidFloat.test(value[i])){
                newValue += "";
            }else{
                newValue += value[i];
            }
        }
        value = newValue;
       

        return value;
    }


    const setShowOverridePopup = (message) => {
        setOverridePopupMessage(message);
        setOverridePopup(true)
    };

    const handleChange = (e) => {
        // let name = name;
        // let value = value;
        // let updatedValue = value;
        setIsDownloadTermSheetClicked(false);
        const { name, value } = e.target;
        if(name == 'programtype' || name == 'propertystate'){
            setStateProgTypeChanged(true)
        }else{
            setStateProgTypeChanged(false)
        }
        let nameInput = name;
        let valueInput = value;
        
        let textareaInputs = ['executionstrategysummary','underwritingsummary','originatorinstructions']
        // let numericInputs = ['yearbuilt',"bathroom", "bedroom",'statedcreditscore','investmentproperties','zipcode','totalbedrooms','totalbathrooms',
        //     'lotsqft','finishedsqft','buildingsqft'
        // ];
        let numericInputs = ['yearbuilt',"bathroom", "bedroom",'statedcreditscore','investmentproperties','zipcode','totalbedrooms','totalbathrooms','numberofunits'
            
        ];
        const formatNumberInputs = ['finishedsqft','buildingsqft','lotsqft'];

        if(textareaInputs?.includes(nameInput)  && value !== ''){
            valueInput = value;
        }

        if(numericInputs.includes(nameInput) && value !== ''){
            const callNumeric = addNumeric(valueInput);
            valueInput = callNumeric;
        }

        
        if (formatNumberInputs.includes(nameInput) && value !== '') {
            valueInput = formatNumericInput(value);
        }
    
        if(nameInput === "yearbuilt" && value !== ''){
           const currentYear = new Date().getFullYear(); 
            // const isValidYear = /^\d{4}$/.test(value);
            if (value > currentYear) {
                valueInput = '';
            } else {
              valueInput = valueInput;
            }
          
          
        }

        if(nameInput==='statedcreditscore' && value !== ''){
            let updatedValue = value;
            updatedValue = isNaN(value) ? '' : value;
            const validatedValue = Math.min(parseFloat(updatedValue, 10), 850);
          //  console.log('updatedVvalidatedValuealue',validatedValue);
            valueInput = validatedValue
        }  

        // let floatInputs = ['price','liquidcashreserve','purchaseprice',
        //     'renovationcost','brokerprocessingfee','docfee','purchaseprice','cashreverserequired',
        //     'easystreetaiv','easystreetarv', 'valuationfee', 'monthlyrentalrate','existingloan', 'floodinsurence','borrowerarv'
            
        // ];

        // if (floatInputs.includes(name) && value !== '') {
        //     const callCurrency = addCurrency(value);
        //     value = callCurrency;
        // }

        let toggleInputs = ["floodinsurencevalue","cashreverserequiredvalue","overridecalculationfee","yearbuiltvalue", "addtoESfeesBrokerPoints", "addtoESfeesBrokerProcessingFee"];

        if(toggleInputs?.includes(nameInput) && e.target.type === "checkbox"){
            
            // if(nameInput === "overridecalculationfee" && valueInput === false){
            //     setCalculationBool(!calculationBool);
            // }
            if(name == 'overridecalculationfee'){
                setTempOverRide(e.target.checked)
               if(e.target.checked){
                    setShowOverridePopup('Enabling override will remove all automatic calculations. Would you like to continue?', e.target.checked)
               }else{
                setShowOverridePopup('Disabling calculation override will overwrite all calculated fields with their default calculations. Would you like to continue?', e.target.checked)
               }  
               return
            }else{
                valueInput = e.target.checked;
            }
        }

        const perecentageInputs = [ 'maxltc', 'maxltv', 'interestrate','points','brokerpoints', 'title'];

        if(perecentageInputs?.includes(nameInput) && value !== ''){
            const callPercentage = addPercentage(value);
            valueInput = callPercentage;
        }

        const abouttheborrower = [
            //'applicantid',
            'borrowerfirstname',
            'borrowerlastname',
            'borroweremail',
            'borrowerphone',
            'statedcreditscore',
            'liquidcashreserve',
            'borroweroccupation',
            'investmentproperties',
            'cashreverserequired',
            'cashreverserequiredvalue',
            'applicationlicense',
            
        ];

        
        
        const entity = [
            //'entityid',
            'legalname'
        ];
        
        const abouttheproperty = [
            //'propertyid',
            'propertytype',
            'numberofunits',
            'propertyaddress',
            'propertyaddress2',
            'apt_unit',
            'propertycity',
            'propertycounty',
            'propertystate',
            'propertyzipcode',
            'totalbedrooms',
            'totalbathrooms',
            'haspool',
            'lotsqft',
            'buildingsqft',
            'finishedsqft',
            'occupancy',
            'purchaseprice',
            'easystreetaiv',
            'renovationcost',
            'easystreetarv',
            'borrowerarv',
            'maxltc',
            'maxltv',
            'points',
            'yearbuilt',
            'yearbuiltvalue',

            //'amortizationterm',
            'valuationfee',
            'exitstrategy',
            'monthlyrentalrate',
            'downpaymentsource',
            'floodinsurence',
            'floodinsurencevalue',
            'debtservicecoverageratio',
           
            'executionstrategysummary',
            'lenderfundedrehab'
        ];
        
        const fee = [
            //'brokerid',
            'brokerpoints',
            'brokerprocessingfee',
            
        ];
        
        
      
        // const noSection = [
        //     'loanid',
        //     'programtype',
        //     'loanfor',
        //     'existingloan',
        //     // Add other keys that do not belong to any specific section
        // ];
        
        
        
        // Add other sections as needed
        
    
        // if(section){
        //     setUnderWritingData(prevState => ({
        //         ...prevState,
        //         [section]: {
        //             ...prevState[section],
        //             [name]: value
        //         }
        //     }));
        // }else{
        //     setUnderWritingData({
        //         ...underWritingData,
        //             [name]:value   
        //     });
        // }
        let changableFields = ['maxltc','maxltv', 'loanterm','interestreservesloanterm', 'interestrate', 'points', 'title', 'brokerpoints', 'actualltv', 'actualltc', 'drawinteresttype', 'interestreserves', 'lenderfundedrehab', 'easystreetarv', 'purchaseprice', 'renovationcost', 'addtoESfeesBrokerProcessingFee', 'addtoESfeesBrokerPoints']
        if (changableFields?.includes(name)) {
            setIsDataChanged(true)
        }
        if (abouttheborrower?.includes(name)) {
            setUnderWritingData(prevState => ({
                ...prevState,
                abouttheborrower: {
                    ...prevState.abouttheborrower,
                    [name]: valueInput
                }
            }));
        } else if (entity.includes(name)) {
            setUnderWritingData(prevState => ({
                ...prevState,
                entity: prevState?.entity?.map((item, index) =>
                    index === 0 ? { ...item, [name]: valueInput } : item
                )
            }));
        }else if (name === 'propertytype') {
            const valueUnits = {
                "Single Family Residence (SFR - 1 Unit)": 1,
                "Duplex": 2,
                "Triplex": 3,
                "Fourplex": 4,
                "5 Units+": 5,
                "Townhouse or Condo": 1,
                "Lot/New Construction": 1
            };
            console.log('value----> propertytype', valueInput);
        
            setUnderWritingData((prevState) => ({
                ...prevState,
                abouttheproperty: {
                    ...prevState.abouttheproperty,
                    [name]: valueInput,
                    numberofunits: valueUnits[valueInput] || ''
                }
            }));
        }
        
        else if (abouttheproperty.includes(name)) {
            setUnderWritingData(prevState => ({
                ...prevState,
                abouttheproperty: {
                    ...prevState.abouttheproperty,
                    [name]: valueInput
                }
            }));
        } else if (fee.includes(name)) {
            setUnderWritingData(prevState => ({
                ...prevState,
                fee: {
                    ...prevState.fee,
                    [name]: valueInput
                }
            }));
        } 
        else{
            setUnderWritingData(prevState => ({
                ...prevState,
                [name]: valueInput
            }));
        }
        // else if (noSection.includes(name)) {
        //     setUnderWritingData(prevState => ({
        //         ...prevState,
        //         [name]: value
        //     }));
        // }
        

        
    };

    const handleCurrencyChange = (name,value,index) => {
        setIsDataChanged(true);
        setIsDownloadTermSheetClicked(false);
     //  console.log('handleCurrencyChange')
            const abouttheborrower = [
                //'applicantid',
                'borrowerfirstname',
                'borrowerlastname',
                'borroweremail',
                'borrowerphone',
                'liquidcashreserve',
                'borroweroccupation',
                'investmentproperties',
                'cashreverserequired',
                'cashreverserequiredvalue',
                'applicationlicense'
            ];
    
            const entity = [
                //'entityid',
                'legalname'
            ];
            
            const abouttheproperty = [
                //'propertyid',
                'propertytype',
                'numberofunits',
                'propertyaddress',
                'apt_unit',
                'propertycity',
                'propertycounty',
                'propertystate',
                'propertyzipcode',
                'totalbedrooms',
                'totalbathrooms',
                'haspool',
                'lotsqft',
                'buildingsqft',
                'finishedsqft',
                'occupancy',
                'purchaseprice',
                'easystreetaiv',
                'renovationcost',
                'easystreetarv',
                'borrowerarv',
                'maxltc',
                'maxltv',
                'points',
                //'amortizationterm',
                'valuationfee',
                'exitstrategy',
                'monthlyrentalrate',
                'downpaymentsource',
                'floodinsurence',
                'floodinsurencevalue',
                'yearbuilt',
                'existingloan',
                'currentvalue'
            ];
            
            const fee = [
                //'brokerid',
                'brokerpoints',
                'brokerprocessingfee',
                
            ];
            
        
            value = (isNaN(value?.replace('$','')) ? '' : value);
            if (abouttheborrower?.includes(name)) {
                
                setUnderWritingData(prevState => ({
                    ...prevState,
                    abouttheborrower: {
                        ...prevState.abouttheborrower,
                        [name]: value
                    }
                }));
            } else if (entity.includes(name)) {
                setUnderWritingData(prevState => ({
                    ...prevState,
                    entity: prevState.entity.map((item, index) =>
                        index === 0 ? { ...item, [name]: value } : item
                    )
                }));
            } else if (abouttheproperty.includes(name)) {
                // if(!underwritingFormulas[name]){
                //     setUnderWritingData({...underwritingFormulas,[name] : ''});
                // }else{
                    setUnderWritingData(prevState => ({
                        ...prevState,
                        abouttheproperty: {
                            ...prevState.abouttheproperty,
                            [name]: value
                        }
                    }));
                // }
                 
            } else if (fee.includes(name)) {
                setUnderWritingData(prevState => ({
                    ...prevState,
                    fee: {
                        ...prevState.fee,
                        [name]: value
                    }
                }));
            }else if(name === "price"){
                setUnderWritingData(prevData => ({
                    ...prevData,
                    propertycomparisons: prevData.propertycomparisons.map((row, rowIndex) => {
                        if (rowIndex === index) {
                            return {
                                ...row,
                                [name]: value
                            };
                        }
                        return row;
                    })
                }));
            }
            else{
                setUnderWritingData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
           
            
        
            
        
    }

    const handleChangecoborrower = (name, value, sectionName, index) => {
       // console.log('valuevalue',value)
        // Ensure the value is sanitized by removing the '$' sign and checking if it's a number
         // Check if value is a string containing a number and convert it if necessary
        if (typeof value === 'string' && value.includes('$')) {
            value = value.replace('$', '');
        }

        // If the value is numeric (either a string or number), convert to number
        // if (['finishedsqft', 'buildingsqft', 'lotsqft'].includes(name)) {
        //     // Apply formatting for specific fields
        //     value = formatNumericInput(value.toString());
        // }else if (!isNaN(value)) {
        //     value = parseFloat(value);
        // }

        if (!isNaN(value)) {
            value = parseFloat(value);
        }
        
     //   console.log('name', name, value, sectionName, index);
        if (name === 'legalname') {
            setUnderWritingData(prevState => ({
                ...prevState,
                entity: prevState?.entity?.map((item, idx) =>
                    idx === index ? { ...item, [name]: value, iscoborrower: true } : item
                )
            }));
        }
        
        
        setUnderWritingData(prevState => ({
            ...prevState,
            [sectionName]: prevState[sectionName].map((item, i) => 
                i === index ? { ...item, [name]: value } : item
            )
        }));
    }

    const handleChangecoProperty = (name, value, sectionName, index) => {
      //  console.log('valuevalue',value)
        // Ensure the value is sanitized by removing the '$' sign and checking if it's a number
         // Check if value is a string containing a number and convert it if necessary
    if (typeof value === 'string' && value.includes('$')) {
        value = value.replace('$', '');
    }

    // If the value is numeric (either a string or number), convert to number
    if (!isNaN(value)) {
        value = parseFloat(value);
    }

    
        
      //  console.log('name', name, value, sectionName, index);
        
        setUnderWritingData(prevState => ({
            ...prevState,
            [sectionName]: prevState[sectionName].map((item, i) => 
                i === index ? { ...item, [name]: value } : item
            )
        }));
    }

    const setLastCharacterToEmpty = (str) => {
        if (str && str?.length > 0) {
            
            return str.slice(0, -1) + ''; // setting 5th character to empty // setting last character to empty
        }
        return str;
    };

    const handleInputChange = (index, fieldName, value) => {
        // let value = value;
        let numericInputs = ['yearbuilt',"bathroom", "bedroom"];
        const numberRegex = /^[0-9]+$/;
        const formatNumberInputs = ['sf'];

      
        if(numericInputs.includes(fieldName) && value !== ''){
            if (numericInputs.includes(fieldName)) {
                if (!numberRegex.test(value)) {
                    return;  // If it's not a valid whole number, exit the function without updating
                }
            } 
            if (numberRegex.test(value)) {
                value = value.toString(); // Ensure value is a string for further processing
                if (fieldName === 'yearbuilt') {
                  if (value.length > 4) {
                    value = value.slice(0, 4); // Keep only the first 4 digits
                  }
                }
                value = parseInt(value);
              } else {
                value = '';
              }
        }

        if(formatNumberInputs.includes(fieldName) && value !== ''){
            const rawValue = value;
            value = formatNumericInput(rawValue);
        }
       

        setUnderWritingData(prevData => ({
            ...prevData,
            propertycomparisons: prevData.propertycomparisons.map((row, rowIndex) => {
                if (rowIndex === index) {
                    return {
                        ...row,
                        [fieldName]: value
                    };
                }
                return row;
            })
        }));
        setIsDownloadTermSheetClicked(false);
        return value;
       
    };

    const validateYear = (value) => {
        const currentYear = new Date().getFullYear();
        const minYear = 1900;
        if (value && (value < minYear || value > currentYear)) {
           return true
        }

    }   
    const handleLocationSearchChange = (index, place) => {
        // Update state based on the index
        // For example:
       
        const updatedPropertyComparisons = [...underWritingData.propertycomparisons];
        updatedPropertyComparisons[index].address = place;
        setIsDownloadTermSheetClicked(false);
        setUnderWritingData(prevState => ({
            ...prevState,
            propertycomparisons: updatedPropertyComparisons
        }));
      
    };
    

   
    const getNonEmptyObjects = () => {
        return underWritingData.propertycomparisons.filter(row => {
            return Object.values(row).some(value => {
                const trimmedValue = typeof value === 'string' ? value.trim() : value;
                return trimmedValue !== ''; // Check if the trimmed value is not empty
            });
        });
    };
    

    const addRows = () => {
        setUnderWritingData((prevData) => {
            const filledObjects = prevData.propertycomparisons.filter(row => Object.values(row).some(value => value !== ""));
            const emptyObjectsCount = 4 - filledObjects.length;
            const emptyObjects = Array.from({ length: emptyObjectsCount }, () => ({
                address: "",
                price: "",
                "bathroom":"",
                "bedroom":"",
                sf: "",
                yearbuilt: "",
                comparisonnarrative: ""
            }));
            return {
                ...prevData,
                propertycomparisons: filledObjects.concat(emptyObjects)
            };
        });
    };

    const formatValue = (value) => {
        if (typeof value === 'string') {
            return value === '' ? '' : parseInt(value.replace(/,/g, ''), 10);
        }
        return value; // Return the value unchanged if it's not a string
    };


    const handleSave =()=>{

        handleValidation();

    
        //const nonEmptyObjects = getNonEmptyObjects();

        // let apiData = {...underWritingData,"propertycomparisons" : nonEmptyObjects}
        
        

        //if(Object?.keys(errors)?.length === 0){
            if(document.querySelectorAll('.invalid-input').length === 0 && Object?.keys(errors)?.length === 0){

            
            

            let floatInputs = ['price','liquidcashreserve','purchaseprice','loanamount',
            'renovationcost','brokerprocessingfee','docfee','currentvalue','cashreverserequired',
            'easystreetaiv','easystreetarv', 'valuationfee', 'monthlyrentalrate','existingloan', 'floodinsurence','borrowerarv',
            'initialequity','initialfunding','rehabequity','rehabfunding', 'maxmonthlyloanpayment', 'interestreserveamount', 'cashtoclose', 'insuranceamount', 'originationFee',  'escrow','titleAmount', 'propertyInsurance', 'drawFee', 'maximummonthlyinterest', 'minimummonthlyinterest', 'estimatedvalue'
            
    
        ];

        let numericInputs = ['statedcreditscore','investmentproperties','zipcode','totalbathrooms','totalbedrooms','numberofunits',
            'amortizationterm','loanterm', 'buildingsqft', 'yearbuilt', 'interestreservesloanterm'
        ];

        let perecentageInputs = [ 'maxltc', 'maxltv', 'interestrate','points','brokerpoints','interestrate', 'title'];
        let bools = ['usewhitelabel'];

        const formatNumberInputs = ['finishedsqft','buildingsqft','lotsqft'];

        let updatedUnderWritingData =  { ...underWritingData};

        // if(underWritingData?.overridecalculationfee === true){
        //     updatedUnderWritingData = { ...underWritingData,
        //         abouttheproperty: {
        //             ...underWritingData.abouttheproperty,
        //             ["loanamount"]: underwritingFormulas?.loanamount
        //         }
    
        //     };
        // }
        

            floatInputs.forEach(input => {
                if (input !== "price" && updatedUnderWritingData.abouttheborrower[input] && typeof updatedUnderWritingData.abouttheborrower[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheborrower[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.abouttheborrower[input] = parseInt(modifiedValue);
                } else if (input !== "price" && updatedUnderWritingData.abouttheproperty[input]  && typeof updatedUnderWritingData.abouttheproperty[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheproperty[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.abouttheproperty[input] = parseInt(modifiedValue);
                   
                    //console.log("Nan",input,updatedUnderWritingData.abouttheproperty[input])
                } else if (input !== "price" && updatedUnderWritingData.fee[input]&& typeof updatedUnderWritingData.fee[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.fee[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.fee[input] = parseInt(modifiedValue);
                } 
                // else if (input === "price" && updatedUnderWritingData[input] && updatedUnderWritingData.abouttheproperty[input] !== "$" && typeof updatedUnderWritingData[input] === 'string') {
                //     updatedUnderWritingData.propertycomparisons.forEach((comparison, index) => {
                //         if (comparison.price !== undefined && typeof comparison.price === 'string') {
                //             const modifiedPrice = comparison.price.replace(/\$|,/g, '');
                //             const parsedPrice = parseInt(modifiedPrice);
                //             //if (!isNaN(parsedPrice)) {
                //                 comparison.price = parsedPrice;
                //             //}
                //         }
                //     });
                // }
                // else if (
                //     input === "price" && // Check if the input is 'price'
                //     updatedUnderWritingData[input] && // Ensure updatedUnderWritingData[input] is defined
                //     updatedUnderWritingData.abouttheproperty[input] !== "$" && // Ensure the input is not just a dollar sign
                //     typeof updatedUnderWritingData[input] === 'string' // Check if the input value is a string
                // ) {
                //     updatedUnderWritingData.propertycomparisons.forEach((comparison, index) => {
                //         if (comparison.price !== undefined && typeof comparison.price === 'string') {
                //             // Remove dollar sign and commas from the price string
                //             const modifiedPrice = comparison.price.replace(/\$|,/g, '');
                //             // Parse the modified price as an integer
                //             const parsedPrice = parseInt(modifiedPrice);
                //             if (!isNaN(parsedPrice)) { // Check if parsedPrice is a valid number
                //                 comparison.price = parsedPrice; // Update the comparison price
                //             }
                //         }
                //     });
                // }
                
                else if (input !== "price" && updatedUnderWritingData[input]  && typeof updatedUnderWritingData[input] === 'string') {
                    const propertyValue = updatedUnderWritingData[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData[input] = parseInt(modifiedValue);
                   
                    //console.log("Nan",input,updatedUnderWritingData.abouttheproperty[input])
                }
                
            });

            numericInputs.forEach(input => {
                if (input !== "yearbuilt" && updatedUnderWritingData.abouttheborrower[input] && typeof updatedUnderWritingData.abouttheborrower[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheborrower[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.abouttheborrower[input] = parseInt(modifiedValue);
                } else if (updatedUnderWritingData.abouttheproperty[input] && typeof updatedUnderWritingData.abouttheproperty[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheproperty[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.abouttheproperty[input] = parseInt(modifiedValue);
                } else if (updatedUnderWritingData.fee[input] && typeof updatedUnderWritingData.fee[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.fee[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.fee[input] = parseInt(modifiedValue);
                } else if (updatedUnderWritingData[input] && typeof updatedUnderWritingData[input] === 'string') {
                    const propertyValue = updatedUnderWritingData[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData[input] = parseInt(modifiedValue);
                }
            }); 

            // formatNumberInputs.forEach(input => {
            //     if (updatedUnderWritingData.abouttheborrower[input] && typeof updatedUnderWritingData.abouttheborrower[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData.abouttheborrower[input];
            //         const modifiedValue = propertyValue === '' ? '' : parseInt(String(propertyValue).replace(/,/g, ''), 10);
            //         updatedUnderWritingData.abouttheborrower[input] = modifiedValue;
            //     } else if (updatedUnderWritingData.abouttheproperty[input] && typeof updatedUnderWritingData.abouttheproperty[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData.abouttheproperty[input];
            //         const modifiedValue = propertyValue === '' ? '' : parseInt(String(propertyValue).replace(/,/g, ''), 10);
            //         updatedUnderWritingData.abouttheproperty[input] = modifiedValue;
            //     } else if (updatedUnderWritingData.fee[input] && typeof updatedUnderWritingData.fee[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData.fee[input];
            //         const modifiedValue = propertyValue === '' ? '' : parseInt(String(propertyValue).replace(/,/g, ''), 10);
            //         updatedUnderWritingData.fee[input] = modifiedValue;
            //     } else if (updatedUnderWritingData[input] && typeof updatedUnderWritingData[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData[input];
            //         const modifiedValue = propertyValue === '' ? '' : parseInt(String(propertyValue).replace(/,/g, ''), 10);
            //         updatedUnderWritingData[input] = modifiedValue;
            //     }
            // }); 

            formatNumberInputs.forEach((input) => {
                ['abouttheborrower', 'abouttheproperty', 'fee'].forEach((section) => {
                    if (updatedUnderWritingData[section]?.[input] !== undefined) {
                        updatedUnderWritingData[section][input] = parseInt(String(updatedUnderWritingData[section][input]).replace(/,/g, ''), 10);
                    }
                });
            
                // Updated section to format `propertyinfo`
                updatedUnderWritingData.propertyinfo.forEach((property) => {
                    if (property[input] !== undefined && typeof property[input] === 'string') {
                        property[input] = property[input] === '' ? '' : parseInt(String(property[input]).replace(/,/g, ''), 10);
                    }
                });
            
                // Handle the main object itself
                if (updatedUnderWritingData[input] !== undefined && typeof updatedUnderWritingData[input] === 'string') {
                    updatedUnderWritingData[input] = parseInt(String(updatedUnderWritingData[input]).replace(/,/g, ''), 10);
                }
            });
            

            updatedUnderWritingData.propertycomparisons.forEach(comparison => {
                if (comparison.yearbuilt && typeof comparison.yearbuilt === 'string') {
                    const modifiedValue = comparison.yearbuilt.replace(/\$|,/g, '');
                    comparison.yearbuilt = parseInt(modifiedValue);
                }
                if(comparison['bedroom'] && typeof comparison['bedroom'] === 'string'){
                    const modifiedValue = comparison['bedroom'].replace(/\$|,/g, '');
                    comparison['bedroom'] = parseInt(modifiedValue);
                }
                if(comparison['bathroom'] && typeof comparison['bathroom'] === 'string'){
                    const modifiedValue = comparison['bathroom'].replace(/\$|,/g, '');
                    comparison['bathroom'] = parseInt(modifiedValue);
                }
                if(comparison.sf && typeof comparison?.sf === 'string'){
                    // const modifiedValue = comparison.sf.replace(/\$|,/g, '');
                    // comparison.sf = parseInt(modifiedValue);
                    const modifiedValue = comparison.sf;
                    comparison.sf = modifiedValue === '' ? '' : parseInt(String(modifiedValue).replace(/,/g, ''), 10);
                    
                }
                if (
                    comparison?.price && 
                    typeof comparison?.price === 'string' // Check if the input value is a string
                ) {
                    
                        //const modifiedPrice = comparison.price.replace(/\$|,/g, '');

                         // Remove non-numeric characters like '$' or ',' from the price
                        const modifiedPrice = comparison.price.replace(/[^\d.]/g, '');
                        // Parse the modified price as an integer
                        const parsedPrice = parseFloat(modifiedPrice);
                        if (!isNaN(parsedPrice)) { // Check if parsedPrice is a valid number
                            comparison.price = parsedPrice; // Update the comparison price
                        }
                    
                }
                
            });

            // perecentageInputs.forEach(input => {
            //     if (updatedUnderWritingData.abouttheborrower[input] && typeof updatedUnderWritingData.abouttheborrower[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData.abouttheborrower[input];
            //         const modifiedValue = propertyValue.replace('%', '');
            //         updatedUnderWritingData.abouttheborrower[input] = parseFloat(modifiedValue);
            //     } else if (updatedUnderWritingData.abouttheproperty[input] && typeof updatedUnderWritingData.abouttheproperty[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData.abouttheproperty[input];
            //         const modifiedValue = propertyValue.replace('%', '');
            //         updatedUnderWritingData.abouttheproperty[input] = parseFloat(modifiedValue);
            //     } else if (updatedUnderWritingData.fee[input] && typeof updatedUnderWritingData.fee[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData.fee[input];
            //         const modifiedValue = propertyValue.replace('%', '');
            //         updatedUnderWritingData.fee[input] = parseFloat(modifiedValue);
            //     } else if (updatedUnderWritingData[input] && typeof updatedUnderWritingData[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData[input];
            //         const modifiedValue = propertyValue.replace('%', '');
            //         updatedUnderWritingData[input] = parseFloat(modifiedValue);
            //     }
            // });
            perecentageInputs.forEach(input => {
                if (updatedUnderWritingData.abouttheborrower[input] && typeof updatedUnderWritingData.abouttheborrower[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheborrower[input];
                    const modifiedValue = propertyValue.replace('%', '');
                    updatedUnderWritingData.abouttheborrower[input] = parseFloat(modifiedValue);
                } else if (updatedUnderWritingData.abouttheproperty[input] && typeof updatedUnderWritingData.abouttheproperty[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheproperty[input];
                    const modifiedValue = propertyValue.replace('%', '');
                    updatedUnderWritingData.abouttheproperty[input] = parseFloat(modifiedValue);
                } else if (updatedUnderWritingData.fee[input] && typeof updatedUnderWritingData.fee[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.fee[input];
                    const modifiedValue = propertyValue.replace('%', '');
                    updatedUnderWritingData.fee[input] = parseFloat(modifiedValue);
                } else if (typeof updatedUnderWritingData[input] === 'string') { // Check if the value is a string
                    const propertyValue = updatedUnderWritingData[input].toString(); // Convert to string
                    const modifiedValue = propertyValue.replace('%', '');
                    updatedUnderWritingData[input] = parseFloat(modifiedValue);
                }
            });
            

            //handle booleans
            bools.forEach(input => {
                //if (updatedUnderWritingData[input] && typeof updatedUnderWritingData[input] === 'string') {
                        
                    // if(input === "usewhitelabel"){
                    //     updatedUnderWritingData[input] = true;
                    // }
                    // if(updatedUnderWritingData[input] === "true"){
                    //     updatedUnderWritingData[input] = true;
                    // }else if(updatedUnderWritingData[input] === "false"){
                    //     updatedUnderWritingData[input] = false;
                    // }
                //}
            });

            let modifyInputs = ['legalname'];
            modifyInputs.forEach(input => {
                if(input == "legalname" && updatedUnderWritingData?.entity?.[0]?.[input] == "No"){
                   delete updatedUnderWritingData?.entity[0][input];
                }
                // if(input == "legalname" && updatedUnderWritingData?.entity[input].length <= 0){
                //     delete updatedUnderWritingData.entity[input];
                // }
            });
            // if(updatedUnderWritingData.entity.legalname && updatedUnderWritingData.entity.legalname.length > 0 && updatedUnderWritingData.entity.entityid === undefined){
            //     var ObjectID = require("bson-objectid");
            //     var entitid = new ObjectID().toString();
            //     updatedUnderWritingData.entity.entityid = entitid
            // }
            // if((updatedUnderWritingData.fee.brokerpoints || updatedUnderWritingData.fee.brokerprocessingfee) && updatedUnderWritingData.fee.brokerid === undefined){
            //     var ObjectID = require("bson-objectid");
            //     var entitid = new ObjectID().toString();
            //     updatedUnderWritingData.fee.brokerid = entitid
            // }

// console.log('updatedUnderWritingData',updatedUnderWritingData);
            dispatch(showLoader());
            underwritingPipelineUpdate(updatedUnderWritingData).then(function (response) {
                if (response) {
                   
                    if (isDownloadTermSheetClicked) {
                     generatePDF();
                     setIsDownloadTermSheetClicked(false)
                   }else{
                    dispatch(hideLoader());
                    setTimeout(() => {
                        setPopupMessages('Saved successfully!')
                        setPopupOpen(true);
                    }, 1500);
                   }                    
                   getUnderwritingData(true);
                   //setMessage("Data saved succesfully");
                  
                   
                }
            }).catch((err) => {
                  console.error.bind("my account error", err);
                  dispatch(hideLoader());
            })

        }else{
           dispatch(showLoader());
           setPopupOpen(true);
           setPopupMessages('Please Enter A Valid Input.');
           dispatch(hideLoader());
        }

       
    };

    // const addErrorClass = (field) => {
    //     const inputElement = document.querySelector(`[name=${field}]`);
    //     if (inputElement) {
    //       inputElement.classList.add('invalid-input');
    //     }
    // };

    const cleanPhoneNumber = (number) => {
        return number.replace(/\D/g, ''); // Remove all non-digit characters
    };

    const handleValidation = () => {

        propertycomparisonsFieldsToCheck = {};
        // borrowerFieldsToCheck= {};
        // propertyFieldsToCheck = {};
        errors = {}; 

        const allowedBorrowerKeys = ['borrowerfirstname','borrowerlastname','borrowerlastname','borrowerphone',
            'statedcreditscore','borroweroccupation','legalname', 'borroweremail'];
        
        if(underwritingErrors?.borroweremail){
            errors['borroweremail'] = underwritingErrors?.borroweremail;
        }

        allowedBorrowerKeys.forEach(key => {
           
            if(key !== 'borrowerphone' && underWritingData?.abouttheborrower?.hasOwnProperty(key) && (underWritingData?.abouttheborrower[key] === '' || underWritingData?.abouttheborrower[key] === undefined)){
                errors[key] = "required";
            }else if(key == 'borrowerphone' && underWritingData?.abouttheborrower?.hasOwnProperty(key) && (underWritingData?.abouttheborrower[key] === '' || underWritingData?.abouttheborrower[key] === undefined || cleanPhoneNumber(underWritingData?.abouttheborrower[key])?.length < 10)){
                errors[key] = "Phone Number is invalid!";
            }
        })

        const allowedPropertyKeys = ['propertytype','propertyaddress','propertycity','propertystate','propertyzipcode','totalbedrooms','totalbathrooms',
            'haspool','lotsqft']; 

        allowedPropertyKeys.forEach(key => {
            // Check if the key exists in underWritingData.abouttheproperty and if the value is not empty
            if (underWritingData.abouttheproperty.hasOwnProperty(key) && (underWritingData.abouttheproperty[key] === "" || underWritingData.abouttheproperty[key] === undefined)) {
                errors[key] = "required";
            }
        });
       // console.log('errorserrors',errors);
        setUnderwritingErrors(errors);

        // underWritingData?.propertycomparisons?.forEach((row,index) => {
        //     if( !row["address"] && (row["price"] !== "" || row["bd/ba"] !== ""
        //     || row["sf"] !== "" || row["yearbuilt"] !== ""
        //     || row["comparisonnarrative"])){
    
        //         propertycomparisonsFieldsToCheck[index] = "required";
        //         // alert("empty")
        //     }
        // })
        // console.log("comparision errors---->",propertycomparisonsFieldsToCheck);
        // setComparisionsErrors(propertycomparisonsFieldsToCheck);
        
        // alert(propertycomparisonsFieldsToCheck);
        // setBorrowerErrors(borrowerFieldsToCheck);
        // setPropertyErrors(propertyFieldsToCheck);
        
        
        
        
    };

    const handleRemoveErrors = (num) => {
        propertycomparisonsFieldsToCheck[num] = '';
        setComparisionsErrors(propertycomparisonsFieldsToCheck);
    }
    // const validateFields = (data, fieldsToCheck, errorsSetter) => {
    //     const errors = {};
    //     const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    
    //     for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
    //         const fieldValue = data[fieldName];
    //         if (fieldName === 'borroweremail' && typeof fieldValue === 'string') {
    //             if (!fieldValue.trim()) {
    //                 errors[fieldName] = `Please Enter ${fieldLabel}`;
    //             } else if (!emailRegex.test(fieldValue.trim())) {
    //                 errors[fieldName] = `Please Enter a Valid Email for ${fieldLabel}`;
    //             }
    //         } else if (typeof fieldValue === 'string') {
    //             if (!fieldValue.trim()) {
    //                 errors[fieldName] = `Please Enter ${fieldLabel}`;
    //             }
    //         } else if (typeof fieldValue === 'number') {
    //             if (fieldValue === null || fieldValue === undefined || isNaN(fieldValue)) {
    //                 errors[fieldName] = `Please Enter ${fieldLabel}`;
    //             }
    //         } else {
    //             // Handle other types if needed
    //         }
    //     }
    //     errorsSetter(errors);
    //     // console.log('errors---->',errors);
    //     return Object.keys(errors).length === 0;
    // };
    

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            const headerOffset = id == 'fee' ? 480 :id == 'calculations' ? 530 : 620; // Adjust this value based on the height of your fixed header/status tab
            const elementPosition = section.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
      };
    const closePopup = () => {
        setPopupOpen(false);
    };

    const [htmlContent, setHtmlContent] = useState('');
    const [Content, setContent] = useState('');
    const [values, setValues] = useState({});
    
    // useEffect(() => {
    //     getTermSheets().then(function (response) {
    //         if (response?.result) {
    //             console.log('programtype', loanInfo.programtype, response?.result[5]?.programtype);
                
    //             const programTypeToFind = loanInfo.programtype; 
    //             const matchingObject = response?.result.find(item => item.programtype === programTypeToFind);

    //             if (matchingObject) {
    //                 console.log("Matching object:", matchingObject);
    //                 setContent(matchingObject)
    //             } else {
    //                 console.log("No matching object found for programtype:", programTypeToFind);
    //             }
    //         }
    //           dispatch(hideLoader());
    //     }).catch((err) => {
    //         dispatch(hideLoader());
    //         console.error.bind("my account error", err);
    //     })
    // }, []);

    const generatePDF = () => {
      // HTML content with placeholders
     
      dispatch(showLoader())
      // Object containing values to replace placeholders
      const vals = {
        'company.logo250x60': 'Company Logo',
        'date': '2024-04-12', // Example date value
        'termsheet.full_address': 'kakinada here',
        'termsheet.existing_loan_amount':'$2024'
        // Add more key-value pairs for other placeholders
      };
      // Generate PDF with replaced values
      generatePDFWithValues(Content, vals);
      setIsDownloadTermSheetClicked(false)
    }
  
    const generatePDFWithValues = (content, vals) => {
       // console.log('loanInfoloanid',loanInfo, loanid);
        let termSheet = termSheetName
        if(termSheetName == 'TERMSHEET'){
            termSheet = underWritingData?.usewhitelabel ? 'TERMSHEETWHITELABELED':'TERMSHEETNOTWHITELABELED'
        }else{
            termSheet = underWritingData?.programtype == 'EASY FIX' ? 'UNDERWRITINGEASYFIX' : 'UNDERWRITINGEASYBUILD';
        }
        let callname = termSheetName == 'TERMSHEET' ? getTermSheetPDF : getUnderwritingPDF;
        callname(loanid, termSheet).then(function (response) {
            if (response?.result) {
              // Replace placeholders with actual values
        //const replacedHTML = replacePlaceholders(content, vals);
        const replacedHTML = response?.result?.template;
        let replacedContent = replacedHTML;
      
        // Create new jsPDF instance with custom width
        let orientation = termSheetName == 'UNDERWRITING' ? 'landscape' : 'portrait' 
        const doc = new jsPDF({
          orientation: orientation,
          unit: 'px', // Units are in pixels
          format: 'a4'  // Custom width and height
        });
      
        // Convert HTML content to PDF
        doc.html(replacedContent, {
            x: 20, // Horizontal position (in pixels)
            y: termSheetName == 'UNDERWRITING' ? 20 : 0, // Vertical position (in pixels)
            html2canvas: {
                scale: 0.565 // Adjust scale to fit content within the page
            },

            callback: () => {
                // Save PDF file after rendering is complete
                let propertyadd = underWritingData?.abouttheproperty?.propertyaddress || underWritingData?.abouttheproperty?.propertycity;
                let termSheet = termSheetName || 'defaultTermSheet';
                let loanId = loanid || 'defaultLoanId';
                let fileName = `${propertyadd}-${termSheet}-${loanId}.pdf`;

                // api call to save in files
                const pdfBlob = doc.output('blob'); 
                handleSaveFiletoS3(pdfBlob, 'pdf', fileName);

                doc.save(fileName);
                dispatch(hideLoader());
            }
            });
            }
            }).catch((err) => {
                dispatch(hideLoader());
              console.error.bind("my account error", err);
            })
       
      }
      const handleSaveFiletoS3 = (file, type,fileName) => {
        // dispatch(showLoader())
        var bucket;
        console.log('file', file)
        const timestamp = Date.now();
        var fileformat = type;
        let name = fileName
        bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
        var ObjectID2 = require("bson-objectid");
        var uniqueId = new ObjectID2().toString();
        let uploadFilePath = Appname + "/dealfiles/" + uniqueId + "/" + timestamp + "." + fileformat;
        var data = { source_bucket: bucket, sourcepath: uploadFilePath }
        axios.post(APIUrl + 'uploadURL?appname=' + Appname, data, { type: 'application/json' })
            .then((response) => {
                if (response?.data && response?.data?.result) {
                    var url = response?.data?.result;
                    axios.put(url, file, {
                        "headers": {
                            "Content-Type": "multipart/form-data",
                            "Accept": "/",
                            "Cache-Control": "no-cache",
                            "Accept-Encoding": "gzip, deflate",
                            "Connection": "keep-alive",
                            "cache-control": "no-cache"
                        }
                    })
                        .then((response) => {
                            if (response && response?.status === 200) {
                                const newDocument = {
                                    filename: name,
                                    filepath: uploadFilePath,
                                    documentid: uniqueId,
                                    fileextension: type,
                                    filetype: type,
                                    filedescription: '',
                                    type:'Package',
                                    loanid: loanInfo?.loanid,
                                }
                                // dispatch(showLoader());
                                addLoanDocumentApi(newDocument).then(function (response) {
                                    // setPopupMessages('File Uploaded');
                                    // setPopupOpen(true);
                                    dispatch(hideLoader());
                                }).catch((err) => {
                                    dispatch(hideLoader());
                                })
                            }
                        });
                }
            }).catch((err) => {
                dispatch(hideLoader());
            })
    };
  
    const replacePlaceholders = (content, vals) => {
      let replacedContent = content.replace(/<p([^>]*)>/g, '<p style="white-space: nowrap;"$1>')
      .replace(/<span([^>]*)>/g, '<span style="white-space: nowrap;"$1>');
      const regex = /{([^}]+)}/g;
      let match;
      
      while ((match = regex.exec(content)) !== null) {
        const placeholder = match[0];
        const key = match[1];
        const cleanedKeyName = key.includes("termsheet.") ? key.split('.')[1] : key;
        const value = getValueByKey(underWritingData, cleanedKeyName);
        
        if (value) {
          replacedContent = replacedContent.replace(placeholder, value);
        }
      }
      return replacedContent;
    }
    const getValueByKey = (obj, keyToFind) => {
        for (const key in obj) {
            if (key === keyToFind) {
                return obj[key];
            }
            if (typeof obj[key] === "object" && obj[key] !== null) {
                const result = getValueByKey(obj[key], keyToFind);
                if (result !== undefined) {
                    return result;
                }
            }
        }
    }

    
    const confirmOverRide = () => {
        setUnderWritingData(prevState => ({
            ...prevState,
            overridecalculationfee: TempOverRide
        }));
        setOverridePopup(false)
    }
    useEffect(() => {
        if(propertyPlace){
                let newStateValue = '';
                const stateExistsInDropdown = states.includes(propertyAddress?.state);
                if (stateExistsInDropdown) {
                    newStateValue = propertyAddress.state;
                }
              //  console.log('newStateValue',newStateValue, propertyAddress);
                setUnderWritingData(prevState => ({
                    ...prevState,
                    abouttheproperty: {
                        ...prevState.abouttheproperty,
                        "propertyaddress": propertyAddress?.address?.trim() ? propertyAddress?.address : propertyAddress?.fullAddress ??  '',
                        "propertycity": propertyAddress?.city ?? '',
                        "propertystate":  newStateValue ?? '',
                        "propertyzipcode": propertyAddress?.zipcode ?? '',
                        "propertyfulladdress": propertyAddress?.fullAddress ?? '',
                        "propertyplaceimage": propertyAddress?.image ?? '',
                        "propertygooglemaplink": propertyAddress?.url ?? '',
                        "propertygeolocation": propertyAddress?.url ?? '',
                        'propertystateshort': propertyAddress?.stateShortName ?? '',
                        "propertycounty": propertyAddress?.county ?? ''
                    },
           
            }));
        }else{
            setUnderWritingData(prevState => ({
                ...prevState,
                abouttheproperty: {
                    ...prevState.abouttheproperty,
                    "propertyaddress": '',
                    "propertycity":'',
                    "propertystate":  '',
                    "propertyzipcode":  '',
                    "propertyfulladdress":  '',
                    "propertyplaceimage":  '',
                    "propertygooglemaplink": '',
                    "propertygeolocation":  '',
                    'propertystateshort': '',
                    "propertycounty":''
                },
       
        }));
        }
        setIsDownloadTermSheetClicked(false);
      }, [propertyPlace, propertyAddress]);

      useEffect(() => {
        coBorrowerSelectedPlace.forEach((selectedPlace, index) => {
            if (selectedPlace) {
                let newStateValue = '';
                const stateExistsInDropdown = states.includes(coBorrowerAddressData[index]?.state);
                if (stateExistsInDropdown) {
                    newStateValue = coBorrowerAddressData[index].state;
                }
               // console.log('newStateValue', newStateValue, coBorrowerAddressData[index]);
    
                setUnderWritingData(prevState => ({
                    ...prevState,
                    ['propertyinfo']: prevState['propertyinfo']?.map((item, i) => 
                        i === index ? { 
                            ...item, 
                            'propertyaddress': coBorrowerAddressData[index]?.address || '',
                            'propertycity': coBorrowerAddressData[index]?.city || '',
                            'propertystate': newStateValue || '',
                            'propertyzipcode': coBorrowerAddressData[index]?.zipcode || '',
                            'propertyfulladdress': coBorrowerAddressData[index]?.fullAddress || '',
                            'propertyplaceimage': coBorrowerAddressData[index]?.image || '',
                            'propertygooglemaplink': coBorrowerAddressData[index]?.url || '',
                            'propertygeolocation': coBorrowerAddressData[index]?.url || '',
                            'propertystateshort': coBorrowerAddressData[index]?.stateShortName || '',
                            'propertycounty': coBorrowerAddressData[index]?.county || ''
                        } : item
                    )
                }));
            } else {
                setUnderWritingData(prevState => ({
                    ...prevState,
                    ['propertyinfo']: prevState['propertyinfo']?.map((item, i) => 
                        i === index ? { 
                            ...item, 
                            'propertyaddress': '',
                            'propertycity': '',
                            'propertystate': '',
                            'propertyzipcode': '',
                            'propertyfulladdress': '',
                            'propertyplaceimage': '',
                            'propertygooglemaplink': '',
                            'propertygeolocation': '',
                            'propertystateshort': '',
                            'propertycounty': ''
                        } : item
                    )
                }));
            }
        });
    
        setIsDownloadTermSheetClicked(false);
    }, [coBorrowerSelectedPlace, coBorrowerAddressData]);

     const sendOriginatorInstructions = () => {
        handleValidation();
       // console.log('originatorinstructions',underWritingData?.originatorinstructions) 
        if(!underWritingData?.originatorinstructions){
            errors['originatorinstructions'] = 'required'
            setUnderwritingErrors(errors);
            setPopupOpen(true);
            setPopupMessages('Please Enter Originator Instructions');
        }else if(Object?.keys(errors)?.length === 0){
            let data ={
                "originatorinstructions":underWritingData?.originatorinstructions,
                "termSheet": [
                    underWritingData?.programtype == 'EASY FIX' ? 'UNDERWRITINGEASYFIX' : 'UNDERWRITINGEASYBUILD', underWritingData?.usewhitelabel ? 'TERMSHEETWHITELABELED':'TERMSHEETNOTWHITELABELED'
                ]
            }
            dispatch(showLoader())
            sendOriginatorInstructionsToEmail(data, loanid).then(function (response) {
                dispatch(hideLoader())
                if (response) {
                  setPopupMessages('Email sent to the account executive successfully!')
                  setPopupOpen(true)
                }
            }).catch((err) => {
                  console.error.bind("my account error", err);
                  dispatch(hideLoader());
            })

        }

     }

     const handleBlur = (event) => {
        const emailValue = event.target.value;
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let errors = {};
        if (!emailRegex.test(emailValue)) {
            errors[event.target.name] = 'Please enter a valid email address.'
            setUnderwritingErrors(errors);
        } else {
            setUnderwritingErrors('');
        }
      };

      const handleLengthChange = (length) => {
        setBorrowerLength(length);
       // console.log('Borrower Exposure Length:', length);
      };
       console.log("pipelinePermissions",pipelinePermissions) ;

    useEffect(() => {
        if(sectionPermissions?.length === 0){
            if(userData?.securityInfo?.pipelinePermissions){
                const underwritingMenu = userData?.securityInfo?.pipelinePermissions?.find((menu) => menu.menu === "Underwriting");
                if(underwritingMenu?.submenus){
                    const termSheetMenu = underwritingMenu?.submenus?.find((submenu) => submenu.menu === "Underwriting Term Sheet");
                    if(termSheetMenu?.sections){
                        console.log("Section Permissions",termSheetMenu?.sections);
                        setSectionPermissions(termSheetMenu?.sections);//array
                    }
                }
                
            }
        }
        
    },[userData])

    

    return (
        <div id="layout-wrapper" className="dashboard">
             {loading ? <Loader/> : null} 
             {isPopupOpen && (
            <PopupMessage
             type={PopupMessages === 'Fields are empty' || PopupMessages === 'Please Enter Originator Instructions' || PopupMessages === 'Please Enter A Valid Input.'? 'warning' : 'success'}
             message={PopupMessages || 'Saved Successfully'}
             onClose={closePopup}
            />
        )}
         {confirmationPopUp && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes !"
                cancelButtonText="No, cancel!"
                title="Are you sure you want to reset?"
                onConfirm={(e) => {getUnderwritingData(); setConfirmationPopUp(false)}}
                onCancel={(e) => {setConfirmationPopUp(false)}}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'reset-yes',
                        className: 'btn btn-primary',
                        onClick: () => {
                            getUnderwritingData(); // This will handle the confirm action
                            setConfirmationPopUp(false); // Close popup after confirming
                          },
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'reset-no',
                        className: 'btn btn-light',
                        onClick: () => {
                            setConfirmationPopUp(false); // Handle the cancel action
                        },
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
            </SweetAlert>}
            {
  // Conditionally render the modal content based on OverridePopup
  OverridePopup && <SweetAlert
  warning
  showCancel
  confirmButtonText="Yes !"
  cancelButtonText="No, cancel!"
  title={OverridePopupMessage}
  onConfirm={(e) => {confirmOverRide()}}
  onCancel={(e) => {setOverridePopup(false)}}
  focusCancel={true}
  customButtons={[
        React.createElement('button', {
            id: 'override-yes',
            className: 'btn btn-primary',
            onClick: () => {
                confirmOverRide()
            },
            style: { marginRight: '8px' ,fontSize: '16px'}
        }, 'Yes'),
        React.createElement('button', {
            id: 'override-no',
            className: 'btn btn-light',
            onClick: () => {
                setOverridePopup(false)
            },
            style: { fontSize: '16px'}
        }, 'No')
    ]}
>
</SweetAlert>
}


           <PipelineHeader />
            <div className="dashboard_block">
            <PipelineStatus />
                <h2 className="form_title">{ isUnderWriting ?  'Deal' : 'Underwriting'} <span className="space">&#10095;</span> <span className="sub">Underwriting
                    Term sheet</span> </h2>
            <div className="card user_management detailed_app underwriting_termsheet">
                <div className="card-body my_account pt-0" id="AboutTheBorrower">
                    {/* {viewStatus == true &&  */}
                    <div className='search top-fix' >
                        <a href="#AboutTheBorrower" onClick={(e) => {e.preventDefault();scrollToSection("abouttheborrower")}} className="create_btn">Borrower 1</a>
                        <a href="#abouttheproperty" onClick={(e) => {e.preventDefault();scrollToSection("abouttheproperty")}} className="create_btn">Subject Property</a>
                        <a href="#aboutthedeal" onClick={(e) => {e.preventDefault();scrollToSection("aboutthedeal")}}  className="create_btn">About The Deal</a>
                        <a href="#loanstructure" onClick={(e) => {e.preventDefault();scrollToSection("loanstructure")}}  className="create_btn">Loan Structure</a>
                        <a href="#fee" onClick={(e) => {e.preventDefault();scrollToSection("fee")}}  className="create_btn">Third Party Fees</a>
                        <a href="#Calculations" onClick={(e) => {e.preventDefault();scrollToSection("calculations")}} className="create_btn">Calculations</a>
                        <a href="#profitabilityanalysis" onClick={(e) => {e.preventDefault();scrollToSection("profitabilityanalysis")}} className="create_btn">Profitability Analysis</a>
                        <a href="#PropertyComparison"  onClick={(e) => {e.preventDefault();scrollToSection("PropertyComparison")}} className="create_btn">Property Comparison</a>
                    </div>
                    {/* } */}
                    
                    <div className="form_section">
                        
                        {dealUnderwritingJson.map((section, index) => (
                            
                            <div className='row' id={Object.keys(section)[0]} key={index} >
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="title">{
                                                                            Object.keys(section)[0] == 'abouttheborrower' ? 'Borrower 1': 
                                                                            Object.keys(section)[0] == 'abouttheproperty' ? 'Subject Property 1':
                                                                            Object.keys(section)[0] == 'aboutthedeal' ? 'About the deal':
                                                                            Object.keys(section)[0] == 'loanstructure' ? 'Loan Structure':
                                                                            Object.keys(section)[0] == 'fee' ? ' Third Party Fees':
                                                                            Object.keys(section)[0] == 'calculations' ? 'Calculations':
                                                                            Object.keys(section)[0] == 'profitabilityanalysis' ? 'Profitability Analysis':''
                                                                        }</h4>{Object.keys(section)[0] == 'abouttheborrower' ?
                                                                        <button id="addFee" type="button" disabled={borrowerLength == 0} className={`fillbtn_new ms-3 ${borrowerLength > 0 ? 'add_button' : ''}`} data-bs-toggle="modal"
                                                                        data-bs-target="#staticBackdrop" data-bs-whatever="@mdo"   >Borrower Exposure</button>
                                                                         : ''}
                              </div>
                                
                                    {section[Object.keys(section)[0]].map(field =>  !(underWritingData?.programtype === 'EASY BUILD' && field?.name === "yearbuilt") && (
                                        <>
                                        
                                            
                                           
                                           <div className= {(field?.name === "overridecalculationfee"  || field?.name === 'floodinsurencevalue') ? "col-md-12" : "col-md-3"} style={{
    display: (((field.name === 'purchasedate' || field.name === 'currentvalue')  && underWritingData?.loanfor != 'REFINANCE') || field.name === 'proposedlienposition' || field.name === 'estimatedvalue') ? 'none' : 'block'
}} >
                                                
                                                
                                                    
                                                <div className={`form-group ${field?.name === "floodinsurencevalue" ? "mt-1" : ""}`} style={{
    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
  }}>
                                                    
                                                
                                                    {
                                                        (field?.checkboxlabel) && (
                                                            <>
                                                                <label className="toggle mb-0  less_than">
                                                                    <span className="toggle-label">{field?.checkboxlabel}</span>
                                                                    <input 
                                                                        onChange={handleChange}
                                                                        checked={field?.checkboxlabelname === "cashreverserequiredvalue" ? underWritingData?.abouttheborrower?.[field?.checkboxlabelname] : field?.checkboxlabelname === 'floodinsurencevalue'? underWritingData?.abouttheproperty?.[field?.checkboxlabelname] : field?.checkboxlabelname === "yearbuiltvalue" ? underWritingData?.abouttheproperty?.[field?.checkboxlabelname] : field?.checkboxlabelname === "addtoESfeesBrokerPoints" ? underWritingData?.addtoESfeesBrokerPoints: field?.checkboxlabelname === "addtoESfeesBrokerProcessingFee" ? underWritingData?.addtoESfeesBrokerProcessingFee : ''} 
                                                                        name={field?.checkboxlabelname} 
                                                                        className="toggle-checkbox" 
                                                                        type="checkbox"
                                                                        
                                                                    />
                                                                    <div className="toggle-switch"></div>
                                                                    {(field?.checkboxlabelname === "cashreverserequiredvalue" || field?.checkboxlabelname ==="floodinsurencevalue") && <span className='label_text'> (If stated is less than required)</span>}
                                                                    {(field?.checkboxlabelname === "yearbuiltvalue") && <span className='label_text'> (If built before 1940)</span>}
                                                                    {(field?.checkboxlabelname === "addtoESfeesBrokerPoints" || field?.checkboxlabelname === "addtoESfeesBrokerProcessingFee") && <span className='label_text'> (Add to ES fees on term sheet)</span>}
                                                                </label> 
                                                            </>
                                                            
                                                        )
                                                    }
                                                
                                                    {
                                                         (field?.type === "checkbox") && (
                                                            <>
                                                                <label className="toggle mb-3">
                                                                    <span className="toggle-label">{field?.label}</span>
                                                                    <input className="toggle-checkbox" 
                                                                    checked={
                                                                        (field?.name === 'floodinsurencevalue' ? underWritingData?.abouttheproperty?.[field?.name] : underWritingData?.overridecalculationfee)
                                                                        
                                                                    } 
                                                                    name={field?.name} onChange={handleChange} type="checkbox" />
                                                                    <div className="toggle-switch"></div>
                                                                </label>
                                                            </>
                                                           
                                                        )
                                                    }

{
                                                    }


                                                    
                                                    {
                                                        (field.type === 'select') ? (
                                                            <>
                                                                <label>{field.label}</label>
                                                                <select name={field?.name} onChange={handleChange}
                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                    disabled={field.name == "interestreservesloanterm" && underWritingData?.interestreserves == "No"}
                                                                    value={
                                                                        (field?.name === "loanfor" || field?.name === "programtype" || field?.name === "loantype"  || field?.name === "drawinteresttype" || field?.name === "loanterm" || field?.name === "interestreserves" || field?.name === "creditreport" || field?.name === "appraisal" || field?.name === "interestreservesloanterm") ?
                                                                            underWritingData?.[field?.name] :
                                                                        (field?.name === "lenderfundedrehab" || field?.name === "exitstrategy") ?
                                                                            underWritingData?.abouttheproperty?.[field?.name] :
                                                                        
                                                                        underWritingData?.[Object.keys(section)[0]]?.[field?.name]
                                                                    }

                                                                >
                                                                    {field.name !== "propertystate" ? 
                                                                        field.name == "interestreservesloanterm" ? field?.options?.filter(option => option.value <= underWritingData?.loanterm).map((option, key) => (
                                                                            <option key={key} value={option.value}>{option.label}</option>
                                                                        )) : field?.options.map((option, key) => (
                                                                        <option key={key} value={option.value}>{option.label}</option>
                                                                        )) : 
                                                                        <>
                                                                            <option value=''>Select</option>
                                                                            {states.map((key,index) => (<option key={index} value={key}>{key}</option>))}
                                                                        </>
                                                                    }
                                                                </select>
                                                            </>
                                                        ) : 
                                                        
                                                        (field?.name === "propertyaddress") ? (
                                                            <>
                                                            <label>Property Address</label>
                                                            <div className={` ${underwritingErrors?.[field?.name] && 'invalid-input'}`}  onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null, ['propertycity']: null, ['propertystate']: null, ['propertyzipcode']: null }) }}>
                                                  {mapsKey &&
                                                  
                                                  <LocationSearch simpleAddress={true} name={'propertyaddress'} setAddressData={setPropertyAddress} addressData={propertyAddress} setSelectedPlace={setPropertyPlace} selectedPlace={underWritingData?.abouttheproperty?.propertyaddress} className={`form-control ${underwritingErrors.propertyaddress && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}/>} 
                                                </div></>
                                                           
                                                        ):(
                                                            <>
                                                                {(field?.type === "text"  && field?.name === "emptyinput" &&
                                                                    <div className={field?.name}>
                                                                        
                                                                    </div>
                                                                    
                                                                )}
                                   
                                                                {(field?.type === "text"  && field?.name !== "emptyinput" &&
                                                                
                                                                    <>
                                                                       { (field?.checkboxlabel) ? '' :  <label>{field?.label}</label>}
                                                                        {floatJsonInputs?.map(ele => (
                                                                            (ele === field?.name) ? 
                                                                            <>
                                                                                <CurrencyInput 
                                                                                    onBlur={()=>{setIsFocused(false); setLastFieldtName(field?.name)}}
                                                                                    style = {{ 'backgroundColor' : 
                                                                                        
                                                                                        (underWritingData?.overridecalculationfee === false &&
                                                                                        (field?.name === 'loanamount' || field?.name === 'initialfunding' || field?.name === 'initialequity' ||
                                                                                        field?.name === 'rehabfunding' || field?.name === 'rehabequity'   || field?.name === 'interestreserveamount'))
                                                                                        ? '#e9ecef' 
                                                                                        :
                                                                                        ( (underWritingData?.overridecalculationfee === true || underWritingData?.overridecalculationfee === false) &&
                                                                                            (field?.name === 'totalcost' || field?.name === 'maxmonthlyloanpayment' || field?.name === 'cashtoclose'
                                                                                           
                                                                                            ))
                                                                                            ? '#e9ecef'
                                                                                        : 
                                                                                        (underWritingData?.overridecalculationfee === true &&
                                                                                            (field?.name === 'loanamount' || field?.name === 'initialfunding' || field?.name === 'initialequity' ||
                                                                                            field?.name === 'rehabfunding' || field?.name === 'rehabequity'   || field?.name === 'interestreserveamount'))
                                                                                            ? '' 
                                                                                            :
                                                                                            (
                                                                                                
                                                                                                field?.name === 'cashreverserequired'
                                                                                            ) ? '#e9ecef' :
                                                                                            (
                                                                                                (!underWritingData?.abouttheproperty?.floodinsurencevalue || underWritingData?.abouttheproperty?.floodinsurencevalue === undefined) &&
                                                                                                (field?.name === 'floodinsurence')
                                                                                            ) ? '#e9ecef' :
                                                                                        ''
                                                                                    }}
                                                                                    title={field?.formula}
                                                                                    readOnly={
                                                                                        
                                                                                        (
                                                                                            underWritingData?.overridecalculationfee === false &&
                                                                                            (
                                                                                                field?.name === 'loanamount' || field?.name === 'initialfunding' || 
                                                                                                field?.name === 'initialequity' || field?.name === 'rehabfunding' || 
                                                                                                field?.name === 'rehabequity' || field?.name === 'interestreserveamount'
                                                                                            )
                                                                                        ) ? true :
                                                                                       
                                                                                        (
                                                                                            (underWritingData?.overridecalculationfee === true || underWritingData?.overridecalculationfee === false) &&
                                                                                            (
                                                                                                field?.name === 'totalcost' || field?.name === 'maxmonthlyloanpayment' || 
                                                                                                field?.name === 'cashtoclose'
                                                                                            )
                                                                                        ) ? true :
                                                                                      
                                                                                        (
                                                                                            underWritingData?.overridecalculationfee === true &&
                                                                                            (
                                                                                                field?.name === 'loanamount' || field?.name === 'initialfunding' || 
                                                                                                field?.name === 'initialequity' || field?.name === 'rehabfunding' || 
                                                                                                field?.name === 'rehabequity' ||  field?.name === 'interestreserveamount'
                                                                                            )
                                                                                        ) ? false :
                                                                                        
                                                                                        (
                                                                                            (underWritingData?.abouttheborrower?.cashreverserequiredvalue === true || 
                                                                                             underWritingData?.abouttheborrower?.cashreverserequiredvalue === false) &&
                                                                                            (field?.name === 'cashreverserequired')
                                                                                        ) ? true : field?.name === 'titleAmount' || field?.name === 'netprofit' || field?.name === 'minimummonthlyinterest' || field?.name === 'maximummonthlyinterest' ?true:
                                                                                       
                                                                                        // (
                                                                                        //     field?.name === 'floodinsurence' && underWritingData?.abouttheproperty?.floodinsurencevalue === false
                                                                                            
                                                                                        // ) ? true :

                                                                                        false
                                                                                    }
                                                                                    disabled={
                                                                                        (field?.name === 'cashreverserequired') || (field?.name === 'floodinsurence' && (!underWritingData?.abouttheproperty?.floodinsurencevalue || underWritingData?.abouttheproperty?.floodinsurencevalue === undefined)) }
                                                                                    
                                                                                    name={field?.name}
                                                                                    //   defaultValue={1000}
                                                                                
                                                                                    decimalsLimit={2}
                                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} 
                                                                                    onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null });setIsFocused(true); setLastFieldtName(field?.name) }}
                                                                                    placeholder="$0"
                                                                                    prefix="$"
                                                                                    groupSeparator=","
                                                                            
                                                                                    // onValueChange={(value, name, values) => {
                                                                                    //     console.log(values)
                                                                                    //     handleCurrencyInputsLoan(name,value);
                                                                                    // }}
                                                                                    onValueChange={(value, name , values) => {
                                                                                        handleCurrencyChange(name,value);
                                                                                        if(field?.name === 'insuranceamount'){
                                                                                            setIsPropertyInsuranseChanged(true)
                                                                                        }
                                                                                    }}
                                                                                    value={
                                                                                        ((field?.name === 'renovationcost' || field?.name === 'purchaseprice' || field?.name === 'downpaymentsource'
                                                                                            || field?.name === 'borrowerarv' || field?.name === 'occupancy' || field?.name === 'easystreetarv' || field?.name === 'easystreetaiv' || field?.name === 'valuationfee'
                                                                                            || field?.name === 'monthlyrentalrate' || field?.name === 'floodinsurence' || field?.name === 'existingloan') 
                                                                                            && floatJsonInputs.includes(field?.name)) ?
                                                                                            
                                                                                            (isNaN(underWritingData?.abouttheproperty?.[field?.name]) ? '' : underWritingData?.abouttheproperty?.[field?.name]) :
                                                                                            
                                                                                        ((field?.name === 'brokerprocessingfee') && floatJsonInputs.includes(field?.name)) ? 
                                                                                            
                                                                                            (isNaN(underWritingData?.fee?.[field?.name]) ? '' : underWritingData?.fee?.[field?.name]) :
                                                                                    
                                                                                        ((field?.name === 'loanamount' || field?.name === 'docfee' || field?.name === 'initialequity'
                                                                                            || field?.name === 'initialfunding' || field?.name === 'rehabequity' || field?.name === 'rehabfunding' || field?.name === 'insuranceamount' || field?.name === 'interestreserveamount' || field?.name === 'maxmonthlyloanpayment' || field?.name === 'cashtoclose'|| field?.name === 'cashreverserequired' || field?.name === 'originationFee' || field?.name === 'escrow' || field?.name === 'titleAmount' || field?.name === 'propertyInsurance' || field?.name === 'drawFee' || field?.name === 'totalcost' || field?.name === 'salesexpense' || field?.name === 'netprofit' || field?.name === 'dealmargin' || field?.name === 'cashoncashreturn' || field?.name === 'annualizedreturn' || field?.name === 'maximummonthlyinterest' || field?.name === 'minimummonthlyinterest') 
                                                                                            && floatJsonInputs.includes(field?.name)) ? 
                                                                                            
                                                                                            (isNaN(underWritingData?.[field?.name]) ? '' : underWritingData?.[field?.name]) :
                                                                                            
                                                                                        (isNaN(underWritingData?.[Object.keys(section)[0]]?.[field?.name]) ? '' : underWritingData?.[Object.keys(section)[0]]?.[field?.name])
                                                                                    }
                                                                                       
                                                                                />
                                                                            </> : ''
                                                                         ))}
                                                                         {!floatJsonInputs.includes(field?.name) && 
                                                                            <>
                                                                                <input 
                                                                                    type="text" name={field?.name} onChange={handleChange} placeholder={field.placeholder} 
                                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => {setLastFieldtName(field?.name); setIsFocused(true); setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                                    style = {{ 'backgroundColor' : 
                                                                                        
                                                                                        ( (underWritingData?.overridecalculationfee === true || underWritingData?.overridecalculationfee === false) &&
                                                                                        (field?.name === 'actualltv' || field?.name === 'actualltc' 
                                                                                        ))
                                                                                            ? '#e9ecef'
                                                                                        : ''
                                                                                    }}
                                                                                    title={field?.formula}
                                                                                    readOnly={
                                                                                        
                                                                                        ( (underWritingData?.overridecalculationfee === true || underWritingData?.overridecalculationfee === false) &&
                                                                                            (field?.name === 'actualltv' || field?.name === 'actualltc' 
                                                                                            ))
                                                                                            ? true
                                                                                        :  (field?.name === 'yearbuilt' && underWritingData?.abouttheproperty?.yearbuiltvalue === true) ? underWritingData?.abouttheproperty?.yearbuiltvalue : field?.name === 'dealmargin' ? true : field?.name === 'cashoncashreturn' ? true : field?.name === 'annualizedreturn' ? true : false
                                                                                    }
                                                                                    value={
                                                                                        (field?.name === "legalname") ? underWritingData?.entity?.[0]?.[field?.name] :
                                                                                            
                                                                                        (field?.name === "downpaymentsource" || field?.name === "apt_unit" || field?.name === "occupancy" ||
                                                                                            field?.name === "maxltc" || field?.name === "maxltv" || field?.name === "points" || field?.name === "debtservicecoverageratio" || field?.name === "exitstrategy") ? 
                                                                                            
                                                                                            underWritingData?.abouttheproperty?.[field?.name] :
                                                                                        
                                                                                        (field?.name === "interestrate" || field?.name === "loanterm" || field?.name === "creditreport" || field?.name === "appraisal" || field?.name === "title") ? 
                                                                                           underWritingData?.[field?.name] :
                                                                                        
                                                                                        (field?.name === "actualltv" || field?.name === "actualltc" || field?.name === "title" || field?.name === "dealmargin" || field?.name === "cashoncashreturn" || field?.name === "annualizedreturn") ? 
                                                                                        (underWritingData?.[field?.name] ? underWritingData?.[field?.name] + '%':0 + '%'):
                                                                                        
                                                                                        underWritingData?.[Object.keys(section)[0]]?.[field?.name]
                                                                                    }
                                                                                    onBlur={()=>{setIsFocused(false); setLastFieldtName(field?.name)}}
                                                                                    
                                                                                /> 
                                                                            </>
                                                                                
                                                                        }   
                                                                       
                                                                         
                                                                        
                                                
                                                                    </>
                                                                        
                                                                    
                                                                )} 
                                                                
                                                                    
                                                                        
                                                                        
                                                                
                                                            </>
                                                        )
                                                    }
                                                    
                                                    {
                                                        field?.type === "textarea" && 
                                                        (
                                                            
                                                            <>
                                                                <label>{field.label}</label>
                                                                        <textarea 
                                                                            name={field?.name} 
                                                                            onChange={handleChange} 
                                                                            placeholder={field.placeholder} 
                                                                            cols={10}
                                                                            rows={5}
                                                                            className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} 
                                                                            onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                            value={field?.name === 'executionstrategysummary' ? underWritingData?.abouttheproperty?.[field?.name] :underWritingData[field?.name]}
                                                                        />
                                                            </>   
                                                                        

                                                                    
                                                                
                                                            
                                                        )
                                                    }

                                                   
                                                    
                                                    {field?.type === "tel" && 
                                                                    <>
                                                                        <label>{field?.label}</label>
                                                                        <input type="tel" name={field?.name} onChange={handleChange} placeholder={field.placeholder} 
                                                                        onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                        className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                        value={(underWritingData?.[Object.keys(section)[0]]?.[field?.name]) } />
                                                                        <span style={{color : 'red'}}>{field?.name == 'borrowerphone' && underwritingErrors?.[field?.name]}</span>
                                                                    </>
                                                                    

                                                    }
                                                    {field?.type === "email" && 
                                                                    <>
                                                                        <label>{field?.label}</label>
                                                                        <input type="email" name={field?.name} onChange={handleChange} placeholder={field.placeholder} 
                                                                        className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                        value={(underWritingData?.[Object.keys(section)[0]]?.[field?.name]) }  onBlur={handleBlur} />
                                                                    </>
                                                                    

                                                    }
                                                    {field?.type === "date" && (
                                                        <>
                                                                    <label>{field?.label}</label>
                                                                    <input type="date" name={field?.name} onChange={handleChange} placeholder={field.placeholder}
                                                                        className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                        max={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}
                                                                        value={underWritingData?.['abouttheproperty']?.['purchasedate'] ? ( new Date(underWritingData?.['abouttheproperty']?.['purchasedate']).toISOString().split('T')[0]) : ''} />
                                                                </>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                {Object.keys(section)[0] == 'abouttheborrower' && loanUnderwriting?.applicant?.map((row, index) => {
                                    return index != 0 && (<>
                                    <div className="accordion mb_20" id={"abouttheborroweraccordion"+index}>
                                    <div className="accordion-item">
                                    <h2 className="accordion-header" id={"abouttheborrowerheadingThree"+index}>
                                            <button className="accordion-button fw-medium collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#abouttheborrowercollapse"+index} aria-expanded="false" aria-controls={"abouttheborrowercollapse"+index}>
                                            <h4 className="title mb-0">Borrower {index + 1}</h4>
                                            </button>
                                        </h2>
                                        <div id={"abouttheborrowercollapse"+index} className="accordion-collapse collapse " aria-labelledby={"abouttheborrowerheadingThree"+index} data-bs-parent={"abouttheborroweraccordion"+index} style={{
    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
  }}>
                                        <div className="accordion-body">
                                        <div className="row">
                                        {section[Object.keys(section)[0]].map(field => !(underWritingData?.programtype === 'EASY BUILD' && field?.name === "yearbuilt") && (
                                                <div className={"col-md-3"}>
                                                    <div className={` form-group `}>

                                                        {(
                                                            <>
                                                                {(field?.type === "text" && field?.name !== "emptyinput" &&

                                                                    <>
                                                                        {<label>{field?.label}</label>}
                                                                        {floatJsonInputs?.map(ele => (
                                                                            (ele === field?.name) ?
                                                                                <>
                                                                                    <CurrencyInput
                                                                                        onBlur={() => { setIsFocused(false); setLastFieldtName(field?.name) }}


                                                                                        name={field?.name}

                                                                                        decimalsLimit={2}
                                                                                        className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`}
                                                                                        onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }); setIsFocused(true); setLastFieldtName(field?.name) }}
                                                                                        placeholder="$0"
                                                                                        prefix="$"
                                                                                        groupSeparator=","
                                                                                        onValueChange={(value, name, values) => {
                                                                                            handleChangecoborrower(name, value, 'applicant', index);
                                                                                        }}
                                                                                        value={underWritingData?.applicant?.[index]?.[field?.name] || ''}

                                                                                    />
                                                                                </> : ''
                                                                        ))}
                                                                        {!floatJsonInputs.includes(field?.name) &&
                                                                            <>
                                                                                <input
                                                                                    type="text" name={field?.name} onChange={(e)=>handleChangecoborrower(e.target.name, e.target.value, 'applicant', index)} placeholder={field.placeholder}
                                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setLastFieldtName(field?.name); setIsFocused(true); setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                                    value={(field?.name == 'legalname' && underWritingData?.applicant?.[index]?.isborroweranentity == "Yes") ? underWritingData?.entity?.[1]?.['legalname'] : field?.name == 'legalname' ? 'No'  : underWritingData?.applicant?.[index]?.[field?.name] || ''}
                                                                                    onBlur={() => { setIsFocused(false); setLastFieldtName(field?.name) }}

                                                                                />
                                                                            </>

                                                                        }
                                                                    </>
                                                                )}
                                                            </>
                                                        )
                                                        }

                                                        {field?.type === "tel" &&
                                                            <>
                                                                <label>{field?.label}</label>
                                                                <input type="tel" name={field?.name} onChange={(e)=>handleChangecoborrower(e.target.name, e.target.value, 'applicant', index)} placeholder={field.placeholder}
                                                                    onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                    value={underWritingData?.applicant?.[index]?.[field?.name] || ''} />
                                                                <span style={{ color: 'red' }}>{field?.name == 'borrowerphone' && underwritingErrors?.[field?.name]}</span>
                                                            </>

                                                        }
                                                        {field?.type === "email" &&
                                                            <>
                                                                <label>{field?.label}</label>
                                                                <input type="email" name={field?.name} onChange={(e)=>handleChangecoborrower(e.target.name, e.target.value, 'applicant', index)} placeholder={field.placeholder}
                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                    value={underWritingData?.applicant?.[index]?.[field?.name] || ''} onBlur={handleBlur} />
                                                            </>


                                                        }
                                                    </div>
                                                </div>
                                        ))}</div></div></div></div></div></>)
                                }

                                )}
                                {Object.keys(section)[0] == 'abouttheproperty' && loanUnderwriting?.propertyinfo?.map((row, index) => { 
                                    return index != 0 && (<>
                                     <div className="accordion mb_20" id={"aboutthepropertyaccordion"+index}>
                                    <div className="accordion-item">
                                    <h2 className="accordion-header" id={"aboutthepropertyheadingThree"+index}>
                                            <button className="accordion-button fw-medium collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#aboutthepropertycollapse"+index} aria-expanded="false" aria-controls={"aboutthepropertycollapse"+index}>
                                            <h4 className="title mb-0">Subject Property {index + 1}</h4>
                                            </button>
                                            </h2>
                                            <div id={"aboutthepropertycollapse"+index} className="accordion-collapse collapse" aria-labelledby={"aboutthepropertyheadingThree"+index} data-bs-parent={"aboutthepropertyaccordion"+index} style={{
    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
  }}>
                                            <div className="accordion-body">
                                            <div className="row">
                                        {section[Object.keys(section)[0]].map(field => !(underWritingData?.programtype === 'EASY BUILD' && field?.name === "yearbuilt") && (
                                            <>
                                                <div className={"col-md-3"} style={{
    display: ((field.name === 'purchasedate' || field.name === 'currentvalue')  && underWritingData?.loanfor != 'REFINANCE') ? 'none' : 'block'
}}>
                                                    <div className={` form-group `}>

                                                        {(
                                                            <>
                                                                {(field?.type === "text" && field?.name !== "emptyinput" &&

                                                                    <>
                                                                        {<label>{field?.name === "propertyaddress" ? "Property Address":field?.label}</label>}
                                                                        {floatJsonInputs?.map(ele => (
                                                                            (ele === field?.name) ?
                                                                                <>
                                                                                    <CurrencyInput
                                                                                        onBlur={() => { setIsFocused(false); setLastFieldtName(field?.name) }}


                                                                                        name={field?.name}

                                                                                        decimalsLimit={2}
                                                                                        className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`}
                                                                                        onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }); setIsFocused(true); setLastFieldtName(field?.name) }}
                                                                                        placeholder="$0"
                                                                                        prefix="$"
                                                                                        groupSeparator=","
                                                                                        onValueChange={(value, name, values) => {
                                                                                            handleChangecoborrower(name, value, 'propertyinfo', index);
                                                                                        }}
                                                                                        value={underWritingData?.propertyinfo?.[index]?.[field?.name] || ''}
                                                                                        
                                                                                    />
                                                                                </> : ''
                                                                        ))}
                                                                    {!floatJsonInputs.includes(field?.name) &&
                                                                        ((field?.name === "propertyaddress") ? (
                                                                            <div>
                                                                                {mapsKey && 
                                                                                <LocationSearch
                                                                                key={index}
                                                                                simpleAddress={true}
                                                                                name={`coborroweraddress`}
                                                                                setCoBorrowerAddressData={(updateFunc) => {
                                                                                    setCoBorrowerAddressData(prevData => {
                                                                                        const newData = [...prevData];
                                                                                        newData[index] = updateFunc(prevData[index] || {});
                                                                                        return newData;
                                                                                    });
                                                                                }}
                                                                                addressData={coBorrowerAddressData[index] || underWritingData?.propertyinfo?.[index]?.[field?.name] || {}} 
                                                                                selectedPlace={underWritingData?.propertyinfo?.[index]?.[field?.name]}
                                                                                setCoBorrowerSelectedPlace={(place) => {
                                                                                    const newSelectedPlace = [...coBorrowerSelectedPlace];
                                                                                    newSelectedPlace[index] = place;
                                                                                    // console.log('newSelectedPlace',place,newSelectedPlace)
                                                                                    setCoBorrowerSelectedPlace(newSelectedPlace);
                                                                                }}
                                                                                coBorrowerSelectedPlace={coBorrowerSelectedPlace[index] || ''}
                                                                                className={`form-control`}
                                                                            />}
                                                                            
                                                                            </div>) : <>
                                                                            <input
                                                                                type="text" name={field?.name} onChange={(e) => handleChangecoborrower(e.target.name, e.target.value, 'propertyinfo', index)} placeholder={field.placeholder}
                                                                                className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setLastFieldtName(field?.name); setIsFocused(true); setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                                // value={underWritingData?.propertyinfo?.[index]?.[field?.name] || ''}
                                                                                value={(field?.name === "lotsqft" || field?.name === "finishedsqft" || field?.name === "buildingsqft" ) ?
                                                                                    formatNumericInput(underWritingData?.propertyinfo?.[index]?.[field?.name]?.toString() || '')
                                                                                      : underWritingData?.propertyinfo?.[index]?.[field?.name] || ''
                                                                                    }
                                                                                onBlur={() => { setIsFocused(false); setLastFieldtName(field?.name) }}

                                                                            />
                                                                        </>)

                                                                    }
                                                                    </>
                                                                )}
                                                            </>
                                                        )
                                                        }
                                                        {field.type === 'select' && (
                                                            <>
                                                                <label>{field.label}</label>
                                                                <select name={field?.name} onChange={(e)=>handleChangecoborrower(e.target.name, e.target.value, 'propertyinfo', index)}
                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                    value={underWritingData?.propertyinfo?.[index]?.[field?.name] || field?.name == 'loanfor' ? 'REFINANCE' : ''}

                                                                >
                                                                    {field.name !== "propertystate" ? 
                                                                        field.name == "interestreservesloanterm" ? field?.options?.filter(option => option.value <= underWritingData?.loanterm).map((option, key) => (
                                                                            <option key={key} value={option.value}>{option.label}</option>
                                                                        )) : field?.options.map((option, key) => (
                                                                        <option key={key} value={option.value}>{option.label}</option>
                                                                        )) : 
                                                                        <>
                                                                            <option value=''>Select</option>
                                                                            {states.map((key,index) => (<option key={index} value={key}>{key}</option>))}
                                                                        </>
                                                                    }
                                                                </select>
                                                            </>
                                                        )}
                                                        
                                                           
                                                        

                                                        {field?.type === "tel" &&
                                                            <>
                                                                <label>{field?.label}</label>
                                                                <input type="tel" name={field?.name} onChange={(e)=>handleChangecoborrower(e.target.name, e.target.value, 'propertyinfo', index)} placeholder={field.placeholder}
                                                                    onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                    value={underWritingData?.propertyinfo?.[index]?.[field?.name] || ''} />
                                                                <span style={{ color: 'red' }}>{field?.name == 'borrowerphone' && underwritingErrors?.[field?.name]}</span>
                                                            </>

                                                        }
                                                        {field?.type === "email" &&
                                                            <>
                                                                <label>{field?.label}</label>
                                                                <input type="email" name={field?.name} onChange={(e)=>handleChangecoborrower(e.target.name, e.target.value, 'propertyinfo', index)} placeholder={field.placeholder}
                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                    value={underWritingData?.propertyinfo?.[index]?.[field?.name] || ''} onBlur={handleBlur} />
                                                            </>


                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        ))}</div></div></div></div></div></>)
                                }

                                )}
                            </div>
                        
                        
                        ))}
                        {/* <hr>
                        </hr> */}
                     
                         
                       




                        <div className="row">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between"
                                id="PropertyComparison">
                                <h4 className="mb-sm-0 font-size-18 title">Property Comparison</h4>

                            </div>
                            <div className="table-responsive" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                                <table className="table db_table">

                                    <thead className="table-light">
                                        <tr>
                                            <th width="21%">Address</th>
                                            <th width="15%">Price</th>
                                            <th width="10%">BedRooms</th>
                                            <th width="10%">BathRooms</th>
                                            <th width="12%">Square Feet</th>
                                            <th width="14%">Year BUILT</th>
                                            <th width="23%">Comparison Narrative</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                    {underWritingData?.propertycomparisons.map((row,index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className={comparisionsErrors[index] && 'invalid-input'} onFocus={(e) => handleRemoveErrors(index)}>
                                                        {/* <LocationSearch  name="address" className = "form-control" setAddressData={setAddressData} addressData={addressData} setSelectedPlace={setSelectedPlace} selectedPlace = {index === addressIndex[index] ? selectedPlace : ''} placeIndex = {index} 
                                                            addressIndex={addressIndex} setAddressIndex={setAddressIndex}/> */}
                                                       {mapsKey && <LocationSearch 
                                                                setAddressData={setAddressData} 
                                                                addressData={addressData} 
                                                                simpleAddress={true}
                                                                //setSelectedPlace={setSelectedPlace} 
                                                                setSelectedPlace={(place) => handleLocationSearchChange(index, place)}
                                                                //setAddressData={(place) => handleLocationSearchChange(index, place)}
                                                                selectedPlace={underWritingData?.propertycomparisons?.[index]?.address} /> } 
                                                    </div>
                                                    
                                                    
                                                    
                                                    {/* <input type="text" className="form-control" value={row?.address} onChange={e => handleInputChange(index, 'address', e.target.value)} /> */}
                                                </td>
                                                {/* <td><input type="text" className="form-control" value={row['price']}  onChange={e => handleInputChange(index, 'price', e.target.value)} /></td> */}
                                                <td>
                                                    <CurrencyInput 
                                                    //   defaultValue={1000}
                                                    name={"price"}
                                                    decimalsLimit={2}
                                                    className="form-control"
                                                    prefix="$"
                                                    groupSeparator=","
                                                    placeholder="$0.00"
                                                    value={row?.price}
                                                    onValueChange={(value, name , values) => {
                                                        handleCurrencyChange(name,value,index)
                                                    }}
                                                    />
                                                </td>
                                                <td><input type="text" className="form-control" value={row?.['bedroom']} onChange={e => handleInputChange(index, 'bedroom', e.target.value)} /></td>
                                                <td><input type="text" className="form-control" value={row?.['bathroom']} onChange={e => handleInputChange(index, 'bathroom', e.target.value)} /></td>
                                                <td><input type="text" className="form-control" value={(row?.sf) ? formatNumericInput(row?.sf?.toString()) : ''} onChange={e => handleInputChange(index, 'sf', e.target.value)} /></td>
                                                <td><input type="text" name={`yearBuilt-${index}`} className={`form-control ${validateYear(row?.yearbuilt) && 'invalid-input'}`} value={row?.yearbuilt} onChange={e => handleInputChange(index, 'yearbuilt', e.target.value)} /></td>
                                                <td><input type="text" className="form-control" value={row?.comparisonnarrative} onChange={e => handleInputChange(index, 'comparisonnarrative', e.target.value)} /></td>
                                            </tr>
                                        ))};
                                    </tbody>
                                </table>
                            </div>
                            <div style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                                <label className="toggle uwl">
                                    <span className="toggle-label">Use White Label</span>
                                    <input className="toggle-checkbox" checked={underWritingData?.usewhitelabel && underWritingData?.usewhitelabel } onChange={(e) => { setUnderWritingData((prevData) => ({ ...prevData, usewhitelabel: e.target.checked  }))}} type="checkbox" name='usewhitelabel' />
                                    <div className="toggle-switch">
                                        
                                    </div>
                                </label>
                            </div>
                            
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-2 mb-4 underwriting_buttons" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                            <a href="#" onClick={(e)=>{e.preventDefault(); setConfirmationPopUp(true);}} className="fs_btn" >RESET<span
                                    className="material-symbols-outlined">restart_alt</span></a>
                            <div className="btn-group">
                                <a href="#" className="fs_btn" onClick={(e)=>{e.preventDefault(); handleSave()}} >SAVE UNDERWRITING SHEET<span
                                        className="material-symbols-outlined">save</span></a>
                                <a href="#" className="fs_btn" onClick={(e)=>{e.preventDefault(); settermSheetName('TERMSHEET'); setIsDownloadTermSheetClicked(true);}} >DOWNLOAD TERM SHEET PDF<span
                                        className="material-symbols-outlined">download_2</span></a>
                                <a href="#" className="fs_btn" onClick={(e)=>{e.preventDefault(); settermSheetName('UNDERWRITING'); setIsDownloadTermSheetClicked(true);}}>DOWNLOAD UNDERWRITING PDF<span
                                        className="material-symbols-outlined">download_2</span></a>
                                <a href="#" className="fs_btn fill_btn" onClick={(e)=>{e.preventDefault(); sendOriginatorInstructions();}} >SEND ORIGINATOR INSTRUCTIONS<span
                                        className="material-symbols-outlined">send</span></a>
                            </div>

                        </div>
                    </div>


                </div>
                <div className="modal popup new_popup fade add_fee borrower_exposure"
    id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Borrower exposure</h5>
                <button type="button" className="pop_close" id="close" data-bs-dismiss="modal"
                    aria-label="Close"><span className="material-symbols-outlined">close</span></button>
            </div>
            <div className="modal-body">
            <BorrowerExposure isSubcomponent = {true} onLengthChange={handleLengthChange}/>
            </div>
        </div>
    </div>
                </div>
            </div>
            </div>
            <PipelineFooter /> 
        </div>
    );
};

export default DealUnderwritingTermsheet;