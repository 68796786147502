import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoanInfo } from '../utils/loanInfoContext';
import { useSelector} from 'react-redux';
import { getallFees, getAllTasks, getTasksApi} from "../utils/reducer";
import { formatCurrency } from '../utils/commonUtils.js';
import moment from 'moment';
import { hideLoader, showLoader } from '../redux/reducers/Actions.js';
import { usePipeline } from '../utils/PipelineContext.js';
const PipelineDashboard = () => {
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const configdata = useSelector(state => state.configdata);
    console.log('loanInfoloanInfo',loanInfo);
    const [loanData, setLoanData] = useState(loanInfo);
    const navigate = useNavigate();
    const location = useLocation();
    const [fees, setFees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Tasks, setTasks] = useState([]);

    const [totalFee, settotalFee] = useState(0);
    const [FundstoCloseFee, setFundstoCloseFee] = useState(0);
    const [monthsLeft, setMonthsLeft] = useState(loanInfo?.loanterm);
    const [nextDueDate, setNextDueDate] = useState('');
    
    const {usePrevious,route, setPerPage,setCurrentPage, currentPage, perPage, setRoute, usePreviousRoute,advFormData,setAdvFormData ,advFlag, setAdvFlag} = usePipeline();  // Get context values
    const prevRoute = usePreviousRoute();  // Track the previous route
    useEffect(() => {
        console.log('useEffect perPage', perPage)
        console.log('useEffect currentPage', currentPage)
    }, [perPage, currentPage]);
    
    useEffect(() => {
        calculateMonthsLeft();
    }, [loanInfo?.wireinstructions?.actualwire_funddate, loanInfo?.loanterm]);

    const calculateMonthsLeft = () => {
        const fundDate = moment(loanInfo?.wireinstructions?.actualwire_funddate);
        const currentDate = moment();
        const monthsPassed = currentDate.diff(fundDate, 'months');
        const remainingMonths = loanInfo?.loanterm - monthsPassed;
        console.log(monthsPassed,remainingMonths)
        setMonthsLeft(remainingMonths > 0 ? remainingMonths : 0);
    };
    
    useEffect(() => {
        function calculateNextDueDate(firstPaymentDate) {
          const today = moment();
          const dueDate = moment(firstPaymentDate);
    
          let nextDueDate;

          if (dueDate.isAfter(today)) {
            nextDueDate = dueDate.add(1, 'month').startOf('month');
          } else {
            nextDueDate = today.add(1, 'month').startOf('month');
          }
          return nextDueDate;
        }
    
        const calculatedDate = calculateNextDueDate(loanInfo?.cleartoclose?.scheduledfirstpaymentdate);
        setNextDueDate( loanInfo?.cleartoclose?.scheduledfirstpaymentdate ? calculatedDate : '');
      }, [loanInfo?.cleartoclose?.scheduledfirstpaymentdate]);

    useEffect(() => {
        if(loanData){
            fetchFees();
            getTasks()
        }
        let totalFeeFunds = 
    (Number(loanData?.originationFee) || 0) +
    (Number(loanData?.docfee) || 0) +
    (Number(loanData?.titleAmount) || 0) +
    (Number(loanData?.escrow) || 0) +
    (Number(loanData?.insuranceamount) || 0) +
    (Number(loanData?.propertyinfo?.[0]?.valuationfee) || 0);

setFundstoCloseFee(totalFeeFunds);
    }, [loanData]);
    
    useEffect(() => {
        setLoanData(loanInfo);
        
    }, [loanInfo]);

    const giveValue = (percentage) => {
        // Assuming data.feesamount.amount is the total value
        const totalValue = loanData?.loanamount
        if (totalValue && percentage) {
          const calculatedValue = (totalValue * percentage) / 100;
          return calculatedValue;
        } else {
          return 0; // or any default value you prefer
        }
      };

      const getTasks1 = () => {
        getAllTasks(loanData?.loanid).then(function (response) {
            if (response?.result) {
                console.log('response?.resultresponse?.result',response?.result)
                setTasks(response?.result?.data);
            }
        })
            .catch((err) => {
                console.error.bind("my account error", err);
            })
    }
    const getTasks = () => {
        // dispatch(showLoader());
        setLoading(true)
        let PayloadBody = {'projection' : 'tasks'};
        PayloadBody["status"] = 'Incomplete';
        let k = location?.search?.split("=");
        console.log('parseInt(k?.[1])',parseInt(k?.[1]))
        PayloadBody["loanid"] = loanData?.loanid || parseInt(k?.[1]) || "";
    //    PayloadBody["loanid"] = loanData?.loanid ?? location?.pathname;
       
        getTasksApi(PayloadBody).then(function (response) {
            if (response) {
                console.log('getTaskFun response', response);
                let data = response?.result?.data
                setTasks(data);
                setLoading(false)
                // dispatch(hideLoader());
            }
        })
            .catch((err) => {
                setLoading(false)
                console.error("error", err);
                // dispatch(hideLoader());
            });
    };

    const fetchFees = async () => {
        if (loanData?.loanid) {
            try {
                const response = await getallFees(loanData.loanid,'dashboard');
            if (response?.result) {
                setFees(response.result);
                }
            } catch (err) {
                console.error("getallFee error", err);
            }
    }
    };
    useEffect(() => {
        let sum = 0;
        fees.forEach((data) => {
        //   if (data?.feesamount?.type === 'Dollar') {
            if (data?.amount) {
                console.log('amounttt',data?.amount)
            sum += parseFloat(data?.amount);
            }
        //   } else {
        //     // Assuming giveValue function calculates the value based on percentage
        //     sum += parseFloat(giveValue(data?.amount));
        //   }
        });
        console.log('summmm',sum)
        settotalFee(sum);
      }, [fees]);


    return (
        <div id="layout-wrapper" className="dashboard">

            <PipelineHeader />
            <div className="dashboard_block">
            <PipelineStatus />

            
                <div className="dsb_wrp">
                    <div className="dsb_left">
                    <div className="card loan_info">
                                    <div className="dsb_card green">
                                        <h4 className="card-title">LOAN info</h4>
                                        <div className="card_body">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label>Loan Amount</label>
                                                        <p>{loanData?.loanamount ? formatCurrency(loanData?.loanamount) : 'N/A'}</p>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label>Rehab Amount</label>
                                                        <p>{loanData?.propertyinfo?.[0]?.renovationcost ? formatCurrency(loanData?.propertyinfo?.[0]?.renovationcost) : 'N/A'}</p>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label>Interest Rate</label>
                                                        <p>{loanData?.interestrate ? loanData?.interestrate+'%' : 'N/A'}</p>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label>Program Type</label>
                                                        <p>{loanData?.programtype ? loanData?.programtype : 'N/A'}</p>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label>Purchase Price</label>
                                                        <p>{loanData?.propertyinfo?.[0]?.purchaseprice ? formatCurrency(loanData?.propertyinfo?.[0]?.purchaseprice) : 'N/A'}</p>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label>Draw Interest Type</label>
                                                        <p>{loanData?.drawinteresttype ? loanData?.drawinteresttype : 'N/A'}</p>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                    <div className="form-group mb-0">
                                                        <label>Loan term</label>
                                                        <p>{loanData?.loanterm ? loanData?.loanterm  + ' Months': 'N/A'}</p>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                    <div className="form-group mb-0">
                                                        <label>Loan to Cost Ratio (LTC)</label>
                                                        <p>{loanData?.actualltc ? loanData?.actualltc+'%'  : 'N/A'}</p>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                    <div className="form-group mb-0">
                                                        <label>Loan Type</label>
                                                        <p>{loanData?.loantype ? loanData?.loantype : 'N/A'}</p>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-2">
                                                    <div className="form-group mb-0">
                                                        <label>Requested Closing Date</label>
                                                        <p>{loanData?.propertyinfo?.[0]?.constructioncompleteddate ? moment.utc(loanData?.propertyinfo?.[0]?.constructioncompleteddate).format("MM-DD-YYYY") : 'N/A'}</p>
                                                    </div>
                                                    </div>
                                                <div className="col-md-2">
                                                    <div className="form-group mb-0">
                                                        <label>Hold Back Amount</label>
                                                        <p>{loanData?.rehabfunding ? formatCurrency(loanData?.rehabfunding) : 'N/A'}</p>
                                                    </div>
                                                    </div>
                                                    
                                                
                                               
                                                   
                                            </div>

                                        </div>
                                    </div>
                                </div>
                        <div className="dsp_flx">
                            <div className="w_50">
                               
                                <div className="dsp_flx">
                                    <div className="card w_50">
                                        <div className="dsb_card fee">
                                            <h4 className="card-title">FEES</h4>
                                            <div className="card_body">
                                                <ul className="loan_list">
                                                    {fees.map((data,index)=>(
                                                        <li key={index}> <span className='text_wrp'>{data?.feename}</span>  <span className='rslt'>{data?.feein !=='Percent' ? formatCurrency(data?.fee) : formatCurrency(data?.amount)}</span></li>
                                                    ))}
                                                    
                                                </ul>
                                                <p className="total">Total <span>{formatCurrency(totalFee)}</span></p> 

                                            </div>
                                        </div>
                                    </div>
                                    <div className='w_50'>
                                    <div className="card ">
                                            <div className="dsb_card">
                                                <h4 className="card-title">CREDIT INFO</h4>
                                                <div className="card_body">
                                                    <div className='row'>
                                                        <div className='col-md-5'>
                                                            <div className="form-group mb-0">
                                                                <label>Credit Score</label>
                                                                <p> {loanData?.applicant?.[0].creditscore ? <span className="badge">{loanData?.applicant?.[0].creditscore} </span>:'N/A'} </p>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-7'>
                                                            <div className="form-group mb-0"><label>Stated Credit Score</label>
                                                                <p>{loanData?.applicant?.[0].statedcreditscore}</p></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card ">
                              
                              <div className="dsb_card">
                                  <h4 className="card-title">FUNDS TO CLOSE</h4>
                                  <div className="card_body">
                                      <ul className="loan_list">
                                          <li> <span className='text_wrp'> Down Payment</span> <span className='rslt'>{formatCurrency(loanData?.initialequity) || '$0'}</span></li>
                                          <li><span className='text_wrp'>Rehab Equity </span> <span className='rslt'>{formatCurrency(loanData?.rehabequity) || '$0'}</span></li>
                                          <li> <span className='text_wrp'> Debt Servicing </span><span className='rslt'>{formatCurrency( (Number(loanData?.loanterm) || 0) *(Number(loanData?.maximummonthlyinterest) || 0))}</span></li>
                                          <li> <span className='text_wrp'> Fees</span><span className='rslt'>{formatCurrency(FundstoCloseFee) || '$0'}</span></li>
                                      </ul>
                                                    <p className="total">
                                                        Total <span>{formatCurrency(
                                                            (Number(loanData?.initialequity) || 0) +
                                                            (Number(loanData?.rehabequity) || 0) +
                                                            (Number(loanData?.loanterm) || 0) * (Number(loanData?.maximummonthlyinterest) || 0) +
                                                            (Number(FundstoCloseFee) || 0)
                                                        )}</span>
                                                    </p>
                                  </div>
                              </div>
                          </div>
                                    </div>
                             
                                </div>

                            </div>


                            <div className="w_50">
                                {/* <div className="card">
                                    <div className="dsb_card">
                                        <h4 className="card-title">BORROWER info</h4>
                                        <div className="card_body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        {loanData?.applicant?.[0] && (
                                                            <p>
                                                                {loanData.applicant?.[0].borrowerfirstname && loanData.applicant?.[0].borrowerfirstname + ' '}
                                                                {loanData.applicant?.[0].borrowerlastname && loanData.applicant?.[0].borrowerlastname}
                                                            </p>
                                                        )}
                                                    </div>
                                                
                                                </div>
                                               {loanData?.applicant?.[0].isborroweranentity == 'yes' && <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Entity</label>
                                                                {loanData?.applicant?.[0].isborroweranentity == 'yes' &&  <p>{loanData?.applicant?.[0]?.entityname}</p>}
                                                            </div>
                                                        </div>}
                                                <div className="col-md-3">
                                                <div className="form-group mb-0">
                                                        <label>Phone</label>
                                                        <p>{loanData?.applicant?.[0]?.borrowerphone}</p>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                    <div className="form-group mb-0">
                                                        <label>Email</label>
                                                        <p>{loanData?.applicant?.[0]?.borroweremail}</p>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                    <div className="form-group mb-0">
                                                        <label>Address</label>
                                                        <p className='tooltip-container'> <span className="tooltip">{loanData?.applicant?.[0]?.borrowerfulladdress}</span> <span className='adress_elp'>{loanData?.applicant?.[0]?.borrowerfulladdress}</span> </p>
                                                    </div>
                                                    </div>
                      
                                            </div>

                                        </div>
                                    </div>
                                </div> */}
                                <div className="card">
                                    <div className="dsb_card">
                                        <h4 className="card-title">DRAWS</h4>
                                        <div className="card_body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Draw Balance Remaining</label>
                                                        <p><span className="badge">{moment.utc().format("MM-DD-YYYY")} </span>{formatCurrency(loanData?.totaldrawbalance)}</p>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <label>Date and Amount of Last Draw </label>
                                                        <p><span className="badge">{loanData?.lastdrawdate && moment.utc(loanData?.lastdrawdate).format("MM-DD-YYYY")}</span>{formatCurrency(loanData?.lastdrawamount)}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                <div className="form-group circle_chart">
    <label>Project Completion Percentage{}</label>
    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" width="95" height="95" xmlns="http://www.w3.org/2000/svg">
        <circle stroke="#efefef" strokeWidth="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
        {/* Adjust the strokeDasharray dynamically */}
        <circle 
            className="circle-chart__circle" 
            stroke="#B8D3AD" 
            strokeWidth="2" 
            strokeDasharray={`${Math.min(loanData?.projectcompletepercentage ?? 0, 100)}, 100`} // Dynamic strokeDasharray
            strokeLinecap="round" 
            fill="none" 
            cx="16.91549431" 
            cy="16.91549431" 
            r="15.91549431" 
        />
        <g className="circle-chart__info">
            {/* Dynamic percentage text */}
            <text 
                className="circle-chart__percent" 
                x="16.91549431" 
                y="17" 
                alignmentBaseline="central" 
                textAnchor="middle" 
                fontSize="8" 
                fill="#657a8e"
            >
                {Math.min(Math.round(loanData?.projectcompletepercentage ?? 0), 100)}%
            </text>
        </g>
    </svg>
</div>

                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                                <div className="card">
                                    <div className="dsb_card">
                                        <h4 className="card-title">SERVICING</h4>
                                        <div className="card_body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Maturity Date</label>
                                                        <p>{loanInfo?.cleartoclose?.currentmaturitydate ? moment.utc(loanInfo?.cleartoclose?.currentmaturitydate).format("MM-DD-YYYY") : 'N/A'}</p>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <label>Next Payment Amount</label>
                                                        <p>{loanInfo?.nextpaymentamount ? formatCurrency(loanInfo?.nextpaymentamount) : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label>Remaining Term</label>
                                                                <p>{monthsLeft ? monthsLeft + ' Months' : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <div className="form-group">
                                                                <label>Next Payment Due Date</label>
                                                                <p>{loanInfo?.nextpaymentDate ? moment.utc(loanInfo?.nextpaymentDate).format("MM-DD-YYYY") : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-0">
                                                        <label>Date and Amount of Last Payment</label>
                                                        <p> <span className="badge">{loanInfo?.lastpaymentdate ? moment.utc(loanInfo?.lastpaymentdate).format("MM-DD-YYYY") : 'N/A'}</span>{loanInfo?.lastpaymentamount ? formatCurrency(loanInfo?.lastpaymentamount) : 'N/A'}</p>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dsb_right">
                        <div className="img default-img">
                            <img src={loanData?.propertyinfo?.[0]?.propertyplaceimage || '/assets/images/Proparty-img.svg'} alt="" />
                            <div className="top">
                                <span className="badge">{loanData?.dealinfo?.propertytype}</span>
                                <span className="badge"> <span className="sub">APN</span>  {loanData?.propertyinfo?.[0]?.apn}</span>
                            </div>
                            <div className="bottom">
                                <div className="left">
                                    <span className="material-symbols-outlined icon"> explore_nearby </span>
                                    <div className="form-group">
                                        <label>Address</label>
                                        <p>{loanData?.propertyinfo?.[0]?.propertyfulladdress}</p>
                                    </div>
                                </div>
                                <div className="right">
                                    {loanData?.dealinfo?.purchaseprice && <div className="form-group">
                                        <label>Purchase Value</label>
                                        <p>{loanData?.dealinfo?.purchaseprice}</p>
                                    </div>}
                                    <div className="form-group">
                                        <label>As is Value</label>
                                        <p>{loanData?.propertyinfo?.[0]?.easystreetaiv ? formatCurrency(loanData?.propertyinfo?.[0]?.easystreetaiv) :'N/A'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dsb_card">
                            <h4 className="card-title">Outstanding tasks</h4>
                            <div className="card_body">
                                <ul className="loan_list">
                                 {Tasks?.length > 0 ? Tasks?.map((task,index)=>{
                                    const parser = new DOMParser();
                                    const htmlDoc = parser?.parseFromString(task.taskDescription ?? "", 'text/html');
                                    const descriptionText = htmlDoc.body.textContent;
                                     return (
                                        <li key={index}> 
                                            <label className="check_box">{descriptionText ?? ""} 
                                            {/* <input type="checkbox" /> 
                                            <span className="checkmark"></span>  */}
                                            </label>
    
                                            <span className='date'>{moment.utc(task.dueDate).format("MM-DD-YYYY")}</span>
                                            <button type="button" className="btn icon_btn"  onClick={(e)=>{e.preventDefault();
                                            navigate(`/tasks?loanid=${loanData?.loanid}`,{ state: { outstanding:true}} )}}> <span className="material-symbols-outlined icon">arrow_forward</span></button>
                                        </li>)
                                 }
                                    )
                                    : loading ? <img src='assets/images/loader.gif' className='search_spinner' /> : <div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No tasks were found</p></div>}
                                    


                                </ul>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PipelineFooter />




        </div>
    );
};

export default PipelineDashboard;