import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMenus } from "./../redux/reducers/reducer";
import { checkRouteVisibility } from '../utils/permissionsCheck';
import { getUserDataFromLocalStorage } from '../utils/local';
import { useLocation} from 'react-router-dom';
const SideMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const initialMenusdata = useSelector(state => state.menusdata);
    const [menusdata, setMenusdata] = useState([]);

    const handleLogout = () => {
        // Clear the token from local storage
        localStorage.clear();

        // Redirect to the login page
        window.location.href = '/login'; // Replace with your login page route
    };
    useEffect(() => {
        let userData = getUserDataFromLocalStorage();
        console.log('userData2',userData);
        setMenusdata(userData?.securityInfo?.permission);
        console.log('location.pathname',location.pathname)
        if(location.pathname){
        let checkedPermission =  checkRouteVisibility(location.pathname, userData?.securityInfo?.permission) 
          console.log('checkedPermission',checkedPermission);  
          if(!checkedPermission){
            navigate('/pipeline')
          }
        }
        console.log("menus",menusdata);
        //dispatch(getMenus())
    }, []);


    const trimString = (str) => {
      
        return str?.replace(/\s+/g, '').trim();
      };

    return (
        <div className="vertical-menu">
            <div className="h-100">
                <div id="sidebar-menu" className="sub_menu">
                    <ul className="metismenu list-unstyled">
                           
                        {menusdata?.sort((a, b) => a.order - b.order).map((menu, index) => menu?.view && (
                            menu.type === "mainmenu" && menu.submenus ? (
                                <li key={index} className="has-sub">
                                    <a id={`sideMenu-${trimString(menu?.menu)}`} href="#" onClick={(e) => { e.preventDefault(); }} className="waves-effect">
                                        <img src={"assets/images/icons/menu-icons/" + menu.iconName} alt="" className="icon" />
                                        <span key="t-file-manager">{menu.menu}</span>
                                    </a>
                                   
                                    <ul>
                                        {menu?.submenus?.sort((a, b) => a.order - b.order).map((subMenu, subIndex) => subMenu?.view && (
                                            subMenu.submenus ? (
                                                <li key={subIndex}>
                                                    <a href="comingup" onClick={(e) => { e.preventDefault(); }} className="sub_item">
                                                        <span className="icon">
                                                            <img src="assets/images/icons/menu-icons/Lookups_icon.svg" alt="" />
                                                        </span>
                                                        {subMenu.menu}
                                                    </a>
                                                    <span className='sub_menu_arrow material-symbols-outlined'>chevron_right</span>
                                                    <ul className="sub_menu">
                                                        {subMenu.submenus
                                                            // Sort child menus based on the order value
                                                            .sort((a, b) => a.order - b.order)
                                                            .map((childMenu, childIndex) => childMenu?.view &&(
                                                                <li key={childIndex}>
                                                                    <a id={`${trimString(childMenu.menu)}SubMenu`} onClick={(e) => { e.preventDefault(); navigate('/' + childMenu.route); }} href={childMenu.route}>
                                                                        {(subMenu.menu === "Lookups" || subMenu.menu === 'Fund/Investor Management')? (
                                                                            <span className="material-symbols-outlined icon">{childMenu.iconName}</span>
                                                                        ):(<span className="icon">
                                                                            {/* <img src={"assets/images/icons/menu-icons/Lookups_icon.svg"} alt="" /> */}
                                                                            <img src={"assets/images/icons/menu-icons/" + childMenu.iconName} alt="" className="icon" />
                                                                        </span>)}

                                                                        
                                                                        {childMenu.menu}
                                                                        {childMenu.menu === "Company's Fund Report" && (
                                                                            <span className='sub_menu_arrow material-symbols-outlined'>chevron_right</span>
                                                                        )}

                                                                        

                                                                    </a>
                                                                    {childMenu.menu === "Company's Fund Report" && (
                                                                            <ul className="sub_menu" aria-labelledby="fundReportDropdown">
                                                                                {childMenu.submenus.map((innermenu, index) => (
                                                                                    <li key={index}>
                                                                                        <a
                                                                                            href={`${innermenu.route}`}
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                navigate('/' + innermenu.route);
                                                                                            }}
                                                                                            className="sub_item"
                                                                                        >
                                                                                           <span className="material-symbols-outlined icon">radio_button_unchecked</span>
                                                                                            {innermenu.menu}
                                                                                        </a>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </li>
                                                
                                            ) :  subMenu?.view && (
                                                <li key={subIndex}>
                                                    <a id={`${trimString(subMenu.menu)}SubMenu`} href={subMenu.route} onClick={(e) => { e.preventDefault(); navigate('/' + subMenu.route); }} className="sub_item">
                                                        <span className="icon">
                                                            <img src={"assets/images/icons/menu-icons/" + subMenu.iconName} alt="" />
                                                        </span>
                                                        {subMenu.menu}
                                                    </a>
                                                </li>
                                            )
                                        ))}
                                       
                                    </ul>
                                </li>
                            ) : (
                                menu.type === "mainmenu" && menu?.view ? (
                                    <li key={index}>
                                        <a id={`sideMenu-${trimString(menu?.menu)}`} href={menu.route || 'pipeline'} onClick={(e) => { e.preventDefault(); navigate('/' + menu.route); }} className="waves-effect">
                                            <img src={"assets/images/icons/menu-icons/" + menu.iconName} className="icon" alt="" />
                                            <span key="t-chat">{menu.menu}</span>
                                        </a>
                                    </li>
                                ) : null
                            )
                        ))}
                        
                            
                        <li className="logout">
                            <a href="#" id="adminLogout" onClick={(e) => { e.preventDefault(); handleLogout() }} className="logout_btn">
                                <span className="material-symbols-outlined icon">arrow_insert</span>LOG OUT
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SideMenu;