import { GET_CONFIGDATA, START_LOADING, STOP_LOADING, GET_MENUSDATA, GET_LOANSDATA,GET_TOTALCOUNT , SET_USER_URL_ID} from './ActionTypes';
import { getConfigData, getMenusData, getLoansData, hideLoader, getTotalCount} from './Actions';
import {getTokenFromLocalStorage, getUserDataFromLocalStorage} from "../../utils/local";
import axios from "axios";
let initial = {
  configdata: {},
  loaderVisible: false,
  menusdata:[],
  loansdata:[],
  totalcount:10,
  userUrlId : null
}
// export const APIUrl = "https://6kebwwhncl.execute-api.us-east-1.amazonaws.com/latest/";
export const APIUrl = "https://phosekms8b.execute-api.us-east-1.amazonaws.com/latest/";
export const Appname = 'easystreetcapital';
const reducer = (state = initial, action) => {
  // console.log('action.type',action.type, state);
  switch (action.type) {
    case GET_CONFIGDATA:
      return Object.assign({}, state, { configdata: action.configdata });
    case GET_MENUSDATA:
      return Object.assign({}, state, { menusdata: action.menusdata});
    case GET_LOANSDATA:
      return Object.assign({}, state, { loansdata: action.loansdata});
    case GET_TOTALCOUNT:
        return Object.assign({}, state, { totalcount: action.totalcount});
    case START_LOADING:
      return { ...state, loaderVisible : true };
    case STOP_LOADING:
      return { ...state , loaderVisible : false};
    case SET_USER_URL_ID:
      return { ...state, userUrlId: action.urlid };
    default:
      return state;
  }
}
export default reducer;


export const getConfig = () => dispatch => {
  let body = {}
  axios.get(
    APIUrl+"config?appname="+Appname,
    body,
    { type: "application/json" }
  )
    .then(function (response) {
      if (response && response.data) {
        // console.log('here is the', response.data?.result);
        dispatch(getConfigData(response.data?.result))
      }
    }).catch((err) => {
      console.error.bind("getConfig reducer.js error", err);
    })
};

export const getMenus = () => dispatch => {
  let body = {}
  axios.get(
    APIUrl+"menus?appname="+Appname+'&projection=menus',
    body,
    { type: "application/json" }
  )
    .then(function (response) {
      if (response && response.data) {
        // console.log('menusdfsdfsdf', response.data?.result);
        dispatch(getMenusData(response.data?.result))
      }
    }).catch((err) => {
      console.error.bind("getMenus reducer.js error", err);
    })
};

// export const getAllLoans = (pageNum,assetCount,status,setTotalCount, filter) => dispatch => {
//   // console.log('getAllLoansgetAllLoansgetAllLoans');
//   let body = {}
//   let token = getTokenFromLocalStorage();
//   let userData = getUserDataFromLocalStorage();
//   let UserId = userData?.userId;
//   if(filter){
//     body = filter
//   }
//   axios.post(
//     APIUrl+"loans?appname="+Appname+"&token="+token +"&projection=pipeline&status="+(status !== undefined && status !== "" ? status : '')+"&pageNumber=" + (pageNum !== undefined && pageNum !== ""? pageNum : '') + "&assetcount=" + (assetCount !== undefined && assetCount !== "" ? assetCount : '')+"&userid="+UserId,
//     body,
//     { type: "application/json" }
//   )
//     .then(function (response) {
//       // console.log('sdfsfsf',response);
//       if (response && response.data) {

//         dispatch(hideLoader());
//         dispatch(getLoansData(response.data?.result?.data));
//         setTotalCount(response.data?.result?.totalCount)
//       }
//     }).catch((err) => {
//       dispatch(hideLoader());
//       console.error.bind("getMenus reducer.js error", err);
//     })
// };
export const getAllLoansPrevoiousVersion = (pageNum, assetCount, status, setTotalCount, filter = {}, isSavedSearch = false,ApiLevel) => dispatch => {
  let body = {};
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  console.log('ApiLevel------->',ApiLevel)
  console.log('pageNum------->',pageNum)
  console.log('assetCount------->',assetCount)
  // Extract optional parameters for sorting and filtering
  const { sortBy, type, ...restPayload } = filter;

  // Build the request body only if it's a saved search or has filters
  if (isSavedSearch || Object.keys(filter).length > 0) {
    body = restPayload;  // Exclude `sortBy` and `type` from the body if present
  }
  if(body?.status){ delete body['status'] }
  if(body?.type){ delete body['type'];} 

  // Construct the API URL, conditionally adding parameters
  const url = `${APIUrl}loans?appname=${Appname}&token=${token}&userid=${UserId}&projection=pipeline&pageNumber=${pageNum}&assetcount=${assetCount}${sortBy ? `&sortBy=${sortBy}` : ""}${ type && sortBy ? `&type=${type}` : ""}${status ? `&status=${status}` : ""}`;


  // Make the API request
  axios.post(url, body, { type: "application/json" })
    .then(function (response) {
      if (response && response.data) {
        dispatch(hideLoader());
        dispatch(getLoansData(response.data?.result?.data));  // Dispatch loan data to state
        setTotalCount(response.data?.result?.totalCount);  // Update total count
      }
    })
    .catch((err) => {
      dispatch(hideLoader());
      console.error("API error in getAllLoans", err);
    });
};
export const getAllLoans = (pageNum, assetCount, status, setTotalCount, filter = {}, isSavedSearch = false, ApiLevel) => dispatch => {
  let body = {};
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;

  console.log('ApiLevel------->', ApiLevel);
  console.log('pageNum------->', pageNum);
  console.log('assetCount------->', assetCount);

  // Extract optional parameters for sorting and filtering
  const { sortBy, type, ...restPayload } = filter;

  // Build the request body only if it's a saved search or has filters
  if (isSavedSearch || Object.keys(filter).length > 0) {
      body = restPayload;  // Exclude `sortBy` and `type` from the body if present
  }
  if (body?.status) { delete body['status']; }
  if (body?.type) { delete body['type']; }

  // Construct the API URL, conditionally adding parameters
  const url = `${APIUrl}loans?appname=${Appname}&token=${token}&userid=${UserId}&projection=pipeline&pageNumber=${pageNum}&assetcount=${assetCount}${sortBy ? `&sortBy=${sortBy}` : ""}${type && sortBy ? `&type=${type}` : ""}${status ? `&status=${status}` : ""}`;

  // Return a Promise to allow chaining `.then()`
  return new Promise((resolve, reject) => {
      axios.post(url, body, { type: "application/json" })
          .then(function (response) {
            // console.log('response in the reducer........',response)

              if (response && response?.data) {
                // console.log('response in the reducer........',response?.data?.result?.totalCount)
                  dispatch(hideLoader());
                  dispatch(getLoansData(response?.data?.result?.data));  // Dispatch loan data to state
                  dispatch(getTotalCount(response?.data?.result?.totalCount));  // Dispatch loan data to state
                  // setTotalCount(response?.data?.result?.totalCount);  // Update total count
                  
                  resolve(response);  // Resolve the promise with the API response
              }
          })
          .catch((err) => {
              dispatch(hideLoader());
              console.error("API error in getAllLoans", err);
              reject(err);  // Reject the promise with the error
          });
  });
};


export const getAllLoansPipeline = (pageNum, assetCount, status, setTotalCount, filter = {}, isSavedSearch = false, ApiLevel) => dispatch => {
  let body = {};
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;

  console.log('ApiLevel------->', ApiLevel);
  console.log('pageNum------->', pageNum);
  console.log('assetCount------->', assetCount);

  // Extract optional parameters for sorting and filtering
  const { sortBy, type, ...restPayload } = filter;

  // Build the request body only if it's a saved search or has filters
  if (isSavedSearch || Object.keys(filter).length > 0) {
      body = restPayload;  // Exclude `sortBy` and `type` from the body if present
  }
  if (body?.status) { delete body['status']; }
  if (body?.type) { delete body['type']; }

  // Construct the API URL, conditionally adding parameters
  const url = `${APIUrl}loans?appname=${Appname}&token=${token}&userid=${UserId}&loandashboard=loandashboard&projection=pipeline&pageNumber=${pageNum}&assetcount=${assetCount}${sortBy ? `&sortBy=${sortBy}` : ""}${type && sortBy ? `&type=${type}` : ""}${status ? `&status=${status}` : ""}`;

  // Return a Promise to allow chaining `.then()`
  return new Promise((resolve, reject) => {
      axios.post(url, body, { type: "application/json" })
          .then(function (response) {
            console.log('response in the reducer........',response)

              if (response && response?.data) {
                const loansData = response?.data?.result;
                const totalCount = Array.isArray(loansData) ? loansData?.length : 0;
                // console.log('response in the reducer........',response?.data?.result?.totalCount)
                  dispatch(hideLoader());
                  dispatch(getLoansData(response?.data?.result?.data));  // Dispatch loan data to state
                  dispatch(getTotalCount(response?.data?.result?.totalCount));  // Dispatch loan data to state
                  // setTotalCount(response?.data?.result?.totalCount);  // Update total count
                  
                  resolve(response);  // Resolve the promise with the API response
              }
          })
          .catch((err) => {
              dispatch(hideLoader());
              console.error("API error in getAllLoans", err);
              reject(err);  // Reject the promise with the error
          });
  });
};





