import React, { useState, useEffect } from 'react';
import { getallUsers, getallBrokers, getSavedNamedSearchApi, getSavedSearchListApi,categoriesforloan , getSingleSavedUserSearchData , updateSingleSavedUserSearchData} from '../utils/reducer';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector } from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import Select from 'react-select';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CurrencyInput from 'react-currency-input-field';
import { getAllLoans } from '../redux/reducers/reducer';
import { usePipeline } from '../utils/PipelineContext';


const SearchOptions = (props) => {
    const [brokersData, setBrokersData] = useState([]);
    // const[props.advFormData,props.setAdvFormData]=useState({});
    const [NameSearchErr, setNameSearchErr] = useState({});
    const [savedSearchData, setSavedSearchData] = useState({});
    const [clearAlert, setClearAlert] = useState(false);
    const [users, setUsers] = useState([]);
    const [categoriesDropdown , setcategoriesDropdown] = useState([]);

    const [categoryOptions,setCategoryOptions]=useState([
        // { value: '', label: 'Select' },
        { value: 'Any', label: 'Any' },
        { value: '212 Loans', label: '212 Loans' },
        { value: '75%+ Complete', label: '75%+ Complete' },
        { value: 'ABS Due 1/1', label: 'ABS Due 1/1' },
        { value: 'ABS Due 10/1', label: 'ABS Due 10/1' },
        { value: 'ABS Due 2/1', label: 'ABS Due 2/1' },
        { value: 'ABS Due 3/1', label: 'ABS Due 3/1' },
        { value: 'ABS Due 4/1', label: 'ABS Due 4/1' },
        { value: 'ABS Due 5/1', label: 'ABS Due 5/1' },
        { value: 'ABS Due 6/1', label: 'ABS Due 6/1' },
        { value: 'ABS Due 7/1', label: 'ABS Due 7/1' },
        { value: 'ABS Due 8/1', label: 'ABS Due 8/1' },
        { value: 'ABS Due 9/1', label: 'ABS Due 9/1' },
        { value: 'ACH Manual', label: 'ACH Manual' },
        { value: 'ACH Needed', label: 'ACH Needed' },
        { value: 'Audit in Progress', label: 'Audit in Progress' },
        { value: 'Auto Extension Provision', label: 'Auto Extension Provision' },
        { value: 'Bankruptcy', label: 'Bankruptcy' },
        { value: 'BLACKLIST', label: 'BLACKLIST' },
        { value: 'Breached', label: 'Breached' },
        { value: 'Broker', label: 'Broker' },
        { value: 'BSI Boarding Issues', label: 'BSI Boarding Issues' },
        { value: 'Buyback', label: 'Buyback' },
        { value: 'Cross Collateralized', label: 'Cross Collateralized' },
        { value: 'Deferred fees owed to ESC', label: 'Deferred fees owed to ESC' },
        { value: 'Deferred Origination Fee', label: 'Deferred Origination Fee' },
        { value: 'Difficult borrower-draws', label: 'Difficult borrower-draws' },
        { value: 'Do Not Extend', label: 'Do Not Extend' },
        { value: 'Draws get w/ Loan Servicer', label: 'Draws get w/ Loan Servicer' },
        { value: 'Draws to Get W/ Marlee if Req.', label: 'Draws to Get W/ Marlee if Req.' },
        { value: 'DSCR', label: 'DSCR' },
        { value: 'EasyRent', label: 'EasyRent' },
        { value: 'Exception - Interior Photos', label: 'Exception - Interior Photos' },
        { value: 'Extension Review', label: 'Extension Review' },
        { value: 'Extension Review - EF', label: 'Extension Review - EF' },
        { value: 'FCI / Fidelis - Auto Extension Eligible', label: 'FCI / Fidelis - Auto Extension Eligible' },
        { value: 'FCL SCHED.', label: 'FCL SCHED.' },
        { value: 'FPD/EPD', label: 'FPD/EPD' },
        { value: 'FPI', label: 'FPI' },
        { value: 'Insurance Check', label: 'Insurance Check' },
        { value: 'Interest Rate Change Ext.', label: 'Interest Rate Change Ext.' },
        { value: 'Interest Reserve', label: 'Interest Reserve' },
        { value: 'Kirk - ACH Needed', label: 'Kirk - ACH Needed' },
        { value: 'LendSure - Easy Rent', label: 'LendSure - Easy Rent' },
        { value: 'Listed', label: 'Listed' },
        { value: 'Listed for rent', label: 'Listed for rent' },
        { value: 'LO-Gabe', label: 'LO-Gabe' },
        { value: 'LO-Gray', label: 'LO-Gray' },
        { value: 'LO-House', label: 'LO-House' },
        { value: 'LO-Josh Brandt', label: 'LO-Josh Brandt' },
        { value: 'LO-Kelly', label: 'LO-Kelly' },
        { value: 'LO-Marlon Wilson', label: 'LO-Marlon Wilson' },
        { value: 'LO-Scott', label: 'LO-Scott' },
        { value: 'Multiple Props', label: 'Multiple Props' },
        { value: 'New Construction', label: 'New Construction' },
        { value: 'New Western', label: 'New Western' },
        { value: 'NO DRAWS', label: 'NO DRAWS' },
        { value: 'NO DRAWS - KIRK', label: 'NO DRAWS - KIRK' },
        { value: 'NO OTHER LOANS', label: 'NO OTHER LOANS' },
        { value: 'NSF after Sale', label: 'NSF after Sale' },
        { value: 'Over 60% Complete', label: 'Over 60% Complete' },
        { value: 'Past Due; No Draws', label: 'Past Due; No Draws' },
        { value: 'Peer Street', label: 'Peer Street' },
        { value: 'Pending Loan Sale', label: 'Pending Loan Sale' },
        { value: 'Pending Plains Pledge', label: 'Pending Plains Pledge' },
        { value: 'Plains Pledge', label: 'Plains Pledge' },
        { value: 'Prefers CIVIC-NO OTHER LOANS', label: 'Prefers CIVIC-NO OTHER LOANS' },
        { value: 'PT Default', label: 'PT Default' },
        { value: 'Red Flag', label: 'Red Flag' },
        { value: 'Red Flag - Draws', label: 'Red Flag - Draws' },
        { value: 'Red Flag - Servicing', label: 'Red Flag - Servicing' },
        { value: 'Repurchase', label: 'Repurchase' },
        { value: 'SBL', label: 'SBL' },
        { value: 'Slow Draw', label: 'Slow Draw' },
        { value: 'SOLD FIRST TRINITY', label: 'SOLD FIRST TRINITY' },
        { value: 'Spanish Speaker', label: 'Spanish Speaker' },
        { value: 'Suspend ACH', label: 'Suspend ACH' },
        { value: 'Transactional Funding', label: 'Transactional Funding' },
        { value: 'Unpaid Taxes', label: 'Unpaid Taxes' },
        { value: 'UW Guide Exception', label: 'UW Guide Exception' },
        { value: 'UW Rebuttal', label: 'UW Rebuttal' },
        { value: 'Veritex LOC', label: 'Veritex LOC' },
        { value: 'VIP - No Loan Sale', label: 'VIP - No Loan Sale' },
        { value: 'Wong Contact Info at Closing', label: 'Wong Contact Info at Closing' }
    ]);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage,setmessage] = useState('Saved Successfully');
    const [searchDisabled,setSearchDisabled] = useState(true);
    const dispatch = useDispatch();
    const [inputEntryVerification,setInputVerification] = useState({});
    const {  setCurrentPage, currentPage, perPage,} = usePipeline();  
    const [updatedFinalData,setUpdatedFinalData] = useState({});
    const trimString = (str) => {
      
        return str?.replace(/[()]/g, '').replace(/&/g, '').replace(/-/g, '').replace(/\s+/g, '').trim();
    };//removes spaces 

    //const getUserSavedSearchData = props?.savedUserSearchData ? props?.savedUserSearchData : {};
    //const getUserSavedSearchId = props?.savedUserSearchId ? props?.savedUserSearchId : null;
    //console.log("getUserSavedSearchData" , getUserSavedSearchData);

    useEffect(() => {
        getAllUserApiFunction();
        getAllBrokersApiFunction();
        getCategories();
    }, []);
    // // console.log('users',users)

    useEffect(() => {
        if(props?.savedUserSearchId && !props?.displayUnSavedData){
            callgetSingleSavedSearchUser(props?.savedUserSearchId);
        }
        
    },[props?.savedUserSearchId])

    // useEffect(() => {
    //     if (!props?.displayUnSavedData) {
    //         callgetSingleSavedSearchUser(props.savedUserSearchId);
    //     }
    // }, [props?.savedUserSearchId,props?.displayUnSavedData]);
    

    const handleSingleSelect = (selectedOption, fieldName) => {
        const updatedFormData = {
            ...props.advFormData,
            [fieldName]: selectedOption ? selectedOption.value : "",
        };
        props.setAdvFormData(updatedFormData);
    };
    const getCategories = async () => {
        categoriesforloan().then(function (response) {
            if (response?.result) {
                console.log("categories from lookups --->",response?.result?.data[0]?.lookupOptions);
                let data = response?.result?.data[0]?.lookupOptions

                data.sort(function (a, b) {
                    if (a.option < b.option) {
                      return -1;
                    }
                    if (a.option > b.option) {
                      return 1;
                    }
                    return 0;
                  });

                const dropdownOptions = data.map(item => ({
                    value: item.option, 
                    label: item.option
                  }));
                setcategoriesDropdown(dropdownOptions);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };
    const onChangeSearch = (e, fieldName = null) => {
        const { name, value } = e.target;
        if (fieldName === 'category') { 
            const selectedValues = e ? e.map(option => option.value) : [];
            props.advFormData['category'] = selectedValues;
        } else {
            props.advFormData[name] = value;
        }
        // props.advFormData[name] = value
        const updatedObject = { ...props.advFormData };
        for (const key in updatedObject) {
            if (updatedObject.hasOwnProperty(key)) {
                const value = updatedObject[key];
                if (value === '' || (Array.isArray(value) && value.length === 0) || value === undefined) {
                    delete updatedObject[key];
                }
            }
        }
        // console.log('updatedObject', updatedObject)
        props.setAdvFormData(updatedObject)
        
        //for dropdowns input verification
        const dropdownInputs = ['propertytype','stage','phase','historydays','latepaymentstatus',
            'drawstatus','broker'
        ];

        if(dropdownInputs.includes(name)){
            if(value){
                setInputVerification({...inputEntryVerification,[name] : value});
            }else{
                const { [name]: _, ...newState } = inputEntryVerification;
                setInputVerification(newState);
            }
        }else{
            if(name !== 'name'){
                setInputVerification({...inputEntryVerification,[name] : value});
            }
            
        }

    };
    const returnArray = (arr) => {
        let arr2 = []
        arr.map((eachItem) => {
            arr2.push(eachItem.value)
        })
        return arr2
    }
    const handleMultiSelectOld = (selected, key) => {
        let selectedArray = returnArray(selected)
        const updatedObject = { ...props.advFormData, [key]: selectedArray };

        // Iterate through the keys of the object
        for (const key in updatedObject) {
            if (updatedObject.hasOwnProperty(key)) {
                const value = updatedObject[key];

                // Check if the value is an empty string, empty array, or undefined
                if (value === '' || (Array.isArray(value) && value.length === 0) || value === undefined) {
                    // Delete the key from the object
                    delete updatedObject[key];

                }

                //setting to input entry to enable search
                if(value){
                    setInputVerification(prev => ({ ...prev, [key]: value }));
                }else{
                     //delete key from input verification
                     const { [key]: _, ...newState } = inputEntryVerification;
                     setInputVerification(newState);
                }
            }
        }
        props.setAdvFormData(updatedObject);
        
        
    };
    const handleMultiSelect = (selected, key) => {
        const selectedArray = selected ? selected.map(item => item.value) : [];
        const updatedObject = { ...props.advFormData, [key]: selectedArray };
    
        // Cleaning and verification logic
        for (const fieldKey in updatedObject) {
            const value = updatedObject[fieldKey];
            if (
                updatedObject[fieldKey] === '' ||
                (Array.isArray(updatedObject[fieldKey]) && updatedObject[fieldKey].length === 0) ||
                updatedObject[fieldKey] === undefined
            ) {
                delete updatedObject[fieldKey];
            }
               //setting to input entry to enable search
               if(value){
                setInputVerification(prev => ({ ...prev, [key]: value }));
            }else{
                 //delete key from input verification
                 const { [key]: _, ...newState } = inputEntryVerification;
                 setInputVerification(newState);
            }
        }
    
        props.setAdvFormData(updatedObject);
    };
    
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    console.log('advformdata', props.advFormData);
    const getAllUserApiFunction = () => {
        dispatch(showLoader());
        getallUsers().then(function (response) {
            // console.log('privacymenunewwegetallUsers', response.result);
            if (response?.result?.data) {
                // Filter the data to include only active users
                const activeUsers = response.result.data
                .filter(item => item?.securityInfo?.status === "ACTIVE") // Filter active users
                .map(item => ({
                    value: item.userid, // Use userid as the value
                    label: `${item.userInfo.firstname} ${item.userInfo.lastname}`.trim() // Combine firstname and lastname for the label
                }));
                    // console.log('Number of active users:', activeUsers);
                    // console.log('response?.result?.data of active users:', response?.result?.data);
            
                // Transform the filtered usernames into the desired format for setting users
            
                setUsers(activeUsers);
                dispatch(hideLoader());
            }
        })
            .catch((err) => {
                // console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }
    // // console.log('oooooooooo',props.advFormData);
    const getAllBrokersApiFunction = () => {
        dispatch(showLoader());
        getallBrokers().then(function (response) {
            // console.log('get all brokers data', response);
            if (response?.result) {

                setBrokersData(response?.result);
                dispatch(hideLoader());
            }
        })
            .catch((err) => {
                // console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }
    // console.log(brokersData,'brokersData');
    
    const handleSaveToMySavedSearch = () => {
        // if (props.advFormData?.name === undefined || props.advFormData?.name?.trim() === "") {
        //     setNameSearchErr("please enter the name")
        // }
        // else {
        //     if(searchDisabled){
        //         dispatch(showLoader());
        //         setPopupOpen(true);
        //         setmessage("Please select an option or enter a value before saving.");
        //         dispatch(hideLoader());
        //     }else{
        //         getSavedNamedSearchFun(props.advFormData);
        //     }
            
        // }
        if (!props?.savedUserSearchId){
            if (props.advFormData?.name === undefined || props.advFormData?.name?.trim() === "") {
                setNameSearchErr("please enter the name")
            }
            else {
                if(searchDisabled){
                    dispatch(showLoader());
                    setPopupOpen(true);
                    setmessage("Please select an option or enter a value before saving.");
                    dispatch(hideLoader());
                }else{
                    
                    getSavedNamedSearchFun(props.advFormData);
                    
                    
                }
                
            }

            
            
        }else if(props?.savedUserSearchId){
            props?.setNotSavedSearchData(false);
            props?.setDisplayUnSavedData(false);
            callUpdateSingleSavedSearchUser();
        }
        
        
    }
    // console.log(users,'props.users');
    
    // const handleSaveWithoutSearch = () => {
    //     let objData = props.advFormData

    //     if (objData.name) {
    //         delete objData['name']
    //     }
    //     props?.setAdvanceNameSearch('advanceNameSearch');
    //     getSavedNamedSearchFun(objData);
        

    // }

    const handleSaveWithoutSearch = () => {
        if(props?.savedUserSearchId){
           

            // Destructure to exclude the 'name' key from savedUserSearchData
            const { name, ...restOfSavedUserSearchData } = props?.savedUserSearchData;

            // Remove the 'name' key from filters in advFormData
            const updatedFilters = { 
                ...props?.advFormData // Use filters from advFormData
            };
            delete updatedFilters.name; // Delete 'name' key from filters

            // Create updatedAdvFormData without the 'name' key
            const updatedAdvFormData = { 
                ...restOfSavedUserSearchData, // Includes all keys except 'name'
                filters: {
                    ...updatedFilters // Adds/overwrites the filters
                }
            };

            // Logs for verification
            console.log("updated adv form data", props?.advFormData);
            console.log("updatedAdvFormData", updatedAdvFormData);
            console.log("updated saved search ", props?.savedUserSearchData);

            // Update state and trigger handlers
            props?.setNotSavedSearchData(true);
            
            props?.handleSavedSearchData(1, 10, updatedAdvFormData, "list");

        }else{
            let objData = props.advFormData

            if (objData.name) {
                delete objData['name']
            }
            props?.setAdvanceNameSearch('advanceNameSearch');
            getSavedNamedSearchFun(objData);
        }
        
        

    }

    
    // const handleClearSearch = () => {
     
    //     dispatch(showLoader());
        
    //     const clearedObject = { ...props.advFormData };
    //     for (let key in clearedObject) {
    //         delete clearedObject[key];
    //     }
    //     props.setAdvFormData(clearedObject);
    //     setInputVerification({});
    //     setPopupOpen(true);
    //     setmessage('Searched Data Reset successfully');
        
    //     dispatch(hideLoader());
    //     setClearAlert(false);
   
    // }

    const handleClearSearch = () => {
     
        dispatch(showLoader());
        
        const clearedObject = { ...props.advFormData };
        // for (let key in clearedObject) {
        //     // Only delete keys other than 'name' if savedSearchId exists
        //     // if (key === 'name' && props?.savedUserSearchId) {
        //     if (key === 'name' && props?.savedUserSearchId) {
        //         continue;
        //     }
        //     delete clearedObject[key];
        // }

        

        // props.setAdvFormData(clearedObject);
        props.setAdvFormData({});
        setInputVerification({});

        props?.setSavedUserSearchId('');
        props?.setSavedUserSearchData({});
        props?.setSearchSaveUserNum('');
        // props?.advFormData({});
        props?.setIsReset(true);
        setPopupOpen(true);
        setmessage('Searched Data Reset successfully');
        
        dispatch(hideLoader());
        setClearAlert(false);
        
    }

    const getSavedSearchListFun = () => {
        dispatch(showLoader());
        getSavedSearchListApi().then(function (response) {
            // console.log('get all advFormData response', response);
            if (response?.result) {

                props.setSavedSearchData(response?.result?.data);
                dispatch(hideLoader());
            }
        })
            .catch((err) => {
                // console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }
    
    const getSavedNamedSearchFun1 = (data) => {
        dispatch(showLoader());
        getSavedNamedSearchApi(1,10,data).then(function (response) {
          
            let objData = {...props.advFormData}

            if (objData?.name) {
                delete objData['name']
            }
           
            props.setAdvFormData(objData)
            console.log('response?.result---->',response?.result)
            props.setLoans(response?.result?.data)
            props?.setTotalCount(response?.result?.totalCount);
            props?.setShowPage('')
            props?.setAdvFlag(true)
            props?.getSavedSearchListFun();
            props?.setAdvanceNameSearch('advanceNameSearch');
            setPopupOpen(true);
            setmessage('Saved Successfully');
            // dispatch(getSavedSearchListFun());
            // setBrokersData(response?.result);
            dispatch(hideLoader());
            // }
        })
            .catch((err) => {
                // console.error.bind("my account error", err);
                dispatch(hideLoader());
        })
    }
    const getSavedNamedSearchFun = (data) => {
        console.log('Executing Saved Named Search');
        dispatch(showLoader());
    
        // Prepare the payload, removing the "name" if it exists
        let objData = { ...data };
        if (objData?.name) {
            delete objData['name'];
        }
        props.setAdvFormData(objData);
        setCurrentPage(1)
        // Use the unified getAllLoans function to handle saved searches
        dispatch(getAllLoans(1, perPage, '', props.setTotalCount, data, true, "A6"))  // Passing saved search flag and unified API
            .then((response) => {
                console.log('response.result:', response);
                if (response?.data?.result) {
                    props.setLoans(response?.data?.result?.data);  // Update loans data
                    props.setTotalCount(response?.data?.result?.totalCount);  // Set total count
                    props.setShowPage('');
                    props.setAdvFlag(true);
                    props.getSavedSearchListFun();  // Call saved search list function
                    props.setAdvanceNameSearch('advanceNameSearch');
                    setPopupOpen(true);
                    setmessage('Saved Successfully');
                    if(props?.isReset){
                        props?.getSavedSearchListFun();
                    }
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                console.error("Error fetching saved search results", err);
                dispatch(hideLoader());
            });
    };
    const getSavedNamedSearchFun21Oct = (data) => {
        console.log('Executing Saved Named Search');
        dispatch(showLoader());
    
        // Prepare the payload, removing the "name" if it exists
        let objData = { ...data };
        if (objData?.name) {
            delete objData['name'];
        }
        props.setAdvFormData(objData);
    
        // Call getAllLoansFun passed as a prop (from Pipeline.js)
        props.getAllLoansFun(1, 10, '', objData, true);  // Ensure you're passing the correct arguments
    
        // Handle the success actions within useEffect or after the function completes
        // props.setLoans(response?.result?.data);  // Update loans data
        // props.setTotalCount(response?.result?.totalCount);  // Set total count
        props.setShowPage('');
        props.setAdvFlag(true);
        props.getSavedSearchListFun();  // Call saved search list function
        props.setAdvanceNameSearch('advanceNameSearch');
        setPopupOpen(true);
        setmessage('Saved Successfully');
        dispatch(hideLoader());
    };
    
    // const getSavedNamedSearchFunaas = (data) => {
    //     console.log('Executing Saved Named Search');
    //     dispatch(showLoader());

    //     // Prepare the payload, removing the "name" if it exists
    //     let objData = { ...data };
    //     if (objData?.name) {
    //         delete objData['name'];
    //     }
    //     setAdvFormData(objData);

    //     // Use the unified getAllLoans function to handle saved searches
    //     dispatch(getAllLoans(1, 10, '', setTotalCount, objData, true, "A6"))  // Passing saved search flag
    //         .then((response) => {
    //             console.log('response.result:', response?.result);
    //             if (response?.result) {
    //                 setLoans(response?.result?.data);  // Update loans data
    //                 setTotalCount(response?.result?.totalCount);  // Set the total count
    //                 setShowPage('');
    //                 setAdvFlag(true);
    //                 getSavedSearchListFun();  // Call saved search list function
    //                 setPopupOpen(true);
    //                 setmessage('Saved Successfully');
    //             }
    //             dispatch(hideLoader());
    //         })
    //         .catch((err) => {
    //             console.error("Error fetching saved search results", err);
    //             dispatch(hideLoader());
    //         });
    // };
    const getSavedNamedSearchFun33333 = (data) => {
        console.log('Executing Saved Named Search');
        dispatch(showLoader());
    
        // Prepare the payload, removing the "name" if it exists
        // let objData = { ...data };
        // if (objData?.name) {
        //     delete objData['name'];
        // }
        // props.setAdvFormData(objData);
    
        // Use the unified getAllLoans function to handle saved searches

        dispatch(getAllLoans(1, 10, '', props.setTotalCount, data, true, "A6"))  // Pass saved search flag and unified API
            .then((response) => {
                console.log('response>>>>>>>>>>>>>>>>>result:', response);
                let objData = {...props.advFormData}

                if (objData?.name) {
                    delete objData['name']
                }
                props.setAdvFormData(objData)

                console.log('response.result:', response?.result);
                if (response?.result?.data) {
                    props.setLoans(response?.result?.data);  // Update loans data
                    // props.setTotalCount(response?.result?.totalCount);  // Set total count
                    props.setShowPage('');
                    props.setAdvFlag(true);
                    props.getSavedSearchListFun();  // Call saved search list function
                    props.setAdvanceNameSearch('advanceNameSearch');
                    setPopupOpen(true);
                    setmessage('Saved Successfully');
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                console.error("Error fetching saved search results", err);
                dispatch(hideLoader());
            });
    };
    

    const options22 = [
        { label: "Select", value: "" },
        { value: "Single Family Residence (SFR - 1 Unit)", label: "Single Family Residence (SFR - 1 Unit)" },
        { value: "Duplex", label: "Duplex" },
        { value: "Triplex", label: "Triplex" },
        { value: "Fourplex", label: "Fourplex" },
        { value: "5 Units+", label: "5 Units+" },
        { value: "Townhouse or Condo", label: "Townhouse or Condo" },
        { label: "Lot/New Construction", value: "lot_new_construction" }
    ];
    
    const inputFields = [
        { name: 'borrowerfirstname', label: 'Borrower/Guarantor First Name', placeholder: 'Enter' },
        { name: 'borrowerlastname', label: 'Borrower/Guarantor Last Name', placeholder: 'Enter' },
        { name: 'borroweraddress', label: 'Borrower/Guarantor Address', placeholder: 'Enter' },
        { name: 'entityname', label: 'Entity Name', placeholder: 'Enter' },
        { name: 'propertyaddress', label: 'Property Address', placeholder: 'Enter' },
        { name: 'propertytype', label: 'Property Type', placeholder: 'Enter' }
    ];
    
    const inputElements = inputFields.map((field, index) => (
        <div key={index} className="col-md-3">
            <div className="form-group">
                <label>{field.label}</label>
                {field.name === 'propertytype' ? (
                    <select
                        id={`generalInfo-${field.name}`}
                        name={field.name}
                        value={props?.advFormData[field.name] ?? "" }
                        onChange={(e) => onChangeSearch(e)}
                        className="form-control"
                    >
                        {options22.map((option, optIndex) => (
                            <option key={optIndex} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                ) : (
                    <input
                        id={`generalInfo-${field.name}`}
                        type="text"
                        name={field.name}
                        value={props.advFormData[field.name] ?? ""}
                        placeholder={field.placeholder}
                        onChange={(e) => onChangeSearch(e)}
                        className="form-control"
                    />
                )}
            </div>
        </div>
    ));
    
    const handleDateChange = (date, name) => {        
        if (!date) {
            console.log("No date selected");
            return;
        }
        // If date is null (cleared), remove the key from the state object
        // const formattedDate = moment(date).utc().format("YYYY-MM-DD");
        // const formattedDate = moment(date).format("YYYY-MM-DD");
        // const formattedDate = moment(date).startOf('day').format("YYYY-MM-DD");
        const formattedDate = moment(date).format("YYYY-MM-DD"); // No `.utc()`

        const updatedFormData = { ...props.advFormData };
        if (date !== null) {
            updatedFormData[name] =formattedDate;
        } else {
            delete updatedFormData[name];
        }
        props.setAdvFormData(updatedFormData);
        setInputVerification({...inputEntryVerification,[name]:formattedDate});
    };

    const fields = [
        { type: 'date', name: 'created', label: 'Created', from: 'createdfrom', to: 'createdto' },
        { type: 'date', name: 'requested closing', label: 'Requested Closing', from: 'requestclosingfrom', to: 'requestclosingto' },
        { type: 'date', name: 'scheduled closing', label: 'Scheduled Closing', from: 'scheduledclosingfrom', to: 'scheduledclosingto' },
        { type: 'date', name: 'actual closed', label: 'Actual Closed', from: 'actualclosingfrom', to: 'actualclosingto' },
        // { type: 'date', name: 'lead received', label: 'Lead Received', from: 'leadreceivedfrom', to: 'leadreceivedto' },
        { type: 'date', name: 'application', label: 'Application', from: 'applicationfrom', to: 'applicationto' },
        // { type: 'date', name: 'follow-up', label: 'Follow-Up', from: 'followupfrom', to: 'followupto' },
        // { type: 'date', name: 'conversion', label: 'Conversion', from: 'conversionfrom', to: 'conversionto' },
        { type: 'date', name: 'servicing maturity', label: 'Servicing Maturity', from: 'servicingmaturityfrom', to: 'servicingmaturityto' },
        { type: 'number', name: 'ltv', label: 'LTV', min: 'minltv', max: 'maxltv' },
        { type: 'number', name: 'loan amount', label: 'Loan Amount', min: 'loanamountfrom', max: 'loanamountto' }

    ];

    const formFields = fields.map((field, index) => {
        if (field.type === 'date') {
            return (
                <div key={index} className="col-md-4">
                    <div className="form_box">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{field.label} Start</label>
                                    <DatePicker
                                        id={`dateRange-${trimString(field.name)}-start`}
                                        // selected={props.advFormData[field.from] ? new Date(props.advFormData[field.from]) : null}
                                        selected={
                                            props.advFormData[field.from]
                                                ? moment(props.advFormData[field.from], "YYYY-MM-DD").toDate() // Properly converts to local date
                                                : null
                                        }
                                        
                                        onChange={(date) => handleDateChange(date, field.from)}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Select Date"
                                        className="form-control"
                                        icon="fa fa-calendar"
                                        showIcon
                                    />
                                   
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{field.label} End</label>
                                    <DatePicker
                                        id={`dateRange-${trimString(field.name)}-end`}
                                        // selected={props.advFormData[field.to] ? new Date(props.advFormData[field.to]) : null}
                                        selected={
                                            props.advFormData[field.to]
                                                ? moment(props.advFormData[field.to], "YYYY-MM-DD").toDate() // Properly converts to local date
                                                : null
                                        }

                                        onChange={(date) => handleDateChange(date, field.to)}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Select Date"
                                        className="form-control"
                                        icon="fa fa-calendar"
                                        showIcon
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (field.type === 'number') {
            return (
                <div key={index} className="col-md-4">
                    <div className="form_box">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{field.label} Low {field.label=== "LTV" ? "(%)" :""} {field.label=== "Loan Amount" ? "($)":''}</label>
                                    {/* <input
                                                type="number"
                                                name={field.min}
                                                value={props.advFormData[field.min] ?? ""}
                                                placeholder="0.00%"
                                                className="form-control"
                                                onChange={(e) => onChangeSearch(e)}
                                            /> */}
                                    <CurrencyInput
                                        id={`dateRange-${trimString(field.name)}`}
                                        name={field.min}
                                        decimalsLimit={2}
                                        className="form-control"
                                        placeholder="0.00"
                                        groupSeparator=","
                                        suffix={field.label=== "LTV" ? "%" :""}
                                        prefix={field.label=== "Loan Amount" ? "$":''}
                                        // value={props.advFormData[field.min] ?? ""}
                                        value={(props.advFormData[field.min] ?? "").toString()}
                                        onValueChange={(value, name) => onChangeSearch({ target: { name, value } })}

                                    />
                           
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{field.label} High {field.label=== "LTV" ? "(%)" :""} {field.label=== "Loan Amount" ? "($)":''}</label>
                                    {/* <input
                                                type="number"
                                                name={field.max}
                                                value={props.advFormData[field.max] ?? ""}
                                                placeholder="0.00%"
                                                className="form-control"
                                                onChange={(e) => onChangeSearch(e)}
                                            /> */}
                                    <CurrencyInput
                                        id={`dateRange-${trimString(field.name)}`}
                                        name={field.max}
                                        decimalsLimit={2}
                                        className="form-control"
                                        placeholder="0.00"
                                        groupSeparator=","
                                        suffix={field.label=== "LTV" ? "%" :""}
                                        prefix={field.label=== "Loan Amount" ? "$":''}
                                        value={(props.advFormData[field.max] ?? "".toString())}
                                        onValueChange={(value, name) => onChangeSearch({ target: { name, value } })}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    });

    // Render formFields wherever you need in your component
    const StageOptions = ['', 'Any', 'L01. Account Executive Review', 'L05. Underwriting', 'L10. Term Sheet Issued', 'L15. Processing', 'L16. Reviewed/Requested', 'L20. On Hold', 'L25. Docs Out', 'L30. Funded', 'L35. Closed', 'L40. Cancelled', 'L41. Terms Not Issued', 'S05. Servicing Setup', 'S10. Servicing'];


    const PhaseOptions = [ 'Any', 'Lead', 'Pre-Approval', 'Credit Pulled', 'DU Pulled', 'AUS Run', 'Initial Submission', 'Lock Requested', 'Manual Lock Requested', 'Locked', 'Change Lock Requested', 'Lock Withdrawn', 'Set Up', 'Conditions', 'Underwriting', 'Closing', 'Closed', 'Funded', 'Committed to Hedge', 'Committed to Best Effort', 'Closed - Denied', 'Sold', 'Sold - Funds Received', 'Shipped', 'Delivered', 'Clear to Close', 'Suspended', 'Register', 'Cancelled', 'Withdrawn', 'Credit App', 'Credit App Denied', 'Credit App Withdrawn', 'Acquired', 'Use Service Link', 'Closed Update REO and Experience', 'Use TitleEQ'];

    const closePopup = () => {
        setPopupOpen(false);
        if(popupMessage === "Updated Successfully"){
            if(props?.savedUserSearchId){
               
                props?.handleSavedSearchData(1,10,updatedFinalData,"list");
            }
            
            props?.getSavedSearchListFun();
        }
    };

    //checking whether user entered any input , if entered set search disable to false
    const checkInputValueExists = (obj) => {
        console.log('input entry',inputEntryVerification)
        const objKeys = Object.keys(inputEntryVerification);
        if(objKeys?.length > 0){
            
            //return objKeys.some(key => obj[key] && obj[key].length > 0 && obj[key] !== 'Select');
            return objKeys.some(key => {
                const value = obj[key];
                // Check if the value exists, is not 'Select', and is not an empty string
                return value && value !== 'Select' && (typeof value !== 'string' || value.trim().length > 0);
            });
        
        }
        return false;
    }

    useEffect(() => {
       
       const hasSearchInputValues = checkInputValueExists(inputEntryVerification);
       setSearchDisabled(!hasSearchInputValues);
    },[inputEntryVerification])

    // useEffect(() => {
    //     const hasSavedSearchId = !!(props?.savedUserSearchId);
    //     setSearchDisabled(!hasSavedSearchId && !checkInputValueExists(inputEntryVerification));
    // }, [props.savedUserSearchId, inputEntryVerification]);

    //reset search 
    const handleAdvanceSearchReset = () => {
        //if(Object.keys(inputEntryVerification)?.length === 0 && !props.advFormData.name){
        if((Object.keys(inputEntryVerification)?.length === 0 && !props.advFormData.name) && (Object.keys(props?.advFormData)?.length === 0)){
            dispatch(showLoader());
            setPopupOpen(true);
            setmessage('No search data found to Reset');
           
            dispatch(hideLoader());
        }else{
            setClearAlert(true);

        }
        
    }

    const callgetSingleSavedSearchUser = (searchUserId) => {
        dispatch(showLoader());
        getSingleSavedUserSearchData(searchUserId).then(function (response) {
            // console.log('privacymenunewwegetallUsers', response.result);
            if (response?.result[0]) {
                
                console.log("getSingleSavedUserSearchData res",response?.result[0]);
                props?.setSavedUserSearchData(response?.result[0]);
                props?.setAdvFormData(response?.result[0]?.filters);
                props?.setAdvFormData((prevData) => ({
                    ...prevData,
                    "name" : response?.result[0]?.name
                }));
               
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                // console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }

    const callUpdateSingleSavedSearchUser = () => {
        /*
        // let updatedAdvFormData = props?.advFormData;
        const updatedAdvFormData = { ...props?.advFormData }; // Ensure a shallow copy
        // Remove unwanted keys
        const { _id, searchedon, searchedby, status, appname, ...updatedSearchData } = updatedAdvFormData;
        updatedSearchData.filters = {
            ...updatedSearchData.filters, // Existing filters
            ...updatedAdvFormData, // Additional data from updatedAdvFormData
        };
        console.log("updatedAdvFormData data",updatedAdvFormData);
        //console.log("updatedSearchData data",updatedSearchData);
        // if(!updatedSearchData.name){
        //     updatedSearchData.name = props?.savedUserSearchData?.name;
        // }
        console.log("final saved search user data ", updatedSearchData);
        */
        const updatedAdvFormData = { ...props?.advFormData }; // Ensure a shallow copy
            // Remove unwanted keys
            //const { _id, searchedon, searchedby, status, appname, filters , ...updatedSearchData } = updatedAdvFormData;
            // updatedSearchData.filters = {
            //     ...updatedSearchData.filters, // Existing filters
            //     ...updatedAdvFormData, // Additional data from updatedAdvFormData
            // };

            // Extract only 'name' and 'filters' from updatedAdvFormData
            const { name , ...updatedSearchData } = updatedAdvFormData;
            let updatedData = {};
            updatedData.filters = updatedSearchData;
            updatedData.searchid = props?.savedUserSearchId;
            updatedData.name = props?.savedUserSearchData?.name;

            
            console.log("updatedAdvFormData data",updatedAdvFormData);
            //console.log("updatedSearchData data",updatedSearchData);
    
            console.log("final saved search user data ", updatedData);
            dispatch(showLoader());
            updateSingleSavedUserSearchData(props?.savedUserSearchId,updatedData).then(function (response) {
                // console.log('privacymenunewwegetallUsers', response.result);
                if (response?.result) {
                    
                    console.log("update search response --- > ",response?.result);
                    
                    
                    setPopupOpen(true);
                    props?.setSaveSearchClose(true);

                    if(props?.savedUserSearchId){
                        //callUpdateSingleSavedSearchUser();
                        
                        const updatedAdvFormData = { 
                            ...props?.savedUserSearchData ,
                            filters : {
                                ...updatedData.filters
                            }
                        };// Ensure a shallow copy
                        setUpdatedFinalData(updatedAdvFormData);
                        console.log("updated adv form data",props?.advFormData);
                        console.log("updatedAdvFormData",updatedAdvFormData);
                        console.log("updated saved search ",props?.savedUserSearchData);
                        //props?.handleSavedSearchData(1,10,updatedAdvFormData,"list");
                    }
                    setmessage("Updated Successfully");
                    //callgetSingleSavedSearchUser(props?.savedUserSearchId)

                    
                    
                    //props?.getSavedSearchListFun();
                    // const updatedAdvFormData = { ...props?.advFormData };// Ensure a shallow copy
                    // props?.handleSavedSearchData(1,10,updatedAdvFormData,"");


                    //props?.setSavedUserSearchData({});
                    //props?.setAdvFormData({});
                    //props?.setSavedSearchId(null);
                    
                    // Force set searchDisabled to true after successful update
                    //setSearchDisabled(true);
                
                
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                // console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }

    useEffect(() => {
        console.log("isUpdateClicked",props?.isUpdateClicked);
        if(props?.isUpdateClicked){
            //props.setNotSavedSearchData(false); // Reset flag after processing
            console.log("calling updateCriteria ");
           callUpdateSingleSavedSearchUser();
            
        }
       
    },[props?.isUpdateClicked])


    const handleSearchOptionsClose = () => {
        
        // props.setShowPage(''); 
       
        // props.setAdvFormData({}); 
        // props.setfilteredLoans([]);
        // props?.setFilteredStage('Account Executive Review');
        // props.handleClearSearch();
        // props?.setSavedUserSearchData({});
        props.setShowPage(''); 
       
        props.setAdvFormData({}); 
        props.setfilteredLoans([]);
        props?.setFilteredStage('Account Executive Review');
        props?.setSavedUserSearchData({});
        props?.setSavedUserSearchId(null);
        //props?.setSearchSaveUserNum(null);
        props?.setAdvFlag(false);
        props.handleClearSearch();
       
        
    }

    
    return (
        <>
                      {clearAlert &&
                            <div className="modal message_popup popup conditional_popup deletepop_new fade show" id="messagepopup" tabindex="-1"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">

                                        <div className="modal-body">
                                            <button id="searchOptionsClose" type="button" className="close_button" data-bs-dismiss="modal"
                                                aria-label="Close"><span className="material-symbols-outlined" onClick={() => {setClearAlert(false)}}>close</span></button>
                                                
                                            {/* <img src="assets/images/icons/refresh.png" alt="" /> */}
                                            {/* <i className="fa fa-refresh" aria-hidden="true"></i> */}
                                    <span className="material-symbols-outlined icon">
                                        restart_alt
                                    </span>
                                            <h3>Reset</h3>
                                            <p>Are you sure you want to reset this form?
                                            </p>
                                            <button type="button" className="fs_btn pop_btn" onClick={() => handleClearSearch()}>Yes, Reset</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        
                        }
                <div className="full_layout">
                    <div className="user_management">
                        <div className="header_block">


                            <div className="search title_block">
                                <h4 className="title">Search Options</h4>

                                {/* <button id="searchOptionsClose" type="button" className="btn close_btn" onClick={() => { 
                                    props.setShowPage(''); 
                                    props.setAdvFormData({}); 
                                    props.setfilteredLoans([]);
                                    props?.setFilteredStage('Account Executive Review');
                                    props.handleClearSearch();
                                    props?.setSavedUserSearchData({}) 
                                    }}>
                                        <span className="material-icons icon" > close </span>
                                </button> */}
                                 <button id="searchOptionsClose" type="button" className="btn close_btn" 
                                 onClick={() => { 
                                    handleSearchOptionsClose()
                                    }}>
                                        <span className="material-icons icon" > close </span>
                                </button>
                            </div>


                        </div>



                        <div className="form_section">
                            <h3 className="title mt_20">GENERAL INFO</h3>
                            <div className="row">
                       
                                {inputElements}
                               
                                <div className="col-md-3">
                                    <div className="form-group">

                                    </div>
                                </div>
                            </div>
                            <h3 className="title mt_15">Date & Ranges</h3>
                         
                            <div className="row">  {formFields}</div>

                            <h3 className="title">Add Filters</h3>
                            {/* 
                            
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="stage">Stage</label>
                                        <select
                                            className="form-control"
                                            id="stage"
                                            name="stage"
                                            value={props.advFormData?.stage ?? ""}
                                            onChange={(e) => onChangeSearch(e)}
                                        >
                                            <option value="">Select</option>
                                            {props.stageOptionKeys.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {option || 'Select'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="phase">Phase</label>
                                        <select
                                            className="form-control"
                                            id="phase"
                                            name='phase'
                                            value={props.advFormData?.phase ?? ""}
                                            onChange={(e) => onChangeSearch(e)}
                                        >
                                            {PhaseOptions.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {option || 'Select'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>History Days</label>
                                        <select id="historyDays" className="form-control" name="historydays" value={props.advFormData.historydays ?? ""} onChange={(e) => onChangeSearch(e)}>
                                            <option selected="">Select</option>
                                            <option value="10">10 Days</option>
                                            <option value="20">20 Days</option>
                                            <option value="30">30 Days</option>
                                            <option value="40">40 Days</option>
                                            <option value="50">50 Days</option>
                                            <option value="60">60 Days</option>
                                            <option value="70">70 Days</option>
                                            <option value="80">80 Days</option>
                                            <option value="90">90 Days</option>
                                            <option value="100">100 Days</option>
                                        </select>
                                    </div>
                                </div>

                              
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Late Payment Status</label>
                                        <select id="latePaymentStatus" className="form-control" name="latepaymentstatus" value={props.advFormData.latepaymentstatus ?? ""} onChange={(e) => onChangeSearch(e)}>
                                            <option selected="">Select</option>
                                            <option value="Any">Any</option>
                                            <option value="Current">Current</option>
                                            <option value="30">30</option>
                                            <option value="60">60</option>
                                            <option value="90">90</option>
                                            <option value="120+">120+</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Draw Status</label>
                                        <select id="drawStatus" className="form-control" name="drawstatus" value={props.advFormData.drawstatus ?? ""} onChange={(e) => onChangeSearch(e)}>
                                            <option selected="">Select</option>
                                            <option value="Any">Any</option>
                                            <option value="Requested">Draw Requested</option>
                                            <option value="Ordered">Inspection Ordered</option>
                                            <option value="Scheduled">Inspection Scheduled</option>
                                            <option value="Insp. Completed">Inspection Completed</option>
                                            <option value="Verified">Inspection Verified</option>
                                            <option value="Approved">Draw Approved</option>
                                            <option value="Paid">Draw Paid</option>
                                            <option value="Denied">Draw Denied</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="category">Category</label>
                                    
                                        
                                            <Select
                                                inputId ='filterscategory'
                                                isMulti
                                                name="category"
                                                value={props.advFormData?.category && Array.isArray(props.advFormData.category) ? props.advFormData.category?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}
                                                onChange={(e) => handleMultiSelect(e, 'category')}
                                                options={categoryOptions}
                                            />
                                        
                                    </div>
                                </div>
                           
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Broker</label>
                                        <select id="brokerFilters" className="form-control" name="broker" value={props.advFormData.broker ?? ""} onChange={(e) => onChangeSearch(e)}>
                                            <option selected="">Select</option>
                                            {brokersData.map(eachItem => {

                                                return (
                                                    <option key={eachItem?.userid} value={eachItem?.userInfo?.firstname + ' '+ eachItem?.userInfo?.lastname } >{eachItem?.userInfo?.firstname + ' '+ eachItem?.userInfo?.lastname }</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>User</label>
                    
                                        <Select
                                            inputId='filterUser'
                                            name="user"
                                            value={props.advFormData?.user && Array.isArray(props.advFormData.user) ? props.advFormData.user?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}
                                            options={users}
                                            onChange={(e) => handleMultiSelect(e, 'user')}
                                            isMulti
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            */}
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="stage">Stage</label>
                                    <Select
                                        inputId="stage"
                                        name="stage"
                                        value={
                                            props.advFormData?.stage && Array.isArray(props.advFormData.stage)
                                                ? props.advFormData.stage.map((eachItem) => ({ value: eachItem, label: eachItem }))
                                                : []
                                        }
                                        onChange={(selected) => handleMultiSelect(selected, 'stage')}
                                        options={props.stageOptionKeys.map((option) => ({ value: option, label: option }))}
                                        isMulti
                                    />
                                </div>
                            </div>

                            {/* <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="phase">Phase</label>
                                    <Select
                                        inputId="phase"
                                        name="phase"
                                        value={
                                            props.advFormData?.phase && Array.isArray(props.advFormData.phase)
                                                ? props.advFormData.phase.map((eachItem) => ({ value: eachItem, label: eachItem }))
                                                : []
                                        }
                                        onChange={(selected) => handleMultiSelect(selected, 'phase')}
                                        options={PhaseOptions.map((option) => ({ value: option, label: option }))}
                                        isMulti
                                    />
                                </div>
                            </div> */}

                            {/* <div className="col-md-3">
                                <div className="form-group">
                                    <label>History Days</label>
                                    <Select
                                        inputId="historyDays"
                                        name="historydays"
                                        value={
                                            props.advFormData?.historydays && Array.isArray(props.advFormData.historydays)
                                                ? props.advFormData.historydays.map((eachItem) => ({ value: eachItem, label: `${eachItem} Days` }))
                                                : []
                                        }
                                        onChange={(selected) => handleMultiSelect(selected, 'historydays')}
                                        options={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((option) => ({
                                            value: option,
                                            label: `${option} Days`,
                                        }))}
                                        isMulti
                                    />
                                </div>
                            </div> */}
                            <div className="col-md-3">
                                    <div className="form-group">
                                        <label>History Days</label>
                                        <select id="historyDays" className="form-control" name="historydays" value={props.advFormData.historydays ?? ""} onChange={(e) => onChangeSearch(e)}>
                                            <option selected="">Select</option>
                                            {/* <option value="-1">No Limit</option> */}
                                            <option value="10">10 Days</option>
                                            <option value="20">20 Days</option>
                                            <option value="30">30 Days</option>
                                            <option value="40">40 Days</option>
                                            <option value="50">50 Days</option>
                                            <option value="60">60 Days</option>
                                            <option value="70">70 Days</option>
                                            <option value="80">80 Days</option>
                                            <option value="90">90 Days</option>
                                            <option value="100">100 Days</option>
                                            {/* <option>NA</option> */}
                                        </select>
                                    </div>
                                </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Late Payment Status</label>
                                    <Select
                                        inputId="latePaymentStatus"
                                        name="latepaymentstatus"
                                        value={
                                            props.advFormData?.latepaymentstatus && Array.isArray(props.advFormData.latepaymentstatus)
                                                ? props.advFormData.latepaymentstatus.map((eachItem) => ({ value: eachItem, label: eachItem }))
                                                : []
                                        }
                                        onChange={(selected) => handleMultiSelect(selected, 'latepaymentstatus')}
                                        options={[
                                            { value: 'Any', label: 'Any' },
                                            { value: 'Current', label: 'Current' },
                                            { value: '30', label: '30' },
                                            { value: '60', label: '60' },
                                            { value: '90', label: '90' },
                                            { value: '120+', label: '120+' },
                                        ]}
                                        isMulti
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Draw Status</label>
                                    <Select
                                        inputId="drawStatus"
                                        name="drawstatus"
                                        value={
                                            props.advFormData?.drawstatus && Array.isArray(props.advFormData.drawstatus)
                                                ? props.advFormData.drawstatus.map((eachItem) => ({ value: eachItem, label: eachItem }))
                                                : []
                                        }
                                        onChange={(selected) => handleMultiSelect(selected, 'drawstatus')}
                                        options={[
                                            { value: 'Any', label: 'Any' },
                                            { value: 'Requested', label: 'Draw Requested' },
                                            { value: 'Ordered', label: 'Inspection Ordered' },
                                            { value: 'Scheduled', label: 'Inspection Scheduled' },
                                            { value: 'Insp. Completed', label: 'Inspection Completed' },
                                            { value: 'Verified', label: 'Inspection Verified' },
                                            { value: 'Approved', label: 'Draw Approved' },
                                            { value: 'Paid', label: 'Draw Paid' },
                                            { value: 'Denied', label: 'Draw Denied' },
                                        ]}
                                        isMulti
                                    />
                                </div>
                            </div>
                       
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="category">Category</label>
                                    <Select
                                        inputId="filterscategory"
                                        isMulti
                                        name="category"
                                        value={
                                            props.advFormData?.category && Array.isArray(props.advFormData.category)
                                                ? props.advFormData.category.map((eachItem) => ({ value: eachItem, label: eachItem }))
                                                : []
                                        }
                                        onChange={(e) => handleMultiSelect(e, 'category')}
                                        options={categoriesDropdown} // Dynamically populated options
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Broker</label>
                                    <Select
                                        inputId="brokerFilters"
                                        name="broker"
                                        value={
                                            props.advFormData?.broker && Array.isArray(props.advFormData.broker)
                                                ? props.advFormData.broker.map((eachItem) => ({ value: eachItem, label: eachItem }))
                                                : []
                                        }
                                        onChange={(selected) => handleMultiSelect(selected, 'broker')}
                                        options={brokersData.map((eachItem) => ({
                                            value: `${eachItem?.userInfo?.firstname} ${eachItem?.userInfo?.lastname}`,
                                            label: `${eachItem?.userInfo?.firstname} ${eachItem?.userInfo?.lastname}`,
                                        }))}
                                        isMulti
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                    <div className="form-group">
                                        <label>User</label>
                    
                                        <Select
                                            inputId='filterUser'
                                            name="user"
                                            // value={props.advFormData?.user && Array.isArray(props.advFormData.user) ? props.advFormData.user?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}
                                            
                                            value={
                                                props.advFormData?.user && Array.isArray(props.advFormData.user)
                                                    ? props.advFormData.user.map(userId => {
                                                          const user = users.find(u => u.value === userId); // Find matching user
                                                          return user ? { value: user.value, label: user.label } : null;
                                                      }).filter(Boolean) // Filter out null/undefined
                                                    : []
                                            }
                                            options={users}
                                            onChange={(e) => handleMultiSelect(e, 'user')}
                                            isMulti
                                        />
                                    </div>
                                </div>
                        </div>

                        </div>
                        <div className='search_btns'>
                            <div className='left'>      
                                <button id="advancedSearchBtn" type="button" className="create_btn mr_5" style={{'opacity' : searchDisabled ? '0.6' : '1'}} disabled={searchDisabled} onClick={() => handleSaveWithoutSearch()}>Search<span
                                    className="material-symbols-outlined icon">search</span>
                                </button>
                                {/* <button type="button" id="resetAdvancedSearchBtn" className="fillbtn_new" onClick={() => {setClearAlert(true)}}>Reset<span
                                    className="material-symbols-outlined">restart_alt</span>
                                </button> */}
                                <button type="button" id="resetAdvancedSearchBtn" className="fillbtn_new" onClick={handleAdvanceSearchReset}>Reset<span
                                    className="material-symbols-outlined">restart_alt</span>
                                </button>
                            </div>
                            <div className='sts'>
                                <p>Save this Search</p>
                                <div className="form-group">

                                    <input id="saveAdvancedSearch" type="text" placeholder="Enter Name" name="name" 
                                            //value={props.advFormData.name ?? ""} 
                                            //value={props.advFormData.name ??  ""} 
                                            value={(props?.savedUserSearchId) ? 
                                                props.savedUserSearchData?.name 
                                                :props.advFormData.name ??  ""} 
                                            readOnly = {!!props?.savedUserSearchId}
                                            style={{ background: (props?.savedUserSearchId) ? 'rgb(233, 236, 239)' : '' }}
                                            onChange={(e) => onChangeSearch(e)} className={`form-control ${NameSearchErr.length > 0 && 'invalid-input'}`} onFocus={() => { setNameSearchErr("") }} /> 
                                            <span className="material-symbols-outlined icon"> id_card </span>
                                </div>
                                <div className="btn-group">
                                    <button id="saveMySavedSearches" type="button" className="fillbtn_new" onClick={() => handleSaveToMySavedSearch()}>Save to My Saved Searches<span
                                        className="material-symbols-outlined">save</span></button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <>
                    {isPopupOpen && (
                        
                        <PopupMessage

                            type={popupMessage ==  'Saved Successfully' || popupMessage == 'Searched Data Reset successfully' || popupMessage == 'Updated Successfully' ? 'success' : 'warning'}
                            message={popupMessage}
                            onClose={closePopup}
                    
                        />
                    )}
                </>
               
        </>
    
    );
};

export default SearchOptions;