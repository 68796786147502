import React from 'react'
import EmailLogs from './EmailLogs'
import HomeFooter from './HomeFooter.js';
import MainHeader from './MainHeader';
import Loader from './Loader';
import SideMenu from './SideMenu';
import { useDispatch, useSelector } from 'react-redux';

const AdminEmaillogs = (props) => {
    const loading = useSelector((state) => state.loaderVisible);
    const pageType = props?.display;
  return (
    <>
     <>
     <div id="layout-wrapper"> 
{loading && pageType !='pipeline' ? <Loader/> : null}

      <><MainHeader />
      <SideMenu /></>

      <div className="main-content">
        <div className="page-content user_management email-logs"> 
          <EmailLogs/>
        </div> 

    <HomeFooter />
      </div>
    </div> 
    </>
    </>
  )
}

export default AdminEmaillogs