import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate,useLocation } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Blockingtextmsg from './Blockingtext';
import { useLoanInfo } from '../utils/loanInfoContext';
import { useDispatch, useSelector} from 'react-redux';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import SweetAlert from 'react-bootstrap-sweetalert';   
import PopupMessage from './PopupMessage.js';
import CurrencyInput from 'react-currency-input-field';
import { getLoanDetails , saveLoanModification , getLoanModificationData , interestDeleteApi} from "./../utils/reducer";
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';


import Loader from './Loader';
const LoanModifications = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const blockingtext = 'Loan Modifications cannot be accessed until servicing setup gets completed';
    const configdata = useSelector(state => state.configdata);
    let showServicingForms = configdata?.common?.showServicingForms || true;
    const navigate = useNavigate();
    const location = useLocation();
    let userData = getUserDataFromLocalStorage();
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const [errors,setErrors] = useState({});
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [showInputs,setShowInputs] = useState(false);
    const [message , setMessage] = useState('Updated Successfully');
    const [interestErrors,setInterestErrors] = useState({});
    const [maturityErrors,setmaturityErrors] = useState({});
    let interestErr = {};
    let maturityErr = {};
    
    const [audtId,setAuditId] = useState(null);
    const [tabName,setTabName] = useState('loanmodifications');

    const data = [
        {
          id: 1,
          createdby: 'Easy street',
          newapplieddate: '2024-07-26T07:16:08.916Z',
          addedon: '2024-07-26T07:16:08.916Z',
          newinterestrate: '10%',
          originalrate: '12%',
          originaldate : '2024-07-26T07:16:08.916Z',
        }, {
            id: 2,
            createdby: 'Easy street',
            newapplieddate: '2024-07-26T07:16:08.916Z',
            addedon: '2024-07-26T07:16:08.916Z',
            newinterestrate: '12.5%',
            originalrate: '12%',
             originaldate : '2024-07-26T07:16:08.916Z',
          }, {
            id: 3,
            createdby: 'Easy street',
            newapplieddate: '2024-07-26T07:16:08.916Z',
            addedon: '2024-07-26T07:16:08.916Z',
            newinterestrate: '12.9%',
            originalrate: '12%',
             originaldate : '2024-07-26T07:16:08.916Z',
          },
    ];
   
    const [formData,setFormData] = useState([...data]);
    const [maturityData,setMaturityData] = useState({
        "extensionfee":false,
        "addthreemonths":false

    });
    const [maturityTableData,setMaturityTableData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10); // or your desired default value
    const [interestRateData,setInterestRateData] = useState({});
    const [interestTableData,setInterestTableData] = useState([]);
    // const [totalInterestRows, setTotalInterestRows] = useState(0);
    // const [interestPage, setInterestPage] = useState(1);
    // const [interestPerPage, setInterestPerPage] = useState(10); // or your desired default value
    // const [tableData,setTableData] = useState([]);
    // const [coloumnsData,setColoumnsData] = useState(summaryTable);
    const [deleteData, setDeleteData] = useState(null); 
    const [deleteIndex, setDeleteIndex] = useState(null); 
    const [showDelete,setShowDelete] = useState(false);

    useEffect(() => {
        if(loanid){
            getLoanApi();
            //if(maturityTableData?.length === 0 || interestTableData?.length === 0 ){
            getLoanModificationDetails();
            //}
            
        }
      }, [loanid,tabName]);
    useEffect(() => {
        setLoanid(loanInfo?.loanid)
    }, [loanInfo?.loanid])

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.pipelinePermissions
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);


    const getLoanApi = () => {
        dispatch(showLoader());
        getLoanDetails(loanid)
        .then(response => {
        console.log('getLoanDetails api', response);
        if (response?.result[0]) {
            setLoanInfo(response?.result[0]);
            console.log(response);
            dispatch(hideLoader());
        }
        })
        .catch(err => {
        console.error('getLoanDetails api', err);
        dispatch(hideLoader());
        });
    
    };

    const columns = [
        {
            name: '#',
            //selector: (row,index) => index+1,
            selector: (row, index) => (page - 1) * perPage + index + 1, 
          //   sortable: true,
            width: '5%',
        },
          {
            name: 'ORIGINAL RATE',
            selector: (row) => row?.originalrateofinterest || '',
          //   sortable: true,
            width: '15%',
        },
          {
            name: 'ORIGINAL DATE ',
            selector: (row) => row?.originaldateapplied ?  moment.utc(row?.originaldateapplied).format("MM-DD-YYYY") : '',
          //   sortable: true,
            width: '14%',
          },
          
         {
            name: 'NEW APPLIED date',
            selector: (row) => row?.newdateapplied ?  moment.utc(row?.newdateapplied).format("MM-DD-YYYY") : '',
          //   sortable: true,
            width: '14%',
          },
        {
            name: 'NEW INTEREST RATE',
            selector: (row) => row?.newrateofinterest || '',
          //   sortable: true,
            width: '14%',
        },
        {
          name: 'ADDED BY',
          selector: (row) => row?.createdBy?.name || '',
        //   sortable: true,
          width: '14%',
        },
        {
          name: 'ADDED ON',
          selector: (row) => row?.created ?  moment.utc(row?.created).format("MM-DD-YYYY") : '',
        //   sortable: true,
          width: '14%',
        },
        {
            name: 'DELETE',
            cell: (row, index) => {
        
                const originalDate = new Date(row?.originaldateapplied);
                const currentDate = new Date();
        
                // Reset both originalDate and currentDate to midnight to compare only the date
                originalDate.setHours(0, 0, 0, 0);
                currentDate.setHours(0, 0, 0, 0);
        
                // Only show the delete button if the original date is greater than or equal to the current date
                if (originalDate >= currentDate) {
                    return (
                        <div className="btn-group">
                            <button id={`delete-interest-${index}`} type="button" className="btn btn-outline-secondary" >
                                <span className="material-symbols-outlined icon"  onClick={(e) => openDeleteConfirmation(row,index)}> delete </span>
                            </button>
                        </div>
                    );
                } else {
                    return null;
                }
            },
            width: '10%',
        }
        
    ];

    const maturityColumns = [
        {
            name: '#',
            //selector: (row, index) => index + 1,
            selector: (row, index) => (page - 1) * perPage + index + 1, 
            width: '12%',
        },
        {
            name: 'PREVIOUS MATURITY DATE',
            selector: (row) => row?.currentmaturitydate ? moment.utc(row?.currentmaturitydate).format("MM-DD-YYYY") : '',
            width: '22%',
        },
        {
            name: 'NEW MATURITY DATE',
            selector: (row) => row?.newmaturitydate ? moment.utc(row?.newmaturitydate).format("MM-DD-YYYY") : '',
            width: '22%',
        },
        {
            name: 'CHANGED ON',
            selector: (row) => row?.created ? moment.utc(row?.created).format("MM-DD-YYYY") : '',
            width: '22%',
        },
        {
            name: 'CHANGED BY',
            selector: (row) => row?.createdBy?.name || '',
            width: '22%',
        },
        
    ];
    

  
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
                width: '100%', // Set to 100% for full container width
                // maxWidth: '70%', // Set the maximum width you want
                // margin: '0 auto', // Centers the table horizontally
            },
            
        },
    };

    const closePopup = () => {
        setPopupOpen(false);
        //getLoanModificationDetails();
    };

    /* Maturity functionality */

    const formattedDate = (dateStr) => {
        if (!dateStr) return ''; // If dateStr is undefined or empty, return an empty string
        const date = new Date(dateStr);
        
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateStr); 
            return ''; // Handle invalid date case
        }
        
        return date.toISOString().split("T")[0]; // Returns YYYY-MM-DD format
    };

    // const formatISODate = (dateString) => {
    //     if (!dateString) return null; // Return null if dateString is falsy
    //     const date = new Date(dateString);
    //     return date.toISOString(); // Keep the full ISO format with time and timezone
        
    // };

    const formatISODate = (dateString) => {
        if (!dateString) return null; // Return null if dateString is falsy
        try {
            const date = new Date(dateString);
            if (isNaN(date)) {
                console.error("Invalid date format:", dateString);
                return null;
            }
            return date.toISOString(); // Keep the full ISO format with time and timezone
        } catch (error) {
            console.error("Error formatting date:", error, "Input:", dateString);
            return null;
        }
    };

    // const formatISODate = (dateString) => {
    //     if (!dateString) return null; // Return null if dateString is falsy
    
    //     // Check if dateString is in MM-DD-YYYY format
    //     const dateParts = dateString.split('-');
    //     if (dateParts.length === 3) {
    //         const [month, day, year] = dateParts;
    
    //         // Create a new Date object, setting the time to 00:00:00 to avoid timezone issues
    //         const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
            
    //         // Check if the date is valid
    //         if (isNaN(date.getTime())) {
    //             throw new Error("Invalid date value.");
    //         }
    
    //         return date.toISOString(); // Return the date in ISO format
    //     }
    
    //     // If the dateString is already in ISO format or another recognizable format
    //     const date = new Date(dateString);
    //     if (isNaN(date.getTime())) {
    //         throw new Error("Invalid date value.");
    //     }
    
    //     return date.toISOString(); // Keep the full ISO format with time and timezone
    // };
    

    useEffect(() => {
        if (loanInfo) {
            if(maturityData?.currentmaturitydate){
                let newMaturityDate = '';
    
                // Check if the original maturity date exists and is valid
                const isValidcurrentmaturitydate = moment.utc(maturityData?.currentmaturitydate, 'YYYY-MM-DD', true).isValid();
        
                // Add 3 months if the addthreemonths flag is true and the original date is valid
                if (maturityData?.addthreemonths && isValidcurrentmaturitydate) {
                    newMaturityDate = moment.utc(maturityData?.currentmaturitydate)
                        .add(3, 'months')
                        .format('YYYY-MM-DD'); // Format to match the input field requirements
                }
        
                // Validate the declared maturity date from loanInfo
                const isValidDeclaredMaturityDate = moment.utc(loanInfo?.cleartoclose?.declaredmaturitydate, 'YYYY-MM-DD', true).isValid();
        
                setMaturityData((prevData) => ({
                    ...prevData,
                    
                    'newmaturitydate': newMaturityDate || '', // Set the new maturity date or leave it empty if invalid
                }));
            }
           
        }
    }, [loanInfo, maturityData?.addthreemonths]);

    useEffect(() => {
        //if(maturityTableData?.length === 0){

        if(tabName === "interestratechange" && interestTableData?.length === 0){
            //if(tabName === "interestratechange"){
                setInterestRateData((prevData) => ({
                    ...prevData,
                        //"originaldateapplied" : formattedDate(loanInfo?.wireinstructions?.actualwire_funddate),
                        "originaldateapplied": moment.utc(loanInfo?.wireinstructions?.actualwire_funddate).format("MM-DD-YYYY"),
                       
                        "originalrateofinterest":loanInfo?.interestrate,
                    
                }));
            
    
    
        }else if(tabName === "loanmodifications"){
                setMaturityData((prevData) => ({
                    ...prevData,
                    'currentmaturitydate': formattedDate(loanInfo?.cleartoclose?.currentmaturitydate), // Format the original date if valid
                    
                }));
        }
            
            //getLoanModificationDetails();
        //}
        
    },[tabName,loanInfo])
    
    
    const handleTextInputs = (param,e) => {
        let {name, value} = e.target;
        if(param === "maturity"){
            setMaturityData((prevData) => ({
                ...prevData,
               [name] : value
           }));
        }
    }

    const handleDateInputs = (param,e) => {
        let {name, value} = e.target;
        if(param === "maturity"){
            setMaturityData((prevData) => ({
                ...prevData,
               [name] : value
           }));
        }else{
            setInterestRateData((prevData) => ({
                ...prevData,
               [name] : value
           }));
        }
    }

    const handleToggles = (param,e) => {
        let {name, value , checked} = e.target;
        if(param === "maturity"){
            setMaturityData((prevData) => ({
                ...prevData,
               [name] : checked
           }));
        }
        
    }

    const handleFloatValues = (param,name,value) => {
       
        if(param === "maturity"){
            setMaturityData((prevData) => ({
                ...prevData,
               [name] : value
           }));
        }
    }

    const handleNumberInputs = (param,e) => {
        let {name, value} = e.target;
        if(param === "interest"){
            value = (name === "newrateofinterest" && value <= 100) ? value : '';
            setInterestRateData((prevData) => ({
                ...prevData,
               [name] : value
           }));
            
        }else{
            setMaturityData((prevData) => ({
                ...prevData,
               [name] : value
           }));
        }
    }

    const handleInterestValidation = () => {
        /*interestErr = {};
        const requiredKeys = ['newrateofinterest','newdateapplied'];
        
        // Iterate over required keys and validate
        requiredKeys.forEach((key) => {
            const value = interestRateData[key];

            // Check if the value is empty or invalid
            if (!value) {
                interestErr[key] = `${key} is required and must be valid.`;
            }
        });
        setInterestErrors(interestErr);
        return Object.keys(interestErr)?.length === 0;*/
        interestErr = {};
        const requiredKeys = ['newrateofinterest', 'newdateapplied'];

        // Check if at least one of the required keys has a valid, non-empty value
        const isAnyFieldValid = requiredKeys.some((key) => interestRateData[key]);

        // If none of the required keys are valid, add an error message
        if (!isAnyFieldValid) {
            requiredKeys.forEach((key) => {
                interestErr[key] = `${key} is required and must be valid.`;
            });
        }

        setInterestErrors(interestErr);
        return isAnyFieldValid;
    }

    function formatDateToISO(dateString) {
        // Check if dateString is in the correct MM-DD-YYYY format
        const dateParts = dateString.split('-');
        if (dateParts.length !== 3) {
            throw new Error("Invalid date format. Expected MM-DD-YYYY.");
        }
        
        const [month, day, year] = dateParts;
        
        // Create a new Date object, make sure month is 0-indexed
        const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
        
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date value.");
        }
        
        return date.toISOString(); // Return the date in ISO format
    }

    const handleMaturityValidation = () => {
        maturityErr = {};

        if(!maturityData?.newmaturitydate){
            maturityErr["newmaturitydate"] = "required"
        }
        
        if(maturityData?.extensionfee === true){
            
             const extkeys = ["notes","effectiveDate","feeAmount"];

             if(maturityData?.extensionfee === true){
                 extkeys.forEach(key => {
                     if (maturityData[key] == null || maturityData[key] === "") {
                         maturityErr[key] = "required"; // Set error for missing fields
                     }
                 });
             }

        }
        setmaturityErrors(maturityErr);
        return Object.keys(maturityErr).length === 0;
    }
    

    const handleLoanSave = (param) => {

        let valid = false;
        let mvalid = false;
        let data = null;
       
        let paramVal = (param == "maturity") ? "loanmodifications" : "interestratechange";

        if(paramVal == "interestratechange" && showInputs){
            valid = handleInterestValidation();
        }else{
            mvalid = handleMaturityValidation();
        }

        if(paramVal == "loanmodifications") {
            if(mvalid){
                data = {...maturityData,
                    "loanid" : loanid,
                    "newmaturitydate": formatISODate(maturityData?.newmaturitydate),
                    "currentmaturitydate": formatISODate(maturityData?.currentmaturitydate),
                    "effectiveDate": formatISODate(maturityData?.effectiveDate),
                    "feeAmount" : parseFloat(maturityData?.feeAmount)
                };
                if(data?.extensionfee){
                    data.quickFee  = [ "Extension Fee" ];
                }
                console.log("final maturity data",data);
            }
            
           
            
        }else if(paramVal == "interestratechange"){
            if(valid && showInputs){
                
                data = {...interestRateData,
                    "loanid" : loanid,
                    "originalrateofinterest":parseFloat(interestRateData?.originalrateofinterest),
                    "newrateofinterest": (parseFloat(interestRateData?.newrateofinterest)) ? parseFloat(interestRateData?.newrateofinterest) : parseFloat(interestRateData?.originalrateofinterest),
                    "originaldateapplied": (interestTableData?.length > 0) ? formatISODate(interestRateData?.originaldateapplied) : loanInfo?.wireinstructions?.actualwire_funddate,
                    //"newdateapplied": formatISODate(interestRateData?.newdateapplied),
                    "newdateapplied" : interestRateData?.newdateapplied
                    ? formatISODate(interestRateData?.newdateapplied)
                    : formatISODate(interestRateData?.originaldateapplied || loanInfo?.wireinstructions?.actualwire_funddate),
                };
                console.log("final interest data",data);
            }else{
                dispatch(showLoader());
                setPopupOpen(true);
                setMessage("Either New Rate or New Date are required.");
                dispatch(hideLoader());
            }
           
        }
       
       
        if((paramVal == "loanmodifications" && mvalid) || (paramVal == "interestratechange" && showInputs && valid)){
            
            
            dispatch(showLoader());
            saveLoanModification(loanid,paramVal,data).then(function (response) {
                if (response?.result) {
                   console.log('response?.result',response.result);
                    setPopupOpen(true);
                    setMessage("Saved Successfully");
                    getLoanModificationDetails();
                    if(paramVal === "interestratechange" && showInputs){
                        setShowInputs(false);
                    }
                    
                    //getLoanModificationDetails();
                    //dispatch(hideLoader());
                }
                }).catch((err) => {
                  console.error.bind("my account error", err);
                  dispatch(hideLoader());
                })
        }
        
    }

    

    const handleExtensionPageChange = (page) => {
        setPage(page);
       
    };
    
 
    const handleExtensionRowsPerPageChange = (newPerPage, page) => {
        // if(newPerPage > totalRows){
        //     setPage(1);
        // }else{
           
        //     setPage(page); // Reset to first page on change
        // }
        setPage(page); 
        setPerPage(newPerPage);
    };

    useEffect(() => {
        
        getLoanModificationDetails();
        
        
    },[page,perPage])

    // useEffect(() => {
    //     if(tabName === "interestratechange"){
    //     //if(tabName === "interestratechange"){
    //         setInterestRateData((prevData) => ({
    //             ...prevData,
    //                 //"originaldateapplied" : formattedDate(loanInfo?.wireinstructions?.actualwire_funddate),
    //                 "originaldateapplied": moment(loanInfo?.wireinstructions?.actualwire_funddate).format("MM-DD-YYYY"),
                   
    //                 "originalrateofinterest":loanInfo?.interestrate,
                
    //         }));
    //     }


    //     getLoanModificationDetails();
        
       
    // },[tabName])
    
    
    //get loan modification API calling
    const getLoanModificationDetails = () => {
        dispatch(showLoader());
        getLoanModificationData(loanid,tabName,perPage,page)
       .then(function(response) {
            if (response) {
                console.log('getLoanModificationData api response', response);
                if(response?.result?.data){
                  
                   //setPayOffId(response.result[0]?.payoffid);
                   if(tabName === "loanmodifications"){
                    

                    setMaturityTableData(response.result?.data);
                    if(response?.result?.data?.length !== 0){
                        setMaturityData((prevData) => ({
                            ...prevData,
                            'currentmaturitydate': formattedDate(response?.result?.data?.[0]?.newmaturitydate), // Format the original date if valid
                            "extensionfee": false,
                            "addthreemonths": false,
                            "newmaturitydate": "",
                            "effectiveDate": "",
                            "feeAmount": "",
                            "notes": "",
                        }));
                        setTotalRows(response?.result?.totalCount);
                        // if(response?.result?.pageNumber > 1){
                        //     setPerPage(response?.result?.assetcount);
                        //     setPage(response?.result?.pageNumber);
                        // }
                        
                        // setMaturityData({
                        //     //...maturityData,
                        //     "extensionfee": false,
                        //     "addthreemonths": false,
                        //     "newmaturitydate": "",
                        //     "effectiveDate": "",
                        //     "feeAmount": "",
                        //     "notes": "",
                        //     //'currentmaturitydate': formattedDate(loanInfo?.cleartoclose?.currentmaturitydate),
                        //     //"currentmaturitydate": formattedDate(response.result?.data?.[0]?.newmaturitydate),
                            
                        // });
                    }
                    
                   }else{
                        setInterestTableData(response.result?.data);
                        const lastObject = response?.result?.data[0];
                        // Set the original rate and original date with the values from the last object
                        setInterestRateData({
                            originalrateofinterest: lastObject.newrateofinterest,
                            originaldateapplied: moment.utc(lastObject.newdateapplied).format("MM-DD-YYYY")
                        });
                        setTotalRows(response?.result?.totalCount);
                   }
                   
                   //getLoanModificationDetails();
                }
                
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("getLoanModificationData api response", err); 
            dispatch(hideLoader());
        });
    }


    const handleTab = (param) => {
        if(param == "loanmodifications"){
            maturityErr = {};
           
           
            setmaturityErrors(maturityErr);
           
            setMaturityData({
                ...maturityData,
                "extensionfee": false,
                "addthreemonths": false,
                "newmaturitydate": "",
                "effectiveDate": "",
                "feeAmount": "",
                "notes": "",
                    
            });
                
            
        }else{
            interestErr = {};
            setInterestErrors(interestErr);
            setShowInputs(false);
           
            
        }
        setPage(1); 
        setPerPage(10);
        setTabName(param);
    }

    const handleShowInputs =()=>{
        setShowInputs(true);
    };
    const hanldeSaveNewRate =()=>{
        setShowInputs(false);
    }

    const handleFocus = (param,e) => {
        let name = e.target.name;
        if(param === "interest"){
            setInterestErrors({});          
            // setInterestErrors((prevErrors) => ({
            //     ...prevErrors,
            //     [name]: null,  // Reset the error for newrateofinterest
            // }));
        }else{
            setmaturityErrors((prevErrors) => ({
                ...prevErrors,
                [name]: null,  // Reset the error for newrateofinterest
            }));
        }
    }

    // useEffect(() => {
    //     if(tabName === "interestratechange" && loanInfo && interestTableData?.length === 0){
    //         setInterestRateData((prevData) => ({
    //             ...prevData,
    //                 "originaldateapplied": moment(loanInfo?.wireinstructions?.actualwire_funddate).format("MM-DD-YYYY"),
    //                 "originalrateofinterest":loanInfo?.interestrate,
                
    //         }))
    //     }
       
    // },[tabName])

    useEffect(() => {
        if(!showInputs){
            getLoanModificationDetails();
        }
    },[showInputs])

    const openDeleteConfirmation = (row, index) => {
        setDeleteData(row); // Save the data for deletion
        setDeleteIndex(index); // Save the index for deletion
        setShowDelete(true); // Show the confirmation popup
    };

    const handleDelete = (data,index) => {
        setShowDelete(false);
        dispatch(showLoader());
        interestDeleteApi(loanid,data.auditid)
       .then(function(response) {
            if (response) {
                console.log('deleteLoanModificationData api response', response);
                if(response?.result){
                  setPopupOpen(true);
                  setMessage('Deleted Succesfully');
                  getLoanModificationDetails();
                }
                
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("deleteLoanModificationData api response", err); 
            dispatch(hideLoader());
        })
        //  const updatedData = interestTableData.filter(item => item.audit !== data.auditid);
        
        //  // Update state with the filtered data
        //  setInterestRateData(updatedData);
        
         //setShowDelete(true);
        
    }

    const closePopup2 = () => {
        
        setShowDelete(false);
        getLoanModificationDetails();
    };

    const handleLoanClear = (param) => {
        if (param === "interestratechange") {
            setInterestRateData((prevData) => ({
                ...prevData,
                newrateofinterest: "",
                newdateapplied: "",
            }));
    
            // Optional: log the state after clearing to verify
            setTimeout(() => console.log("Cleared interestRateData:", interestRateData), 0);
        }else {
            setMaturityData((prevData) => ({
                ...prevData,
                newmaturitydate: "",
                extensionfee : false,
                addthreemonths : false,
                effectiveDate : "",
                feeAmount : "",
                notes : ""
            }));
            
    
            setTimeout(() => console.log("Cleared maturityData:", maturityData), 0);
        }
    };
    
    
    

    


    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader /> : null}
            {isPopupOpen && (
                <PopupMessage
                    type={message === "Either New Rate or New Date are required."? 'warning' : 'success'}
                    message={message}
                    onClose={closePopup}
                />
            )}
             {showDelete && (
                <SweetAlert
                    warning
                    showCancel
                    confirmButtonText="Yes, delete it!"
                    cancelButtonText="No, cancel!"
                    title="Are you sure?"
                    onConfirm={() => handleDelete(deleteData, deleteIndex)} // Pass the correct data and index
                    onCancel={closePopup2}
                    focusCancel={true}
                    customButtons={[
                        React.createElement('button', {
                            id: 'delete-interest-yes',
                            className: 'btn btn-primary',
                            onClick: () => handleDelete(deleteData, deleteIndex), // Ensure correct data is passed
                            style: { marginRight: '8px', fontSize: '16px' }
                        }, 'Yes'),
                        React.createElement('button', {
                            id: 'delete-interest-no',
                            className: 'btn btn-light',
                            onClick: closePopup2,
                            style: { fontSize: '16px' }
                        }, 'No')
                    ]}
                >
                    <p>Do you really want to delete this Record?</p>
                </SweetAlert>
            )}

                <PipelineHeader />
            <div className="dashboard_block">
            <PipelineStatus />

            <h2 className="form_title">Servicing <span className="space">&#10095;</span>  <span className="sub">Loan Modifications</span> </h2>
            {!loanInfo?.servicingsetup?.isServiceSetupCompleted && !configdata?.common?.showServicingForms ? 
            <Blockingtextmsg  textmessage={blockingtext}/> :
            <div className="card user_management detailed_app services_form pay_off">
                    <div className="card-body my_account">

                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a id="loanMaturityTab" className="nav-link active" data-bs-toggle="tab" href="#MATURITY" role="tab" onClick={() => handleTab('loanmodifications')}>
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">MATURITY</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="loanInterestTab" className="nav-link " data-bs-toggle="tab" href="#INTEREST" role="tab" onClick={() => handleTab('interestratechange')}>
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">INTEREST</span>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content pt-15 text-muted">

                            <div className="tab-pane active" id="MATURITY" role="tabpanel" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                            <div className="form_section mt-3">
                                <div className="row">

                                    <div className="col-md-3"><h3 className="title">ORIGINAL Maturity DATE</h3></div>
                                    <div className="col-md-3"></div>
                                    <div className="col-md-3"><h3 className="title">NEW Maturity DATE</h3></div>
                                    <div className="col-md-3"></div>
                                </div>
                        
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Original Maturity Date</label>
                                            <input type="date" 
                                                name="currentmaturitydate" 
                                                className={`form-control`} 
                                                value={maturityData?.currentmaturitydate} 
                                                onChange={(e) => handleDateInputs('maturity',e)}
                                                readOnly
                                                style={{background:'rgb(233, 236, 239)'}}
                                                id="maturityTabDate"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 interest_only">
                                    <div className="form-group">
                                                    <label></label>
                                                    <label className="toggle mb-0 mt-1">
                                                        <span className="toggle-label">ADD 3 month to term</span>
                                                        <input id="maturityMonthsTerm" className="toggle-checkbox" type="checkbox" name="addthreemonths" checked={maturityData?.addthreemonths} onChange={(e) => handleToggles('maturity',e)}/>
                                                        <div className="toggle-switch"></div>
                                                    </label>
                                                </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>New Maturity Date</label>
                                            
                                            <input type="date" inputmode="decimal" name="newmaturitydate" id="newMaturityTabDate"
                                                value={maturityData?.newmaturitydate} 
                                                onChange={(e) => handleDateInputs('maturity',e)}
                                                readOnly = {(maturityData?.addthreemonths) ? true : false}
                                                style={{ background: (maturityData?.addthreemonths) ? 'rgb(233, 236, 239)' : ''}}
                                                className={`form-control ${maturityErrors?.newmaturitydate && 'invalid-input'}`}
                                                onFocus={(e) => handleFocus("maturity",e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 interest_only">
                                        <div className="form-group">
                                           <label></label>
                                            <label className="toggle mb-0 mt-1">
                                                <span className="toggle-label">Add Extension Fee</span>
                                                <input id="maturityTabExtensionFee" className="toggle-checkbox" name="extensionfee" type="checkbox" checked={maturityData?.extensionfee} onChange={(e) => handleToggles('maturity',e)}/>
                                                <div className="toggle-switch"></div>
                                            </label>
                                         </div>
                                    </div>

                                    {maturityData?.extensionfee && 
                                        <>
                                             <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Effective Date</label>
                                                    <input type="date"
                                                        id="maturityTabEffectiveDate"
                                                        name='effectiveDate' 
                                                        className={`form-control ${maturityErrors?.effectiveDate && 'invalid-input'}`}
                                                        onFocus={(e) => handleFocus("maturity",e)}
                                                    // className={`form-control ${feeFormError?.effectiveDate && 'invalid-input'}`}
                                                    // onFocus={handleFocus}
                                                    // min={loanInfo?.wireinstructions?.actualwire_funddate 
                                                    //     ? new Date(loanInfo?.wireinstructions?.actualwire_funddate).toISOString().split('T')[0] 
                                                    //     : ''}
                                                    // value={feeValue.effectiveDate ? new Date(feeValue.effectiveDate ).toISOString().split('T')[0] : ''}
                                                    // value={feeValue?.effectiveDate || ''}
                                                        
                                                        value={maturityData?.effectiveDate} 
                                                        onChange={(e) => handleDateInputs('maturity',e)}
                                                        
                                                        //onChange={handleChange}
                                                        />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Fee Amount</label>
                                                    <CurrencyInput
                                                            id="maturityTabFeeAmount"
                                                            name='feeAmount'
                                                            className={`form-control ${maturityErrors?.feeAmount && 'invalid-input'}`}
                                                            onFocus={(e) => handleFocus("maturity",e)}
                                                            // className={`form-control ${feeFormError?.feeAmount && 'invalid-input'}`}
                                                            // onFocus={handleFocus}
                                                            placeholder='$0.00'
                                                            value={maturityData?.feeAmount}
                                                            decimalsLimit={2}
                                                            prefix="$"
                                                            onValueChange={(value, name, values) => {
                                                                handleFloatValues('maturity',name,value)
                                                            }}
                                                        />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Notes</label>
                                                    <input 
                                                        id="maturityTabNotes"
                                                        type="text" 
                                                        name='notes'
                                                        className={`form-control ${maturityErrors?.notes && 'invalid-input'}`}
                                                        onFocus={(e) => handleFocus("maturity",e)}
                                                        //className={`form-control ${feeFormError?.notes && 'invalid-input'}`}
                                                        // onFocus={handleFocus}
                                                        value={maturityData?.notes} 
                                                        onChange={(e) => handleTextInputs('maturity',e)}  
                                                        placeholder="Enter the notes"/>
                                                </div>
                                            </div>
                                        </>
                                   }
                                    

                                </div>
                                <div className="col-md-12 mt-2 mb-2 mt-3">
                                   <div className="form-group d-flex">
                                       <button id="maturityTabSave" className="add_button fillbtn_new d-inline-block" onClick={(e) => handleLoanSave('maturity')}>SAVE</button>
                                       <button id="maturityTabClear" onClick={() => handleLoanClear("maturity")} className ="fs_btn" style={{fontSize : '14px' , padding : '9px 20px' , fontWeight : '500'}}>Clear</button>
                                   </div>
                               </div>
                               <h3 className="title mt-4">extension history</h3>
                                    <div className="table-responsive">
                                        
                                        <DataTable 
                                        // key={tableKey}
                                            columns={maturityColumns}
                                            data={maturityTableData}
                                            //pagination
                                            customStyles={customStyles}
                                            persistTableHead
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationPerPage={perPage}
                                            paginationDefaultPage={page}
                                            onChangePage={handleExtensionPageChange}
                                            onChangeRowsPerPage={handleExtensionRowsPerPageChange}
                                            paginationComponentOptions={{
                                                rowsPerPageText: 'Per Page',
                                            }}
                                            //customStyles={customStyles}
                                        />

                                        

                                    </div>

                                
                        </div>
                                
                            </div>


                            <div className="tab-pane" id="INTEREST" role="tabpanel" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                                <div className="form_section mt-3">
                                    <h3 className="title">INTEREST RATE CHANGE</h3>
                                    <div className="row mb-4">
                                        <div className="col-md-2">
                                                <h5>Original Rate</h5>
                                                <p>{(interestRateData?.originalrateofinterest) ? 
                                                    interestRateData?.originalrateofinterest : 
                                                    parseFloat(loanInfo?.interestrate)}%
                                                </p>  
                                            </div>
                                            <div className="col-md-2">
                                                <h5>Original Date Applied</h5>
                                                <p>{(interestTableData?.length > 1) ? 
                                                    interestRateData?.originaldateapplied : 
                                                    moment.utc(loanInfo?.wireinstructions?.actualwire_funddate).format("MM-DD-YYYY")}
                                                </p>
                                                
                                            </div>
                                            <div className="col-md-2">
                                                <span onClick={handleShowInputs} id='showpayoffinput' className="material-symbols-outlined" style={{'cursor':'pointer'}}>edit_square</span>
                                            </div>
                                            
                                    </div>
                          

                                    {showInputs && 
                                    <div className="row  mb-4">
                                            <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>New Rate(%)</label>
                                                    <input id="newRate" type="number" inputmode="decimal" name="newrateofinterest"  placeholder="Enter the New Rate" 
                                                    value = {interestRateData?.newrateofinterest}
                                                    onChange={(e) => handleNumberInputs('interest',e)} className={`form-control ${interestErrors?.newrateofinterest && 'invalid-input'}`}
                                                    onFocus={(e) => handleFocus("interest",e)}/>
                                                </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>New Date Applied</label>
                                                        <input id="newDateApplied" type="date" inputmode="decimal" name="newdateapplied" value = {interestRateData?.newdateapplied}
                                                        onFocus={(e) => handleFocus("interest",e)} className={`form-control ${interestErrors?.newdateapplied && 'invalid-input'}`} onChange={(e) => handleDateInputs('interest',e)}/>
                                                    </div>
                                                </div>
                                                
                                            <div className="col-md-1 hide_lable">
                                                <div className="form-group">
                                                    <label>a</label>
                                                    <div className='d-flex'>
                                                        <button id="saveInterest" onClick={() => handleLoanSave("interestratechange")} className="add_button fillbtn_new d-inline-block">SAVE</button>
                                                        <button id="clearInterest" onClick={() => handleLoanClear("interestratechange")} className="fs_btn">Clear</button>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                    </div>
                                    }
                            
                               
                                    <div className="right">
                                        <h3 className="title">RATE CHANGE HISTORY</h3>
                                        <div className="table-responsive">
                                            <DataTable 
                                            // key={tableKey}
                                                columns={columns}
                                                data={interestTableData}
                                                //pagination
                                                customStyles={customStyles}
                                                persistTableHead
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                paginationPerPage={perPage}
                                                paginationDefaultPage={page}
                                                onChangePage={handleExtensionPageChange}
                                                onChangeRowsPerPage={handleExtensionRowsPerPageChange}
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Per Page',
                                                }}
                                                //customStyles={customStyles}
                                            />
                                           


                                        </div>
                                    </div>
                                </div>
                             

                            </div>

                        </div>

                    </div>
                    
                </div>
            }  
            </div>
            <PipelineFooter />
        </div>
    );
};

export default LoanModifications;