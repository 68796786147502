import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate,useLocation  } from 'react-router-dom';
import {getTermSheetRefills, deleteTermSheets} from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import SweetAlert from 'react-bootstrap-sweetalert';   
import PopupMessage from './PopupMessage.js';
import HomeFooter from './HomeFooter';
import DataTable from 'react-data-table-component';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';

const TermSheetRefills = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.loaderVisible);
    const [termsSheetRefils, setTermsSheetRefils] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [showConfirmation,setShowConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalcount,setTotalCount] = useState(null);
    const [currentPage,setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    let userData = getUserDataFromLocalStorage();
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const location = useLocation();

    useEffect(() => {
      if(location.pathname){
          const route = location.pathname;
          const cleanRoute = route.slice(1);
          let permission = userData?.securityInfo?.permission
         
          let checkedPermission =  hasViewPermission(cleanRoute, permission) 
            console.log('viewPermission',location.pathname,checkedPermission); 
            setPipelinePermissions(checkedPermission) 
          //   if(!checkedPermission){
          //     navigate('/pipeline')
          //   }
          }
  
          if(location.pathname){
              const route = location.pathname;
              const cleanRoute = route.slice(1);
              let permission = userData?.securityInfo?.permission
             
              let editPermission =  hasEditPermission(cleanRoute, permission) 
                console.log('editPermission',location.pathname,editPermission); 
                setEditPermissions(editPermission) 
              //   if(!checkedPermission){
              //     navigate('/pipeline')
              //   }
              }
          //setViewStatus()
    }, []);


      useEffect(() => {
        
        getTermSheets(currentPage,perPage);
      }, [currentPage,perPage]);

      const getTermSheets = ()=>{
        dispatch(showLoader());
      getTermSheetRefills(currentPage,perPage).then(function (response) {
        if (response?.result) {
            console.log("get termsheet api called",response?.result);
            setTermsSheetRefils(response?.result);
            setTotalCount(response?.result?.totalCount);
        }
        dispatch(hideLoader());
        }).catch((err) => {
          dispatch(hideLoader());
          console.error.bind("my account error", err);
        })
    }

    /* data table */

    const handleSearchChange = (e) => {
        console.log('serach term',e.target.value);
        setSearchTerm(e.target.value);
    }; 
    
    const filteredTermsheets = termsSheetRefils?.data?.filter((sheet) =>
        sheet?.interestType?.toLowerCase()?.trim().includes(searchTerm?.toLowerCase()?.trim()) ||
        sheet?.programtype?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        sheet?.state?.join(',')?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const editSheet = (data) => {
        console.log('data',data)
        navigate('/createtermsheets', { state: { termId : data?.termsheetid,selectedsheet: data, termsSheetRefils:termsSheetRefils , mode : "Edit" } });
    };
    
    const deleteSheet = (data) => {
        setShowConfirmation(true);
        setSelectedOption(data?.termsheetid);
        console.log('data',data);
    };
    
      const columns = [
        
        {
          name: 'PROGRAM TYPE',
          selector: (row) => row?.programtype,
          sortable: true,
          sortFunction: (a, b) => {
        
            const nameA = a?.programtype?.toLowerCase();
            const nameB = b?.programtype?.toLowerCase();
      
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0; 
          },
          minWidth: '20%',
          maxWidth: '20%',
        },
        {
            name: 'STATES',
            selector: (row) => row?.state?.join(','),
            sortable: true,
            sortFunction: (a, b) => {
          
              const nameA = a?.state?.join(',')?.toLowerCase();
              const nameB = b?.state?.join(',')?.toLowerCase();
        
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0; 
            },
            minWidth: '72%',
            maxWidth: '72%',
        },
        // {
        //     name: 'INTEREST RESERVES',
        //     selector: (row) => row?.interestReserve,
        //     sortable: true,
        //     sortFunction: (a, b) => {
          
        //       const nameA = a?.interestReserve
        //       const nameB = b?.interestReserve
        
        //       if (nameA < nameB) {
        //         return -1;
        //       }
        //       if (nameA > nameB) {
        //         return 1;
        //       }
        //       return 0; 
        //     },
        //     minWidth: '20%',
        //     maxWidth: '20%',
        // },
        // {
        //     name: 'INTEREST TYPE',
        //     selector: (row) => row?.interestType,
        //     sortable: true,
        //     sortFunction: (a, b) => {
          
        //       const nameA = a?.interestType?.toLowerCase() || '';
        //       const nameB = b?.interestType?.toLowerCase() || '';
        
        //       if (nameA < nameB) {
        //         return -1;
        //       }
        //       if (nameA > nameB) {
        //         return 1;
        //       }
        //       return 0; 
        //     },
        //     minWidth: '20%',
        //     maxWidth: '20%',
        // },
      
        {
            name: 'ACTIONS',
            cell: (row,index) => (
            <>
            <div className="btn-group">
              <button id = {`editPrefill-${index}`} type="button" onClick={(e) => {  editSheet(row); }} className="btn btn-outline-secondary" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                <span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span>edit</span>
              </button>
              <button id = {`deletePrefill-${index}`} type="button" onClick={(e) => { deleteSheet(row); }} className="btn btn-outline-secondary" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                <span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span>delete</span>
                </button>
                </div>
            </>
            ),
            minWidth: '8%',
            maxWidth: '8%',
          },
      ];

      const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
      };

    

      /*const totalPages = Math.ceil(totalcount / perPage);
      //console.log('total Pages',totalPages);

      const handlePrevious = () => {
        setCurrentPage((prevPage) => prevPage - 1);
      };
    
      const handleNext = () => {
        setCurrentPage((prevPage) => prevPage + 1);
      };*/
    
      const handlePerPageChange = (newperPage,page) => {
        setPerPage(newperPage);
        setCurrentPage(page); // Reset to first page
      };

      const handlePageChange = (page) => {
        setCurrentPage(page);
      };

      // const handlePageNumber = (e) => {
      //   setCurrentPage(e.target.value);
      // }

      const handleDelete = ()=>{
        closePopup()
        console.log('termSheetsData',selectedOption);
        dispatch(showLoader());
        deleteTermSheets(selectedOption).then(function (response) {
            getTermSheets();
            setDeleteConfirmation(true);
            //dispatch(hideLoader());
          }).catch((err) => {
           // dispatch(hideLoader());
            console.error.bind("my account error", err);
          })
    }
    const closePopup = () => {
        setShowConfirmation(false)
     }

     const closePopup2 = ()=>{
        setDeleteConfirmation(false)
     }

    return (
        <div id="layout-wrapper">

{loading ? <Loader/> : null} 
           <MainHeader />
           {deleteConfirmation && (
                <PopupMessage
                    type={'success'}
                    message={'Deleted Successfully'}
                    onClose={closePopup2}
                />
            )}
           {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDelete}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                  React.createElement('button', {
                      id: 'delete-prefills-yes',
                      className: 'btn btn-primary',
                      onClick: handleDelete,
                      style: { marginRight: '8px' ,fontSize: '16px'}
                  }, 'Yes'),
                  React.createElement('button', {
                      id: 'delete-prefills-no',
                      className: 'btn btn-light',
                      onClick: closePopup,
                      style: { fontSize: '16px'}
                  }, 'No')
              ]}
            >
                    <p>Do you really want to delete this term sheet?</p>
                {/* Additional content can go here */}
            </SweetAlert>}
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Underwriting Term Sheet Prefills</h4>
                            </div>
                            <div className="card" >
                                <div className="card-body ">
                                <div className='search'>
                                    <button id="createPrefills" type="button" onClick={(e) => {  navigate('/createtermsheets')}} className="create_btn" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>Create Term Sheet <span className="material-symbols-outlined icon"> add </span></button>
                                    <div className="right">
                                        <div className='form-group search_block'>
                                          <input id="prefillsSearchText" type="text" className='form-control' placeholder='Search Term Sheet' value={searchTerm} onChange={handleSearchChange}/>
                                          <button id="prefillsSearch" className="material-symbols-outlined btn">search</button>
                                        </div>
                                        
                                    </div>
                                </div>
                                    
                                    {filteredTermsheets?.length > 0 &&
                                        <DataTable className='termsheetprefills_table termsheet_refil_table'
                                            columns={columns}
                                            data={filteredTermsheets}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={(!searchTerm) ? totalcount : filteredTermsheets?.length}
                                            customStyles={customStyles}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[15, 30, 45, 60,75,90]}
                                            paginationComponentOptions={{
                                              rowsPerPageText: 'Per Page',
                                            }}
                                            // onChangePage={handlePageChange}
                                            // onChangeRowsPerPage={handlePerPageChange}
                                            // id="prefillPagination"
                                        />
                                    } 

                                   

                                    {filteredTermsheets?.length === 0  &&
                                        <div className="inprogress_page">
                                            <span className="material-symbols-outlined icon"> content_paste_search </span>
                                            <p>No Data Found</p>
                                        </div>
                                    }
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default TermSheetRefills;