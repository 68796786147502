import React from 'react'
import AdminAch from './AdminAch'

const InvestorAch = () => {
  return (
    <>
    <AdminAch transactiontype={'debit'}/>
    </>
  )
}

export default InvestorAch