import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate,useLocation } from 'react-router-dom';
import {getLOSFees, deleteLOSFees} from "../utils/reducer";
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import HomeFooter from './HomeFooter';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
const LosFees = () => {
    const dispatch = useDispatch();
      const loading = useSelector((state) => state.loaderVisible);
    const navigate = useNavigate();
    const [losFeesJson, setlosFeesJson] = useState([]);
    let userData = getUserDataFromLocalStorage();
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const location = useLocation();

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.permission
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }
    
            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.permission
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

    useEffect(() => {
        dispatch(showLoader());
        getLOSFees().then(function (response) {
            if (response?.result) {
                setlosFeesJson(response?.result);
                dispatch(hideLoader());
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader());
            })
      }, []);
      const handleCreateNew = ()=>{
        navigate('/createfee');
      };

      const handleEdit =(data)=>{
        navigate('/createfee',{ state: { createfee :data ,mode : 'edit'} });
      }


    return (
        <div id="layout-wrapper">
{loading ? <Loader/> : null} 

           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management add_default">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">los fees</h4>
                      
                            </div>
                            <div className="card" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                                <div className="card-body">
                                    <div className='search'>
                                        <button id="createLosFee" type="button" onClick={handleCreateNew} className="create_btn">Create New Fee <span className="material-symbols-outlined icon"> add </span></button>
                                        {/* <button type="button" className="border_btn">Create New Fee Template</button> */}
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table db_table tsr_table los_table">

                                            <thead className="table-light">
                                                <tr>
                                                    <th width="5%"># </th>
                                                    <th width="29%">Fee Name</th>
                                                    <th width="28%">description</th>
                                                    <th width="15%">Fee</th>
                                                    {/* <th width="15%">Prepaid</th> */}
                                                    <th width="8%">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {losFeesJson.map((data,index)=>{
                                                    return(
                                                        <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{data?.feename}</td>
                                                    <td>{data?.description}</td>
                                                    <td>{data?.feein === 'Dollar' ? '$' + data?.fee.toLocaleString().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : data?.fee+ '%'}</td>
                                                    {/* <td><label className="toggle mb-0">
                                                            <input className="toggle-checkbox" checked={data?.preepaid} type="checkbox" />
                                                            <div className="toggle-switch"></div>
                                                        </label></td> */}
                                                    <td>
                                                        <button id={`edit-LosFee-${index}`} onClick={(e)=>{e.preventDefault();handleEdit(data)}} className="icon_btn edit"> <span
                                                                className="material-icons icon tooltip-container"><span className="tooltip">Edit</span>
                                                                edit </span>
                                                        </button>
                                                        
                                                    </td>
                                                </tr>
                                              
                                                    )
                                                })}
                                            
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal popup fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Checklist</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <div className="form-group">
                                        <label>Line Item name</label>
                                        <div className="dsp_flx">
                                            <input type="text" placeholder="Enter" className="form-control" />
                                            <button type="button" className="fs_btn ml_20">Add</button>
                                        </div>
                                    </div>

                                    <p><b>Checklist</b> <br />
                                        Begin creating your checklist by typing the <span className="color">Line item name
                                        </span> and clicking<span className="color"> Add</span> button.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal message_popup popup fade" id="messagepopup" tabindex="-1"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-body">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                    <span className="material-symbols-outlined icon"> delete </span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone. <br />
                                        Do you want to continue?</p>
                                    <button type="button" className="fs_btn">Yes</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default LosFees;