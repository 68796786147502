import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { achReportApi ,achReportApiGet} from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector } from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import { formatCurrency } from '../utils/commonUtils.js';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';

const AdminAch = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const configdata = useSelector(state => state.configdata);
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({nsf : false});
    const [formDataCheck, setFormDataCheck] = useState({nsf : false});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message , setMessage] = useState('Please Select Data');
    const [errors, setErros] = useState({});
    const [showTable, setShowTable] = useState(false);
    const [achData, setAchData] = useState([]);
    const [checkedData, setCheckedData] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    let userData = getUserDataFromLocalStorage();
    console.log('selectedEmail---->',userData);
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );

    const handleRowCheck = (row) => {
        if (checkedData.some((item) => item._id === row._id)) {
            setCheckedData(checkedData.filter((item) => item._id !== row._id));
        } else {
            setCheckedData([...checkedData, row]);
        }
    };

    const handleSelectAll = (e) => {
        let checkedStatus = e.target.checked
        console.log(checkedStatus);
        setSelectAll(checkedStatus)
        if (!checkedStatus) {
            setCheckedData([]);
        } else {
            setCheckedData(achData);
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.permission
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.permission
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);
    useEffect(() => {
        console.log('checkedData', checkedData);
        if (checkedData.length === achData.length && achData.length > 0) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [checkedData, achData]);

    const isChecked = (row) =>
        checkedData.some((item) => item._id === row._id);

    const handleGenerate = () => {
        const fieldsToCheck = { 
            "fromdate" : "fromdate" , "todate" : "todate" , "effectivedate" : "effectivedate"
        };
        let errors = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = formData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!formData[fieldName]?.trim()) {
                errors[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        setErros(errors);
        if (Object.keys(errors).length === 0) {
            // setAchData(loanData);
            setFormDataCheck(formData)
            handleAchAPiGet(formData)
            // setShowTable(true)
        }
    }

    const handleChange = (e) => {
        let { name, value, type } = e.target
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? e.target.checked : value
        })
    };

    const handleFocus = (e) => {
        let { name } = e.target
        setErros({
            ...errors,
            [name]: '',
        })
    };

    const handleDownload = (type) => {
       console.log('clicked')
        if(checkedData?.length == 0){
            setMessage('Please Select Checkbox to Download File');
            setPopupOpen(true);
        }else{
            let fileType = type
            handleAchAPi(fileType);
        }
        
    };
    const handleAchAPiGet = (formData) => {
        dispatch(showLoader());
       
        achReportApiGet(formData).then(function (response) {
            if (response?.result) {
                setAchData(response?.result);
                setCheckedData(response?.result);
                setShowTable(true)
            }else{
                setAchData([]);
                setShowTable(true)
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    };
    const handleAchAPi = (fileType) => {
        dispatch(showLoader());
       let transactiontype = props.transactiontype ? props.transactiontype  : 'credit';
        achReportApi(checkedData,formDataCheck,fileType,transactiontype).then(function (response) {
            if (response?.result) {
                // const fileUrl = configdata?.common?.resourcesCloudfront + response?.result;
                DownaloadFile(response?.result,fileType);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    };
    const DownaloadFile = async (docURl,fileType) => {
        try {
            console.log('configdatadocURL',docURl, configdata?.common?.resourcesCloudfront);
          const response = await fetch(configdata?.common?.resourcesCloudfront+docURl);
          if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
          }
    
          const blob = await response.blob();
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          let downloadFileType = '';
          if(fileType === 'text'){
            downloadFileType = 'txt'
          }else if(fileType === 'pdf'){
            downloadFileType = 'pdf'
          }else if(fileType === 'xlsx'){
            downloadFileType = 'xlsx'
          }
          link.download = `NACHA.${downloadFileType}`; 
    
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    
          URL.revokeObjectURL(link.href);
          console.log('File downloaded successfully');
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      };

      const closePopup = () => {
        setPopupOpen(false);
     };

    return (
        <div id="layout-wrapper">
            {loading ? <Loader /> : null}

            {isPopupOpen && (
        <PopupMessage
          type={'warning'}
          message={message}
          onClose={closePopup}
        />
      )}
            <MainHeader />
            <SideMenu />
            <div className="main-content">

                <div className="page-content user_management">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">ACH</h4>
                                    <a id="navigatePrivacy" className="back_btn" href="#" onClick={(e) => { e.preventDefault(); navigate('/pipeline'); }}><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                                </div>

                                <div className="card" >
                                    <div className="card-body " >
                                        <div className="form_section" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                                            <div className='row'>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>From Date</label>
                                                    <input id="effective_date" type="date" className={`form-control  ${errors?.fromdate && 'invalid-input'}`} placeholder="mm-dd-yyyy" name="fromdate" onChange={handleChange} value={formData?.fromdate || ''}  onFocus={handleFocus}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>To Date</label>
                                                    <input id="todate" type="date" className={`form-control  ${errors?.todate && 'invalid-input'}`} placeholder="mm-dd-yyyy" name="todate" onChange={handleChange} value={formData?.todate || ''} onFocus={handleFocus}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Effective Date</label>
                                                    <input id="effectivedate" type="date" className={`form-control  ${errors?.effectivedate && 'invalid-input'}`} min={new Date().toISOString().split('T')[0]} placeholder="mm-dd-yyyy" name="effectivedate" onChange={handleChange} value={formData?.effectivedate || ''} onFocus={handleFocus}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3 interest_only">
                                                <div className="form-group mt_10">
                                                    <label>&nbsp;</label>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <label className="toggle mb-0 mt-1"><span className="toggle-label mr_5">NSF</span><input id="autoach" name='nsf' onChange={handleChange} checked={formData?.nsf} className="toggle-checkbox" type="checkbox" /><div className="toggle-switch"></div></label>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            {showTable && (
                                                <>
                                                    {achData.length > 0 ? (
                                                        <table className="table db_table">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectAll}
                                                                            onChange={handleSelectAll}
                                                                        />
                                                                    </th>
                                                                    <th>Loan ID</th>
                                                                    <th>Lender Case ID</th>
                                                                    <th>Account Name</th>
                                                                    <th>Request Amount</th>
                                                                    <th>Description</th>
                                                                    <th>Is NSF</th>
                                                                    <th>Routing</th>
                                                                    <th>Account Number</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {achData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                // disabled={row.isnsf}
                                                                                checked={isChecked(row)}
                                                                                onChange={() => handleRowCheck(row)}
                                                                            />
                                                                        </td>
                                                                        <td>{row.loanid}</td>
                                                                        <td>{row?.loan?.[0]?.lead?.lendercaseid}</td>
                                                                        <td>{row?.loan?.[0]?.wireinstructions?.bankaccountname}</td>
                                                                        <td>{(row.interestAmount ||row.interestamount)  ? formatCurrency(row.interestAmount ||row.interestamount) : ''}</td>
                                                                        <td>{row.description}</td>
                                                                        <td>
                                                                            <input type="checkbox" disabled checked={row.nsf} />
                                                                        </td>
                                                                        <td>{row?.loan?.[0]?.wireinstructions?.routingnumber}</td>
                                                                        <td>{row?.loan?.[0]?.wireinstructions?.bankaccountnumber}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <div className="inprogress_page">
                                                            <span className="material-symbols-outlined icon">
                                                                content_paste_search
                                                            </span>
                                                            <p>No Data Found</p>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            <div className="btn-group mt_30">
                                            <button id="generateach" onClick={handleGenerate} className="fs_btn mr_10" style = {{'opacity' : (false) ? '0.6' : '1'}} >GENERATE</button>
                                            {showTable && 
                                            <>
                                              <button id="downloadAchText" onClick={()=>{handleDownload('text')}} disabled={achData.length == 0} className="border_btn mr_10" style = {{'opacity' : (achData.length == 0) ? '0.6' : '1'}}  >DOWNLOAD</button>
                                              <button id="downloadAchPdf" onClick={()=>{handleDownload('pdf')}} disabled={achData.length == 0} className="border_btn mr_10" style = {{'opacity' : (achData.length == 0) ? '0.6' : '1'}}  >DOWNLOAD AS PDF</button>
                                              <button id="downloadAchWord" onClick={()=>{handleDownload('xlsx')}} disabled={achData.length == 0} className="border_btn" style = {{'opacity' : (achData.length == 0) ? '0.6' : '1'}}  >DOWNLOAD AS EXCEL</button>
                                              </>
                                            }
                                          
                                        </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <HomeFooter />
            </div>



        </div>
    );
};

export default AdminAch;