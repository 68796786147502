import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import Select from 'react-select';
import {getDefaultNeeds, getNeedGroups,addDefaultNeed, deleteNeed, deleteNeedGroup,addDefaultNeedGroup} from "../utils/reducer";
import { useNavigate, useLocation} from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import {showLoader, hideLoader } from '../redux/reducers/Actions';
import HomeFooter from './HomeFooter';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
const NeedsConditionlist = (props) => {
    const location = useLocation();
      const previousRoute = location.state?.from;
      console.log(location)
      console.log('previousRoutenew',location.state);
      const navigate = useNavigate();
      const dispatch = useDispatch();
      const [isPopupOpen, setPopupOpen] = useState(false);
      const [popupMessage,setmessage] = useState('Fee successfully submitted.');
      const [selectedOption, setSelectedOption] = useState(null);
      const [needs, setNeeds] = useState(null);
      const [needGroups, setneedGroups] = useState([]);
      const [selectedNeed, setSelectedNeed] = useState(null);
      const [SelectedNeedGroup, setSelectedNeedGroup] = useState(null);
      const [activeTab, setActiveTab] = useState(previousRoute == 'adddefaultneedGroup' ? 'needgroups' :'defaultneeds');
      const loading = useSelector((state) => state.loaderVisible);
      const [checklist,setChecklist] = useState([]);
      const [checklistData,setChecklistData] = useState('');
      const [checklistEdit,setChecklistEdit] = useState({});
      const [checklistError,setCheckListError] = useState(false)
      let userData = getUserDataFromLocalStorage();
      const [pipelinePermissions, setPipelinePermissions] = useState();
      const [editPermissions, setEditPermissions] = useState( );
      const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        console.log(`Option selected:`, selectedOption);
      };
   
      useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.permission
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }
    
            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.permission
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

      useEffect(() => {
        getDefaultNeed()
      }, []);
    const getDefaultNeed = () => {
        dispatch(showLoader());
        getDefaultNeeds().then(function (response) {
            if (response?.result) {
               setNeeds(response?.result)
            }
            dispatch(hideLoader());
            }).catch((err) => {
            dispatch(hideLoader());
              console.error.bind("my account error", err);
            })
            getNeedGroups().then(function (response) {
              if (response?.result) {
                  setneedGroups(response?.result)
              }
              }).catch((err) => {
                console.error.bind("my account error", err);
              })
      };

 const addDefaultNeedPage = () => {
    navigate('/adddefaultneed');
 }
 const editDefaultNeed = (need) => {
    navigate('/adddefaultneed',{ state: { defaultNeed:need, mode : "edit" } });
 }

const deleteDefaultNeed = () => {
    if(selectedNeed || SelectedNeedGroup){
        console.log('activeTab',activeTab);
        dispatch(showLoader());
       if (activeTab === 'defaultneeds') {
            deleteNeed(selectedNeed).then(function (response) {
              getDefaultNeed();
              setmessage("Item deleted successfully");
              setPopupOpen(true);
              dispatch(hideLoader())
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
        }else{
            deleteNeedGroup(SelectedNeedGroup).then(function (response) {
                getDefaultNeed();
                setmessage("Item deleted successfully");
                setPopupOpen(true);
                dispatch(hideLoader())
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader())
            })
        }
           
    }
   
 }
 const copyNeedGroup =(needGroup)=>{
    let needData = {
        groupName : needGroup?.groupName + ' 2',
        mode : "create",
        programType : needGroup?.programType,
        stage : needGroup?.stage,
        type :  "group"
    }
    dispatch(showLoader());
    addDefaultNeedGroup(needData).then(function (response) {
        dispatch(hideLoader());
        console.log('response--->',response)
        if (response?.result) {
            getDefaultNeed();
        }
        }).catch((err) => {
          dispatch(hideLoader());
          console.error.bind("my account error", err);
        })
};

const handleChecklist = (need)=>{
    setChecklistData('')
    console.log(need);
    setChecklistEdit(need);
    setChecklist(need?.checklist)
};
const handleCheckIndex =(index)=>{
    const updatedChecklist = { ...checklistEdit, mode: 'update', checklist: [...checklistEdit.checklist] };
    updatedChecklist.checklist.splice(index, 1);
    setmessage('Deleted Successfully.');
    document?.getElementById('closechecklist').click();
    handleUpdate(updatedChecklist);
};
const handleAddChecklist = ()=>{
    if(!checklistData?.trim()){
        setCheckListError(true)
        return
    };
    setmessage('Checklist Added.')
    document?.getElementById('closechecklist').click();
    let apiData = {...checklistEdit, mode : 'update' ,checklist : [...checklistEdit?.checklist ,checklistData]}
    handleUpdate(apiData)
}
const handleUpdate =(apiData)=>{
    dispatch(showLoader());
    addDefaultNeed(apiData).then(function (response) {
        dispatch(hideLoader());
        if (response?.result) {
            setPopupOpen(true);
            setChecklistData('');
            getDefaultNeed();
        }
        }).catch((err) => {
          dispatch(hideLoader());
          console.error.bind("my account error", err);
        })
}


 const addNeedGroup = () => {
    navigate('/adddefaultneedGroup',{ state : { needGroupsData : needGroups , mode : "create"} });
 }
 const editNeedGroup = (needGroup) => {
    navigate('/adddefaultneedGroup',{ state: { needGroups:needGroup , mode : "edit"} });
 }

 const closePopup = () => {
    setPopupOpen(false);
    // navigate('/losfees')
    };
    return (
        <div id="layout-wrapper">
 {/* {loading ? <Loader/> : null}  */}

           <MainHeader />
           {loading ? <Loader/> : null} 
        {isPopupOpen && (
            <PopupMessage
             type={popupMessage ==  'Please try with new reset link' ? 'warning' : 'success'}
             message={popupMessage}
             onClose={closePopup}
            />
        )}

            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management states_cities">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Needs</h4>
                      
                            </div>
                            <div className="card">
                                <div className="card-body my_account">
                                    <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                        <li className="nav-item">
                                            <button id="defaultNeedsTab" className={`nav-link ${activeTab === 'defaultneeds' ? 'active' : ''}`} data-bs-toggle="tab" href="#defaultneeds" onClick={() => setActiveTab('defaultneeds')}
                                                role="tab">
                                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                <span className="d-none d-sm-block">default Needs</span>
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button id="needsGroupTab" className={`nav-link ${activeTab === 'needgroups' ? 'active' : ''}`}data-bs-toggle="tab" href="#needgroups" role="tab" onClick={() => setActiveTab('needgroups')}>
                                                <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                <span className="d-none d-sm-block">Need groups</span>
                                            </button>
                                        </li>

                                    </ul>


                                    <div className="tab-content pt-15 text-muted">
                                        <div className={`tab-pane ${activeTab === 'defaultneeds' ? 'active' : ''}`} id="defaultneeds" role="tabpanel" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                                            <div className='search'>
                                                <button id="addDefaultNeedBtn" type="button" onClick={(e)=>{e.preventDefault(); addDefaultNeedPage()}}  className="create_btn">Add Default Need</button>

                                            </div>
                                            <div className="table-responsive">
                                                <table className="table db_table">

                                                    <thead className="table-light">
                                                        <tr>
                                                            <th width="18%">GROUP</th>
                                                            <th width="35%">Need description</th>
                                                            <th width="15%" className="text-center">DUE DATE OFFSET</th>
                                                            <th width="10%">INTERNAL nEED</th>
                                                            <th width="12%" className="text-center">nEED oRDER</th>
                                                            <th width="10%" className="text-center">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {needs?.map((need, chunkIndex) => (
                                                        <tr key={chunkIndex}>
                                                            <td>{need?.needgroups?.join(', ')}</td>
                                                            <td><div className='max_text'> {need?.needDescription} </div> </td>
                                                            <td className="text-center">{need?.dueDateOffset}</td>
                                                            <td className="text-center"><label className="toggle mb-0">
                                                                    <input id={`internalNeed-${chunkIndex}`} className="toggle-checkbox" type="checkbox" checked={need?.internalNeed} />
                                                                    <div className="toggle-switch"></div>

                                                                </label></td>
                                                            <td className="text-center">{need?.needOrder}</td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <button id={`checkListBtn-${chunkIndex}`} type="button" onClick={()=>{handleChecklist(need)}}
                                                                        className="btn btn-outline-secondary"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#staticBackdrop"
                                                                        ><span
                                                                            className="material-symbols-outlined icon">
                                                                            checklist </span>
                                                                    </button>
                                                                    <button id={`editListBtn-${chunkIndex}`} type="button" onClick={(e)=>{e.preventDefault(); editDefaultNeed(need)}}
                                                                        className="btn btn-outline-secondary"><span
                                                                            className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span>
                                                                            edit </span></button>
                                                                    <button id={`deleteListBtn-${chunkIndex}`} type="button" onClick={(e)=>{e.preventDefault(); setSelectedNeed(need)}}
                                                                        className="btn btn-outline-secondary"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#messagepopup"
                                                                        data-bs-whatever="@mdo"><span
                                                                            className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span>
                                                                            delete </span></button>
                                                                </div>
                                                            </td>
                                                        </tr>))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className={`tab-pane ${activeTab === 'needgroups' ? 'active' : ''}`} id="needgroups" role="tabpanel" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                                            <div className='search'>
                                                <button id="AddNewGroupbtn" type="button"  onClick={(e)=>{e.preventDefault(); addNeedGroup()}} className="create_btn">Add New Group</button>

                                            </div>
                                            <div className="needgroups_list">
                                            {needGroups?.map((needGroup, chunkIndex) => (
                                                <div key={chunkIndex} className="item"> {needGroup?.groupName}
                                                    <div className="btn-group">
                                                        <button id={`contentCopyBtn${chunkIndex}`} type="button" className="btn btn-outline-secondary" onClick={(e)=>{copyNeedGroup(needGroup);}}><span
                                                                className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Copy</span>
                                                                content_copy </span></button>
                                                        <button id={`editGroupBtn-${chunkIndex}`} type="button" className="btn btn-outline-secondary" onClick={(e)=>{e.preventDefault(); editNeedGroup(needGroup)}}><span
                                                                className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span>
                                                                edit </span></button>
                                                        <button id={`deleteGroupBtn-${chunkIndex}`} type="button" className="btn btn-outline-secondary" onClick={(e)=>{e.preventDefault(); setSelectedNeedGroup(needGroup)}} 
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#messagepopup"
                                                        data-bs-whatever="@mdo"><span
                                                                className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span>
                                                                delete </span></button>
                                                    </div>
                                                </div>
                                            ))}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal popup fade" id="staticBackdrop" tabindex="-1" aria-labelledby="staticBackdropLabel" data-bs-backdrop="static" data-bs-keyboard="false"
                        aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Checklist</h5>
                                    <button type="button" id='closechecklist' className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <div className="form-group">
                                        <label>Line Item name</label>
                                        <div className="dsp_flx">
                                            <input id="lineItemName" type="text" value={checklistData} onChange={(e)=>{setChecklistData(e.target.value)}} placeholder="Enter" onFocus={()=>{setCheckListError(false)}} className={`form-control ${checklistError && 'invalid-input'}`}  />
                                            <button id="addCheckList" type="button" onClick={handleAddChecklist} className={`fs_btn ml_20`} >Add</button>
                                        </div>
                                    </div>

                                    <p><b>Checklist</b></p>
                                        <br />
                                        {checklist?.length > 0 && 
                                            checklist?.map((list, index) => (
                                            <> <p key={index}>{list}</p> <a id="deleteCheckList" onClick={(e)=>{e.preventDefault();handleCheckIndex(index)}}><span style={{cursor : 'pointer'}} className="material-symbols-outlined icon"> delete </span></a></> 
                                            ))
                                        }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal message_popup popup" id="messagepopup" tabindex="-1"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-body">
                                    <button id="actionUndoneCloseBtn" type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                    <span className="material-symbols-outlined icon"> delete </span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone. <br />
                                        Do you want to continue?</p>
                                    <button id="actionUndoneBtn" type="button" data-bs-dismiss="modal" onClick={(e)=>{e.preventDefault(); deleteDefaultNeed()}} className="fs_btn">Yes</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default NeedsConditionlist;