import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import InfoBar from './InfoBar';
import { useSelector} from 'react-redux';
import { footerSiteUrl } from '../utils/reducer';
import Footer from './Footer';
const RefinancingFix = () => {
    const configdata = useSelector(state => state.configdata);
    const replaceEasyStreetCapital = configdata?.admin?.rights?.replace(/Easy Street Capital LLC/g,"easystreetcap.com");
    const siteUrl = footerSiteUrl
    const navigate = useNavigate();
    return (
        <div className="create_process">
            <Header />
            <div className="content_wrp">
                <InfoBar progress={'100%'} infoProgress={'30%'} />

                <div className="content_blk">
                <div className="hed">
                    <a href="#" className="back material-icons"> keyboard_backspace </a>
                    <h2 className="title"> <span className="title2">EASY <span className="sub"> FIX</span></span> Deal Info
                    </h2>
                </div>
                <div className="cont">
                    <h3 className="feild_title">What property type of this would be ?</h3>
                    <div className="rf_boxs">
                        <div className="box">
                            <img src="assets/images/icons/easy-fix1.png" className="icon" alt="" />
                            <h3>Single Family Residence (SFR - 1 Unit)</h3>
                        </div>
                        <div className="box">
                            <img src="assets/images/icons/easy-fix2.png" className="icon" alt="" />
                            <h3>Triplex  </h3>
                        </div>
                        <div className="box">
                            <img src="assets/images/icons/easy-fix3.png" className="icon" alt="" />
                            <h3>Townhouse or Condo  </h3>
                        </div>
                        <div className="box">
                            <img src="assets/images/icons/easy-fix4.png" className="icon" alt="" />
                            <h3>Fourplex </h3>
                        </div>
                        <div className="box">
                            <img src="assets/images/icons/easy-fix5.png" className="icon" alt="" />
                            <h3>Duplex </h3>
                        </div>
                        <div className="box">
                            <img src="assets/images/icons/easy-fix6.png" className="icon" alt="" />
                            <h3>5 Units+ </h3>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Footer/>
        </div>
    );
};

export default RefinancingFix;