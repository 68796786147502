import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate,useLocation } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { getallFees, addFee ,deleteFee,getLoanDetails } from "./../utils/reducer";
import Loader from './Loader';
import PopupMessage from './PopupMessage';
import { useLoanInfo } from '../utils/loanInfoContext';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import { formatCurrency } from '../utils/commonUtils.js';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
const CustomFooter = () => {
    return (
        <div>
            <p>This is a custom footer.</p>
        </div>
    );
};



const DealFees = () => {
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const location = useLocation();
  //  console.log("loanInfo---->",loanInfo);
    const [loanid, setLoanid] = useState(loanInfo?.loanid);
 //   console.log(loanid)
    const navigate = useNavigate();
    const loading = useSelector((state) => state.loaderVisible);
    const modalRef = useRef(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deletRow, setDeleteRow] = useState(null)
    let userData = getUserDataFromLocalStorage();
    // console.log("userData",userData)
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const handleDelete = (row) => {
        setShowConfirmation(true);
        setDeleteRow(row)
    }

    const dispatch = useDispatch();
    const [fees, setFees] = useState([]);
    const [checkfee, setCheckFee] = useState([]);
    const [errors, setErrors] = useState({});
    const [poc, setPOC] = useState(false);
    const [checkPoc, setCheckPoc] = useState(false);
    const [borrowerPaidOnHUD, setBorrowerPaidOnHUD] = useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);
    let feejson ={
        "fee": "",
        "feein": "",
        "feename" : "",
        "feetype": "",
        "description" : '',
        'paidby' : '',
        "loanid" : loanid
    }
    const [fee, setFee] = useState({...feejson});
    const [popupMessage, setmessage] = useState('fee Added.');
    const [feeBtn, setFeeBtn] = useState('add');
    const [showFeeForm, setShowFeeForm] = useState(false);
    // const [footer, setFooter] = useState('<div className="rdt_TableRow"><div className="rdt_TableCell">test</div></div');
const [isFeeChaned,setIsFeeChanged] = useState(false);
    const paidbyOptions = [
        { value: '', label: 'Please Select' },
        { value: 'Borrower', label: 'Borrower' },
        { value: 'Seller', label: 'Seller' },
        { value: 'Lender', label: 'Lender' },
        { value: 'Broker', label: 'Broker' },
        { value: 'LoanOfficer', label: 'Loan Officer' },
      ];

    const onChangeValue = (event) => {
        setPOC(event.target.checked);
    }
    const onChangeHUD = (event) => {
        setBorrowerPaidOnHUD(event.target.checked);
    }
    useEffect(() => {
        const isFormChanged =
            JSON.stringify(fee) !== JSON.stringify(checkfee) || poc !== checkPoc;
            setIsFeeChanged(isFormChanged);
    }, [fee, checkfee,checkPoc,poc]);

    const handleAddFee = () => {
        const fieldsToCheck = { 'description': 'description', 'feetype': 'feetype' ,'feename' : 'feename','paidby' : 'paidby'};
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if (!fee[fieldName]?.trim()) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
            }
        };
        const fields = {'fee': 'fee'};
        for (const [fieldName,fieldLabel] of Object.entries(fields)){
            if(typeof fee?.fee === 'number'){

            }else{
                if(!fee?.fee?.trim()){
                    error[fieldName] = `Please Enter ${fieldLabel} `;
                }
            }
        }
        fee['poc'] = poc;
        fee['borrowerPaidOnHUD'] = borrowerPaidOnHUD;
        if(fee?.fee && fee?.fee == '0' || typeof fee?.fee === 'number' && fee?.fee ===0){
            error["fee"] = `Please Enter ${'fee'} `;
        }
        setErrors(error);
        if (Object.keys(error).length === 0) {
            document.getElementById("close").click();
            //ready to api call
            
            let apiData = {...fee, loanid : loanid}
            const numericInputs = ['fee']
        numericInputs.forEach(key => {
            if (apiData.hasOwnProperty(key)) {
                apiData[key] = parseFloat(apiData[key]);
            }
        });
        if(fee?.feename?.trim()?.toLowerCase()  === checkfee?.feename?.trim()?.toLowerCase()){
            delete apiData['feename'];
        };
        feeAddApi(apiData);
        }
    };
    const feeAddApi = (apiData)=>{
        dispatch(showLoader());
        addFee(apiData).then(function (response) {
            if(response?.result ==='fee name already exists'){
                setmessage(`Fee name already exists`);
                setPopupOpen(true);
            }else if(response?.result === 'Deal fee modified successfully'){
                let message = apiData?.feeid ? 'Updated' : 'Added';
                setmessage(`Fee ${message} Successfully`);
                setPopupOpen(true);
                if(fee.feename ==='Daily Interest Charges'){
                    setLoandetails();
                }
            }
            fetchFees();
            // dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            setmessage(` ${err.message} `)
            console.error.bind("my error", err);
        })
    }
    const setLoandetails =()=>{
        getLoanDetails(loanid)
        .then(response => {
          console.log('getLoanDetails', response);
          if (response?.result[0]) {
            setLoanInfo(response?.result[0]);
            console.log(response);
          }
        })
        .catch(err => {
          console.error('my account error', err);
        });
      }
    const columns = [
        {
            name: 'NAME',
            selector: (row) => row?.feename || '',
            sortable: true,
            sortFunction: (a, b) => {
                const nameA = a?.feename?.toLowerCase();
                const nameB = b?.feename?.toLowerCase();
    
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            },
            minWidth: '16%',
            maxWidth: '16%',
        },
        {
            name: 'TYPE',
            selector: (row) => row?.feetype || '',
            sortable: true,
            sortFunction: (a, b) => {
                const nameA = a?.feetype?.toLowerCase();
                const nameB = b?.feetype?.toLowerCase();
    
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            },
            minWidth: '15%',
            maxWidth: '15%',
        },
        {
            name: 'DESCRIPTION',
            selector: (row) => row?.description || '',
            // sortable: true,
            // sortFunction: (a, b) => {
            //     const nameA = a?.description?.toLowerCase();
            //     const nameB = b?.description?.toLowerCase();
    
            //     if (nameA < nameB) {
            //         return -1;
            //     }
            //     if (nameA > nameB) {
            //         return 1;
            //     }
            //     return 0;
            // },
            minWidth: '22%',
            maxWidth: '22%',
        },
        {
            name: 'PERCENT',
            selector: (row) => row?.feein === 'Percent' ? `${row?.fee}%` : '',
            minWidth: '10%',
            maxWidth: '10%',
        },
        {
            name: 'AMOUNT',
            selector: (row) => row?.feein === 'Dollar' && row?.amount != 0 ? `${row?.fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}` :  (row?.amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })),
            minWidth: '10%',
            maxWidth: '10%',
        },
        {
            name: 'LENDER PAID POST CLOSE',
            cell: (row) => (
                <label style={{
                    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                    cursor: 'default' }}  className="toggle mb-0" >
                    <input style={{ cursor: 'default' }} className="toggle-checkbox" type="checkbox" onChange={(e)=>{handleLenderPoc(e,row)}}  checked={row?.poc || false} />
                    <div className="toggle-switch"></div>
                </label>
            ),
            minWidth: '10%',
            maxWidth: '10%',
        },
        // {
        //     name: 'Borrower Paid on HUD',
        //     cell: (row) => (
        //         <label className="toggle mb-0">
        //             <input className="toggle-checkbox" type="checkbox" disabled checked={row?.borrowerPaidOnHUD} />
        //             <div className="toggle-switch"></div>
        //         </label>
        //     ),
        //     minWidth: '10%',
        //     maxWidth: '10%',
        // },
        {
            name: 'CREATED DATE',
            selector: (row) => moment.utc(row?.created).format("MM-DD-YYYY"),
            minWidth: '10%',
            maxWidth: '10%',
        },
        {
            name: 'ACTIONS',
            cell: (row,index) => (
                <>
                
                {row?.feetype === 'Loan Origination Fee' ? '' : (<>
                    <button id={`editFee-${index}`} data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={(e) => { editFee(row); }} className="icon_btn edit btn-outline-secondary" style={{
                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
              }}>
                        <span style={{ fontSize: '16px' }} className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span>edit</span>
                    </button>
                    <button id={`deleteFee-${index}`} onClick={(e) => { handleDelete(row) }} className="icon_btn delete btn-outline-secondary" style={{
                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
              }}>
                        <span style={{  fontSize: '16px' }} className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span>delete</span>
                    </button> </>
                )}
                    {/* <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={(e) => { e.preventDefault(); editFee(row); }} className="icon_btn edit">
                        <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container"><span className="tooltip">Edit</span>edit</span>
                    </a>
                    <a href="#" onClick={(e) => { e.preventDefault(); handleDelete(row) }} className="icon_btn delete">
                        <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container"><span className="tooltip">Delete</span>delete</span>
                    </a> */}
                </>
            ),
            minWidth: '7%',
            maxWidth: '7%',
        },
    ];
    
    const customStyles = {
        //   headRow: {
        // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
        // 	},
        // },
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
    useEffect(() => {
        if(loanid){
            fetchFees();
            setFee(prevState => ({
                ...prevState,
                loanid: loanInfo?.loanid
            }));
        }
      }, [loanid]);
    useEffect(() => {
        setLoanid(loanInfo?.loanid);
        
    }, [loanInfo?.loanid]);
  

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.pipelinePermissions
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

    const fetchFees = () => {
        dispatch(showLoader());
        getallFees(loanInfo?.loanid).then(function (response) {
            if (response?.result) {
                setFees(response?.result);
                dispatch(hideLoader());
            }
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }


useEffect(() => {
  console.log(fee)
}, [fee])


    const handleChange = (e) => {
        if(e.target.name === 'percent'){
            const parsedValue = parseFloat(e.target.value);
            let updatedValue = isNaN(parsedValue) || parsedValue < 0 ? '' : parsedValue;
            if(updatedValue<=100){
                setFee(prevState => ({
                    ...prevState,
                        fee: updatedValue,
                        feein : 'Percent'
                }));
            }
        }else if(e.target.name ==='amount'){
            const parsedValue = parseFloat(e.target.value);
            let updatedValue = isNaN(parsedValue) || parsedValue < 0 ? '' : parsedValue;
            setFee(prevState => ({
                ...prevState,
                    fee: updatedValue,
                    feein : 'Dollar'
            }))
        }
        else{
            setFee((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
            }));
        }
        console.log(e, "E")
    };

    const editFee = (fee) => {
        console.log("fee",fee);
        console.log(fee)
        setFee({ ...fee });
        setCheckFee({...fee});
        setCheckPoc(fee?.poc || false);
        setPOC(fee?.poc || false);
        setBorrowerPaidOnHUD(fee?.borrowerPaidOnHUD || false)
        setFeeBtn('edit');
        setShowFeeForm(!showFeeForm);
        setErrors({});
    };
    const handleCreate = () => {
        setShowFeeForm(!showFeeForm);
        setFee({...feejson});
        setPOC(false)
        setFeeBtn('add');
        setErrors({});
    };
    const closePopup = () => {
       
        if (popupMessage.includes('Successfully')) {
            setShowFeeForm(false);
            document.getElementById("pop_close").click();
            console.log(popupMessage);
            // fetchFees();
        }
        setPopupOpen(false);
    };
    const [confirmation, setConfirmation] = useState(false);



    const handleDeleteTemplate = () => {
        setShowConfirmation(false);
        dispatch(showLoader());
        let feeObj = { ...deletRow };
        feeObj['status'] = 'ARCHIVE'
        deleteFee(feeObj).then(function (response) {
            console.log('response delete', response);
            dispatch(hideLoader());
            setmessage('Deleted Successfully');
            setConfirmation(true);
            setPopupOpen(true);
            fetchFees();
        }).catch((err) => {
            dispatch(hideLoader());
            console.error.bind("my account error", err);
        })
    }
    const handleCurrencyInputs = (name,value)=>{
        console.log(value)
        
            let updatedValue = isNaN(value) || value < 0 ? '' : value;
            setFee(prevState => ({
                ...prevState,
                    fee: updatedValue,
                    feein : 'Dollar'
            }))
    }

    const handleLenderPoc = (e,data)=>{
        let apiData = {...data}
        apiData['poc'] = e.target.checked;
        delete apiData['feename'];
        feeAddApi(apiData);
        console.log(e.target.checked,data);
    }

    return (
        <div id="layout-wrapper" className="dashboard">
            <PipelineHeader />
            {loading ? <Loader /> : null}
            {isPopupOpen &&
                <PopupMessage
                    type={popupMessage?.includes('Successfully') ? 'success' : 'warning'}
                    message={popupMessage}
                    onClose={closePopup}
                />
            }

            {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDeleteTemplate}
                onCancel={() => setShowConfirmation(false)}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-fees-yes',
                        className: 'btn btn-primary',
                        onClick: handleDeleteTemplate,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-fees-no',
                        className: 'btn btn-light',
                        onClick: () => setShowConfirmation(false),
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                <p>Do you want to delete this Fee</p>
            </SweetAlert>}
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Closing and Funding <span className="space">&#10095;</span> <span className="sub">Fees</span> </h2>
                <div className="card user_management detailed_app services_form" >
                    <div className="card-body my_account">
                        <div className="form_section">
                            <div className="row">

                            <div className="col-md-2 principal_balance">
                                <div className="form-group">
                                    <label>Loan Amount</label>
                                    <p>{loanInfo?.loanamount ? loanInfo?.loanamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 'N/A'}</p>
                                </div>
                            </div>
                                <div className="col-md-2 principal_balance">
                                <div className="form-group">
                                    <label>Interest rate</label>
                                    <p>{loanInfo?.interestrate ? (loanInfo?.interestrate.toFixed(2) + '%') : '0.00%'}</p>
                                </div>
                                </div>
                                <div className="col-md-2 principal_balance">
                                <div className="form-group">
                                    <label>Remaining Days</label>
                                    <p>{loanInfo?.remainingdays ? loanInfo?.remainingdays :'N/A'}</p>
                                </div>
                                </div>
                                <div className="col-md-2 principal_balance">
                                <div className="form-group">
                                    <label>Daily Interest Amount</label>
                                    <p>{loanInfo?.dailyinterestamt ? formatCurrency(loanInfo?.dailyinterestamt):'$0'}</p>
                                </div>
                                </div>
                                <div className="col-md-2 principal_balance">
                                <div className="form-group">
                                    <label>Daily Interest Charges</label>
                                    <p>{loanInfo?.dailyInterestCharges ? formatCurrency(loanInfo?.dailyInterestCharges):'$0'}</p>
                                </div>
                                </div>
                            </div>
                       
                       
                            <div className='search mt-3'>
                                <div className="btn-group">
                                    <button id="addFee" type="button" className="fillbtn_new add_button me-3" data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop" data-bs-whatever="@mdo" onClick={() => handleCreate()} style={{
                                            pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                          }}>Add Fee<span
                                            className="material-symbols-outlined">add_card</span></button>

                                    {/* <button type="button" className="create_btn" data-bs-toggle="modal"
                                    data-bs-target="#chargecredit" data-bs-whatever="@mdo">Charge Credit<span
                                        className="material-symbols-outlined">credit_card</span></button> */}
                                {/* <button type="button" className="create_btn" data-bs-toggle="modal"
                                    data-bs-target="#send_ach" data-bs-whatever="@mdo">Send to ACH<span
                                        className="material-symbols-outlined">credit_card</span></button> */}
                                </div>
                                {/* <div className="d-flex align-items-center justify-content-between">
                                <p className="mb-sm-0 font-size-18">Select Fee Template</p>
                                <select className="form-control fee_select">
                                    <option selected="">Select</option>
                                    <option>NA</option>
                                </select>
                                <button type="button" className="create_btn">Apply Fee Template</button>
                            </div> */}

                            </div>


                            <div className="table-responsive fee_table">
                                {/* <table className="table db_table">

                                <thead className="table-light">
                                    <tr>
                                        <th width="8%">Description</th>
                                        <th width="6%" className="text-center">Percent</th>
                                        <th width="7%" className="text-end">Dollar Amount (USD)</th>
                                        <th width="7%" className="text-end">tOTAL (USD)</th>
                                        <th width="11%" className="text-center">pOC</th>
                                        <th width="1%">Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        <td>Mortgage Broker’s Fee</td>
                                        <td className="text-center">0.5%</td>
                                        <td className="text-end">395.00</td>
                                        <td className="text-end">395.00</td>
                                        <td className="text-center"><label className="toggle mb-0">
                                                <input className="toggle-checkbox" type="checkbox" />
                                                <div className="toggle-switch"></div>

                                            </label></td>

                                        <td>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-outline-secondary"
                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                    data-bs-whatever="@mdo"><span
                                                        className="material-symbols-outlined icon">
                                                        edit </span></button>
                                                <button type="button" className="btn btn-outline-secondary"><span
                                                        className="material-symbols-outlined icon">
                                                        delete </span></button>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>Mortgage Broker’s Fee</td>
                                        <td className="text-center">0.5%</td>
                                        <td className="text-end">395.00</td>
                                        <td className="text-end">395.00</td>
                                        <td className="text-center"><label className="toggle mb-0">
                                                <input className="toggle-checkbox" type="checkbox" />
                                                <div className="toggle-switch"></div>

                                            </label></td>

                                        <td>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-outline-secondary"
                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                    data-bs-whatever="@mdo"><span
                                                        className="material-symbols-outlined icon">
                                                        edit </span></button>
                                                <button type="button" className="btn btn-outline-secondary"><span
                                                        className="material-symbols-outlined icon">
                                                        delete </span></button>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>Mortgage Broker’s Fee</td>
                                        <td className="text-center">0.5%</td>
                                        <td className="text-end">395.00</td>
                                        <td className="text-end">395.00</td>
                                        <td className="text-center"><label className="toggle mb-0">
                                                <input className="toggle-checkbox" type="checkbox" />
                                                <div className="toggle-switch"></div>

                                            </label></td>

                                        <td>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-outline-secondary"
                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                    data-bs-whatever="@mdo"><span
                                                        className="material-symbols-outlined icon">
                                                        edit </span></button>
                                                <button type="button" className="btn btn-outline-secondary"><span
                                                        className="material-symbols-outlined icon">
                                                        delete </span></button>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>Mortgage Broker’s Fee</td>
                                        <td className="text-center">0.5%</td>
                                        <td className="text-end">395.00</td>
                                        <td className="text-end">395.00</td>
                                        <td className="text-center"><label className="toggle mb-0">
                                                <input className="toggle-checkbox" type="checkbox" />
                                                <div className="toggle-switch"></div>

                                            </label></td>

                                        <td>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-outline-secondary"
                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                    data-bs-whatever="@mdo"><span
                                                        className="material-symbols-outlined icon">
                                                        edit </span></button>
                                                <button type="button" className="btn btn-outline-secondary"><span
                                                        className="material-symbols-outlined icon">
                                                        delete </span></button>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>Mortgage Broker’s Fee</td>
                                        <td className="text-center">0.5%</td>
                                        <td className="text-end">395.00</td>
                                        <td className="text-end">395.00</td>
                                        <td className="text-center"><label className="toggle mb-0">
                                                <input className="toggle-checkbox" type="checkbox" />
                                                <div className="toggle-switch"></div>

                                            </label></td>

                                        <td>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-outline-secondary"
                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                    data-bs-whatever="@mdo"><span
                                                        className="material-symbols-outlined icon">
                                                        edit </span></button>
                                                <button type="button" className="btn btn-outline-secondary"><span
                                                        className="material-symbols-outlined icon">
                                                        delete </span></button>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="total_count">

                                        <td></td>
                                        <td className="text-center"></td>
                                        <td className="text-end"></td>
                                        <td className="text-end"><span>Total $ 1765.00</span></td>
                                        <td className="text-center"></td>

                                        <td>

                                        </td>
                                    </tr>



                                </tbody>
                            </table> */}


                                {
                                    fees?.length > 0 && <DataTable
                                        columns={columns}
                                        data={fees}
                                        pagination
                                        customStyles={customStyles}
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Per Page',
                                        }}
                                        footer={<CustomFooter />}

                                    />
                                }
                            </div>
                            {/* <a href="#" className="fillbtn_new add_button d-inline-block">save</a> */}
                        </div>
                  
                        <div className="modal popup new_popup fade add_fee" ref={modalRef}
                            id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{feeBtn} Fee</h5>
                                        <button type="button" className="pop_close" id="pop_close" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label> Name</label>
                                                <input id="feeName" disabled={fee?.underwriting || fee?.default ? true : false } type="text" value={fee?.feename || ''} name='feename' onChange={(e) => handleChange(e)} className={`form-control ${errors?.feename && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, feename: null }) }}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                        <div className="row">
                                        <div className="col-md-6">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>Description</label>
                                                {/* <input id="feeDescription" type="text" value={fee?.description || ''} name='description' onChange={(e) => handleChange(e)} className={`form-control ${errors?.description && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, description: null }) }}/> */}
                                                <textarea id="feeDescription" value={fee?.description || ''} disabled={fee?.underwriting ||  false } className={`form-control ${errors?.description && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, description: null }) }} onChange={(e) => handleChange(e)} name="description" rows="4" cols="50" placeholder="Enter Description" spellcheck="false"></textarea>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Percent</label>
                                                    <input id="feePercent" type="number"  disabled={fee?.underwriting ||  (checkfee?.feename === 'Origination Fee'|| checkfee?.feename === 'Interest Reserve') || false } value={fee?.feein==='Percent' ? fee?.fee : ''} name='percent' onChange={(e) => handleChange(e)} className={`form-control ${errors?.fee && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fee: null }) }} company placeholder="Enter Percent" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                <label>Dollar Amount (USD)</label>
                                                <CurrencyInput id="feeDollar" name="amount"
                                                disabled={fee?.underwriting  ? true : (checkfee?.feename === 'Origination Fee'|| checkfee?.feename === 'Interest Reserve') || false }
                                                //   defaultValue={1000}
                                                decimalsLimit={2}
                                                className={`form-control ${errors?.fee && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fee: null }) }} placeholder="Enter Dollar Amount"
                                                prefix="$"
                                                groupSeparator=","
                                                value={fee?.feein==='Dollar' ? fee?.fee : ''}
                                                onValueChange={(value, name, values) => {
                                                    console.log(values)
                                                    handleCurrencyInputs(name,value);
                                                }}
                                                />
                                                    {/* <label>Dollar Amount (USD)</label> */}
                                                    {/* <input type="number" value={fee?.feein==='Dollar' ? fee?.fee : ''}  name='amount' onChange={(e) => handleChange(e)} className={`form-control ${errors?.fee && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fee: null }) }} company placeholder="Enter Dollar Amount" /> */}
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                                         <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Paid By</label>
                                                                <select id="feePaidBy" value={fee?.paidby || ''} disabled={fee?.underwriting ||  false } onChange={handleChange} name='paidby' placeholder="Please Select Option" className={`form-control ${errors?.paidby && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, paidby: null }) }}>
                                                                {paidbyOptions.map((data,index)=>(
                                                                         <option value={data.value} >{data.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Type</label>
                                                    <select id="feeType" value={fee?.feetype} disabled={fee?.underwriting ||  false } name='feetype' onChange={(e) => handleChange(e)} className={`form-control ${errors?.feetype && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, feetype: null }) }} >
                                                        <option value="" selected="">Select</option>
                                                        <option value="Origination Charges">Origination Charges</option>
                                                        <option value="Processing Charges">Processing Charges</option>
                                                        <option value="Underwriting Charges">Underwriting Charges</option>
                                                        <option value="Valuation Charges">Valuation Charges</option>
                                                        <option value="Payment Reserves">Payment Reserves</option>
                                                        <option value="3rd party Charges">3rd party Charges</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                            <label className="toggle mb-3 mt-2">
                                                      <span>Lender Paid Post Close</span>
                                                        <input id="feePOC" className="toggle-checkbox" onChange={onChangeValue} checked={poc} name="poc" type="checkbox" /><div className="toggle-switch"></div>
                                                    </label>
                                            </div> */}
                                            {/* <div className="col-md-6">
                                            <label className="toggle mb-3 mt-2">
                                                      <span>Borrower Paid on HUD</span>
                                                        <input id="feePOC" className="toggle-checkbox" onChange={onChangeHUD} checked={borrowerPaidOnHUD} name="borrowerPaidOnHUD" type="checkbox" /><div className="toggle-switch"></div>
                                                    </label>
                                            </div> */}
                                            {/* <div className="col-md-5">
                                                <div className="form-group">
                                                    <label>poc</label>
                                                    <label className="toggle mb-0">
                                                        <input className="toggle-checkbox" onChange={onChangeValue} defaultChecked={poc} name="poc" type="checkbox" />
                                                        <div className="toggle-switch"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <a onClick={(e) => { e.preventDefault(); handleAddFee(); }} className="fs_btn">{feeBtn === 'add' ? 'Save' : 'Update'}</a>
                                                </div>
                                            </div> */}
                                               <div className="col-md-12">
                                            <div className="form-group">
                                                        <button id="saveFee"  type="button"  onClick={(e) => { handleAddFee(); }} disabled={fee?.underwriting  || !isFeeChaned} style={{'opacity' : isFeeChaned ? 1 : 0.6}} className="fs_btn">{feeBtn === 'add' ? 'Save' : 'Update'}</button>
                                                    </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal popup new_popup fade charge_credit" id="chargecredit" tabindex="-1"
                            aria-labelledby="chargecreditLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">CHARGE CREDIT</h5>
                                        <button type="button" className="pop_close" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <h5>USER INFORMATION</h5>
                                        <div className="row mb-4">

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>CardHolder First Name</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>CardHolder Last Name</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 principal_balance">
                                                <div className="form-group">
                                                    <label>Payment Amount</label>
                                                    <p>$ 125,0000</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">

                                                </div>
                                            </div>
                                        </div>
                                        <h5>CARD INFORMATION</h5>
                                        <div className="card_info">
                                            <div className="pay_crdtcard">
                                                <input className="form-check-input" type="radio" name="radioNoLabel"
                                                    id="radioNoLabel1" value="" aria-label="..." />
                                                <p>Pay with Credit Card</p>
                                            </div>

                                            <img className="card_imges" src="assets/images/cards.png" alt="" height="30" />

                                        </div>
                                        <div className="row card_details mb-4">

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Card number</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Expiration Date</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Card Security Code</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label></label>
                                                    <p>What is this?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <h5>BILLING ADDRESS</h5>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Billing City</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>State</label>
                                                    <select className="form-control">
                                                        <option selected="">Select</option>
                                                        <option>NA</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>ZIP</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <button type="button" className="fs_btn">sUBMIT PAYMENT</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal popup new_popup fade Pay_ach" id="send_ach" tabindex="-1"
                            aria-labelledby="send_achLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Pay Fee by ach</h5>
                                        <button type="button" className="pop_close" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">

                                        <div className="row">

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Account Name</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Routing Number</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Account Number</label>
                                                    <input type="text" placeholder="Enter" className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 principal_balance mt-3 mb-3">
                                                <div className="form-group">
                                                    <label>Payment Amount</label>
                                                    <p>$ 125,0000</p>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <button type="button" className="fs_btn">sUBMIT PAYMENT</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default DealFees;