import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {getDropDownLookups, updateProgramType, updateLookupsData} from "../utils/reducer";
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import PopupMessage from './PopupMessage';
import { useNavigate,useLocation} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';   
import HomeFooter from './HomeFooter';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
const ProgramTypeManager = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [popupMessage, setmessage] = useState('Update successfull');
      const [programs , setPrograms] = useState([]);
      const [newProgram, setNewProgram] = useState('');
      const [testProgram, setTestProgram] = useState('');
      const [buttonType , setButtonType] = useState('add');
      const [editIndex, setEditIndex] = useState('');
      const [inputError, setInputError] = useState(false)
      const loading = useSelector((state) => state.loaderVisible);
      const [isPopupOpen, setPopupOpen] = useState(false);
      const [selectedOption, setSelectedOption] = useState({});
      const [showConfirmation,setShowConfirmation] = useState(false);
      let userData = getUserDataFromLocalStorage();
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const location = useLocation();

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.permission
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }
    
            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.permission
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

      useEffect(() => {
        getProgramTypes()
      }, []);

      const getProgramTypes = () => {
        dispatch(showLoader());
        getDropDownLookups('programtype').then(function (response) {
        if (response?.result) {
           setPrograms(response?.result?.data)
        }
        dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
          console.error.bind("my account error", err);
        })
    }

    function cleanString(str) {
        // Remove symbols and spaces, and convert to lowercase
        return str?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    }

    function inputValueMatches(inputValue) {
       
        const cleanedInputValue = cleanString(inputValue);
       
        // Check if any object in arr has both cleaned sheetName and programType properties that match the cleaned inputValue
        // if (Array.isArray(pastData)) {
            // termSheetsData is an array
            return programs?.some(item => cleanString(item?.lookup) === cleanedInputValue);
        // } 
        
    }
                
    const handleAdd =()=>{
        // console.log("programs list",programs);
        let error = false
        
        let valid = inputValueMatches(newProgram);
        console.log("matches",valid);
        
        if(newProgram.trim().length === 0){
            error = true
        }
        setInputError(error);

        if(newProgram && valid){
           
            dispatch(showLoader());
            setPopupOpen(true);
            setmessage("Program Already Exists.")
            dispatch(hideLoader());
            
        }
        else if(newProgram && !valid){
                
                dispatch(showLoader());
                updateProgramType({appname: "easystreetcapitaldev", lookup: newProgram.trim(), status: "ACTIVE", type: "programtype"}).then(function (response) {
                    console.log("add progra type response",response);
                    if(response?.statusCode === 200 && response?.result === "Lookup already exists"){
                        dispatch(hideLoader());
                        setmessage("Program Already Exists.");
                        setPopupOpen(true);
                    }else{
                        setPrograms([...programs, {appname: "easystreetcapitaldev", lookup: newProgram.trim(), status: "ACTIVE", type: "programtype"}]);
                        getProgramTypes();
                        dispatch(hideLoader());
                        setmessage('Program added Successfully');
                        setPopupOpen(true);
                    }
                   
                }).catch((err) => {
                    dispatch(hideLoader());
                  console.error.bind("my account error", err);
                })
            setNewProgram('');
            setInputError(false);
            setEditIndex('');
        }
        
      };

      const handleEdit =(index)=>{
        setButtonType ('update');
        setInputError(false);
        setEditIndex(index);
        console.log('----------->',index);
        setNewProgram(programs[index]?.lookup);
        setTestProgram(programs[index]?.lookup);
      };

      const handleUpdate=()=>{
        let error = false
        
        let valid = inputValueMatches(newProgram);
        console.log("matches",valid);

        if(newProgram.trim().length === 0){
            error = true
        }
        setInputError(error);
        if(newProgram && valid){
           
            dispatch(showLoader());
            setPopupOpen(true);
            setmessage("Program Already Exists.")
            dispatch(hideLoader());
            
        }
        else if(newProgram && !valid){
       
            const updateProgram = [...programs];
            if (updateProgram[editIndex] !== undefined) {
                updateProgram[editIndex].lookup = newProgram.trim();
            }
            delete updateProgram[editIndex]._id;
            dispatch(showLoader());
            updateLookupsData(updateProgram[editIndex]).then(function (response) {
          
                getProgramTypes()
                dispatch(hideLoader());
                setmessage("Program updated successfully");
                setPopupOpen(true);
            }).catch((err) => {
                dispatch(hideLoader());
                  console.error.bind("my account error", err);
            })
            console.log('updateProgram',updateProgram);
            setPrograms(updateProgram);
            setNewProgram('');
            setButtonType('add');
            setInputError(false);
            setEditIndex('');
        }
      };

      const handleDelete = ()=>{
        let index = selectedOption;
        const updatedlPrograms = programs?.filter((_, i) => i !== index);
        const updateProgram = [...programs];
        if (updateProgram[index] !== undefined) {
            updateProgram[index].status = "Archive";
            delete updateProgram[index]._id;
            setShowConfirmation(false);
            dispatch(showLoader());
            updateLookupsData(updateProgram[index]).then(function (response) {
                getProgramTypes();
                setmessage("Program Deleted successfully");
                setPopupOpen(true);
                dispatch(hideLoader());
            }).catch((err) => {
                  console.error.bind("my account error", err);
                  dispatch(hideLoader());
            })
        }
        console.log('handleDelete',updatedlPrograms);
        setPrograms(updatedlPrograms);
        setNewProgram('');
        setButtonType('add');
        setInputError(false);
        setEditIndex('');
      };

      const handleChange =(e)=>{
        setNewProgram(e.target.value)
      }

      const closePopup = () => {
        setPopupOpen(false)
        setShowConfirmation(false)
     }

    return (
        <div id="layout-wrapper">
        {loading ? <Loader/> : null} 
          {isPopupOpen && (
        <PopupMessage
          type={popupMessage ===  'Please try with new reset link' || popupMessage === "Program Already Exists." ? 'warning' : 'success'}
          message={popupMessage}
          onClose={closePopup}
        />
      )}
      {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                //confirmBtnBsStyle="success"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={handleDelete}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-program-yes',
                        className: 'btn btn-primary',
                        onClick: handleDelete,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-program-no',
                        className: 'btn btn-light',
                        onClick: closePopup,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                    <p>Do you really want to delete this program?</p>
                {/* Additional content can go here */}
            </SweetAlert>}

           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Program Type Manager</h4>
                                <a href="#" id="programTypeBack" className="back_btn" onClick={(e) => {e.preventDefault(); navigate(-1);}} ><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                                <div className="card-body ">
                                    <div className="form_section status">
                                       <div className="sl_block">
                                            <div className="left">
                                                <div className="cont">
                                                    <div className="form-group">
                                                        <label>PROGRAM TYPE</label>
                                                       
                                                        <input id="programType" value={newProgram} type="text" className={`form-control ${inputError && 'invalid-input'}`} onFocus={()=>{setInputError(false)}} onChange={handleChange}/>
                                                    </div>
                                                  
                                                    <div className="form-group">
                                                        {/* <button onClick={handleAdd} className="fs_btn">Add</button> */}

                                                        {buttonType==='add' ? (<button id="programTypeAdd" onClick={handleAdd} className="fs_btn">
                                                        Add 
                                                        </button>) : <button id="programTypeUpdate" disabled={newProgram === testProgram}  onClick={handleUpdate} 
                                                        className="fs_btn" style={{ opacity: newProgram === testProgram ? 0.6 : 1 }}>  Update
                                                                </button>
                                                                }
                              
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="table-responsive">
                                                    <table className="table data_table">

                                                        <thead className="table-light">
                                                            <tr>
                                                                <th width="10%">#</th>
                                                                <th width="80%">Program Type</th>
                                                               <th width="10">Actions</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {programs.map((programName,index)=>{
                                                                return(
                                                                    <tr>
                                                                <td>{index+1}</td>
                                                                <td>{programName?.lookup}</td>
                                                                <td>
                                                                    <div className="btn-group">
                                                                        <button id={`editProgramType-${index}`} type="button" onClick={()=>{handleEdit(index)}} className="btn btn-outline-secondary"><span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span> edit </span></button>
                                                                        <button id={`deleteProgramType-${index}`} type="button" onClick={(e)=>{e.preventDefault(); setShowConfirmation(true); setSelectedOption(index);}} className="btn btn-outline-secondary"><span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span> delete </span></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                                )
                                                            })}
                                                            
                                                          </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default ProgramTypeManager;