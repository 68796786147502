import axios from "axios";
import {getTokenFromLocalStorage, getUserDataFromLocalStorage, saveUserDataLocalStorage} from "./../utils/local";
// export const APIUrl="https://6kebwwhncl.execute-api.us-east-1.amazonaws.com/latest/";
export const APIUrl = "https://phosekms8b.execute-api.us-east-1.amazonaws.com/latest/";
export const footerSiteUrl = "https://easystreetcap.com/"

export const Appname = 'easystreetcapital';
export const signIn = async (userData) => {
    const promise = axios
      .post(
        APIUrl+"signin?appname="+Appname,
        userData,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          // console.log('response',response);
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const forgotPassword = async (userData) => {
    const promise = axios
      .post(
        APIUrl+"forgotPassword?appname="+Appname,
        userData,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      }).catch((err) => {
        // console.error.bind("Login error", err);
        return err;
      })
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  
  export const savePassword = async (userData) => {
    const promise = axios
      .post(
        APIUrl+"setPassword?appname="+Appname+"&uuid="+userData.id,
        {password:userData.password},
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

    
  export const getMenus = async (menuCode) => {
    let token = getTokenFromLocalStorage()
    const promise = axios
      .get(
        APIUrl+"menus?appname="+Appname+"&token="+token+"&menuid=" + menuCode,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getMenuTermsandPrivacy = async (menuCode) => {
    let token = getTokenFromLocalStorage()
    const promise = axios
      .get(
        APIUrl+"menus?appname="+Appname+"&token="+token+"&menuid=" + menuCode+"&projection=termsandprivacy",
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const updateMenu = async (menuCode, content) => {
    let token = getTokenFromLocalStorage()
    const promise = axios
      .post(
        APIUrl+"menus?appname="+Appname+"&token="+token+"&menuid=" + menuCode,{
    
          "content": content

      },
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };


  export const submitNew = async (userData) => {
    let token = getTokenFromLocalStorage()
    const promise = axios
      .post(
        APIUrl+"loan/request?appname="+Appname+"&token="+token,
        {userData},
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getUser = async (menuCode) => {
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    let token = getTokenFromLocalStorage()
    const promise = axios
      .get(
        APIUrl+"user?appname="+Appname+"&token="+token+"&userid="+UserId+"&projection=user",
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response?.data?.result?.[0]) {
          const combinedData = {
            ...userData,
            ...response?.data?.result?.[0]?.userInfo || response?.data?.result?.[0],
            securityInfo: response?.data?.result?.[0]?.securityInfo
          };
    // console.log('combinedDatahere',response?.data?.result?.[0], combinedData);
          // Save the combined data to local storage
          saveUserDataLocalStorage(combinedData);
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getUserPipelineColumns = async (menuCode) => {
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    let token = getTokenFromLocalStorage()
    const promise = axios
      .get(
        APIUrl+"user?appname="+Appname+"&token="+token+"&userid="+UserId+"&projection=pipelinecolumns",
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response?.data?.result?.[0]) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
 
  export const getLoans = async (menuCode) => {
    console.log('getLoansgetLoansgetLoansgetLoans');

    let token = getTokenFromLocalStorage()
    const promise = axios
      .post(
        APIUrl+"loans?appname="+Appname+"&token="+token,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const searchLoanApi = async (param) => {
    console.log('searchLoanApi');
    let token = getTokenFromLocalStorage()
    const promise = axios
      .post(
        APIUrl+"loans?appname="+Appname+"&token="+token+"&search="+param,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getLoanDetails = async (loanId) => {
    console.log('getLoanDetails');

    let token = getTokenFromLocalStorage()
    const promise = axios
      .post(
        APIUrl+"loans?appname="+Appname+"&token="+token+ "&loanid="+loanId,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getForm1098Details = async (loanId) => {
    console.log('getForm1098Details');

    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = axios
      .get(
        APIUrl+"form1098?appname="+Appname+"&token="+token+ "&loanid="+loanId+ "&userid="+UserId,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const generateForm1098 = async (loanId, year ,lenderName , lenderLin) => {
    console.log('generateForm1098');

    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = axios
      .post(
        APIUrl+"form1098?appname="+Appname+"&token="+token+ "&loanid="+loanId+ "&userid="+UserId+"&year="+year+"&lendername=" + lenderName + "&lenderlin=" + lenderLin,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  
  // export const generateForms1098 = async (loans, year ,lenderName , lenderLin) => {
  //   console.log('generateForm1098',loans);

  //   let token = getTokenFromLocalStorage()
  //   let userData = getUserDataFromLocalStorage();
  //   let UserId = userData?.userId;
  //   const promise = axios
  //     .post(
  //       APIUrl+"multiform1098?appname="+Appname+"&token="+token+ "&userid="+UserId+"&lendername=" + lenderName + "&lenderlin=" + lenderLin,
  //       {loanid:loans, year:year},
  //       { type: "application/json" }
  //     )
  //     .then(function (response) {
  //       if (response && response.data) {
  //         return response;
  //       }
  //     });
  //   const dataPromise = promise.then((response) => response.data);
  //   return dataPromise;
  // };

  export const getmultiForm1098Details = async (pagenum,perpage) => {

    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = axios
      .get(
        APIUrl+"multiform1098?appname="+Appname+"&token="+token+ "&userid="+UserId + "&assetcount=" + perpage + "&pageNumber=" + pagenum,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const deleteForm1098 = async (loansData) => {
    console.log('deleteForm1098', loansData);

    let token = getTokenFromLocalStorage()
    const promise = axios
      .delete(
        APIUrl+"form1098?appname="+Appname+"&token="+token, { data: loansData }, 
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };


  export const emailForm1098 = async (loanId, loansData) => {
    console.log('emailForm1098', loansData);

    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = axios
      .post(
        APIUrl+"sendform1098?appname="+Appname+"&token="+token + "&loanid="+loanId+ "&userid="+UserId, loansData, 
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };


  export const copyForm1098 = async (loanId, loansData) => {
    console.log('copyForm1098', loansData);

    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = axios
      .post(
        APIUrl+"form1098?appname="+Appname+"&token="+token + "&loanid="+loanId+ "&userid="+UserId, loansData, 
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateUser = async (content) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    let mainData = {userid: UserId, userInfo: content}
    const promise = 
      axios({
        method: 'POST',
        url: APIUrl + '/user?appname=' + Appname + "&token=" + token+"&userid="+UserId,
        data: mainData,
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getLoanprograms = async () => {
    const promise = axios
      .get(
        APIUrl+"loanprograms?appname="+Appname,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getOrganization = async (projection) => {
    const promise = axios
      .post(
        APIUrl+"organization?appname="+Appname+"&projection="+projection,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateOrganization = async (content) => {
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = axios
      .post(
        APIUrl+"organization/update?appname="+Appname+"&organizationCode="+content.organizationCode+"&userid="+UserId,
        content,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getCities = async () => {
    const promise = axios
      .post(
        APIUrl+"cities?appname="+Appname,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getStates= async () => {
    const promise = axios
      .get(
        APIUrl+"states?appname="+Appname,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateLoanPrograms = async (loansData, loancode,) => {
    delete loansData._id;
    let token = getTokenFromLocalStorage()
    const promise = axios
      .post(
        APIUrl+"loanprograms/update?appname="+Appname+"&token="+token+"&loanProgramId="+loancode,loansData,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const createUser = async (content) => {
    let token = getTokenFromLocalStorage()
    let callType = content._id ? '': '/create'
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    let method = content._id ? 'post' : 'put';
    // if(content?._id){
    //   delete content._id;
    // }

const promise = axios({
  method,
  url: APIUrl + "user" + callType + "?appname=" + Appname + "&token=" + token + "&userid=" + UserId ,
  data: content,
  headers: { 'Content-Type': 'application/json' }, // Set headers for clarity
})
.then(response => {
  // Simplified response handling
  return response;
})
.catch(error => {
  // Handle errors appropriately
  // console.error('Error:', error);
  // You can return a rejected promise with an error object here
  // throw new Error(error.message);
});
    const dataPromise = promise.then((response) => response.data);
    
    return dataPromise;
  };

  export const getallUsers = async (type) => {
    let token = getTokenFromLocalStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
  
    let url = `${APIUrl}allusers?appname=${Appname}`
    if(type == 'loselectable'){
      url = url+'&loselectable=true'
    }
    const promise = axios
      .get(url, config)
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
  
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getallUsersDropdown = async (type) => {
    let token = getTokenFromLocalStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
  
    let url = `${APIUrl}allusers?appname=${Appname}&projection=userdropdown`
    if(type == 'loselectable'){
      url = url+'&loselectable=true'
    }
    const promise = axios
      .get(url, config)
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
  
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getallBrokers = async (type) => {
    let userData = getUserDataFromLocalStorage();
  
    
    let token = getTokenFromLocalStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
  
  
    const promise = axios
      .post(`${APIUrl}brokers?appname=${Appname}&userid=${userData?.userId}`, config)
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
  
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getallContacts = async (currentPage, perPage, type, search, sortBy, sortType, advData = null) => {
    let token = getTokenFromLocalStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
  
    const url = `${APIUrl}getcontacts?appname=${Appname}&pageNumber=${currentPage}&assetcount=${perPage}` +
      `${sortBy ? `&sortBy=${sortBy}` : ''}` +
      `${sortType ? `&sortType=${sortType}` : ''}` +
      `${type ? `&type=${type}` : ''}` +
      `${search ? `&search=${search}` : ''}`;
  
    const payload = advData ? advData : null; // Only include advData if provided
  
    try {
      const response = await axios.post(url, payload, config);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
      throw error;
    }
  };


  export const getallFees = async (loanid,projection) => {
    let token = getTokenFromLocalStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
  
  
    const promise = axios
      .get(`${APIUrl}dealfee?appname=${Appname}&loanid=${loanid}${projection ? `&projection=${projection}` : ''}`, config)
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
  
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getRoleMenus = async (type) => {
    let token = getTokenFromLocalStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
  
    const promise = axios
      .get(`${APIUrl}userPermissions?appname=${Appname}&type=${type}`, config)
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
  
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getDropDownLookups = async (type) => {
    let token = getTokenFromLocalStorage();
    const config = {
      "type":[type],
    };
  
    const promise = axios
      .post(`${APIUrl}lookups?appname=${Appname}&projection=lookups`, config)
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
  
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getTypeLookups = async (type) => {
    let token = getTokenFromLocalStorage();
    const config = {
      "lookup":[type],
    };
  
    const promise = axios
      .post(`${APIUrl}lookups?appname=${Appname}&projection=lookups`, config)
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
  
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  
  export const getStatewiseCities = async (type) => {
    let token = getTokenFromLocalStorage();
   
  
    const promise = axios
      .post(`${APIUrl}cities?appname=${Appname}`+`&regionname=`+type, )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
  
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getTermSheetRefills = async () => {
    let token = getTokenFromLocalStorage();
   
  
    const promise = axios
      .get(`${APIUrl}termsheets?appname=${Appname}`+ "&token=" + token )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
  
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };



  export const updateLookupsData = async (data) => {
    // console.log('datadata',data)
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = 
      axios({
        method: 'POST',
        url: APIUrl + 'updatelookups?appname=' + Appname + "&token=" + token +'&lookupCode='+data?.lookupCode,
        data: data,
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateProgramType = async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = 
      axios({
        method: 'PUT',
        url: APIUrl + '/lookups?appname=' + Appname + "&token=" + token ,
        data: data,
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };


  export const updateTermSheets = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'termsheets?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };



  
  export const updateCitiesData = async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise =  axios({
      method: 'POST',
      url: APIUrl + '/cities/update?appname=' + Appname + "&token=" + token + "&userid=" + UserId,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

export const getDefaultNeeds = async () => {
    let token = getTokenFromLocalStorage()
    const promise = axios
      .get(
        APIUrl+"defaultneed?appname="+Appname+"&token="+token,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

export const getNeedGroups = async (projection) => {
    let token = getTokenFromLocalStorage()
    const promise = axios
      .get(
        APIUrl+"groupneed?appname="+Appname+"&token="+token+`${projection ? '&projection=groupneed' : ''}`,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

export const addDefaultNeed = async (data) => {
    let token = getTokenFromLocalStorage()
    if ('_id' in data) {
      delete data._id;
    }
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'defaultneed?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };


  export const addContact = async (data,loanid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    if ('_id' in data) {
      delete data._id;
    }
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'contacts?appname=' + Appname + "&token=" + token+'&loanid='+loanid+'&userid='+userData?.userId ,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const addFee = async (data) => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    if ('_id' in data) {
      delete data._id;
    }
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'dealfee?appname=' + Appname + "&token=" + token + "&userid=" + UserId,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  
  
  export const getUnderwritingstates = async () => {
    const promise = axios
      .get(
        APIUrl+"underwritingstates?appname="+Appname,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

   
  export const deleteNeed = async (data) => {
      let token = getTokenFromLocalStorage()
      const promise =  axios({
        method: 'DELETE',
        url: APIUrl + 'defaultneed?appname=' + Appname + "&token=" + token + "&needid="+data?.needid
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const deleteNeedGroup = async (data) => {
      let token = getTokenFromLocalStorage()
      const promise =  axios({
        method: 'DELETE',
        url: APIUrl + 'groupneed?appname=' + Appname + "&token=" + token + "&needid="+data?.needid
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const addDefaultNeedGroup = async (data) => {
      let token = getTokenFromLocalStorage()
      if ('_id' in data) {
        delete data._id;
      }
      const promise =  axios({
        method: 'POST',
        url: APIUrl + 'groupneed?appname=' + Appname + "&token=" + token ,
        data: data,
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const getFormTemplates = async (pagenum,assetCount , searchterm) => {
      let token = getTokenFromLocalStorage()
      const promise = axios
        .get(
          APIUrl+"formtemplates?appname="+Appname + "&token=" + token +
          "&pageNumber=" + pagenum + "&assetcount=" + assetCount + "&search=" + searchterm,
          { type: "application/json" }
        )
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };
    export const searchTemplate = async (currentPage,perPage,searchterm) => {
      let token = getTokenFromLocalStorage()
      const promise = axios
        .get(
          APIUrl+"formtemplates?appname="+Appname + "&token=" + token + "&pageNumber=" + currentPage + "&assetcount=" + perPage + "&search="+searchterm,
          { type: "application/json" }
        )
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };
    export const getSingleFormTemplate = async (id) => {
      let token = getTokenFromLocalStorage()
      const promise = axios
        .get(
          APIUrl+"formtemplates?appname="+Appname + "&token=" + token + "&templateid="+id,
          { type: "application/json" }
        )
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };
    export const getIndividualLoansContentApiData = async (templateName,loanid, templateid,formData,licencenumber) => {
      let token = getTokenFromLocalStorage();
      let userData = getUserDataFromLocalStorage();
      const url = `sendLoanEmail?appname=${Appname}&token=${token}&loanid=${loanid}&pagename=formtemplates&templateid=${templateid}&templatename=${templateName}${formData?.lendername ? `&lendername=${formData?.lendername }` : ''}${formData?.stateofformation ? `&stateofformation=${formData?.stateofformation}` : ''}${formData?.entity ? `&entity=${formData?.entity}` : ''}${licencenumber ? `&licencenumber=${licencenumber}` : ''} `;
      const promise =  axios({
        method: 'POST',
        url: APIUrl + url
      })
        .then(function (response) {
          if (response ) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const getAllloanpackage = async (templateNames, loanid, lendername, stateofformation, entity, licenceNumber,packageName,fileType) => {
      let token = getTokenFromLocalStorage();
      let userData = getUserDataFromLocalStorage();
      let data = {
        "loanid": loanid,
        "templatenames": templateNames,
        "lendername": lendername,
        "stateofformation": stateofformation,
        "entity": entity,
        "licencenumber":licenceNumber,
        "packageName" : packageName,
    }
    // old api is loanpackage new one is loanpackagev2 updated on 2jan by ganesh s
      const url = `loanpackagev2?appname=${Appname}&token=${token}&loanid=${loanid}&pagename=formtemplates&saveAs=pdf`;
      const promise =  axios({
        method: 'POST',
        url: APIUrl + url,
        data: data,
      })
        .then(function (response) {
          if (response ) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    // files single get 
    export const getDealFilesApi = async (loanid,docid) => {
      let token = getTokenFromLocalStorage()
      const promise =  axios({
        method: 'GET',
        url: APIUrl + 'loandocuments?appname=' + Appname + "&token=" + token +'&loanid='+loanid + '&documentid=' + docid
      })
        .then(function (response) {
          if (response ) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const addFormTamplate = async (data) => {
      let token = getTokenFromLocalStorage()
      // if ('_id' in data) {
      //   delete data._id;
      // }
      const promise =  axios({
        method: 'POST',
        url: APIUrl + 'formtemplates?appname=' + Appname + "&token=" + token ,
        data: data,
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const deleteFormTemplate = async (id) => {
      let token = getTokenFromLocalStorage()
      const promise =  axios({
        method: 'DELETE',
        url: APIUrl + 'formtemplates?appname=' + Appname + "&token=" + token + "&templateid="+id
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const updateUnderwritingStates = async (data) => {
      let token = getTokenFromLocalStorage();
      let userData = getUserDataFromLocalStorage();
      let UserId = userData?.userId;
      const promise = 
        axios({
          method: 'POST',
          url: APIUrl + '/underwritingstates?appname=' + Appname + "&token=" + token ,
          data: data,
        }).then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };
    
    export const getLOSFees = async () => {
      let token = getTokenFromLocalStorage()
      const promise = axios
        .get(
          APIUrl+"fee?appname="+Appname + "&token=" + token ,
          { type: "application/json" }
        )
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };
    export const addLOSFees = async (data) => {
      let token = getTokenFromLocalStorage()
      // if ('_id' in data) {
      //   delete data._id;
      // }
      const promise =  axios({
        method: 'POST',
        url: APIUrl + 'fee?appname=' + Appname + "&token=" + token ,
        data: data,
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const deleteLOSFees = async (id) => {
      let token = getTokenFromLocalStorage()
      const promise =  axios({
        method: 'DELETE',
        url: APIUrl + 'fee?appname=' + Appname + "&token=" + token + "&id="+id
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const getTermSheets = async (pageNum,assetCount) => {
      let token = getTokenFromLocalStorage();
   
    
      // const promise = axios
      //   .get(`${APIUrl}termsheetsdata?appname=${Appname}&token=${token}${termId ? `&sheetid=${termId}` : ''}` , )
      //   .then(function (response) {
      //     if (response && response.data) {
      //       return response;
      //     }
      //   });
      const promise = axios
        .get(`${APIUrl}termsheetsdata?appname=${Appname}` +"&token=" +token + "&pageNumber=" + pageNum + "&assetcount=" + assetCount  , )
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
    
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const getSingleTermSheetManager = async (templateid) => {
      let token = getTokenFromLocalStorage();
   
    
      const promise = axios
        .get(`${APIUrl}termsheetsdata?appname=${Appname}` + "&token" + token + "&templateid=" + templateid , )
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      
    
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };
    
    export const addTermSheets = async (data) => {
      let token = getTokenFromLocalStorage()
      // if ('_id' in data) {
      //   delete data._id;
      // }
      const promise =  axios({
        method: 'POST',
        url: APIUrl + 'termsheetsdata?appname=' + Appname + "&token=" + token ,
        data: data,
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };

    export const deleteTermSheetsData = async (templateid) => {
      let token = getTokenFromLocalStorage()
      const promise =  axios({
        method: 'DELETE',
        url: APIUrl + 'termsheetsdata?appname=' + Appname + "&token=" + token + "&templateid="+templateid
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };


    export const deleteTermSheets = async (termid) => {
      let token = getTokenFromLocalStorage()
      const promise =  axios({
        method: 'DELETE',
        url: APIUrl + 'termsheets?appname=' + Appname + "&token=" + token + "&termsheetid="+termid,
        //data:{"_id":data?._id} 
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };


  export const getselectedUser = async (userID) => {
    let userData = getUserDataFromLocalStorage();
    let UserId = userID;
    let token = getTokenFromLocalStorage()
    const promise = axios
      .get(
        APIUrl+"user?appname="+Appname+"&token="+token+"&userid="+UserId,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response?.data?.result?.[0]) {
          const combinedData = {
            ...userData,
            ...response?.data?.result?.[0]?.userInfo || response?.data?.result?.[0],
          };
    // console.log('combinedDatahere',response?.data?.result?.[0], combinedData);
          // Save the combined data to local storage
          // saveUserDataLocalStorage(combinedData);
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const createNoteTemplates = async (data) => {
    let token = getTokenFromLocalStorage()
    // if ('_id' in data) {
    //   delete data._id;
    // }
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'usertemplates?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getUserNoteTemplates = async (uid) => {
    let token = getTokenFromLocalStorage()
    // if ('_id' in data) {
    //   delete data._id;
    // }
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'usertemplates?appname=' + Appname + "&token=" + token +"&userid=" +uid,
     
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getTemplateInfo = async (usrId,tempId) => {
    let token = getTokenFromLocalStorage()
    // if ('_id' in data) {
    //   delete data._id;
    // }
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'usertemplates?appname=' + Appname + "&token=" + token +"&userid=" +usrId + "&id=" + tempId,
     
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateNoteTemplate = async (data) => {
    let token = getTokenFromLocalStorage()
    // if ('_id' in data) {
    //   delete data._id;
    // }
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'usertemplates?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getFormPackage = async () => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'documentpackage?appname=' + Appname + "&token=" + token ,
      
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const addFormPackage = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'documentpackage?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateFormPackages = async (data) => {
    let token = getTokenFromLocalStorage()
  
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'documentpackage?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  
  export const deletePackage = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'DELETE',
      url: APIUrl + 'documentpackage?appname=' + Appname + "&token=" + token + "&id="+data?._id
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getAllEntities = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loan?appname=' + Appname + "&token=" + token,
      data: {
        'loanid' : loanid,
        'projection' : 'entity'
      }
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getSingleEnytity = async (data,loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'entityinfo?appname=' + Appname + "&token=" + token + "&loanid="+loanid +"&entityid=" + data.entityid
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const addEntity = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'PUT',
      url: APIUrl + 'addentity?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateEntity = async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'entity/update?appname=' + Appname + "&token=" + token +'&userid='+ UserId,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const deleteEntity = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'DELETE',
      url: APIUrl + 'deleteentity?appname=' + Appname+ "&entityid="+data?.entityid + "&token=" + token 
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      }).catch((err) => {
        // console.error.bind("Login error", err);
        return err;
      })
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  
  export const resendPassword = async (email) => {
    let token = getTokenFromLocalStorage()
    const promise = axios
      .post(
        APIUrl+"resendMail?appname="+Appname+"&token=" + token,
        {"emailid":email},
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      }).catch((err) => {
        // console.error.bind("Login error", err);
        return err;
      })
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getAllApplicants = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loan?appname=' + Appname + "&token=" + token,
      data: {
        'loanid' : loanid,
        'projection' : 'applicant'
      }
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getSingleApplicant = async (data,loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'applicantinfo?appname=' + Appname + "&token=" + token + "&loanid="+loanid +'&applicantid='+data.applicantid
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const addApplicant = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'PUT',
      url: APIUrl + 'addapplicant?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateApplicant = async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'applicant/update?appname=' + Appname + "&token=" + token + '&userid='+UserId,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const deleteApplicant = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'DELETE',
      url: APIUrl + 'deleteapplicant?appname=' + Appname+ "&applicantid="+data?.applicantid + "&token=" + token 
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getAllProperties = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loan?appname=' + Appname + "&token=" + token,
      data: {
        'loanid' : loanid,
        'projection' : 'propertyinfo'
      }
    }) 
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getSingleProperty = async (data,loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'property?appname=' + Appname + "&token=" + token +'&loanid='+loanid+ '&propertyid='+data.propertyid
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const addProperty = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'property?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateProperty = async (data) => {
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'property?appname=' + Appname + "&token=" + token +'&userid='+ UserId,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const deleteProperty = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'DELETE',
      url: APIUrl + 'property?appname=' + Appname + "&token=" + token +"&propertyid="+data.propertyid
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const loanUpdate = async (userData) => {
    let token = getTokenFromLocalStorage()
    const promise = axios
      .post(
        APIUrl+"updateLoan?appname="+Appname+"&token="+token+"&loancode="+userData?.loancode,
        userData,
        { type: "application/json" }
      )
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const genSingleLoanDeal = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loan?appname=' + Appname + "&token=" + token,
      data: {
        'loanid' : loanid,
        'projection' : 'deal'
      }
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  LoanDeal= async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'deal?appname=' + Appname + "&token=" + token +'&userid='+ UserId,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const  updateWorkflow = async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'deal?appname=' + Appname + "&token=" + token +"&userid="+UserId ,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  
  export const getAllDealFilesApi = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loandocuments?appname=' + Appname + "&token=" + token +'&loanid='+loanid
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const deleteDealFileApi = async (payload) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'DELETE',
      url: APIUrl + 'loandocuments?appname=' + Appname + "&token=" + token +'&loanid='+payload?.loanid ,
      data: payload?.documentid
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getLoanGroupNames = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loanfilegroup?appname=' + Appname + "&token=" + token +'&loanid='+loanid
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getSingleHmda = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'hmda?appname=' + Appname + "&token=" + token +'&loanid='+loanid
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  hmdaAdd = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'hmda?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getServicingSetupApi = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'servicingsetup?appname=' + Appname + "&token=" + token +'&loanid='+loanid
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  
  export const  addServicingSetupApi = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'servicingsetup?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  
  export const addLoanDocumentApi= async (data) => {
 
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'PUT',
      url: APIUrl + 'addloandocuments?appname=' + Appname + "&token=" + token +'&loanid='+data?.loanid ,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const updatedDealFileApi= async (data) => {
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'updateloandocuments?appname=' + Appname + "&token=" + token +'&loanid='+data?.loanid + '&documentid='+data.documentid +'&userid='+UserId,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
   
  export const getSavedNamedSearchApi = async (pageNumber,assetcount,payload) => {
    console.log('getSavedNamedSearchApi');

    // let sortBy = payload.sortBy ?? ""
    // let type = payload.type ?? "";
    // let payload2 = {...payload}
    // if(payload?.sortBy)  delete payload2.sortBy
    // if(payload?.type)  delete payload2.type
    const { sortBy, status, type, ...payload2 } = payload;
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
   
    const promise =  axios({
      method: 'POST',
      // url: APIUrl + 'loans?appname=' + Appname + "&token=" + token +'&userid='+UserId+'&projection=pipeline'+ "&pageNumber=" + pageNumber + "&assetcount=" + assetcount ,
      // url: `${APIUrl}loans?appname=${Appname}&token=${token}&userid=${UserId}&projection=pipeline&pageNumber=${pageNumber}&assetcount=${assetcount}${sortBy !== "" && sortBy !== undefined ? `&sortBy=${sortBy}` : ""}${type !== "" && type !== undefined ? `&type=${type}` : ""}`,
      url: `${APIUrl}loans?appname=${Appname}&token=${token}&userid=${UserId}&projection=pipeline&pageNumber=${pageNumber}&assetcount=${assetcount}${sortBy ? `&sortBy=${sortBy}` : ""}${type && sortBy ? `&type=${type}` : ""}${status ? `&status=${status}` : ""}`,
      data: payload2,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getSavedSearchListApi = async () => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
   
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'savedsearch?appname=' + Appname + "&token=" + token +'&userid='+UserId,
   
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const  addGroupNameApi= async (data) => {
    let payload = {'groupname':data.groupname}
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'PUT',
      url: APIUrl + 'addloanfilegroup?appname=' + Appname + "&token=" + token +'&loanid='+data?.loanid,
      data: payload,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getSingleLead = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loan?appname=' + Appname + "&token=" + token,
      data: {
        'loanid' : loanid,
        'projection' : 'lead'
      }
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  leadAdd = async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'lead?appname=' + Appname + "&token=" + token +"&userid=" + userData.userId,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  dealratepointsAdd = async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'rateandpoints?appname=' + Appname + "&token=" + token +"&userid=" + userData.userId,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  dealratepointsGet = async (loanid,projection) => {
    let token = getTokenFromLocalStorage();
    const typeParam = projection ? `&type=${projection}` : '';
const url = `${APIUrl}rateandpoints?appname=${Appname}&token=${token}&loanid=${loanid}${typeParam}`;
    const promise =  axios({
      method: 'GET',
      url: url
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  dealratepointsUpdate = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'rateandpoints?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getUnderwriting = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'dealunderwriting?appname=' + Appname + "&token=" + token +'&loanid='+loanid
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const  addUnderwriting = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'dealunderwriting?appname=' + Appname + "&token=" + token ,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  categoriesforloan= async () => {
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'lookups?appname=' + Appname + "&lookupCode=" + 10026+'&showAllData=true' +'&projection=lookups',
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  loanUnderwritingData= async (loanid) => {
    let token = getTokenFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loan/underwriting?appname=' + Appname + "&token=" + token + "&loanid=" + loanid ,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  LoanTermDropdown= async () => {
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'lookups?appname=' + Appname + "&lookupCode=" + 10018 + '&showAllData=true' +'&projection=lookups',
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const  lookupsCallForPipeline= async (code) => {
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'lookups?appname=' + Appname + "&lookupCode=" + code + '&showAllData=true' +'&projection=lookups',
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  underwritingPipelineUpdate= async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'updateloanunderwriting?appname=' + Appname + "&token=" + token +"&userid="+ userData?.userId,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getAllTasks = async (loanid) => {
    let token = getTokenFromLocalStorage();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    let userData = getUserDataFromLocalStorage();
  
    const promise = axios
      .get( APIUrl + "task?appname=" + Appname + "&token=" + token + "&userid=" + userData.userId)
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
  
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };


  export const getLoanassignment = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loan?appname=' + Appname + "&token=" + token+"&loanid="+loanid,
      data: {
        'loanid' : loanid,
        'projection' : 'loanassignment'
      }
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const addloanassignment = async (data,loanid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'updateworkflow?appname=' + Appname + "&token=" + token +"&loanid="+loanid+'&userid='+userData?.userId,
      data: data
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getNotes = async (loanid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'getNotes?appname=' + Appname + "&token=" + token + "&loanid=" + loanid 
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const addNotes = async (data) => {
    // const {loanid, ...dataPayload }= data
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'addNotes?appname=' + Appname + "&token=" + token,
      data: {...data, "userid" : userData?.userId,"username":userData?.username },
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const addToolWorkflowAlerts = async (data,loanid) => {
    let token = getTokenFromLocalStorage();
    const user_data = JSON.parse(localStorage.getItem("userdata"));
    const user_id = user_data?.userId;
    // // console.log(user_id);
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'addalert?appname=' + Appname + "&token=" + token + "&loanid=" + loanid + "&userid="+user_id,
      data: data
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getToolWorkflowAlerts = async (loanid, getallAlerts) => {
    let token = getTokenFromLocalStorage();
    const user_data = JSON.parse(localStorage.getItem("userdata"));
    const user_id = user_data?.userId;
    // // console.log(user_id);
    const url = getallAlerts ?
  `${APIUrl}alerts?appname=${Appname}&token=${token}&loanid=${loanid}&userid=${user_id}&getAllAlerts=${getallAlerts}` :
  `${APIUrl}alerts?appname=${Appname}&token=${token}&loanid=${loanid}&userid=${user_id}`;

    const promise =  axios({
      method: 'GET',
      url: url,
     
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const deleteToolWorkflowAlert = async (alert_id) => {
    let token = getTokenFromLocalStorage();
    const user_data = JSON.parse(localStorage.getItem("userdata"));
    const user_id = user_data?.userId;
    // // console.log(user_id);
    const promise =  axios({
      method: 'DELETE',
      url: APIUrl + 'alerts?appname=' + Appname +  "&token=" + token + "&alertid=" + alert_id + "&userid=" + user_id,
     
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getNeeds = async (loanid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'needs?appname=' + Appname + "&token=" + token+"&loanid="+loanid +'&userid='+userData?.userId
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const addNeeds = async (data,loanid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'PUT',
      url: APIUrl + 'addneed?appname=' + Appname + "&token=" + token+"&loanid="+loanid+'&userid='+userData?.userId,
      data: data,
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateNeed = async (data,loanid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'updateneed?appname=' + Appname + "&token=" + token+"&loanid="+loanid+'&userid='+userData?.userId,
      data: data,
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };


  export const addNeedNote = async (data,loanid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'PUT',
      url: APIUrl + 'addnote?appname=' + Appname + "&token=" + token+"&loanid="+loanid+'&userid='+userData?.userId,
      data: data,
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const DeleteNeedNote = async (data,loanid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'DELETE',
      url: APIUrl + 'deletenote?appname=' + Appname + "&token=" + token+"&loanid="+loanid +'&userid='+userData?.userId+"&noteid="+data?.noteid,
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const addEmailManager = async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'emailtemplates?appname=' + Appname + "&token=" + token + '&userid=' + userData?.userId,
      data: data,
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getAllEmails = async () => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'emailtemplates?appname=' + Appname + "&token=" + token +'&userid='+userData?.userId 
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getAllEmailsAdmin = async (pagenum,assetCount , searchterm) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      // url: APIUrl + 'emailtemplates?appname=' + Appname + "&token=" + token +'&userid='+userData?.userId+"&projection=emailtemplates"
      url: APIUrl + 'emailtemplates?appname=' + Appname + "&token=" + token +'&userid='+userData?.userId+"&projection=emailtemplates" +
      "&pageNumber=" + pagenum + "&assetcount=" + assetCount + "&search=" + searchterm, 
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getSingleEmailTemplateAdmin = async (templateId) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'emailtemplates?appname=' + Appname + "&token=" + token  +'&userid='+userData?.userId +'&templateid='+ templateId 
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getSingleEmailTemplateData = async (templateId,loanId) => {
    let token = getTokenFromLocalStorage();
    //let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'sendLoanEmail?appname=' + Appname + "&token=" + token +'&templateid='+ templateId + '&loanid='+loanId + '&pagename=emailtemplates' 
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getSingleEmail = async (tempid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'emailtemplates?appname=' + Appname + "&token=" + token +'&userid='+userData?.userId + "&templateid="+tempid 
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getIndividualLoansContentApi = async (templateName,loanid, templateid,formData,licencenumber,fileType) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    // const url = `sendLoanEmail?appname=${Appname}&token=${token}&loanid=${loanid}&pagename=formtemplates&templateid=${templateid}&templatename=${templateName}${formData?.lendername ? `&lendername=${formData?.lendername }` : ''}${formData?.stateofformation ? `&stateofformation=${formData?.stateofformation}` : ''}${formData?.entity ? `&entity=${formData?.entity }` : ''}${licencenumber ? `&licencenumber=${licencenumber}` : ''} ${fileType ==='word' ? `&saveAs=${fileType}` : ''}`;
    const url = `sendLoanEmailv2?appname=${Appname}&token=${token}&loanid=${loanid}&pagename=formtemplates&templateid=${templateid}&templatename=${templateName}${formData?.lendername ? `&lendername=${formData?.lendername }` : ''}${formData?.stateofformation ? `&stateofformation=${formData?.stateofformation}` : ''}${formData?.entity ? `&entity=${formData?.entity }` : ''}${licencenumber ? `&licencenumber=${licencenumber}` : ''} ${`&saveAs=${'pdf'}`}`;
    const promise =  axios({
      method: 'POST',
      url: APIUrl + url
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getTermSheetPDF = async (loanid, termSheet) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'sendLoanEmail?appname=' + Appname + "&token=" + token+"&loanid="+loanid+"&pagename=underwritingtermssheet&termSheet="+termSheet
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const getUnderwritingPDF = async (loanid, termSheet) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'underwritingtemplate?appname=' + Appname + "&token=" + token+"&loanid="+loanid+"&pagename=underwritingtermssheet&termSheet="+termSheet
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getCleartoClose = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loan?appname=' + Appname + "&token=" + token+"&loanid="+loanid,
      data: {
        'loanid' : loanid,
        'projection' : 'cleartoclose'
      }
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const addCleartoclose = async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'cleartoclose?appname=' + Appname + "&token=" + token + '&userid='+ userData?.userId,
      data: data,
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getWireInstructions = async (loanid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loan?appname=' + Appname + "&token=" + token+"&loanid="+loanid,
      data: {
        'loanid' : loanid,
        'projection' : 'wireinstructions'
      }
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const addWireInstructions = async (data) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'wireinstruction?appname=' + Appname + "&token=" + token  + '&userid='+ userData?.userId,
      data: data,
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const updateFee = async (data) => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'dealfee?appname=' + Appname + "&token=" + token + '&userid='+ userData?.userId,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const   manageMostRecent = async (data, loanid) => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    // console.log('datadatadata',data);
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'addRecentLoan?appname=' + Appname + "&token=" + token +"&loanid="+loanid+'&userid='+userData?.userId ,
      data: data,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  
  export const addShareSearchApi = async (payload) => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'sharesearch?appname=' + Appname + "&token=" + token   +'&userid='+userData?.userId ,
      data:payload
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const   getRecentLoans = async () => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'recentLoans?appname=' + Appname  +'&userid='+userData?.userId ,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };


  export const   getloanHistory = async (loanid,assignment,workflow,type,field) => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    const url = `loanhistory?appname=${Appname}&token=${token}&userid=${userData?.userId}${type ? `&type=${type}` : ''}&loanid=${loanid}${workflow ? `&workflow=${workflow}` : ''}${assignment ? `&assignment=${assignment}` : ''}${field ? `&field=${field}` : ''}`;
    const promise =  axios({
      method: 'POST',
      url: APIUrl + url
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const  getloanStatus = async (loanid,assignment,workflow,type) => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    const url = `loanStatusHistory?appname=${Appname}&token=${token}&userid=${userData?.userId}&loanid=${loanid}`;
    const promise =  axios({
      method: 'POST',
      url: APIUrl + url
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getTasksApi = async (payload) => {
    let status = payload?.status ?? "";
    let loanid = payload?.loanid ?? "";
    let projection = payload?.projection ?? "";
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + `task?appname=${Appname}&token=${token}${status !== "" && status !== undefined ? `&status=${status}` : ""}${loanid !== "" && loanid !== undefined ? `&loanid=${loanid}` : ""}${userData?.userId !== "" && userData?.userId !== undefined ? `&userid=${userData?.userId}${projection ? `&projection=${projection}` : ''}` : ""}`,
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const addTasksApi = async (payload) => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + `addTask?appname=${Appname}&token=${token}&userid=${userData?.userId}`,
      data:payload
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const updateTasksApi = async (payload) => {
      let payload2 ={...payload}
      delete payload2['taskid']
      let token = getTokenFromLocalStorage()
      let userData = getUserDataFromLocalStorage();
      const promise =  axios({
        method: 'POST',
        url: APIUrl + `updateTasks?appname=${Appname}&token=${token}&userid=${userData?.userId}&taskid=${payload?.taskid}`,
        data:payload2
      })
        .then(function (response) {
          if (response && response.data) {
            return response;
          }
        });
      const dataPromise = promise.then((response) => response.data);
      return dataPromise;
    };
  export const   updateloanStatus = async (loanid, data) => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'updateLoanStatus?appname=' + Appname  +'&userid='+userData?.userId+'&loanid='+loanid+"&token="+token ,
      data: data
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const   getStagesForList = async () => {
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loansData?appname=' + Appname  +'&userid='+userData?.userId ,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateUserColumns = async (content) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    let mainData = {userid: UserId, pipelinecolumns: content}
    const promise = 
      axios({
        method: 'POST',
        url: APIUrl + 'user?appname=' + Appname + "&token=" + token+"&userid="+UserId,
        data: mainData,
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const deleteEmailManager = async (tempid) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'DELETE',
      url: APIUrl + 'emailtemplates?appname=' + Appname + "&token=" + token + "&templateid=" +tempid
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };


  export const updateAlertStatus = async (loanid, alertid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = 
      axios({
        method: 'POST',
        url: APIUrl + 'donotShowAlerts?appname=' + Appname + "&token=" + token + "&alertid=" +alertid+ "&loanid=" +loanid +"&userid="+UserId
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getBorrowerExposure = async (loanid) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = 
      axios({
        method: 'POST',
        url: APIUrl + 'borrowerExposure?appname=' + Appname + "&token=" + token + "&userid="+UserId +"&loanid=" +loanid
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const addBorrowerExposure = async (loanid,apiData) => {
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = 
      axios({
        method: 'POST',
        url: APIUrl + 'borrowerExposureLoanUpdate?appname=' + Appname + "&token=" + token + "&userid="+UserId +"&loanid=" +loanid,
        data: apiData
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const sendEmail = async (emailpayload) => {
    let token = getTokenFromLocalStorage();
    
    const promise = 
      axios({
        method: 'POST',
        url: APIUrl + 'sendmail?appname=' + Appname + "&token=" + token ,
        data: emailpayload,
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getEmailsHistory = async (loan_id,pagename) => {
    let token = getTokenFromLocalStorage()
    //let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'emailhistory?appname=' + Appname + "&token=" + token + "&loanid=" +loan_id + '&pagename='+ pagename,
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getSingleEmailHistory = async (uuid) => {
    let token = getTokenFromLocalStorage()
    //let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'emailhistory?appname=' + Appname + "&token=" + token + "&id=" +uuid,
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  
export const getFileTemplates = async (uuid) => {
    let token = getTokenFromLocalStorage()
    //let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'createloanfiles?appname=' + Appname + "&token=" + token,
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getSingleTermSheetPrefill = async (termid) => {
    let token = getTokenFromLocalStorage();
    //let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'termsheets?appname=' + Appname + "&token=" + token +'&termsheetid=' + termid
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getSingleTermSheetPrefillState = async (programtype, state) => {
    let token = getTokenFromLocalStorage();
    //let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'termsheets?appname=' + Appname + "&token=" + token +'&programtype=' + programtype+'&state=' + state
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const  sendOriginatorInstructionsToEmail = async (data, loanId) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'POST',
      url: APIUrl+ 'sendOriginationEmail?appname=' + Appname + "&token=" + token+ "&loanid="+loanId ,
      data: data,
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const getPipelinemenus = async () => {
    let token = getTokenFromLocalStorage();
    //let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'pipelinemenus?appname=' + Appname + "&token=" + token
    })
      .then(function (response) {
        if (response ) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  
  export const   loanidsget = async () => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'loans?appname=' + Appname  +'&userid='+userData?.userId + '&loans=loans' ,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const   checkContacts = async (emailid) => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    const promise =  axios({
      method: 'GET',
      url: APIUrl + 'checkcontact?appname=' + Appname+ "&token=" + token + '&emailid='+emailid ,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const deleteSavedSearchListApi = async (searchid) => {
    let token = getTokenFromLocalStorage()
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
   
    const promise =  axios({
      method: 'POST',
      url: APIUrl + 'deleteSearch?appname=' + Appname + "&token=" + token +'&searchid=' + searchid + '&userid='+UserId,
   
    })
      .then(function (response) {
        if (response) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };
  export const deleteFee = async (data) => {
    let token = getTokenFromLocalStorage()
    const promise =  axios({
      method: 'DELETE',
      url: APIUrl + 'dealfee?appname=' + Appname + "&token=" + token + '&feeid=' + data?.feeid,
    })
      .then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const servicingUpdate = async (apiData,loanid,pagename) => {
      let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = 
      axios({
        method: 'POST',
        url: `${APIUrl}updateLoan?appname=${Appname}${pagename ? `&pagename=${pagename}` : ''}&token=${token}&userid=${UserId}&loanid=${loanid}`,
        data: apiData
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const createFeeManagement = async (feeData) => {
    delete feeData['_id'];
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = 
      axios({
        method: 'PUT',
        url: APIUrl + 'addServiceFee?appname=' + Appname +"&token=" + token + "&userid="+UserId,
        data: feeData
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

  export const updateFeeManagement = async (apiData,loanid,feeid) => {
    delete apiData['_id']
    let token = getTokenFromLocalStorage();
    let userData = getUserDataFromLocalStorage();
    let UserId = userData?.userId;
    const promise = 
      axios({
        method: 'POST',
        url: APIUrl + 'updateServiceFee?appname=' + Appname +"&token=" + token + "&userid="+UserId +"&loanid=" +loanid + "&feeid=" +feeid,
        data: apiData
      }).then(function (response) {
        if (response && response.data) {
          return response;
        }
      });
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  };

export const deleteFeeManagement = async (loanid,feeid) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'DELETE', 
    url: APIUrl + 'deleteServiceFee?appname=' + Appname + "&token=" + token + "&userid="+UserId +"&loanid=" +loanid + "&feeid=" +feeid,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const  getAllServiceFee = async (loanid,pagename) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: `${APIUrl}getServiceFee?appname=${Appname}${pagename ? `&pagename=${pagename}` : ''}&token=${token}&userid=${UserId}&loanid=${loanid}`,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const  singleFeeGet = async (loanid,feeid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'getServiceFee?appname=' + Appname+ "&token=" + token +"&userid="+UserId +"&loanid=" +loanid + "&feeid=" +feeid,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};
  
export const  getAllLateFee = async (loanid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'getLateFee?appname=' + Appname+ "&token=" + token +"&userid="+UserId +"&loanid=" +loanid ,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const AddLateFee= async (data) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'PUT',
    url: APIUrl + 'addLateFee?appname=' + Appname + "&token=" + token + "&userid="+UserId  +'&loanid='+data?.loanid ,
    data: data,
  })
    .then(function (response) {
      if (response) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const updateLateFee = async (apiData,loanid) => {
  delete apiData['_id']
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + 'updateLateFee?appname=' + Appname +"&token=" + token + "&userid="+UserId +"&loanid=" +loanid + "&feeid=" +apiData?.feeid,
      data: apiData
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const deleteLateFee = async (loanid,feeid) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'DELETE', 
    url: APIUrl + 'deleteLateFee?appname=' + Appname + "&token=" + token + "&userid="+UserId +"&loanid=" +loanid + "&feeid=" +feeid,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const  singleLateFeeGet = async (loanid,feeid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'getLateFee?appname=' + Appname+ "&token=" + token +"&userid="+UserId +"&loanid=" +loanid + "&feeid=" +feeid,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getUserPermissionsAdmin = async () => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'userPermissions?appname=' + Appname +"&userid=" + UserId + "&token=" + token,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const addUserPermissionsAdmin = async (addData) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  addData.appname = Appname;
  const promise =  axios({
    method: 'PUT',
    url: APIUrl + 'adddUserPermissions?appname=' + Appname + "&userid=" + UserId  +"&token=" + token,
    data: addData
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const updateUserPermissionsAdmin = async (updateData,roleId) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  updateData.appname = Appname;
  const promise =  axios({
    method: 'POST',
    url: APIUrl + 'updateUserPermissions?appname=' + Appname + "&userid=" + UserId  +"&roleid=" + roleId,
    data: updateData
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getSingleUserPermissionsAdmin = async (roleid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'userPermissions?appname=' + Appname + "&userid=" + UserId  +"&roleid=" + roleid,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const deleteUserRoleAdmin = async (roleId) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'DELETE', 
    url: APIUrl + 'deleteUserPermissions?appname=' + Appname  + "&userid="+UserId +"&roleid=" +roleId ,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const  getPaytable = async (loanid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'payTable?appname=' + Appname+ "&token=" + token +"&loanid=" +loanid ,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const addPayment= async (data, paymentType) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  let url = APIUrl + 'payments?appname=' + Appname + "&token=" + token + "&userid="+UserId  +'&loanid='+data?.loanid;
  if(paymentType == 'edit'){
    url= url+'&paymentid='+data?.paymentid;
    delete data._id;
  }
  const promise =  axios({
    method: paymentType == 'edit' ? 'POST' :'PUT',
    url: url,
    data: data,
  })
    .then(function (response) {
      if (response) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const  getPayments = async (loanid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'payments?appname=' + Appname+ "&token=" + token +"&loanid=" +loanid ,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getWarmAppEmailDetails = async (emailData) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'POST',
    url: APIUrl + 'userData?appname=' + Appname +"&token=" + token,
    data: emailData
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const  getloanSummary = async (loanid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'paymentsSummery?appname=' + Appname+ "&token=" + token +"&loanid=" +loanid ,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getActiveContacts = async (currentPage,perPage,loanid) => {
  let token = getTokenFromLocalStorage();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const promise = axios
    .post(`${APIUrl}getcontacts?appname=${Appname}&pageNumber=${currentPage}&assetcount=${perPage}&loanid=${loanid}`, config)
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });

  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getContactLoans = async (contactid) => {
  let token = getTokenFromLocalStorage();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const promise = axios
    .post(`${APIUrl}getcontacts?appname=${Appname}&contactid=${contactid}`, config)
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });

  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const  getValuesDropdown = async () => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'templateFeilds?appname=' + Appname + '&token=' + token,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};


export const  getAllDraws = async (loanid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'drawrequest?appname=' + Appname+ "&token=" + token +"&loanid=" +loanid ,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const  getSingleDraw = async (loanid,drawrequestid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'GET',
    url: APIUrl + 'drawrequest?appname=' + Appname+ "&token=" + token + "&drawrequestid="+ drawrequestid +"&loanid=" +loanid ,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const addDrawRequest = async (loanid,data) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'PUT',
      url: APIUrl + '/drawrequest?appname=' + Appname + "&token=" + token+"&loanid=" + loanid+"&userid="+UserId, 
      data: data,
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const updateDrawRequest = async (loanid,data) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/drawrequest?appname=' + Appname + "&token=" + token +"&loanid=" + loanid+"&userid="+UserId, 
      data: data,
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const savePayoffData = async (loanid,data,param) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  let urlNew = null;
  if(param === "sendemail"){
    urlNew = APIUrl + '/payoff?appname=' + Appname + "&userid="+UserId + "&loanid=" + loanid + "&sendemail=true"
  }else{
    urlNew = APIUrl + '/payoff?appname=' + Appname + "&userid="+UserId + "&loanid=" + loanid 
  }
   
  const promise = 
    axios({
      method: 'POST',
      url: urlNew, 
      data: data,
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getPayoffData = async (loanid) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'GET',
      url: APIUrl + '/payoff?appname=' + Appname + "&loanid=" + loanid, 
     
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const drawDeleteApi = async (loanid,drawrequestid) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'DELETE', 
    url: APIUrl + 'drawrequest?appname=' + Appname + "&userid="+UserId + "&loanid=" + loanid +"&drawrequestid=" +drawrequestid ,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getRecurringBillingData = async (loanid) => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'GET',
      url: APIUrl + '/recurringBilling?appname=' + Appname + "&loanid=" + loanid+"&userid="+UserId, 
     
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const updateRecurringBillingData = async (data, loanid) => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  delete data._id;
  const promise =  axios({
    method: 'POST',
    url: APIUrl + 'recurringBilling?appname=' + Appname +"&userid="+UserId+ "&loanid=" + loanid ,
    data: data,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};
export const  getAudit = async (loanid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'POST',
    url: APIUrl + 'serviceAuditData?appname=' + Appname+ "&token=" +token+ "&pagename=serviceaudit" +"&loanid=" +loanid +"&userid="+UserId,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};
export const  getExtension = async (loanid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'POST',
    url: APIUrl + 'serviceAuditData?appname=' + Appname+ "&token="+ token + "&pagename=loanmodifications" +"&loanid=" +loanid +"&userid="+UserId,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const  saveLoanModification = async (loanid,param,data) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'POST',
    url: APIUrl + 'loanmodifications?appname=' + Appname + "&userid=" + UserId + "&loanid=" +loanid +"&pagename="+param,
    data: data,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getLoanModificationData = async (loanid,pagename,count,pagenum) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/serviceAuditData?appname=' + Appname + "&token=" + token  + "&pagename=" + pagename + "&loanid=" + loanid + "&userid=" + UserId
      + "&assetcount=" + count + "&pageNumber=" + pagenum, 
     
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const interestDeleteApi = async (loanid,auditId) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'DELETE', 
    url: APIUrl + 'loanmodifications?appname=' + Appname + "&userid="+UserId + "&loanid=" + loanid +"&auditid=" +auditId ,
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const orderInspectionEmail = async (loanid,data) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/orderinspectionmail?appname=' + Appname + "&token=" + token  + "&loanid=" + loanid + "&userid=" + UserId,
      data : data
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const paymentsReminder = async (loanid) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/sendremainder?appname=' + Appname + "&token=" + token  + "&loanid=" + loanid + "&userid=" + UserId,
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const sendExcelDataForm1098 = async (data) => {
 
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
  axios({
    method: 'POST',
    url: APIUrl + '/import?appname=' + Appname + "&userid=" + UserId,
    data : data
  }).then(function (response) {
    if (response && response.data) {
      return response;
    }
  });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
  
};

export const  generateForms1098 = async (data) => {
  let token = getTokenFromLocalStorage();
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise =  axios({
    method: 'POST',
    url: APIUrl + 'multiform1098?appname=' + Appname + "&token=" + token + "&userid="+UserId,
    data: data,
  })
    .then(function (response) {
      if (response) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getAllFundsAdmin = async () => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/getfunds?appname=' + Appname +"&userid="+UserId, 
     
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const createFundAdmin = async (data) => {
 
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
  axios({
    method: 'PUT',
    url: APIUrl + '/funds?appname=' + Appname + "&userid=" + UserId,
    data : data
    
  }).then(function (response) {
    if (response && response.data) {
      return response;
    }
  });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
  
};

export const updateFundsAdmin = async (data,fundid) => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/funds?appname=' + Appname +  "&fundid=" + fundid + "&userid=" + UserId, 
      data : data
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getSingleFundAdmin = async (fundid) => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/getfunds?appname=' + Appname +  "&fundid=" + fundid + "&userid=" + UserId, 
    
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getForm1098LoanIds = async () => {
  let userData = getUserDataFromLocalStorage();
  //let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/loans?appname=' + Appname + '&pagename=form1098', 
    
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const sendStatementPayments = async (loanid,pageName) => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  let urlNew = (pageName == "preview") ? APIUrl + '/paymentsSummery?appname=' + Appname + '&preview=true' + '&pagename=sendstatement' + '&loanid=' + loanid + '&userid=' + UserId :
  APIUrl + '/paymentsSummery?appname=' + Appname + '&pagename=sendstatement' + '&loanid=' + loanid + '&userid=' + UserId;
  const promise = 
    axios({
      method: 'GET',
      url: urlNew, 
    
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const createFundManagementAdmin = async (data) => {
 
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
  axios({
    method: 'PUT',
    url: APIUrl + '/fundmanagement?appname=' + Appname + "&userid=" + UserId,
    data : data
    
  }).then(function (response) {
    if (response && response.data) {
      return response;
    }
  });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
  
};


export const getAllFundManagmentAdmin = async (pagenum,assetCount,type,searchterm) => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/fundmanagement?appname=' + Appname +"&userid="+UserId +
      "&pageNumber=" + pagenum + "&assetcount=" + assetCount + "&type="+ type + "&search=" + searchterm, 
     
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getSingleFundManagementAdmin = async (uuId) => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/fundmanagement?appname=' + Appname  + "&userid=" + UserId +  "&uuid=" + uuId , 
    
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const updateFundManagementAdmin = async (data,uuid) => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/updateFunds?appname=' + Appname + "&userid=" + UserId +  "&uuid=" + uuid , 
      data : data
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const deleteFundManagementAdmin = async (uuid) => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'DELETE',
      url: APIUrl + '/fundmanagement?appname=' + Appname + "&userid=" + UserId +  "&uuid=" + uuid , 
      
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getAllInvestors = async (pageNumber,perpage,search,filter,fundIds) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}investor?appname=${Appname}&token=${token}&userid=${UserId}&pageNumber=${pageNumber}&assetcount=${perpage}${search ? `&search=${search}` : ''}${filter ? `&fundid=${filter}` : ''}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
      data : fundIds
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getSingleInvestor = async (uuid) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/investor?appname=' + Appname + "&token=" + token +"&userid="+UserId +"&uuid=" +uuid 
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const addInvestor = async (data) => {
 
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
  axios({
    method: 'PUT',
    url: APIUrl + 'addinvestor?appname=' + Appname+ "&token=" + token + "&userid=" + UserId,
    data : data
    
  }).then(function (response) {
    if (response && response.data) {
      return response;
    }
  });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
  
};
export const updateInvestor = async (data,uuid) => {
 
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
  axios({
    method: 'POST',
    url: APIUrl + '/updateinvestor?appname=' + Appname+ "&token=" + token + "&userid=" + UserId +"&uuid=" +uuid ,
    data : data
    
  }).then(function (response) {
    if (response && response.data) {
      return response;
    }
  });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
  
};

export const addTransaction = async (data) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
  axios({
    method: 'POST',
    url: APIUrl + '/addDeposit?appname='
     + Appname
    // + "&token=" + token 
     + "&userid=" + UserId + "&investorid=" + data.investorid,
    data : data
    
  }).then(function (response) {
    if (response && response.data) {
      return response;
    }
  });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getTransaction = async (pageNumber,perpage,investorid,type,sortBy) => {
  let token = getTokenFromLocalStorage();
  
  const url = `${APIUrl}getTransactions?appname=${Appname}&token=${token}

  &pageNumber=${pageNumber}&assetcount=${perpage}
&status=ACTIVE
  ${`&investorid=${investorid}`}${type.fromdate ? `&from=${type.fromdate}` : ''}${type.todate ? `&to=${type.todate}` : ''}${sortBy ? `&sortby=${sortBy}` : ''}`;
  const promise =  axios({
    method: 'GET',
    url: url,
   
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const fundSummary = async (pageNumber,perpage,filter) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}fundsummary?appname=${Appname}&token=${token}&userid=${UserId}&pageNumber=${pageNumber}&assetcount=${perpage}${filter ? `&uuid=${filter}` : ''}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const investorStatements = async (startdate,enddate,fundid,investorid,singleinvestordata) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  let url = '';
  if(singleinvestordata){
      url = `${APIUrl}generateStatement?appname=${Appname}&token=${token}&userid=${UserId}&investorid=${investorid}&startDate=${startdate}&endDate=${enddate}&fundid=${fundid}&sendEmail=${true}`;
  }else{
    url = `${APIUrl}generateStatement?appname=${Appname}&token=${token}&userid=${UserId}&investorid=${investorid}&startDate=${startdate}&endDate=${enddate}&fundid=${fundid}`;
  }  
  const promise = 
    axios({
      method: 'POST',
      url: url,
      data : singleinvestordata
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const defaultBulkInvestorTemplate = async () => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}generateStatement?appname=${Appname}&token=${token}&userid=${UserId}&bulkTemplate=true`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const achReportApiGet = async (formData) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}nachoreport?appname=${Appname}&token=${token}&fromdate=${formData?.fromdate}&todate=${formData?.todate}&effectivedate=${formData?.effectivedate}${formData?.nsf ? '&nsf='+formData?.nsf : '' }`;
  const promise = 
    axios({
      method: 'GET',
      url: url,
      // data : formData
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const achReportApi = async (checkedData,formData,fileType,transactiontype) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}nachoreport?appname=${Appname}&token=${token}&filetype=${fileType}&transactiontype=${transactiontype}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
      data : {effectivedate : formData?.effectivedate ,paymentData :[...checkedData] }
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getDetailedTransactions = async (pageNumber,perpage,fundid,year) => {
  let token = getTokenFromLocalStorage();
  
  const url = `${APIUrl}detailedTransactions?appname=${Appname}&token=${token}

  &pageNumber=${pageNumber}&assetcount=${perpage}

  ${`&fundid=${fundid}`}${year ? `&year=${year}` : ''}`;
  const promise =  axios({
    method: 'GET',
    url: url,
   
  })
    .then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const bulkInvestorStatements = async (pullstartdate,pullenddate,fundid,bulkinvestordata) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  let url = '';
  url = `${APIUrl}generateStatement?appname=${Appname}&token=${token}&userid=${UserId}&startDate=${pullstartdate}&endDate=${pullenddate}&fundid=${fundid}&sendEmail=true&type=bulk`;
   
  const promise = 
    axios({
      method: 'POST',
      url: url,
      data : bulkinvestordata
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const statementsLogs = async (pagenum,perPage) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}statementLogs?appname=${Appname}&token=${token}&userid=${UserId}&pageNumber=${pagenum}&assetcount=${perPage}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const adminHmdaReport = async (from,to,hmdaData,uuidNum) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  let url = '';
  if(uuidNum){
    url = `${APIUrl}hmdareport?appname=${Appname}&userid=${UserId}&fromdate=${from}&todate=${to}&uuid=${uuidNum}`;
  }else{
    url = `${APIUrl}hmdareport?appname=${Appname}&userid=${UserId}&fromdate=${from}&todate=${to}`;
  }
  
  const promise = 
    axios({
      method: 'POST',
      url: url,
      data : hmdaData
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const updateTransactions = async (transactionId,data) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}updateTransactions?appname=${Appname}&transactionId=${transactionId}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
      data :{...data,
      userid : UserId} 
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const deleteTransaction = async (investorid,deleteID,data) => {
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'DELETE',
      url: APIUrl + '/deleteTransactions?appname=' + Appname + "&userid=" + UserId +  "&transactionId=" + deleteID + "&investorid=" + investorid+ "&reason=" + data?.reason , 
      
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const fundDistribution = async (distributiondata) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}incomereturns?appname=${Appname}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
      data :distributiondata,
      
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const payDistribution = async (payData) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}incomedisburse?appname=${Appname}&userid=${UserId}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
      data : payData,
      
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getPayoffEmailBodyTemplate = async () => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}payoff?appname=${Appname}&userid=${UserId}&payoff=${true}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
      //data :distributiondata,
      
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getAdminHmdaDetails = async () => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}gethmda?appname=${Appname}&userid=${UserId}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
      
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const updateAdminHmdaDetails = async (updateData) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}managehmda?appname=${Appname}&userid=${UserId}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
      data :updateData,
      
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const pdfToWordConverter = async (loanid,s3key,tabName) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/wordconversion?appname=' + Appname + "&token=" + token +"&loanid="+loanid + "&s3key="+s3key + "&form="+ tabName
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const pdfToWordConverterV2 = async (loanid,s3key,tabName) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const promise = 
    axios({
      method: 'POST',
      url: APIUrl + '/wordconversionv2?appname=' + Appname + "&token=" + token +"&loanid="+loanid + "&s3key="+s3key + "&form="+ tabName
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getSingleSavedUserSearchData = async (savedUserSearchId) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}savedsearch?appname=${Appname}&userid=${UserId}&searchid=${savedUserSearchId}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
      
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const updateSingleSavedUserSearchData = async (savedusersearchid,updateSavedSeacrhUserData) => {
  let token = getTokenFromLocalStorage()
  let userData = getUserDataFromLocalStorage();
  let UserId = userData?.userId;
  const url = `${APIUrl}updateSearch?appname=${Appname}&userid=${UserId}&searchid=${savedusersearchid}&token=${token}`;
  const promise = 
    axios({
      method: 'POST',
      url: url,
      data :updateSavedSeacrhUserData,
      
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};



export const emaillogs = async (currentPage,perPage,loanid) => {
  let url = 
  APIUrl + '/emaillogs?appname=' + Appname + '&pageNumber=' + currentPage + '&assetcount=' + perPage + "&loanid="+ loanid;
  const promise = 
    axios({
      method: 'GET',
      url: url, 
    
    }).then(function (response) {
      if (response && response.data) {
        return response;
      }
    });
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};