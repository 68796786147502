import React,{useEffect, useState} from 'react';
import { useNavigate,useLocation , useParams} from 'react-router-dom';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import HomeFooter from './HomeFooter';
import { useLoanInfo } from '../utils/loanInfoContext';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
import {useSelector, useDispatch} from 'react-redux';
import { setUrlUserid } from '../redux/reducers/Actions'; 

const NewLoan = (props) => {
  const navigate = useNavigate();
  const { loanInfo, setLoanInfo} = useLoanInfo();
  let userData = getUserDataFromLocalStorage();
  const [pipelinePermissions, setPipelinePermissions] = useState();
  const [editPermissions, setEditPermissions] = useState( );
  const location = useLocation();
  //getting user id from url
  const { id } = useParams();
   const dispatch = useDispatch();

  useEffect(() => {
    if(location.pathname){
        const route = location.pathname;
        const cleanRoute = route.slice(1);
        let permission = userData?.securityInfo?.permission
       
        let checkedPermission =  hasViewPermission(cleanRoute, permission) 
          console.log('viewPermission',location.pathname,checkedPermission); 
          setPipelinePermissions(checkedPermission) 
        //   if(!checkedPermission){
        //     navigate('/pipeline')
        //   }
        }

        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.permission
           
            let editPermission =  hasEditPermission(cleanRoute, permission) 
              console.log('editPermission',location.pathname,editPermission); 
              setEditPermissions(editPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }
        //setViewStatus()
  }, []);

  const refreshLoan = () => {
    setLoanInfo({
      propertyinfo: {},
      strategyinfo: {},
      borrowerinfo: {},
      dealinfo: {},
      requestor:''
    })
  }

  useEffect(() => {
    console.log("useEffect id:", id); // Log the value of id
    if (id) {
      dispatch(setUrlUserid(id));
    }
}, [id,dispatch]);


  return (
    <div id="layout-wrapper">
       <MainHeader />
       <SideMenu />
    
<div className="main-content new_loan">
<div className="page-content">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 content_body">
               <div className="card" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                    <div className="card-body">
                    <h1>New loan</h1>
                    <div className="d-flex justify-content-center">    
                      <button id="newPipelineBorrowerButton" className="btn" onClick={(e) => {refreshLoan(); navigate('/propertyinfo/?usertype=borrower');}}><img src="assets/images/borrower.svg" />Borrower<span>+</span></button>
                      <button id="newPipelineBrokerButton" className="btn me-0"  onClick={(e) => {refreshLoan(); navigate('/propertyinfo/?usertype=broker');}}><img src="assets/images/broker1.svg" />Broker<span>+</span></button>
                    </div>
                      
                </div>
            </div>
        </div>

    </div>
</div>

  
</div>
<HomeFooter />
</div>
</div>

  );
};

export default NewLoan;
