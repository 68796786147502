import heic2any from 'heic2any';

export const handleImageUrl = async (imageUrl, awsImgix, fallbackImage = "assets/images/icons/mode-portrait.svg") => {

  // console.log('imageUrl',imageUrl,awsImgix);
  // Check if image URL exists and config exists
  if (!imageUrl || !awsImgix) {
    return fallbackImage;
  }

  // Check if it's a HEIC image
  const isHeic = imageUrl.toLowerCase().endsWith('.heic');
  const baseUrl = awsImgix + imageUrl;

  // If not HEIC, return the AWS URL directly
  if (!isHeic) {
    return baseUrl;
  }

  try {
    // Fetch and convert HEIC image
    const response = await fetch(baseUrl);
    const blob = await response.blob();
    
    const jpegBlob = await heic2any({
      blob,
      toType: 'image/jpeg',
      quality: 0.8
    });
    
    return URL.createObjectURL(jpegBlob);
  } catch (error) {
    console.error('Error handling image:', error);
    return baseUrl;
  }
};

// Optional: Cleanup function for blob URLs
export const cleanupBlobUrl = (url) => {
  if (url && url.startsWith('blob:')) {
    URL.revokeObjectURL(url);
  }
};