import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { getStates, getTermSheetPDF, getSingleTermSheetPrefillState, getUnderwritingPDF} from "./../utils/reducer";
import {getUnderwriting ,loanUnderwritingData , underwritingPipelineUpdate , LoanTermDropdown ,getLoanDetails} from "./../utils/reducer";
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import PopupMessage from './PopupMessage';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import { useLoanInfo } from '../utils/loanInfoContext';
import { formatPhoneNumber } from '../utils/commonUtils';
import jsPDF from 'jspdf';
import LocationSearch from './LocationsSeachBox';
import CurrencyInput from 'react-currency-input-field';


const Calculator = () => {
    const dispatch = useDispatch();
    const { loanInfo } = useLoanInfo();
    const [loanid, setLoanid] = useState(loanInfo?.loanid);
    const [states, setStates] = useState([]);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message,setMessage] = useState('');
    const loading = useSelector((state) => state.loaderVisible);
    const [borrowerErrors , setBorrowerErrors] = useState({});
    const [propertyErrors , setPropertyErrors] = useState({});
    const [dealErrors,setDealErrors] = useState({});
    const [feeErrors , setFeesErrors] = useState({});
    const [calculationErrors , setCalculationErrors] = useState({});
    const [comparisionsErrors,setComparisionsErrors] = useState({});
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [addressData,setAddressData] = useState({ });
    const [addressIndex,setAddressIndex] = useState([]);
    const [bool,setBool] = useState(false);
    const [underwritingFormulas,setUnderwritingFormulas] = useState({});
    const [calcBool,setCalcBool] = useState(false);
    const [loanTerm,setLoanTerm] = useState([]);
    const [calculationBool,setCalculationBool] = useState(false);
    const [singleTermSheet,setSingleTermSheet] = useState({});
    const [isFocused, setIsFocused] = useState(false);
    const [lastFieldtName, setLastFieldtName] = useState('');


    let underwritingVariable = {
        "loanid": loanid,
        "abouttheborrower":{
            "applicantid":"",
            "borrowerfirstname":"",
            "borrowerlastname":"",
            "borroweremail":"",
            "borrowerphone":"",
            "statedcreditscore":"",
	        "liquidcashreserve" : "",
            //"lenderfundedrehab":"",
            "borroweroccupation":"",
            "investmentproperties": "",
            "cashreverserequired" : "",
            "cashreverserequiredvalue" : false,
            "applicationlicense" : "",
           
        },
        "entity": {
            "entityid":"",
            "legalname": ""
        },
        "abouttheproperty":{
            "propertyid": "",
            "propertytype":"",
            "propertyaddress":"",
            "apt_unit":"",
            "propertycity":"",
            "propertystate":"",
            "propertyzipcode":"",
            "totalbedrooms":"",
            "totalbathrooms":"",
            "haspool":"",
            "lotsqft":"",
            "finishedsqft":"",
            "occupancy" : "",
            "purchaseprice" : "",
            "easystreetaiv" : "",
            "renovationcost" : "",
            "easystreetarv": "",
            "renovationarv": "",
            "maxltc": "",
            "maxltv": "",
            "points": "",
            //"amortizationterm":"",
            "valuationfee": "",
            "exitstrategy": "",
            "monthlyrentalrate": "",
            "downpaymentsource": "",
            "floodinsurence": "",
            "floodinsurencevalue": false ,
            "lenderfundedrehab" : "",
            "debtservicecoverageratio": "",
            "existingloan": "",
            "executionstrategysummary": "",
           
        },
       
       
        "fee":{
            "brokerid" : "",
            "brokerpoints":"",
            "brokerprocessingfee":"",
           
            
        },
        "overridecalculationfee":false,
        "initialfunding": "",
        "initialequity": "",
        "rehabfunding": "",
        "rehabequity": "",
        "loanamount" : "",
        "insuranceamount": "",
        "interestreserveamount": "",
        "underwritingsummary": "",
        "originatorinstructions": "",
        // "holdbackamount":"",
        "interestrate": "",
        "loanterm":"",
        "programtype": "",
        "loanfor": "",
        "drawinteresttype": "",
        //"interestonly":"",
        "originationFee":"",
        "title":"", 
        "escrow":"",
        "titleAmount":"",
        // "propertyInsurance":"",
        "drawFee":"",
        "loanorganisationpoints":"",
        "usewhitelabel":false,
        "docfee":"",
        "interestreserves":"no",
        "propertycomparisons":  [
            {
                "address": "",
                "price": "",
                "bd/ba": "",
                "sf": "",
                "yearbuilt": "",
                "comparisonnarrative": ""
            },
            {
                "address": "",
                "price": "",
                "bd/ba": "",
                "sf": "",
                "yearbuilt": "",
                "comparisonnarrative": ""
            },
            {
                "address": "",
                "price": "",
                "bd/ba": "",
                "sf": "",
                "yearbuilt": "",
                "comparisonnarrative": ""
            },
            {
                "address": "",
                "price": "",
                "bd/ba": "",
                "sf": "",
                "yearbuilt": "",
                "comparisonnarrative": ""
            },
        ]
        
    };
    const [underWritingData , setUnderWritingData]= useState(underwritingVariable);
    const [loanUnderwriting,setLoanUnderwriting] = useState({});
    const [underwritingErrors,setUnderwritingErrors] = useState({});

    const emptySectionKeys = [ "overridecalculationfee",
    "initialfunding",
    "initialequity",
    "rehabfunding",
    "rehabequity",
    "debtservicecoverageratio",
    "insuranceamount",
    "executionstrategysummary",
    "underwritingsummary",
    "originatorinstructions",
    "holdbackamount",
    "interestrate",
    "loanterm",
    "programtype",
    "loanfor",
    "drawinteresttype",
    //"interestonly",
    "existingloan",
    "usewhitelabel"];

    let checkLength = (Object.keys(underWritingData).length > 0) ? true : false;
    // let checkApplicantLength = (Object.keys(loanUnderwriting?.applicant).length > 0) ? true : false;
    // let checkpropertyLength = (Object.keys(loanUnderwriting?.propertyinfo).length > 0) ? true : false;
    let checkBrokerLength = (loanUnderwriting?.broker !== null) ? true : false;
    let borrowerFieldsToCheck = {};
    let propertyFieldsToCheck = {};
    let propertycomparisonsFieldsToCheck = {};
    let errors = {};
    let floatJsonInputs = ['price','liquidcashreserve',
            'renovationcost','brokerprocessingfee','docfee','purchaseprice','cashreverserequired',
            'easystreetaiv','easystreetarv', 'valuationfee', 'monthlyrentalrate','existingloan', 'floodinsurence','borrowerarv',
            'loanamount','initialfunding','initialequity','rehabfunding','rehabequity','insuranceamount',
            'totalcost',"maxmonthlyloanpayment","cashtoclose", "interestreserveamount", 'originationFee',  'escrow','titleAmount', 'propertyInsurance', 'drawFee'
            
    ];
    
    let dealUnderwritingJson = [
        {
            "abouttheborrower": [

                
                {
                    "label": "Program Type",
                    "name": "programtype",
                    "options": [
                        {"label": "Select", "value": ""},
                        {"label": "Easy Fix", "value": "EASY FIX"},
                        {"label": "Easy Build", "value": "EASY BUILD"}
                    ],
                    "type": "select"
                },
                
                {
                    "label": "Loan Type",
                    "name": "loanfor",
                    "options": [
                        {"label": "Select", "value": ""},
                        {"label": "Purchase", "value": "PURCHASE"},
                        {"label": "Refinance", "value": "REFINANCE"}
                    ],
                    "type": "select"
                },

                {
                    "label": "Draw Interest Type",
                    "name": "drawinteresttype",
                    "options": [
                        {"label": "Select", "value": ""},
                        {"label": "Interest as Disbursed", "value": "Interest as Disbursed"},
                        {"label": "Interest on Full Balance", "value": "Interest on Full Balance"}
                    ],
                    "type": "select"
                }, 
                {
                    "type": "select",
                    "label": "State",
                    "name": "propertystate",
                   
                },
                
                {
                    "type": "text",
                    "label": "Cash Reserves",
                    "placeholder": "Enter Cash Reserves",
                    "name": "liquidcashreserve"
                },
               
                
                {
                    "label": "Cash Reserves Required",
                    "name": "cashreverserequired",
                    "type": "text",
                    "placeholder" : "Enter Cash Reserves Required",
                    "checkboxlabel": "Cash Reserves Required",
                    "checkboxlabelname": "cashreverserequiredvalue",
                    "formula": "Cash to Close + Rehab Equity + (Max Monthly Loan Payment * Loan Term);" 
                },
                
               
                {
                    "type": "select",
                    "label": "Lender Funded Rehab",
                    "name": "lenderfundedrehab",
                    "options": [
                        { "label": "Select ", "value": "" },
                        { "value": "Yes", "label": "Yes" },
                        { "value": "No", "label": "No" },
                    ]
                        
                },
                
            ]
        },
        {
            "aboutthedeal": [
                {
                    "label": "Purchase",
                    "name": "purchaseprice",
                    "placeholder": "Enter Purchase",
                    "type": "text"
                },
                {
                    "label": "Easy Street AIV",
                    "name": "easystreetaiv",
                    "placeholder": "Enter AIV",
                    "type": "text"
                },
                {
                    "label": "Renovation/Construction $",
                    "name": "renovationcost",
                    "placeholder": "Enter Renovation/Construction $",
                    "type": "text"
                },
                {
                    "label": "Easy Street ARV",
                    "name": "easystreetarv",
                    "placeholder": "Enter Easy Street ARV",
                    "type": "text"
                },
                {
                    "label": "Borrower ARV",
                    "name": "renovationarv",
                    "placeholder": "Enter Borrower ARV",
                    "type": "text"
                },
                {
                    "label": "Max LTC (%)",
                    "name": "maxltc",
                    "placeholder": "Enter Max LTC (%)",
                    "type": "text"
                },
                {
                    "label": "Max LTV (%)",
                    "name": "maxltv",
                    "placeholder": "Enter Max LTV (%)",
                    "type": "text"
                },
                {
                    "label": "Interest Rate (%)",
                    "name": "interestrate",
                    "placeholder": "Enter Interest Rate (%)",
                    "type": "text"
                },
                {
                    "label": "Points (%)",
                    "name": "points",
                    "placeholder": "Enter Points (%)",
                    "type": "text"
                },
                {
                    "label": "Loan Term",
                    "name": "loanterm",
                    "options": [
                        {"label": "Select", "value": ""},
                        ...loanTerm.map(option => {
                            const numericValue = parseInt(option.option); 
                            return { "label": option.option, "value": numericValue }; 
                        })
                    ],
                    "type": "select"
                },
                
                {
                    "label": "Valuation Fee",
                    "name": "valuationfee",
                    "placeholder": "Enter Valuation Fee",
                    "type": "text"
                },               
                // {
                //     "label": "Interest Only",
                //     "name": "interestonly",
                //     "options": [
                //         {"label": "Select", "value": ""},
                //         {"label": "True", "value": true},
                //         {"label": "False", "value": false}
                //     ],
                //     "type": "select"
                // },
                
                {
                    "label": "Monthly Rental Rate",
                    "name": "monthlyrentalrate",
                    "placeholder": "Enter Monthly Rental Rate",
                    "type": "text"
                },
                {
                    "label": "Existing Loan",
                    "name": "existingloan",
                    "placeholder": "Enter Existing Loan",
                    "type": "text"
                },
                {
                    "type" : "checkbox",
                    "label": "Flood Insurance Required",
                    "name": "floodinsurencevalue"
                },
                {
                    "label": "Flood Insurance",
                    "name": "floodinsurence",
                    "type": "text",
                    "placeholder" : "$0.00"
                },
                
                {
                    "type": "text",
                    "label": "Insurance Amount",
                    "placeholder": "Enter Insurance Amount",
                    "name": "insuranceamount",
                    
                },
                {
                    "type": "text",
                    "label": "Debt Service Coverage Ratio",
                    "placeholder": "Enter Debt Service Coverage Ratio",
                    "name": "debtservicecoverageratio",
                   
                },
                {
                    "label": "Credit Report",
                    "name": "creditreport",
                    "options": [
                        {"label": "Select", "value": ""},
                        {"label": "Yes", "value": "Yes"},
                        {"label": "No", "value": "No"},
                    ],
                    "type": "select"
                },
                {
                    "label": "Appraisal",
                    "name": "appraisal",
                    "options": [
                        {"label": "N/A", "value": ""},
                        {"label": "Exterior", "value": "Exterior"},
                        {"label": "Interior", "value": "Interior"},
                    ],
                    "type": "select"
                   
                },
                {
                    "type": "text",
                    "label": "Title (%)",
                    "name": "title",
                },
                {
                    "type": "text",
                    "label": "Title Amount",
                    "name": "titleAmount",
                },
                {
                    "type": "text",
                    "label": "Escrow",
                    "name": "escrow",
                },
                {
                    "type": "text",
                    "label": "Origination Fee",
                    "name": "originationFee",
                },
                {
                    "type": "text",
                    "label": "Property Insurance",
                    "name": "insuranceamount",
                },
                {
                    "type": "text",
                    "label": "Draw Fee",
                    "name": "drawFee",
                },
                
            ]
        },
        {
            "fee": [
                {
                    "type": "text",
                    "label": "Broker Points (%)",
                    "placeholder": "Enter Broker Points",
                    "name": "brokerpoints"
                },
                {
                    "type": "text",
                    "label": "Broker Processing Fee",
                    "placeholder": "Enter Broker Processing Fee",
                    "name": "brokerprocessingfee"
                },
                {
                    "type": "text",
                    "label": "Doc Prep Fee",
                    "placeholder": "Enter Doc Prep Fee",
                    "name": "docfee"
                },
                {
                    "type": "select",
                    "label": "Interest Reserves",
                    "options": [
                        { "label": "Select", "value": "" },
                        { "label": "Yes", "value": "yes" },
                        { "label": "No", "value": "no" }
                    ],
                    "name": "interestreserves"
                }
            ]
        },
        {
            "calculations": [
                {
                    "type": "checkbox",
                    "label": "Override Calculated Fields",
                    "name": "overridecalculationfee",
                    
                },
                {
                    "type": "text",
                    "label": "Total Cost",
                    "placeholder": "Total Cost",
                    "name": "totalcost",
                    "formula": "Purchase + Reno $" 
                    
                },
                {
                    "type": "text",
                    "label": "Loan Amount",
                    "placeholder": "Enter Loan Amount",
                    "name": "loanamount",
                    "formula": "Lender Funded Rehab === 'Yes' ? Math.min(Max LTV  * Easy Street ARV, Max LTC * (Purchase + Reno $)) : Math.min(Max LTV *  Easy Street ARV / (Purchase + Reno $), Max LTC) * Purchase;" 
                    
                   
                },
                {
                    "type": "text",
                    "label": "Actual LTV (%)",
                    "placeholder": "Enter Actual LTV (%)",
                    "name": "actualltv",
                    "formula": "Loan Amount / Easy Street ARV" 
                    
                },
                {
                    "type": "text",
                    "label": "Actual LTC (%)",
                    "placeholder": "Enter Actual LTC (%)",
                    "name": "actualltc",
                    "formula": "Loan Amount / Total Cost" 
                    
                },
                {
                    "type": "text",
                    "label": "Initial Funding",
                    "placeholder": "Enter Initial Funding",
                    "name": "initialfunding",
                    "formula": "Lender Funded Rehab === 'Yes' ? Math.min(Max LTV * Easy Street ARV, Max LTC * Purchase + Reno $) - (Reno $ * (Math.min(Max LTV * Easy Street ARV, Max LTC * Purchase +  Reno $) / Purchase + Reno $)); : Math.min(Max LTV * Easy Street ARV / Purchase + Reno $,  Max LTC) * Purchase;" 
                    
                },
                {
                    "type": "text",
                    "label": "Initial Equity",
                    "placeholder": "Enter Initial Equity",
                    "name": "initialequity",
                    "formula": "Purchase - Initial Funding;" 
                    
                },
                {
                    "type": "text",
                    "label": "Rehab Funding",
                    "placeholder": "Enter Rehab Funding",
                    "name": "rehabfunding",
                    "formula": "Lender Funded Rehab === 'Yes' ? Math.min(Max LTV * Easy Street ARV, Max LTC * Total Cost) - Initial Funding : 0;" 
                    
                },
                {
                    "type": "text",
                    "label": "Rehab Equity",
                    "placeholder": "Enter Rehab Equity",
                    "name": "rehabequity",
                    "formula": "Reno $ - Rehab Funding" 
                   
                },
                // {
                //     "type": "text",
                //     "label": "Max Monthly Loan Payment",
                //     "placeholder": "Enter Max Monthly Loan Payment",
                //     "name": "maxmonthlyloanpayment",
                //     "formula": "(Interest Rate / 12) * Loan Amount;" 
                   
                // },
                {
                    "type": "text",
                    "label": "Cash to Close",
                    "placeholder": "Enter Cash to Close",
                    "name": "cashtoclose",
                    "formula": "Purchase + (Points * Loan Amount) + Valuation Fee + Flood Insurance + (Broker Points * Loan Amount) + Broker Processing Fee + Doc Prep Fee + Insurance Amount - Initial Funding;" 
                   
                },

                {
                    "type": "text",
                    "label": "Interest Reserve Amount",
                    "placeholder": "Enter interest Reserve Amount",
                    "name": "interestreserveamount",
                    "formula": "Max Monthly Loan Payment * Loan Term"
                    
                },
                
                {
                    "type" : "text",
                    "name": "emptyinput",
                },
            ]
        }
    ]
    

    useEffect(() => {
        if(loanInfo?.loanid){
            getUnderwritingData();
            let term_sheet_id = '66500cb4a780b607d59b2bd2'
            setLoanid(term_sheet_id);
        }
    }, [loanInfo?.loanid])
    
    useEffect(() => {
        console.log('underWritingData?.programtype',underWritingData?.programtype, underWritingData?.abouttheproperty?.propertystate)
        if(underWritingData?.programtype && underWritingData?.abouttheproperty?.propertystate){
           console.log('stateshere',states);
           getSingleTermSheetPrefillState(underWritingData?.programtype, underWritingData?.abouttheproperty?.propertystate).then(function (response) {
        if(response?.result){
            console.log('get single term sheet ',response?.result?.data[0]);
            setSingleTermSheet(response?.result?.data[0]);
        }
        
        
        }).catch((err) => {
        console.error.bind("get single term sheet", err);
        })
    }
    }, [underWritingData?.programtype, underWritingData?.abouttheproperty?.propertystate])

    useEffect(() => {
        getstates();
        loandropdown();
    }, [])

    useEffect(() => {
        console.log('singleTermSheetsingleTermSheet',singleTermSheet);
        setUnderWritingData(prevState => ({
            ...prevState,
            aboutthedeal: {
                ...prevState.abouttheproperty,
                points: singleTermSheet?.underwritingSheet?.points,
                maxltv: singleTermSheet?.underwritingSheet?.maxLTV,
                interestrate: singleTermSheet?.underwritingSheet?.interestRate,
                maxltc: singleTermSheet?.underwritingSheet?.maxLTC
            },
            loanterm: singleTermSheet?.underwritingSheet?.loanTerm,
        }));
    }, [singleTermSheet])

  



    const loandropdown = async () => {
        LoanTermDropdown().then(function (response) {
            if (response?.result) {
                let data = response?.result?.data[0]?.lookupOptions
                setLoanTerm(data);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };

 

     // useEffect to update underWritingData when loanUnderwriting changes

    //  useEffect(() => {
    //     // Function to update underWritingData with values from loanUnderwriting
    //     // dispatch(showLoader());
       
    //         const updateUnderWritingData = () => {
    //             const updatedUnderWritingData = { ...underWritingData };
                
    //             // Loop through keys in loanUnderwriting
    //             for (const key in loanUnderwriting) {
    //                 // Check if the key exists in underWritingData
    //                 if (updatedUnderWritingData.hasOwnProperty(key)) {
    //                     // Update the value in underWritingData with value from loanUnderwriting
    //                     updatedUnderWritingData[key] = loanUnderwriting[key];
    //                 }
    //             }
                
    //             // Set the updated underWritingData state
    //             setUnderWritingData(updatedUnderWritingData);
    //             // dispatch(hideLoader());
    //         };
    //         // if(loanUnderwriting?.length !== 0){
    //             updateUnderWritingData();
    //         // }
        

    //     // Call the update function
        
        
        
    // }, []);

   
    function addCommas(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function handleInputFormatting(value, type) {
        if (value === undefined || value === null) {
            return '';
        }
        const strValue = String(value);
    
        switch (type) {
            // case 'numeric':
            //     // Remove any non-numeric characters
            //     return strValue.replace(/[^0-9]/g, '');
            // case 'float':
            //     // Remove any non-numeric characters except dot (.)
            //     const floatValue = strValue.replace(/[^0-9.]/g, '');
            //     return '$' + floatValue;
            // case 'numeric':
            //     // Remove any non-numeric characters
            //     const numericValue = strValue.replace(/[^0-9]/g, '');
            //     // Add commas to the numeric value
            //     return addCommas(numericValue);
            case 'numeric':
                // Remove any non-numeric characters
                return strValue.replace(/[^0-9]/g, '');
            case 'float':
                // Remove any non-numeric characters except dot (.)
                const floatValue = strValue.replace(/[^0-9.]/g, '');
                // Add commas to the float value
                let formattedFloat = addCommas(floatValue);
                // Add $ currency symbol
                formattedFloat = '$' + formattedFloat;
                return formattedFloat;
            case 'percentage':
                // Remove any non-numeric characters
                const percentageValue  = strValue.replace(/[^0-9]/g, '');
                // Append '%' symbol to the sanitized numeric value
                return percentageValue  + '%';
            default:
                return strValue;
        }
    }
    

    function handleTypeChange(fieldName, value) {
        let formattedValue = value;
        let numericInputs = ['yearbuilt','bd/ba','statedcreditscore','investmentproperties','zipcode','totalbedrooms','totalbathrooms',
            'lotsqft','finishedsqft'
        ];

        let floatInputs = ['price','liquidcashreserve','purchaseprice',
            'renovationcost','brokerprocessingfee','docfee','purchaseprice','cashreverserequired',
            'easystreetaiv','easystreetarv', 'valuationfee', 'monthlyrentalrate','existingloan', 'floodinsurence'
            
        ];

        const perecentageInputs = [ 'maxltc', 'maxltv', 'interestrate','points','brokerpoints'];
    
        // Check if the field name belongs to numeric inputs
        if (numericInputs.includes(fieldName)) {
            formattedValue = handleInputFormatting(value, 'numeric');
        }
        // Check if the field name belongs to float inputs
        else if (floatInputs.includes(fieldName)) {
            formattedValue = handleInputFormatting(value, 'float');
            formattedValue = (formattedValue === '$' ? '' : formattedValue)
        }
        // Check if the field name belongs to percentage inputs
        else if (perecentageInputs.includes(fieldName)) {
            formattedValue = handleInputFormatting(value, 'percentage');
        }
        
        formattedValue = (formattedValue === 'NaN') ? '' : formattedValue;
        return formattedValue;
    }

    useEffect(() => {
         
        if (loanUnderwriting) {
       
      
            const hasData = loanUnderwriting?.propertycomparisons?.some(comparison => Object.values(comparison).some(value => value !== ""));
            setUnderWritingData({
                ...underwritingVariable,
                loanid: loanInfo?.loanid,
                abouttheborrower: {
                  ...underwritingVariable.abouttheborrower,
                  applicantid: loanUnderwriting?.applicant?.[0]?.applicantid,
                  borrowerfirstname: loanUnderwriting?.applicant?.[0]?.borrowerfirstname,
                  borrowerlastname: loanUnderwriting?.applicant?.[0]?.borrowerlastname,
                  borroweremail: loanUnderwriting?.applicant?.[0]?.borroweremail,
                  borrowerphone: loanUnderwriting?.applicant?.[0]?.borrowerphone,
                  statedcreditscore: loanUnderwriting?.applicant?.[0]?.statedcreditscore,
                  liquidcashreserve: loanUnderwriting?.applicant?.[0]?.liquidcashreserve,
                  borroweroccupation: loanUnderwriting?.applicant?.[0]?.borroweroccupation,
                  investmentproperties: loanUnderwriting?.applicant?.[0]?.investmentproperties,
                //   cashreverserequired: loanUnderwriting?.applicant?.[0]?.cashreverserequired,
                //   cashreverserequiredvalue: loanUnderwriting?.applicant?.[0]?.cashreverserequiredvalue,
                applicationlicense: loanUnderwriting?.applicant?.[0]?.applicationlicense,
                  
                },
                entity: {
                  ...underwritingVariable.entity,
                  entityid: loanUnderwriting?.entity?.[0]?.entityid,
                  legalname: (loanUnderwriting?.entity?.[0]?.legalname) ? loanUnderwriting?.entity?.[0]?.legalname : "No"
                },
                abouttheproperty: {
                  ...underwritingVariable.abouttheproperty,
                  propertyid: loanUnderwriting?.propertyinfo?.[0]?.propertyid,
                  apt_unit : loanUnderwriting?.propertyinfo?.[0]?.apt_unit,
                  propertytype: loanUnderwriting?.propertyinfo?.[0]?.propertytype,
                  propertyaddress: loanUnderwriting?.propertyinfo?.[0]?.propertyaddress?.trim(),
                  propertycity: loanUnderwriting?.propertyinfo?.[0]?.propertycity,
                  propertystate: loanUnderwriting?.propertyinfo?.[0]?.propertystate,
                  propertyzipcode: loanUnderwriting?.propertyinfo?.[0]?.propertyzipcode,
                  totalbedrooms: loanUnderwriting?.propertyinfo?.[0]?.totalbedrooms,
                  totalbathrooms: loanUnderwriting?.propertyinfo?.[0]?.totalbathrooms,
                  haspool: loanUnderwriting?.propertyinfo?.[0]?.haspool,
                  lotsqft: loanUnderwriting?.propertyinfo?.[0]?.lotsqft,
                  finishedsqft: loanUnderwriting?.propertyinfo?.[0]?.finishedsqft,
                  purchaseprice: loanUnderwriting?.propertyinfo?.[0]?.purchaseprice,
                  renovationcost: loanUnderwriting?.propertyinfo?.[0]?.renovationcost || loanUnderwriting?.propertyinfo?.[0]?.construction_rehab_budget,
                  downpaymentsource: loanUnderwriting?.propertyinfo?.[0]?.downpaymentsource,
                  floodinsurence: loanUnderwriting?.propertyinfo?.[0]?.floodinsurence,
                  floodinsurencevalue: loanUnderwriting?.propertyinfo?.[0]?.floodinsurencevalue,
                  debtservicecoverageratio : loanUnderwriting?.propertyinfo?.[0]?.debtservicecoverageratio,
                  renovationarv : (loanUnderwriting?.propertyinfo?.[0]?.borrowerarv ) ? (loanUnderwriting?.propertyinfo?.[0]?.borrowerarv) : (loanUnderwriting?.propertyinfo?.[0]?.renovationarv),
                  occupancy : loanUnderwriting?.propertyinfo?.[0]?.occupancy,
                  easystreetaiv : loanUnderwriting?.propertyinfo?.[0]?.easystreetaiv,
                  easystreetarv : loanUnderwriting?.propertyinfo?.[0]?.easystreetarv,
                  maxltc :  loanUnderwriting?.propertyinfo?.[0]?.maxltc ,
                  maxltv :  loanUnderwriting?.propertyinfo?.[0]?.maxltv ,
                  points :  loanUnderwriting?.propertyinfo?.[0]?.points ,
                  //amortizationterm :  loanUnderwriting?.propertyinfo?.[0]?.amortizationterm ,
                  valuationfee :  loanUnderwriting?.propertyinfo?.[0]?.valuationfee ,
                  exitstrategy :  loanUnderwriting?.propertyinfo?.[0]?.exitstrategy ,
                  monthlyrentalrate :  loanUnderwriting?.propertyinfo?.[0]?.monthlyrentalrate ,
                  lenderfundedrehab : loanUnderwriting?.propertyinfo?.[0]?.lenderfundedrehab || loanUnderwriting?.propertyinfo?.[0]?.refinanceloanrehabfunding,
                  existingloan:  loanUnderwriting?.propertyinfo?.[0]?.existingloan,
                  executionstrategysummary:  loanUnderwriting?.propertyinfo?.[0].executionstrategysummary,
                },
                fee: {
                  ...underwritingVariable.fee,
                  brokerid: loanUnderwriting?.broker?.[loanUnderwriting?.broker?.length - 1]?.brokerid,
                  brokerpoints: loanUnderwriting?.broker?.[loanUnderwriting?.broker?.length - 1]?.brokerpoints,
                  brokerprocessingfee: loanUnderwriting?.broker?.[loanUnderwriting?.broker?.length - 1]?.brokerprocessingfee,
                 
                  
                },
                programtype: loanUnderwriting?.programtype,
                loanfor: loanUnderwriting?.loanfor,
                overridecalculationfee: loanUnderwriting?.overridecalculationfee || false,
                initialfunding: loanUnderwriting?.initialfunding,
                initialequity:  loanUnderwriting?.initialequity,
                rehabfunding:  loanUnderwriting?.rehabfunding,
                rehabequity:  loanUnderwriting?.rehabequity,
                debtservicecoverageratio:  loanUnderwriting?.debtservicecoverageratio,
                insuranceamount:  loanUnderwriting?.insuranceamount,
                interestreserveamount: loanUnderwriting?.interestreserveamount,
                underwritingsummary:  loanUnderwriting?.underwritingsummary,
                originatorinstructions:  loanUnderwriting?.originatorinstructions,
                interestrate:  loanUnderwriting?.interestrate,
                loanterm:  loanUnderwriting?.loanterm,
                loanamount :  loanUnderwriting?.loanamount ,
                drawinteresttype:  loanUnderwriting?.drawinteresttype,
                //interestonly:  loanUnderwriting?.interestonly,
              
                usewhitelabel:  loanUnderwriting?.usewhitelabel,
                propertycomparisons : (hasData) ? loanUnderwriting?.propertycomparisons : underwritingVariable?.propertycomparisons,
                //propertycomparisons : loanUnderwriting?.propertycomparisons 
                //propertycomparisons: loanUnderwriting?.propertycomparisons
                docfee : loanUnderwriting?.docfee,
                interestreserves: loanUnderwriting?.interestreserves,
                actualltc: loanUnderwriting.actualltc,
                actualltv: loanUnderwriting.actualltv,
                title: loanUnderwriting.title,
                originationFee: loanUnderwriting.originationFee,
                escrow: loanUnderwriting.escrow,
                drawFee:loanUnderwriting.drawFee,
                titleAmount: loanUnderwriting.titleAmount 
                 
            });

            
            //if(hasData){
                //setUnderWritingData({...underwritingVariable,propertycomparisons : loanUnderwriting?.propertycomparisons})
                
            //}
       


            

        }

    }, [loanUnderwriting])

  
    /*get call api */
    // useEffect(() => {
    //     dispatch(showLoader());
    //     getUnderwriting(loanid).then(function(response) {
    //         if (response?.statusCode === 200 && response?.result?.length !== 0) {
    //             // console.log('loan underwriting data api response', response);
    //             setLoanUnderwriting(response?.result[0]);
    //             dispatch(hideLoader());
    //         }
    //     })
    //     .catch((err) => {
    //         console.error("loan underwrting data api", err); 
    //         dispatch(hideLoader());
    //     });
    // },[])

    


    
    const getUnderwritingData = () =>{
        dispatch(showLoader());
        // getUnderwriting(loanInfo?.loanid)
        // .then(function(response) {
        //     if (response?.statusCode === 200 && response?.result?.length !== 0) {
        //         console.log('get underwriting data api response', response);
        //         setLoanUnderwriting(response?.result[0]);
               
        //     }
        //     dispatch(hideLoader());
        // })
        // .catch((err) => {
        //     console.error("error", err); 
        //     dispatch(hideLoader());
        // });
        getLoanDetails(loanInfo?.loanid)
        .then(function(response) {
            if (response?.statusCode === 200 && response?.result?.length !== 0) {
                setLoanUnderwriting(response?.result[0]);
               
            }
            dispatch(hideLoader());
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    }; 
    
    
    function calculateFormulas() {
        if (!underWritingData || !underWritingData.abouttheproperty || !underWritingData.fee) {
            console.error('Missing required data for calculation.');
            return;
        }
        console.log('incormaualr');
        let {
            floodinsurence = 0,
            valuationfee = 0,
            renovationcost = 0,
            purchaseprice = 0,
            maxltv = 0,
            maxltc = 0,
            easystreetarv = 0,
            points = 0,
            lenderfundedrehab = 'No'
        } = underWritingData?.abouttheproperty || {};
    
        let {
            brokerprocessingfee = 0,
            brokerpoints = 0
        } = underWritingData?.fee || {};
    
        let {
            interestrate = 0,
            loanamount = 0,
            insuranceamount = 0,
            docfee = 0,
            loanterm = 0,
            initialfunding = 0,
            rehabfunding = 0,
            rehabequity = 0,
            interestreserveamount = 0,
            originationFee = 0,
            loanorganisationpoints = 0,
            escrow=0,
            titleAmount = 0,
            title = 0
        } = underWritingData || {};
    
        // Convert strings to numbers
        renovationcost = parseFloat(renovationcost) || 0;
        purchaseprice = parseFloat(purchaseprice) || 0;
        loanamount = parseFloat(loanamount) || 0;
        easystreetarv = parseFloat(easystreetarv) || 0;
        maxltc = parseFloat(maxltc) || 0;
        maxltv = parseFloat(maxltv) || 0;
        insuranceamount = parseFloat(insuranceamount) || 0;
        initialfunding = parseFloat(initialfunding) || 0;
        docfee = parseFloat(docfee) || 0; // Ensure docfee is converted to a number
        brokerprocessingfee = parseFloat(brokerprocessingfee) || 0;
        brokerpoints = parseFloat(brokerpoints) || 0;
        points = parseFloat(points) || 0;
        interestrate = parseFloat(interestrate) || 0;
        valuationfee = parseFloat(valuationfee) || 0;
        floodinsurence = parseFloat(floodinsurence) || 0;
        loanterm = parseFloat(loanterm) || 0;
        rehabfunding = parseFloat(rehabfunding) || 0;
        rehabequity = parseFloat(rehabequity) || 0;
        interestreserveamount = parseFloat(interestreserveamount) || 0;
        originationFee = parseFloat(originationFee) || 0;
        escrow = parseFloat(escrow) || 0;
        titleAmount = parseFloat(titleAmount) || 0;
  
        // Convert percentage values
        let maxLTV = maxltv / 100;
        let maxLTC = maxltc / 100;
        let pointsVal = points / 100;
        let brokerpointsVal = brokerpoints / 100;
        let interestrateVal = interestrate / 100;
        let loanorganisationpointsVal = loanorganisationpoints/100;
        let titleVal = title / 100;

        // Calculate maximum loan amounts
        let LTVARVMaxLoan = maxLTV * easystreetarv;
        let costBasis = purchaseprice + renovationcost;
        let LTCMaxLoan = maxLTC * costBasis;
        let lenderFundedRehabRatio = costBasis ? LTVARVMaxLoan / costBasis : 0;
        let loanSize = Math.min(lenderFundedRehabRatio, maxLTC) * purchaseprice;
        let lenderRehabNoLoanSize = Math.min(LTVARVMaxLoan, LTCMaxLoan);
        let loanAmount = (lastFieldtName == 'loanamount' && underWritingData?.overridecalculationfee) ? loanamount : lenderfundedrehab === 'Yes' ? lenderRehabNoLoanSize : loanSize;
        // Calculate actual LTV and LTC
        let actualLTV = easystreetarv ? Math.ceil((loanAmount / easystreetarv) * 100 * 100) / 100 : 0;
        let actualLTC = costBasis ? Math.ceil((loanAmount / costBasis) * 100 * 100) / 100 : 0;
    
        // Calculate initial funding and equity
        let initialFund = lenderRehabNoLoanSize - (renovationcost * (Math.min(LTVARVMaxLoan, LTCMaxLoan) / costBasis));
        let initialFunding = (lastFieldtName == 'initialfunding' && underWritingData?.overridecalculationfee) ? initialfunding : lenderfundedrehab === 'Yes' ? initialFund : loanSize;
        let initialEquity = purchaseprice - initialFunding;

        // Calculate rehab funding and equity
        let rehabFund = lenderRehabNoLoanSize - initialFunding;
        let rehabFunding = (lastFieldtName == 'rehabfunding' && underWritingData?.overridecalculationfee) ? rehabfunding : lenderfundedrehab === 'Yes' ? rehabFund : 0;
        let rehabEquity = (lastFieldtName == 'rehabequity' && underWritingData?.overridecalculationfee) ? rehabequity : renovationcost - rehabFunding;

        // Calculate max monthly loan payment
        let maxMonthlyInterestForCal = (interestrateVal / 12) * loanAmount;
        maxMonthlyInterestForCal = Math.round(maxMonthlyInterestForCal * 100) / 100;
console.log('titleValtitleVal',title,titleVal, loanAmount);
        titleAmount =  titleVal * loanAmount
        // Calculate cash to close
        const cashToClose = purchaseprice
            + (pointsVal * loanAmount)
            + valuationfee
            + floodinsurence
            + (brokerpointsVal * loanAmount)
            + brokerprocessingfee
            + docfee
            + titleAmount
            + escrow
            + insuranceamount
            - initialFunding;
    
            console.log('cashReserveRequired',purchaseprice, (pointsVal * loanAmount), valuationfee, floodinsurence, (brokerpointsVal * loanAmount), insuranceamount, initialFunding);
        let initialMonthlyLoanPayment = underWritingData?.drawinteresttype == 'Interest on Full Balance' ? maxMonthlyInterestForCal : (interestrate/12) * initialFunding;
        // Calculate cash reserve required
        //const cashReserveRequired = cashToClose + rehabEquity + (maxMonthlyLoanPayment * loanterm);
        const cashReserveRequired = cashToClose + rehabEquity + ((maxMonthlyInterestForCal + initialMonthlyLoanPayment)/2)*loanterm;
        let originationFeesVal = underWritingData?.loanorganisationpoints && loanorganisationpointsVal / 100;
        let originationFees = originationFeesVal ? (originationFeesVal * loanAmount) : null;
        let minimumFee = originationFees !== null ? originationFees : originationFee;
        originationFees = Math.max(minimumFee, originationFee);
        let interestReserveamount = (lastFieldtName == 'interestreserveamount' && underWritingData?.overridecalculationfee) ? interestreserveamount : underWritingData?.interestreserves == 'Yes' ? maxMonthlyInterestForCal * loanterm : 0;
      
        console.log('cashToCloseoriginationFees',cashReserveRequired);
        // Prepare formulas to set
        const formulas = {
            totalcost: Math.round(Number(costBasis.toFixed(2))),
            initialfunding: Math.round(Number(initialFunding.toFixed(2))),
            initialequity: Math.round(Number(initialEquity.toFixed(2))),
            rehabfunding: Math.round(Number(rehabFunding.toFixed(2))),
            rehabequity: Math.round(Number(rehabEquity.toFixed(2))),
            loanamount: Math.round(Number(loanAmount.toFixed(2))),
            actualltv: Number(actualLTV.toFixed(2)),
            actualltc: Number(actualLTC.toFixed(2)),
            // maxmonthlyloanpayment: Math.round( Number(maxMonthlyInterestForCal.toFixed(2))),
            cashtoclose: Math.round(Number(cashToClose.toFixed(2))),
            cashreverserequired: Math.round(Number(cashReserveRequired.toFixed(2))),
            interestreserveamount: Math.round(Number(interestReserveamount.toFixed(2))),
            originationFees:  Math.round(Number(originationFees.toFixed(2))),
            titleAmount : Math.round(Number(titleAmount.toFixed(2)))
        };
    
        // Update state with calculated formulas
        setUnderwritingFormulas(formulas);
    
        setUnderWritingData(prevState => ({
            ...prevState,
            loanamount: formulas.loanamount,
            initialfunding: formulas.initialfunding,
            initialequity: formulas.initialequity,
            rehabfunding: formulas.rehabfunding,
            rehabequity: formulas.rehabequity,
            // maxmonthlyloanpayment: formulas.maxmonthlyloanpayment,
            cashtoclose: formulas.cashtoclose,
            cashreverserequired: formulas.cashreverserequired,
            interestreserveamount:formulas.interestreserveamount,
            actualltc: formulas.actualltc,
            actualltv: formulas.actualltv,
            originationFee: formulas.originationFees,
            titleAmount: formulas?.titleAmount
        }));
    }
    function calculateFormulasBuild() {
        if (!underWritingData || !underWritingData.abouttheproperty || !underWritingData.fee) {
            console.error('Missing required data for calculation.');
            return;
        }
        console.log('incormaualr');
        let {
            floodinsurence = 0,
            valuationfee = 0,
            renovationcost = 0,
            purchaseprice = 0,
            maxltv = 0,
            maxltc = 0,
            easystreetarv = 0,
            points = 0,
            lenderfundedrehab = 'No'
        } = underWritingData?.abouttheproperty || {};
    
        let {
            brokerprocessingfee = 0,
            brokerpoints = 0
        } = underWritingData?.fee || {};
    
        let {
            interestrate = 0,
            loanamount = 0,
            insuranceamount = 0,
            docfee = 0,
            loanterm = 0,
            initialfunding = 0,
            rehabfunding = 0,
            rehabequity = 0,
            interestreserveamount = 0,
            originationFee = 0,
            loanorganisationpoints = 0,
            escrow=0,
            titleAmount = 0,
            title = 0
        } = underWritingData || {};
    
        // Convert strings to numbers
        renovationcost = parseFloat(renovationcost) || 0;
        purchaseprice = parseFloat(purchaseprice) || 0;
        loanamount = parseFloat(loanamount) || 0;
        easystreetarv = parseFloat(easystreetarv) || 0;
        maxltc = parseFloat(maxltc) || 0;
        maxltv = parseFloat(maxltv) || 0;
        insuranceamount = parseFloat(insuranceamount) || 0;
        initialfunding = parseFloat(initialfunding) || 0;
        docfee = parseFloat(docfee) || 0; // Ensure docfee is converted to a number
        brokerprocessingfee = parseFloat(brokerprocessingfee) || 0;
        brokerpoints = parseFloat(brokerpoints) || 0;
        points = parseFloat(points) || 0;
        interestrate = parseFloat(interestrate) || 0;
        valuationfee = parseFloat(valuationfee) || 0;
        floodinsurence = parseFloat(floodinsurence) || 0;
        loanterm = parseFloat(loanterm) || 0;
        rehabfunding = parseFloat(rehabfunding) || 0;
        rehabequity = parseFloat(rehabequity) || 0;
        interestreserveamount = parseFloat(interestreserveamount) || 0;
        originationFee = parseFloat(originationFee) || 0;
        escrow = parseFloat(escrow) || 0;
        titleAmount = parseFloat(titleAmount) || 0;
  
        // Convert percentage values
        let maxLTV = maxltv / 100;
        let maxLTC = maxltc / 100;
        let pointsVal = points / 100;
        let brokerpointsVal = brokerpoints / 100;
        let interestrateVal = interestrate / 100;
        let loanorganisationpointsVal = loanorganisationpoints/100;
        let titleVal = title / 100;

        // Calculate maximum loan amounts
        let LTVARVMaxLoan = maxLTV * easystreetarv;
        let costBasis = purchaseprice + renovationcost;
        let LTCMaxLoan = maxLTC * costBasis;
        let loanSize = Math.min(LTVARVMaxLoan, LTCMaxLoan);
        let lenderRehabNoLoanSize = Math.min(LTVARVMaxLoan, LTCMaxLoan);
        let loanAmount = (lastFieldtName == 'loanamount' && underWritingData?.overridecalculationfee) ? loanamount : lenderfundedrehab === 'Yes' ? lenderRehabNoLoanSize : loanSize;
        // Calculate actual LTV and LTC
        let actualLTV = easystreetarv ? Math.ceil((loanAmount / easystreetarv) * 100 * 100) / 100 : 0;
        let actualLTC = costBasis ? Math.ceil((loanAmount / costBasis) * 100 * 100) / 100 : 0;
    
       

        // Calculate rehab funding and equity
        let rehabFunding = (lastFieldtName == 'rehabfunding' && underWritingData?.overridecalculationfee) ? rehabfunding : (lenderfundedrehab === 'Yes' ? (Math.min(renovationcost, loanSize)) : 0) ;
        let rehabEquity = (lastFieldtName == 'rehabequity' && underWritingData?.overridecalculationfee) ? rehabequity : renovationcost - rehabFunding;

         // Calculate initial funding and equity
         let initialFunding = (lastFieldtName == 'initialfunding' && underWritingData?.overridecalculationfee) ? initialfunding : loanSize - Math.min(renovationcost, loanSize);
         let initialEquity = purchaseprice - initialFunding;
         console.log('renovationcost',renovationcost, rehabFunding,loanSize, initialFunding)
        // Calculate max monthly loan payment
        let maxMonthlyInterestForCal = (interestrateVal / 12) * loanAmount;
        maxMonthlyInterestForCal = Math.round(maxMonthlyInterestForCal * 100) / 100;

        titleAmount =  titleVal * loanAmount
        // Calculate cash to close
        const cashToClose = purchaseprice
            + (pointsVal * loanAmount)
            + valuationfee
            + floodinsurence
            + (brokerpointsVal * loanAmount)
            + brokerprocessingfee
            + docfee
            + titleAmount
            + escrow
            + insuranceamount
            - initialFunding;
    
            console.log('cashReserveRequired',purchaseprice, (pointsVal * loanAmount), valuationfee, floodinsurence, (brokerpointsVal * loanAmount), insuranceamount, initialFunding);
        let initialMonthlyLoanPayment = underWritingData?.drawinteresttype == 'Interest on Full Balance' ? maxMonthlyInterestForCal : (interestrateVal/12) * initialFunding;
        // Calculate cash reserve required
        //const cashReserveRequired = cashToClose + rehabEquity + (maxMonthlyLoanPayment * loanterm);
        console.log('heresdfsdf', cashToClose,  rehabEquity, maxMonthlyInterestForCal, initialMonthlyLoanPayment, loanterm);
        const cashReserveRequired = cashToClose + rehabEquity + ((maxMonthlyInterestForCal + initialMonthlyLoanPayment)/2)*loanterm;
        let originationFeesVal = underWritingData?.loanorganisationpoints && loanorganisationpointsVal / 100;
        let originationFees = originationFeesVal ? (originationFeesVal * loanAmount) : null;
        let minimumFee = originationFees !== null ? originationFees : originationFee;
        originationFees = Math.max(minimumFee, originationFee);
        let interestReserveamount = (lastFieldtName == 'interestreserveamount' && underWritingData?.overridecalculationfee) ? interestreserveamount : underWritingData?.interestreserves == 'Yes' ? maxMonthlyInterestForCal * loanterm : 0;
      
        console.log('cashToCloseoriginationFees',cashReserveRequired);
        // Prepare formulas to set
        const formulas = {
            totalcost: Math.round(Number(costBasis.toFixed(2))),
            initialfunding: Math.round(Number(initialFunding.toFixed(2))),
            initialequity: Math.round(Number(initialEquity.toFixed(2))),
            rehabfunding: Math.round(Number(rehabFunding.toFixed(2))),
            rehabequity: Math.round(Number(rehabEquity.toFixed(2))),
            loanamount: Math.round(Number(loanAmount.toFixed(2))),
            actualltv: Number(actualLTV.toFixed(2)),
            actualltc: Number(actualLTC.toFixed(2)),
            // maxmonthlyloanpayment: Math.round( Number(maxMonthlyInterestForCal.toFixed(2))),
            cashtoclose: Math.round(Number(cashToClose.toFixed(2))),
            cashreverserequired: Math.round(Number(cashReserveRequired.toFixed(2))),
            interestreserveamount: Math.round(Number(interestReserveamount.toFixed(2))),
            originationFees:  Math.round(Number(originationFees.toFixed(2))),
            titleAmount : Math.round(Number(titleAmount.toFixed(2)))
        };
    
        // Update state with calculated formulas
        setUnderwritingFormulas(formulas);
    
        setUnderWritingData(prevState => ({
            ...prevState,
            loanamount: formulas.loanamount,
            initialfunding: formulas.initialfunding,
            initialequity: formulas.initialequity,
            rehabfunding: formulas.rehabfunding,
            rehabequity: formulas.rehabequity,
            // maxmonthlyloanpayment: formulas.maxmonthlyloanpayment,
            cashtoclose: formulas.cashtoclose,
            cashreverserequired: formulas.cashreverserequired,
            interestreserveamount:formulas.interestreserveamount,
            actualltc: formulas.actualltc,
            actualltv: formulas.actualltv,
            originationFee: formulas.originationFees,
            titleAmount: formulas?.titleAmount
        }));
    }

    
    
    
    //const loanAmount = Math.round(Math.min(maxLTV * easyStreetARV, maxTLC * totalCost));
    useEffect(() => {
        
        if(underWritingData?.programtype === "EASY FIX" ){
            calculateFormulas();
        }else{
            calculateFormulasBuild();
        }
        
    
    }, [   underWritingData?.abouttheproperty?.floodinsurence,
        underWritingData?.abouttheproperty?.valuationfee,
        underWritingData?.abouttheproperty?.renovationcost,
        underWritingData?.abouttheproperty?.purchaseprice,
        underWritingData?.abouttheproperty?.maxltv,
        underWritingData?.abouttheproperty?.maxltc,
        underWritingData?.abouttheproperty?.easystreetarv,
        underWritingData?.abouttheproperty?.easystreetaiv,
        underWritingData?.abouttheproperty?.borrowerarv,
        underWritingData?.abouttheproperty?.points,
        underWritingData?.abouttheproperty?.lenderfundedrehab,
        underWritingData?.abouttheproperty?.monthlyrentalrate,
        underWritingData?.abouttheproperty?.existingloan,
        underWritingData?.fee?.brokerprocessingfee,
        underWritingData?.fee?.brokerpoints,
        underWritingData?.interestrate,
        // underWritingData?.loanamount,
        underWritingData?.docfee,
        underWritingData?.loanterm,
        underWritingData?.overridecalculationfee,
        underWritingData?.insuranceamount,
        underWritingData?.abouttheborrower?.liquidcashreserve,
        underWritingData?.drawinteresttype,
        underWritingData?.interestreserves,
        underWritingData?.title,
        underWritingData?.escrow,
        underWritingData?.titleAmount,
        ]);

        useEffect(() => {
            console.log(underWritingData?.overridecalculationfee,'isFocused',isFocused,);
            if(underWritingData?.overridecalculationfee && !isFocused){
                if(underWritingData?.programtype === "EASY FIX" ){
                    calculateFormulas();
                }else{
                    calculateFormulasBuild();
                }
            }
            
        
        }, [isFocused, underWritingData?.loanamount,underWritingData?.totalcost, underWritingData?.formulas?.actualltc, underWritingData?.formulas?.actualltv, underWritingData?.initialfunding, underWritingData?.rehabfunding, underWritingData?.rehabequity, underWritingData?.maxmonthlyloanpayment, underWritingData?.cashtoclose, underWritingData?.insuranceamount]);
    // useEffect(() => {
       
    //     if (!underWritingData.overridecalculationfee &&
    //         (underWritingData.abouttheproperty.purchaseprice !== underwritingFormulas?.purchaseprice ||
    //          underWritingData.abouttheproperty.renovationcost !== underwritingFormulas?.renovationcost ||
    //          underWritingData.abouttheproperty.maxltc !== underwritingFormulas?.maxltc ||
    //          underWritingData.abouttheproperty.maxltv !== underwritingFormulas?.maxltv)) {
    //             console.log("calculations called");
    //             calculateFormulas();
    //     }
    // }, [underWritingData]);
    

    // useEffect(() => {
    //     setUnderWritingData(prevState => ({
    //         ...prevState,
    //         abouttheproperty: {
    //             ...prevState.abouttheproperty,
    //             ["loanamount"]: underwritingFormulas?.loanamount
    //         }
    //     }));
    // },[underwritingFormulas]);

   
    const getstates = async () => {
        getStates().then(function (response) {
            if (response?.result) {
               setStates(response?.result)
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };
    useEffect(() => {
        console.log('underWritingData----->',underWritingData);
    }, [underWritingData]);

    // function addCurrency(value){
    //     // Remove any non-numeric characters except dot (.)
    //     value = value.replace(/[^0-9.]/g, '');

    //     // Add dollar symbol and format as currency
    //     value = `$${parseFloat(value)}`;
    //     value = (value === "$NaN" ? '' : value);
    //     return value;
    // }

    /* taking float values also */
    // function addCurrency(value){

    // // Remove any non-numeric characters except dot (.)
    // const numericValue = value.replace(/[^0-9.]/g, '');

    // // If the value is empty or only contains a dot, return an empty string
    // if (!numericValue || numericValue === '.') {
    //     return '';
    // }

    // // Parse the numeric value as a float
    // const floatValue = parseFloat(numericValue);

    // // If the parsed value is NaN, return an empty string
    // if (isNaN(floatValue)) {
    //     return '';
    // }

    // // Format the float value with commas and two decimal places
    // const formattedValue = floatValue.toLocaleString('en-US', {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2
    // });

    // // Add dollar symbol at the beginning
    // return `$${formattedValue}`;
    // }
    
    function addCurrency(value){
        // Remove any non-numeric characters except dot (.)
        const numericValue = value.replace(/[^0-9.]/g, '');
    
        // If the value is empty or only contains a dot, return an empty string
        if (!numericValue || numericValue === '.') {
            return '';
        }
    
        // Parse the numeric value as a float
        const floatValue = parseFloat(numericValue);
    
        // If the parsed value is NaN, return an empty string
        if (isNaN(floatValue)) {
            return '';
        }
    
        // Format the float value with commas
        const formattedValue = floatValue.toLocaleString('en-US');
    
        // Add dollar symbol at the beginning
        return `$${formattedValue}`;
    }
    

    function addNumeric(value){
        const numberRegex = /^[0-9]+$/;
        //let value = e.target.value;
        let newValue = "";
        
        for(let i = 0; i< value?.length ; i++){
            if(!numberRegex.test(value[i])){
                newValue += "";
            }else{
                newValue += value[i];
            }
        }
        value = newValue;
        return value;
    }

    function addPercentage(value){
       
        // Check if the numeric value is a valid float
        const isValidFloat = /^-?\d*\.?\d*$/;

        let newValue = "";
        //const regex = /^[a-zA-Z\s]+$/;
        for(let i = 0; i< value?.length ; i++){
            if(!isValidFloat.test(value[i])){
                newValue += "";
            }else{
                newValue += value[i];
            }
        }
        value = newValue;
       

        return value;
    }


    const handleChange = (e) => {
        // let name = name;
        // let value = value;
        // let updatedValue = value;
        const { name, value } = e.target;
        let nameInput = name;
        let valueInput = value;
        
        let textareaInputs = ['executionstrategysummary','underwritingsummary','originatorinstructions']
        let numericInputs = ['yearbuilt','bd/ba','statedcreditscore','investmentproperties','zipcode','totalbedrooms','totalbathrooms',
            'lotsqft','finishedsqft'
        ];

        if(textareaInputs?.includes(nameInput)  && value !== ''){
            valueInput = value;
        }

        if(numericInputs.includes(nameInput) && value !== ''){
            const callNumeric = addNumeric(valueInput);
            valueInput = callNumeric;
        }


        // let floatInputs = ['price','liquidcashreserve','purchaseprice',
        //     'renovationcost','brokerprocessingfee','docfee','purchaseprice','cashreverserequired',
        //     'easystreetaiv','easystreetarv', 'valuationfee', 'monthlyrentalrate','existingloan', 'floodinsurence','renovationarv'
            
        // ];

        // if (floatInputs.includes(name) && value !== '') {
        //     const callCurrency = addCurrency(value);
        //     value = callCurrency;
        // }

        let toggleInputs = ["floodinsurencevalue","cashreverserequiredvalue","overridecalculationfee"];

        if(toggleInputs?.includes(nameInput) && e.target.type === "checkbox"){
            valueInput = e.target.checked;
            // if(nameInput === "overridecalculationfee" && valueInput === false){
            //     setCalculationBool(!calculationBool);
            // }
        }

        const perecentageInputs = [ 'maxltc', 'maxltv', 'interestrate','points','brokerpoints'];

        if(perecentageInputs?.includes(nameInput) && value !== ''){
            const callPercentage = addPercentage(value);
            valueInput = callPercentage;
        }

        const abouttheborrower = [
            //'applicantid',
            'borrowerfirstname',
            'borrowerlastname',
            'borroweremail',
            'borrowerphone',
            'statedcreditscore',
            'liquidcashreserve',
            'borroweroccupation',
            'investmentproperties',
            'cashreverserequired',
            'cashreverserequiredvalue',
            'applicationlicense',
            
        ];

        
        
        const entity = [
            //'entityid',
            'legalname'
        ];
        
        const abouttheproperty = [
            //'propertyid',
            'propertytype',
            'propertyaddress',
            'apt_unit',
            'propertycity',
            'propertystate',
            'propertyzipcode',
            'totalbedrooms',
            'totalbathrooms',
            'haspool',
            'lotsqft',
            'finishedsqft',
            'occupancy',
            'purchaseprice',
            'easystreetaiv',
            'renovationcost',
            'easystreetarv',
            'renovationarv',
            'maxltc',
            'maxltv',
            'points',
            
            
            //'amortizationterm',
            'valuationfee',
            'exitstrategy',
            'monthlyrentalrate',
            'downpaymentsource',
            'floodinsurence',
            'floodinsurencevalue',
            'debtservicecoverageratio',
           
            'executionstrategysummary',
            'lenderfundedrehab'
        ];
        
        const fee = [
            //'brokerid',
            'brokerpoints',
            'brokerprocessingfee',
            
        ];
        
        
      
        // const noSection = [
        //     'loanid',
        //     'programtype',
        //     'loanfor',
        //     'existingloan',
        //     // Add other keys that do not belong to any specific section
        // ];
        
        
        
        // Add other sections as needed
        
    
        // if(section){
        //     setUnderWritingData(prevState => ({
        //         ...prevState,
        //         [section]: {
        //             ...prevState[section],
        //             [name]: value
        //         }
        //     }));
        // }else{
        //     setUnderWritingData({
        //         ...underWritingData,
        //             [name]:value   
        //     });
        // }

        if (abouttheborrower?.includes(name)) {
            setUnderWritingData(prevState => ({
                ...prevState,
                abouttheborrower: {
                    ...prevState.abouttheborrower,
                    [name]: valueInput
                }
            }));
        } else if (entity.includes(name)) {
            setUnderWritingData(prevState => ({
                ...prevState,
                entity: {
                    ...prevState.entity,
                    [name]: valueInput
                }
            }));
        } else if (abouttheproperty.includes(name)) {
            setUnderWritingData(prevState => ({
                ...prevState,
                abouttheproperty: {
                    ...prevState.abouttheproperty,
                    [name]: valueInput
                }
            }));
        } else if (fee.includes(name)) {
            setUnderWritingData(prevState => ({
                ...prevState,
                fee: {
                    ...prevState.fee,
                    [name]: valueInput
                }
            }));
        } 
        else{
            setUnderWritingData(prevState => ({
                ...prevState,
                [name]: valueInput
            }));
        }
        // else if (noSection.includes(name)) {
        //     setUnderWritingData(prevState => ({
        //         ...prevState,
        //         [name]: value
        //     }));
        // }
        

        
    };

    const handleCurrencyChange = (name,value,index) => {
       
            const abouttheborrower = [
                //'applicantid',
                'borrowerfirstname',
                'borrowerlastname',
                'borroweremail',
                'borrowerphone',
                'statedcreditscore',
                'liquidcashreserve',
                'borroweroccupation',
                'investmentproperties',
                'cashreverserequired',
                'cashreverserequiredvalue',
                'applicationlicense'
            ];
    
            const entity = [
                //'entityid',
                'legalname'
            ];
            
            const abouttheproperty = [
                //'propertyid',
                'propertytype',
                'propertyaddress',
                'apt_unit',
                'propertycity',
                'propertystate',
                'propertyzipcode',
                'totalbedrooms',
                'totalbathrooms',
                'haspool',
                'lotsqft',
                'finishedsqft',
                'occupancy',
                'purchaseprice',
                'easystreetaiv',
                'renovationcost',
                'easystreetarv',
                'renovationarv',
                'maxltc',
                'maxltv',
                'points',
                //'amortizationterm',
                'valuationfee',
                'exitstrategy',
                'monthlyrentalrate',
                'downpaymentsource',
                'floodinsurence',
                'floodinsurencevalue',
               
                'existingloan'
            ];
            
            const fee = [
                //'brokerid',
                'brokerpoints',
                'brokerprocessingfee',
                
            ];
            
        
            value = (isNaN(value?.replace('$','')) ? '' : value);
            if (abouttheborrower?.includes(name)) {
                
                setUnderWritingData(prevState => ({
                    ...prevState,
                    abouttheborrower: {
                        ...prevState.abouttheborrower,
                        [name]: value
                    }
                }));
            } else if (entity.includes(name)) {
                setUnderWritingData(prevState => ({
                    ...prevState,
                    entity: {
                        ...prevState.entity,
                        [name]: value
                    }
                }));
            } else if (abouttheproperty.includes(name)) {
                // if(!underwritingFormulas[name]){
                //     setUnderWritingData({...underwritingFormulas,[name] : ''});
                // }else{
                    setUnderWritingData(prevState => ({
                        ...prevState,
                        abouttheproperty: {
                            ...prevState.abouttheproperty,
                            [name]: value
                        }
                    }));
                // }
                 
            } else if (fee.includes(name)) {
                setUnderWritingData(prevState => ({
                    ...prevState,
                    fee: {
                        ...prevState.fee,
                        [name]: value
                    }
                }));
            }else if(name === "price"){
                setUnderWritingData(prevData => ({
                    ...prevData,
                    propertycomparisons: prevData.propertycomparisons.map((row, rowIndex) => {
                        if (rowIndex === index) {
                            return {
                                ...row,
                                [name]: value
                            };
                        }
                        return row;
                    })
                }));
            }
            else{
                setUnderWritingData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
           
            
        
            
        
    }

    

    const handleInputChange = (index, fieldName, value) => {
        // let value = value;
        let numericInputs = ['yearbuilt','bd/ba'];
        let floatInputs = ['price'];
        const numberAndFloatRegex = /^[0-9]*\.?[0-9]*$/;
        const numberRegex = /^[0-9]+$/;

        // if (floatInputs.includes(fieldName) && value !== '') {
            
        //     if(value.startsWith('$')){
        //         let replace = value.replace(/\$/g,'');
        //         value = (numberAndFloatRegex.test(replace)) ? '$' + replace : '';
        //     }else{
        //         value = (numberAndFloatRegex.test(value)) ? '$' + value : '';
        //     }
        //     // updatedValue = parseFloat(value);
        // }
        
        if(numericInputs.includes(fieldName) && value !== ''){
            if(numberRegex.test(value)){
                value = parseInt(value);
            }else{
                value = '';
            }
            // updatedValue = parseInt(value);
        }

        setUnderWritingData(prevData => ({
            ...prevData,
            propertycomparisons: prevData.propertycomparisons.map((row, rowIndex) => {
                if (rowIndex === index) {
                    return {
                        ...row,
                        [fieldName]: value
                    };
                }
                return row;
            })
        }));

        
        
    
        // Update the state with the new value
        // const newData = [...underWritingData.propertycomparisons];
        // newData[index][column] = value;
        // setUnderWritingData({ ...underWritingData, propertycomparisons: newData });
        // let numericInputs = ['sf','price','yearbuilt'];
        // if(numericInputs.includes(column)){
        //     const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
        //     updatedValue = isNaN(parsedValue) ? '' : parsedValue;
        //     const newData = [...underWritingData.propertycomparisons];
        //     newData[index][column] = updatedValue;
        //     setUnderWritingData({ ...underWritingData, propertycomparisons: newData });

        // }
        // else{
        //     const newData = [...underWritingData.propertycomparisons];
        //     newData[index][column] = value;
        //     setUnderWritingData({ ...underWritingData, propertycomparisons: newData });
        // }
       
    };

    const handleLocationSearchChange = (index, place) => {
        // Update state based on the index
        // For example:
       
        const updatedPropertyComparisons = [...underWritingData.propertycomparisons];
        updatedPropertyComparisons[index].address = place;
        setUnderWritingData(prevState => ({
            ...prevState,
            propertycomparisons: updatedPropertyComparisons
        }));
      
    };
    

   
    const getNonEmptyObjects = () => {
        return underWritingData.propertycomparisons.filter(row => {
            return Object.values(row).some(value => {
                const trimmedValue = typeof value === 'string' ? value.trim() : value;
                return trimmedValue !== ''; // Check if the trimmed value is not empty
            });
        });
    };
    

    const addRows = () => {
        setUnderWritingData((prevData) => {
            const filledObjects = prevData.propertycomparisons.filter(row => Object.values(row).some(value => value !== ""));
            const emptyObjectsCount = 4 - filledObjects.length;
            const emptyObjects = Array.from({ length: emptyObjectsCount }, () => ({
                address: "",
                price: "",
                "bd/ba": "",
                sf: "",
                yearbuilt: "",
                comparisonnarrative: ""
            }));
            return {
                ...prevData,
                propertycomparisons: filledObjects.concat(emptyObjects)
            };
        });
    };


    const handleSave =()=>{

        handleValidation();

    
        //const nonEmptyObjects = getNonEmptyObjects();

        // let apiData = {...underWritingData,"propertycomparisons" : nonEmptyObjects}
        
        

        if(Object?.keys(errors)?.length === 0){

            
            

            let floatInputs = ['price','liquidcashreserve','purchaseprice','loanamount',
            'renovationcost','brokerprocessingfee','docfee','purchaseprice','cashreverserequired',
            'easystreetaiv','easystreetarv', 'valuationfee', 'monthlyrentalrate','existingloan', 'floodinsurence','borrowerarv',
            'initialequity','initialfunding','rehabequity','rehabfunding', 'maxmonthlyloanpayment', 'interestreserveamount', 'cashtoclose', 'insuranceamount', 'originationFee',  'escrow','titleAmount', 'propertyInsurance', 'drawFee'
            
    
        ];

        let numericInputs = ['statedcreditscore','investmentproperties','zipcode','totalbathrooms','totalbedrooms',
            'lotsqft','finishedsqft','amortizationterm','loanterm'
        ];

        let perecentageInputs = [ 'maxltc', 'maxltv', 'interestrate','points','brokerpoints','interestrate','title'];
        let bools = ['usewhitelabel'];

        let updatedUnderWritingData =  { ...underWritingData};

        // if(underWritingData?.overridecalculationfee === true){
        //     updatedUnderWritingData = { ...underWritingData,
        //         abouttheproperty: {
        //             ...underWritingData.abouttheproperty,
        //             ["loanamount"]: underwritingFormulas?.loanamount
        //         }
    
        //     };
        // }
        

            floatInputs.forEach(input => {
                if (input !== "price" && updatedUnderWritingData.abouttheborrower[input] && typeof updatedUnderWritingData.abouttheborrower[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheborrower[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.abouttheborrower[input] = parseInt(modifiedValue);
                } else if (input !== "price" && updatedUnderWritingData.abouttheproperty[input]  && typeof updatedUnderWritingData.abouttheproperty[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheproperty[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.abouttheproperty[input] = parseInt(modifiedValue);
                   
                    //console.log("Nan",input,updatedUnderWritingData.abouttheproperty[input])
                } else if (input !== "price" && updatedUnderWritingData.fee[input]&& typeof updatedUnderWritingData.fee[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.fee[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.fee[input] = parseInt(modifiedValue);
                } 
                // else if (input === "price" && updatedUnderWritingData[input] && updatedUnderWritingData.abouttheproperty[input] !== "$" && typeof updatedUnderWritingData[input] === 'string') {
                //     updatedUnderWritingData.propertycomparisons.forEach((comparison, index) => {
                //         if (comparison.price !== undefined && typeof comparison.price === 'string') {
                //             const modifiedPrice = comparison.price.replace(/\$|,/g, '');
                //             const parsedPrice = parseInt(modifiedPrice);
                //             //if (!isNaN(parsedPrice)) {
                //                 comparison.price = parsedPrice;
                //             //}
                //         }
                //     });
                // }
                // else if (
                //     input === "price" && // Check if the input is 'price'
                //     updatedUnderWritingData[input] && // Ensure updatedUnderWritingData[input] is defined
                //     updatedUnderWritingData.abouttheproperty[input] !== "$" && // Ensure the input is not just a dollar sign
                //     typeof updatedUnderWritingData[input] === 'string' // Check if the input value is a string
                // ) {
                //     updatedUnderWritingData.propertycomparisons.forEach((comparison, index) => {
                //         if (comparison.price !== undefined && typeof comparison.price === 'string') {
                //             // Remove dollar sign and commas from the price string
                //             const modifiedPrice = comparison.price.replace(/\$|,/g, '');
                //             // Parse the modified price as an integer
                //             const parsedPrice = parseInt(modifiedPrice);
                //             if (!isNaN(parsedPrice)) { // Check if parsedPrice is a valid number
                //                 comparison.price = parsedPrice; // Update the comparison price
                //             }
                //         }
                //     });
                // }
                
                else if (input !== "price" && updatedUnderWritingData[input]  && typeof updatedUnderWritingData[input] === 'string') {
                    const propertyValue = updatedUnderWritingData[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData[input] = parseInt(modifiedValue);
                   
                    //console.log("Nan",input,updatedUnderWritingData.abouttheproperty[input])
                }
                
            });

            numericInputs.forEach(input => {
                if (input !== "yearbuilt" && updatedUnderWritingData.abouttheborrower[input] && typeof updatedUnderWritingData.abouttheborrower[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheborrower[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.abouttheborrower[input] = parseInt(modifiedValue);
                } else if (updatedUnderWritingData.abouttheproperty[input] && typeof updatedUnderWritingData.abouttheproperty[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheproperty[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.abouttheproperty[input] = parseInt(modifiedValue);
                } else if (updatedUnderWritingData.fee[input] && typeof updatedUnderWritingData.fee[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.fee[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData.fee[input] = parseInt(modifiedValue);
                } else if (updatedUnderWritingData[input] && typeof updatedUnderWritingData[input] === 'string') {
                    const propertyValue = updatedUnderWritingData[input];
                    const modifiedValue = propertyValue.replace(/\$|,/g, '');
                    updatedUnderWritingData[input] = parseInt(modifiedValue);
                }
            });

            updatedUnderWritingData.propertycomparisons.forEach(comparison => {
                if (comparison.yearbuilt && typeof comparison.yearbuilt === 'string') {
                    const modifiedValue = comparison.yearbuilt.replace(/\$|,/g, '');
                    comparison.yearbuilt = parseInt(modifiedValue);
                }else if(comparison['bd/ba'] && typeof comparison['bd/ba'] === 'string'){
                    const modifiedValue = comparison['bd/ba'].replace(/\$|,/g, '');
                    comparison['bd/ba'] = parseInt(modifiedValue);
                }else if(comparison.sf && typeof comparison?.sf === 'string'){
                    const modifiedValue = comparison.sf.replace(/\$|,/g, '');
                    comparison.sf = parseInt(modifiedValue);
                }else if (
                    comparison?.price && 
                    typeof comparison?.price === 'string' // Check if the input value is a string
                ) {
                    
                        const modifiedPrice = comparison.price.replace(/\$|,/g, '');
                        // Parse the modified price as an integer
                        const parsedPrice = parseFloat(modifiedPrice);
                        if (!isNaN(parsedPrice)) { // Check if parsedPrice is a valid number
                            comparison.price = parsedPrice; // Update the comparison price
                        }
                    
                }
                
            });

            // perecentageInputs.forEach(input => {
            //     if (updatedUnderWritingData.abouttheborrower[input] && typeof updatedUnderWritingData.abouttheborrower[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData.abouttheborrower[input];
            //         const modifiedValue = propertyValue.replace('%', '');
            //         updatedUnderWritingData.abouttheborrower[input] = parseFloat(modifiedValue);
            //     } else if (updatedUnderWritingData.abouttheproperty[input] && typeof updatedUnderWritingData.abouttheproperty[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData.abouttheproperty[input];
            //         const modifiedValue = propertyValue.replace('%', '');
            //         updatedUnderWritingData.abouttheproperty[input] = parseFloat(modifiedValue);
            //     } else if (updatedUnderWritingData.fee[input] && typeof updatedUnderWritingData.fee[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData.fee[input];
            //         const modifiedValue = propertyValue.replace('%', '');
            //         updatedUnderWritingData.fee[input] = parseFloat(modifiedValue);
            //     } else if (updatedUnderWritingData[input] && typeof updatedUnderWritingData[input] === 'string') {
            //         const propertyValue = updatedUnderWritingData[input];
            //         const modifiedValue = propertyValue.replace('%', '');
            //         updatedUnderWritingData[input] = parseFloat(modifiedValue);
            //     }
            // });
            perecentageInputs.forEach(input => {
                if (updatedUnderWritingData.abouttheborrower[input] && typeof updatedUnderWritingData.abouttheborrower[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheborrower[input];
                    const modifiedValue = propertyValue.replace('%', '');
                    updatedUnderWritingData.abouttheborrower[input] = parseFloat(modifiedValue);
                } else if (updatedUnderWritingData.abouttheproperty[input] && typeof updatedUnderWritingData.abouttheproperty[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.abouttheproperty[input];
                    const modifiedValue = propertyValue.replace('%', '');
                    updatedUnderWritingData.abouttheproperty[input] = parseFloat(modifiedValue);
                } else if (updatedUnderWritingData.fee[input] && typeof updatedUnderWritingData.fee[input] === 'string') {
                    const propertyValue = updatedUnderWritingData.fee[input];
                    const modifiedValue = propertyValue.replace('%', '');
                    updatedUnderWritingData.fee[input] = parseFloat(modifiedValue);
                } else if (typeof updatedUnderWritingData[input] === 'string') { // Check if the value is a string
                    const propertyValue = updatedUnderWritingData[input].toString(); // Convert to string
                    const modifiedValue = propertyValue.replace('%', '');
                    updatedUnderWritingData[input] = parseFloat(modifiedValue);
                }
            });
            

            //handle booleans
            bools.forEach(input => {
                //if (updatedUnderWritingData[input] && typeof updatedUnderWritingData[input] === 'string') {
                        
                    if(input === "usewhitelabel"){
                        updatedUnderWritingData[input] = true;
                    }
                    // if(updatedUnderWritingData[input] === "true"){
                    //     updatedUnderWritingData[input] = true;
                    // }else if(updatedUnderWritingData[input] === "false"){
                    //     updatedUnderWritingData[input] = false;
                    // }
                //}
            });


            dispatch(showLoader());
            underwritingPipelineUpdate(updatedUnderWritingData).then(function (response) {
                if (response) {
                   
                   
                    
                   setPopupOpen(true);
                   getUnderwritingData();
                   //setMessage("Data saved succesfully");
                  
                   dispatch(hideLoader());
                  
                   
                }
            }).catch((err) => {
                  console.error.bind("my account error", err);
                  dispatch(hideLoader());
            })

        }

       
    };

    // const addErrorClass = (field) => {
    //     const inputElement = document.querySelector(`[name=${field}]`);
    //     if (inputElement) {
    //       inputElement.classList.add('invalid-input');
    //     }
    // };

    const handleValidation = () => {

        propertycomparisonsFieldsToCheck = {};
        // borrowerFieldsToCheck= {};
        // propertyFieldsToCheck = {};
        errors = {};

        const allowedBorrowerKeys = ['borrowerfirstname','borrowerlastname','borrowerlastname','borrowerphone',
            'statedcreditscore','borroweroccupation','legalname'];
        
        

        allowedBorrowerKeys.forEach(key => {
            if(underWritingData?.abouttheborrower?.hasOwnProperty(key) && (underWritingData?.abouttheborrower[key] === '' || underWritingData?.abouttheborrower[key] === undefined)){
                errors[key] = "required";
            }
        })

        const allowedPropertyKeys = ['propertytype','propertyaddress','propertycity','propertystate','propertyzipcode','totalbedrooms','totalbathrooms',
            'haspool','lotsqft']; 

        allowedPropertyKeys.forEach(key => {
            // Check if the key exists in underWritingData.abouttheproperty and if the value is not empty
            if (underWritingData.abouttheproperty.hasOwnProperty(key) && (underWritingData.abouttheproperty[key] === "" || underWritingData.abouttheproperty[key] === undefined)) {
                errors[key] = "required";
            }
        });

        setUnderwritingErrors(errors);

        // underWritingData?.propertycomparisons?.forEach((row,index) => {
        //     if( !row["address"] && (row["price"] !== "" || row["bd/ba"] !== ""
        //     || row["sf"] !== "" || row["yearbuilt"] !== ""
        //     || row["comparisonnarrative"])){
    
        //         propertycomparisonsFieldsToCheck[index] = "required";
        //         // alert("empty")
        //     }
        // })
        // console.log("comparision errors---->",propertycomparisonsFieldsToCheck);
        // setComparisionsErrors(propertycomparisonsFieldsToCheck);
        
        // alert(propertycomparisonsFieldsToCheck);
        // setBorrowerErrors(borrowerFieldsToCheck);
        // setPropertyErrors(propertyFieldsToCheck);
        
        
        
        
    };

    const handleRemoveErrors = (num) => {
        propertycomparisonsFieldsToCheck[num] = '';
        setComparisionsErrors(propertycomparisonsFieldsToCheck);
    }
    // const validateFields = (data, fieldsToCheck, errorsSetter) => {
    //     const errors = {};
    //     const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    
    //     for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
    //         const fieldValue = data[fieldName];
    //         if (fieldName === 'borroweremail' && typeof fieldValue === 'string') {
    //             if (!fieldValue.trim()) {
    //                 errors[fieldName] = `Please Enter ${fieldLabel}`;
    //             } else if (!emailRegex.test(fieldValue.trim())) {
    //                 errors[fieldName] = `Please Enter a Valid Email for ${fieldLabel}`;
    //             }
    //         } else if (typeof fieldValue === 'string') {
    //             if (!fieldValue.trim()) {
    //                 errors[fieldName] = `Please Enter ${fieldLabel}`;
    //             }
    //         } else if (typeof fieldValue === 'number') {
    //             if (fieldValue === null || fieldValue === undefined || isNaN(fieldValue)) {
    //                 errors[fieldName] = `Please Enter ${fieldLabel}`;
    //             }
    //         } else {
    //             // Handle other types if needed
    //         }
    //     }
    //     errorsSetter(errors);
    //     // console.log('errors---->',errors);
    //     return Object.keys(errors).length === 0;
    // };
    

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
    const closePopup = () => {
        setPopupOpen(false);
    };

    const [htmlContent, setHtmlContent] = useState('');
    const [Content, setContent] = useState('');
    const [values, setValues] = useState({});
    
    // useEffect(() => {
    //     getTermSheets().then(function (response) {
    //         if (response?.result) {
    //             console.log('programtype', loanInfo.programtype, response?.result[5]?.programtype);
                
    //             const programTypeToFind = loanInfo.programtype; 
    //             const matchingObject = response?.result.find(item => item.programtype === programTypeToFind);

    //             if (matchingObject) {
    //                 console.log("Matching object:", matchingObject);
    //                 setContent(matchingObject)
    //             } else {
    //                 console.log("No matching object found for programtype:", programTypeToFind);
    //             }
    //         }
    //           dispatch(hideLoader());
    //     }).catch((err) => {
    //         dispatch(hideLoader());
    //         console.error.bind("my account error", err);
    //     })
    // }, []);

    const generatePDF = () => {
      // HTML content with placeholders
     
  
      // Object containing values to replace placeholders
      const vals = {
        'company.logo250x60': 'Company Logo',
        'date': '2024-04-12', // Example date value
        'termsheet.full_address': 'kakinada here',
        'termsheet.existing_loan_amount':'$2024'
        // Add more key-value pairs for other placeholders
      };
      // Generate PDF with replaced values
      generatePDFWithValues(Content, vals);
    }
  
    const generatePDFWithValues = (content, vals) => {
        console.log('loanInfoloanid',loanInfo, loanid);
        getTermSheetPDF(loanid).then(function (response) {
            if (response?.result) {
              // Replace placeholders with actual values
        //const replacedHTML = replacePlaceholders(content, vals);
        const replacedHTML = response?.result
        // Create new jsPDF instance with custom width
        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'px', // Units are in pixels
          format: [1600, 2000] // Custom width and height
        });
      
        // Convert HTML content to PDF
        doc.html(replacedHTML, {
          x: 200, // Horizontal position (in pixels)
          y: 200, // Vertical position (in pixels)
          callback: () => {
            // Save PDF file after rendering is complete
            doc.save('generated-pdf.pdf');
          }
        });
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
       
      }
  
    const replacePlaceholders = (content, vals) => {
      let replacedContent = content.replace(/<p([^>]*)>/g, '<p style="white-space: nowrap;"$1>')
      .replace(/<span([^>]*)>/g, '<span style="white-space: nowrap;"$1>');
      const regex = /{([^}]+)}/g;
      let match;
      
      while ((match = regex.exec(content)) !== null) {
        const placeholder = match[0];
        const key = match[1];
        const cleanedKeyName = key.includes("termsheet.") ? key.split('.')[1] : key;
        const value = getValueByKey(underWritingData, cleanedKeyName);
        
        if (value) {
          replacedContent = replacedContent.replace(placeholder, value);
        }
      }
      return replacedContent;
    }
    const getValueByKey = (obj, keyToFind) => {
        for (const key in obj) {
            if (key === keyToFind) {
                return obj[key];
            }
            if (typeof obj[key] === "object" && obj[key] !== null) {
                const result = getValueByKey(obj[key], keyToFind);
                if (result !== undefined) {
                    return result;
                }
            }
        }
    }
console.log('underWritingData',underWritingData);
    return (
        <div id="layout-wrapper" className="dashboard">
             {loading ? <Loader/> : null} 
             {isPopupOpen && (
            <PopupMessage
             type={PopupMessage === 'Fields are empty' ? 'warning' : 'success'}
             message={'Saved Successfully'}
             onClose={closePopup}
            />
        )}
           {/* <PipelineHeader /> */}
            <div className="dashboard_block calculator_block mt-0">
            {/* <PipelineStatus /> */}
                {/* <h2 className="form_title">Underwriting <span className="space">&#10095;</span> <span className="sub">Underwriting
                    Term sheet</span> </h2> */}
            <div className="card user_management detailed_app underwriting_termsheet">
                <div className="card-body my_account" id="AboutTheBorrower">
                    <h3 className='mb_20'>Calculator</h3>
                    <button className='page_close'><span className="material-symbols-outlined"> close </span></button>
                    {/* <div className='search top-fix'>
                        <a href="#AboutTheBorrower" onClick={(e) => {e.preventDefault();scrollToSection("abouttheborrower")}} className="create_btn">About The Borrower</a>
                        <a href="#abouttheproperty" onClick={(e) => {e.preventDefault();scrollToSection("abouttheproperty")}} className="create_btn">About The Property</a>
                        <a href="#aboutthedeal" onClick={(e) => {e.preventDefault();scrollToSection("aboutthedeal")}}  className="create_btn">About The Deal</a>
                        <a href="#Calculations" onClick={(e) => {e.preventDefault();scrollToSection("calculations")}} className="create_btn">Calculations</a>
                    </div> */}
                    
                    <div className="form_section">
                        
                        {dealUnderwritingJson.map(section => (
                            <div className='row' id={Object.keys(section)[0]}>
                                {/* <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="title">{
                                                                            // Object.keys(section)[0] == 'abouttheborrower' ? 'About the borrower': 
                                                                            // Object.keys(section)[0] == 'abouttheproperty' ? 'About the property':
                                                                            // Object.keys(section)[0] == 'aboutthedeal' ? 'About the deal':
                                                                            // Object.keys(section)[0] == 'fee' ? 'Fee':
                                                                            Object.keys(section)[0] == 'calculations' ? 'Calculations':''
                                                                        }</h4>
                                </div> */}
                                
                                {section[Object.keys(section)[0]].map(field => (
                                        <>
                                        
                                            
                                           
                                           <div className= {field?.name === "overridecalculationfee" ? "col-md-12" : "col-md-3"}>
                                                
                                                
                                                    
                                                <div className={`form-group ${field?.name === "floodinsurencevalue" ? "mt-1" : ""}`}>
                                                    
                                                
                                                    {
                                                        (field?.checkboxlabel) && (
                                                            <>
                                                                <label className="toggle mb-0">
                                                                    <span className="toggle-label">{field?.checkboxlabel}</span>
                                                                    <input 
                                                                        onChange={handleChange}
                                                                        checked={field?.checkboxlabelname === "cashreverserequiredvalue" ? underWritingData?.abouttheproperty?.[field?.checkboxlabelname] : ''} 
                                                                        name={field?.checkboxlabelname} 
                                                                        className="toggle-checkbox" 
                                                                        type="checkbox"
                                                                        
                                                                    />
                                                                    <div className="toggle-switch"></div>
                                                                </label> 
                                                            </>
                                                            
                                                        )
                                                    }
                                                
                                                    {
                                                         (field?.type === "checkbox") && (
                                                            <>
                                                                <label className="toggle mb-3">
                                                                    <span className="toggle-label">{field?.label}</span>
                                                                    <input className="toggle-checkbox" 
                                                                    checked={
                                                                        (field?.name === 'floodinsurencevalue' ? underWritingData?.abouttheproperty?.[field?.name] : underWritingData?.overridecalculationfee)
                                                                        
                                                                    } 
                                                                    name={field?.name} onChange={handleChange} type="checkbox" />
                                                                    <div className="toggle-switch"></div>
                                                                </label>
                                                            </>
                                                           
                                                        )
                                                    }
                                                    
                                                    {
                                                        (field.type === 'select') ? (
                                                            <>
                                                                <label>{field.label}</label>
                                                                <select name={field?.name} onChange={handleChange}
                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                    value={
                                                                        (field?.name === "loanfor" || field?.name === "programtype" || field?.name === "drawinteresttype" || field?.name === "loanterm" || field?.name === "interestreserves" || field?.name === "creditreport" || field?.name === "appraisal") ?
                                                                            underWritingData?.[field?.name] :
                                                                        (field?.name === "lenderfundedrehab" || field?.name === "exitstrategy") ?
                                                                            underWritingData?.abouttheproperty?.[field?.name] :
                                                                        
                                                                        underWritingData?.[Object.keys(section)[0]]?.[field?.name]
                                                                    }

                                                                >
                                                                    {field.name !== "propertystate" ? 
                                                                        field.options.map(option => (
                                                                            <option value={option.value}>{option.label}</option>
                                                                        )) : 
                                                                        <>
                                                                            <option value=''>Select</option>
                                                                            {states.map((key,index) => (<option value={key}>{key}</option>))}
                                                                        </>
                                                                    }
                                                                </select>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {(field?.type === "text"  && field?.name === "emptyinput" &&
                                                                    <div className={field?.name}>
                                                                        
                                                                    </div>
                                                                    
                                                                )}
                                   
                                                                {(field?.type === "text"  && field?.name !== "emptyinput" &&
                                                                
                                                                    <>
                                                                       { (field?.checkboxlabel) ? '' :  <label>{field?.label}</label>}
                                                                        {floatJsonInputs?.map(ele => (
                                                                            (ele === field?.name) ? 
                                                                            <>
                                                                                <CurrencyInput 
                                                                                    onBlur={()=>{setIsFocused(false); setLastFieldtName(field?.name)}}
                                                                                    style = {{ 'backgroundColor' : 
                                                                                        
                                                                                        (underWritingData?.overridecalculationfee === false &&
                                                                                        (field?.name === 'loanamount' || field?.name === 'initialfunding' || field?.name === 'initialequity' ||
                                                                                        field?.name === 'rehabfunding' || field?.name === 'rehabequity'   || field?.name === 'interestreserveamount'))
                                                                                        ? '#e9ecef' 
                                                                                        :
                                                                                        ( (underWritingData?.overridecalculationfee === true || underWritingData?.overridecalculationfee === false) &&
                                                                                            (field?.name === 'totalcost' || field?.name === 'maxmonthlyloanpayment' || field?.name === 'cashtoclose'
                                                                                           
                                                                                            ))
                                                                                            ? '#e9ecef'
                                                                                        : 
                                                                                        (underWritingData?.overridecalculationfee === true &&
                                                                                            (field?.name === 'loanamount' || field?.name === 'initialfunding' || field?.name === 'initialequity' ||
                                                                                            field?.name === 'rehabfunding' || field?.name === 'rehabequity'   || field?.name === 'interestreserveamount'))
                                                                                            ? '' 
                                                                                            :
                                                                                            (
                                                                                                
                                                                                                field?.name === 'cashreverserequired'
                                                                                            ) ? '#e9ecef' :
                                                                                            (
                                                                                                (!underWritingData?.abouttheproperty?.floodinsurencevalue || underWritingData?.abouttheproperty?.floodinsurencevalue === undefined) &&
                                                                                                (field?.name === 'floodinsurence')
                                                                                            ) ? '#e9ecef' :
                                                                                        ''
                                                                                    }}
                                                                                    title={field?.formula}
                                                                                    readOnly={
                                                                                        
                                                                                        (
                                                                                            underWritingData?.overridecalculationfee === false &&
                                                                                            (
                                                                                                field?.name === 'loanamount' || field?.name === 'initialfunding' || 
                                                                                                field?.name === 'initialequity' || field?.name === 'rehabfunding' || 
                                                                                                field?.name === 'rehabequity' || field?.name === 'interestreserveamount'
                                                                                            )
                                                                                        ) ? true :
                                                                                       
                                                                                        (
                                                                                            (underWritingData?.overridecalculationfee === true || underWritingData?.overridecalculationfee === false) &&
                                                                                            (
                                                                                                field?.name === 'totalcost' || field?.name === 'maxmonthlyloanpayment' || 
                                                                                                field?.name === 'cashtoclose'
                                                                                            )
                                                                                        ) ? true :
                                                                                      
                                                                                        (
                                                                                            underWritingData?.overridecalculationfee === true &&
                                                                                            (
                                                                                                field?.name === 'loanamount' || field?.name === 'initialfunding' || 
                                                                                                field?.name === 'initialequity' || field?.name === 'rehabfunding' || 
                                                                                                field?.name === 'rehabequity' ||  field?.name === 'interestreserveamount'
                                                                                            )
                                                                                        ) ? false :
                                                                                        
                                                                                        (
                                                                                            (underWritingData?.abouttheborrower?.cashreverserequiredvalue === true || 
                                                                                             underWritingData?.abouttheborrower?.cashreverserequiredvalue === false) &&
                                                                                            (field?.name === 'cashreverserequired')
                                                                                        ) ? true :
                                                                                       
                                                                                        // (
                                                                                        //     field?.name === 'floodinsurence' && underWritingData?.abouttheproperty?.floodinsurencevalue === false
                                                                                            
                                                                                        // ) ? true :

                                                                                        false
                                                                                    }
                                                                                    disabled={
                                                                                        (field?.name === 'cashreverserequired') || (field?.name === 'floodinsurence' && (!underWritingData?.abouttheproperty?.floodinsurencevalue || underWritingData?.abouttheproperty?.floodinsurencevalue === undefined)) }
                                                                                    
                                                                                    name={field?.name}
                                                                                    //   defaultValue={1000}
                                                                                
                                                                                    decimalsLimit={2}
                                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} 
                                                                                    onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null });setIsFocused(true) }}
                                                                                    placeholder="$0.00"
                                                                                    prefix="$"
                                                                                    groupSeparator=","
                                                                            
                                                                                    // onValueChange={(value, name, values) => {
                                                                                    //     console.log(values)
                                                                                    //     handleCurrencyInputsLoan(name,value);
                                                                                    // }}
                                                                                    onValueChange={(value, name , values) => {
                                                                                        handleCurrencyChange(name,value)
                                                                                    }}
                                                                                    value={
                                                                                        ((field?.name === 'renovationcost' || field?.name === 'purchaseprice' || field?.name === 'downpaymentsource'
                                                                                            || field?.name === 'borrowerarv' || field?.name === 'occupancy' || field?.name === 'easystreetarv' || field?.name === 'easystreetaiv' || field?.name === 'valuationfee'
                                                                                            || field?.name === 'monthlyrentalrate' || field?.name === 'floodinsurence' || field?.name === 'existingloan') 
                                                                                            && floatJsonInputs.includes(field?.name)) ?
                                                                                            
                                                                                            (isNaN(underWritingData?.abouttheproperty?.[field?.name]) ? '' : underWritingData?.abouttheproperty?.[field?.name]) :
                                                                                    
                                                                                        ((field?.name === 'totalcost') && floatJsonInputs.includes(field?.name)) ?
                                                                                            
                                                                                            (isNaN(underwritingFormulas[field?.name]) ? '' : underwritingFormulas[field?.name]) :
                                                                                            
                                                                                        ((field?.name === 'brokerprocessingfee') && floatJsonInputs.includes(field?.name)) ? 
                                                                                            
                                                                                            (isNaN(underWritingData?.fee?.[field?.name]) ? '' : underWritingData?.fee?.[field?.name]) :
                                                                                    
                                                                                        ((field?.name === 'loanamount' || field?.name === 'docfee' || field?.name === 'initialequity'
                                                                                            || field?.name === 'initialfunding' || field?.name === 'rehabequity' || field?.name === 'rehabfunding' || field?.name === 'insuranceamount' || field?.name === 'interestreserveamount' || field?.name === 'maxmonthlyloanpayment' || field?.name === 'cashtoclose'|| field?.name === 'cashreverserequired' || field?.name === 'originationFee' || field?.name === 'escrow' || field?.name === 'titleAmount' || field?.name === 'propertyInsurance' || field?.name === 'drawFee') 
                                                                                            && floatJsonInputs.includes(field?.name)) ? 
                                                                                            
                                                                                            (isNaN(underWritingData?.[field?.name]) ? '' : underWritingData?.[field?.name]) :
                                                                                            
                                                                                        (isNaN(underWritingData?.[Object.keys(section)[0]]?.[field?.name]) ? '' : underWritingData?.[Object.keys(section)[0]]?.[field?.name])
                                                                                    }
                                                                                       
                                                                                />
                                                                            </> : ''
                                                                         ))}
                                                                         {!floatJsonInputs.includes(field?.name) && 
                                                                            <>
                                                                                <input 
                                                                                    type="text" name={field?.name} onChange={handleChange} placeholder={field.placeholder} 
                                                                                    className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                                    style = {{ 'backgroundColor' : 
                                                                                        
                                                                                        ( (underWritingData?.overridecalculationfee === true || underWritingData?.overridecalculationfee === false) &&
                                                                                        (field?.name === 'actualltv' || field?.name === 'actualltc' 
                                                                                        ))
                                                                                            ? '#e9ecef'
                                                                                        : ''
                                                                                    }}
                                                                                    readOnly={
                                                                                        
                                                                                        ( (underWritingData?.overridecalculationfee === true || underWritingData?.overridecalculationfee === false) &&
                                                                                            (field?.name === 'actualltv' || field?.name === 'actualltc' 
                                                                                            ))
                                                                                            ? true
                                                                                        : false
                                                                                    }
                                                                                    value={
                                                                                        (field?.name === "legalname") ? underWritingData?.entity?.[field?.name] :
                                                                                            
                                                                                        (field?.name === "downpaymentsource" || field?.name === "apt_unit" || field?.name === "occupancy" ||
                                                                                            field?.name === "maxltc" || field?.name === "maxltv" || field?.name === "points" || field?.name === "debtservicecoverageratio" || field?.name === "exitstrategy") ? 
                                                                                            
                                                                                            underWritingData?.abouttheproperty?.[field?.name] :
                                                                                        
                                                                                        (field?.name === "interestrate" || field?.name === "loanterm" || field?.name === "creditreport" || field?.name === "appraisal" || field?.name === "title") ? 
                                                                                           underWritingData?.[field?.name] :
                                                                                        
                                                                                        (field?.name === "actualltv" || field?.name === "actualltc" || field?.name === "title") ? 
                                                                                        (underwritingFormulas?.[field?.name] ? underwritingFormulas?.[field?.name] + '%':underWritingData?.[field?.name] + '%'):
                                                                                        
                                                                                        underWritingData?.[Object.keys(section)[0]]?.[field?.name]
                                                                                    }
                                                                                    
                                                                                /> 
                                                                            </>
                                                                                
                                                                        }   
                                                                       
                                                                         
                                                                        
                                                
                                                                    </>
                                                                        
                                                                    
                                                                )} 
                                                                
                                                                    
                                                                        
                                                                        
                                                                
                                                            </>
                                                        )
                                                    }
                                                    
                                                    {
                                                        field?.type === "textarea" && 
                                                        (
                                                            
                                                            <>
                                                                <label>{field.label}</label>
                                                                        <textarea 
                                                                            name={field?.name} 
                                                                            onChange={handleChange} 
                                                                            placeholder={field.placeholder} 
                                                                            cols={10}
                                                                            rows={5}
                                                                            className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} 
                                                                            onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                            value={field?.name === 'executionstrategysummary' ? underWritingData?.abouttheproperty?.[field?.name] :underWritingData[field?.name]}
                                                                        />
                                                            </>   
                                                                        

                                                                    
                                                                
                                                            
                                                        )
                                                    }

                                                   
                                                    
                                                    {field?.type === "tel" && 
                                                                    <>
                                                                        <label>{field?.label}</label>
                                                                        <input type="tel" name={field?.name} onChange={handleChange} placeholder={field.placeholder} 
                                                                        onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                        className={`form-control ${underwritingErrors?.[field?.name] && 'invalid-input'}`} onFocus={() => { setUnderwritingErrors({ ...underwritingErrors, [field?.name]: null }) }}
                                                                        value={(underWritingData?.[Object.keys(section)[0]]?.[field?.name]) } />
                                                                    </>
                                                                    

                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ))}

                                
                            </div>
                        
                        
                        ))}
                        {/* <hr>
                        </hr> */}
                     
                         
                       




                    

                        <div className="d-flex align-items-center justify-content-between mt-2 underwriting_buttons">
                            {/* <a href="#" onClick={(e)=>{e.preventDefault();}} className="fs_btn">RESET<span
                                    className="material-symbols-outlined">restart_alt</span></a> */}
                            {/* <div className="btn-group">
                                <a href="#" className="fs_btn" onClick={(e)=>{e.preventDefault(); calculateFormulas()}}>Calculate<span
                                        className="material-symbols-outlined">save</span></a>
                               
                            </div> */}

                        </div>
                    </div>


                </div>
            </div>
            </div>
            <PipelineFooter /> 
        </div>
    );
};

export default Calculator;